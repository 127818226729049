import { useState } from "react";

import { type ContractTransaction } from "@ethersproject/contracts";

import { endOfLegacyContract } from "~/config";
import { usePartyContext } from "~/contexts/PartyContext";
import { useTransactionContext } from "~/contexts/TransactionContext";
import { usePartyContract } from "~/hooks/party";
import { handleCustomError } from "~/modules/party/errors";
import {
  TransactionActions,
  type AnnouncementForm,
} from "~/modules/party/types";

import { usePlatformCheck } from "../platform";

const createAnnouncementSteps = [
  {
    label: "Create Announcement",
    content: "Confirm announcement creation action in your wallet",
  },
  {
    label: "Awaiting confirmation",
    content: "Please wait until the announcement creation action is confirmed",
    loading: true,
    showGame: true,
  },
];
const deleteAnnouncementSteps = [
  {
    label: "Delete Announcement",
    content: "Confirm announcement deletion action in your wallet",
  },
  {
    label: "Awaiting confirmation",
    content: "Please wait until the announcement deletion action is confirmed",
    loading: true,
    showGame: true,
  },
];

interface CreateAnnouncementHandlerParams {
  announcementForm: AnnouncementForm;
}

const usePartyAnnouncements = (partyAddress: string) => {
  const partyContract = usePartyContract(partyAddress);
  const { partyInfo } = usePartyContext();
  const { checkWallet } = usePlatformCheck();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    addTransaction,
    updateTransaction,
    openConfirmationWithSteps,
    closeConfirmationWithSteps,
    setStep,
    setErrorMsg,
  } = useTransactionContext();

  const isLegacyParty =
    !!partyInfo && partyInfo.inception < endOfLegacyContract;

  const createAnnouncement = async ({
    announcementForm,
  }: CreateAnnouncementHandlerParams) => {
    const account = checkWallet();
    if (!account || !partyContract) return false;
    setLoading(true);
    openConfirmationWithSteps(createAnnouncementSteps);

    let txHash = "";

    try {
      const isOwner = await partyContract.managers(account);
      if (!isOwner) {
        throw Error(`Only party owner is allowed to create announcements`);
      }

      // 1) Create Announcement
      let newPost: ContractTransaction;
      if (isLegacyParty) {
        /// @dev [PostMigration] Remove this function
        newPost = await partyContract.createPost(
          announcementForm.title,
          announcementForm.content,
          announcementForm.url || "",
          announcementForm.img,
        );
      } else {
        newPost = await partyContract.createAnnouncement(
          announcementForm.title,
          announcementForm.content,
          announcementForm.url || "",
          announcementForm.img,
        );
      }
      txHash = newPost.hash;
      addTransaction(TransactionActions.createAnnouncement, txHash);

      // 2) Get tx hash
      setStep(1);
      await newPost.wait();
      updateTransaction(txHash, true, true);
      txHash = "";

      setLoading(false);
      closeConfirmationWithSteps();
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.log(err);
      const customError = handleCustomError(err);
      setErrorMsg(customError.message);
      if (txHash) {
        updateTransaction(txHash, false, true);
      }
      setLoading(false);
    }
    return false;
  };

  const deleteAnnouncement = async (announcementIdx: number) => {
    const account = checkWallet();
    if (!account || !partyContract) return false;
    setLoading(true);
    openConfirmationWithSteps(deleteAnnouncementSteps);
    let txHash = "";

    try {
      const isOwner = await partyContract.managers(account);
      console.log("isOwner ", isOwner);
      if (!isOwner) {
        throw Error(`Only party owner is allowed to create announcements`);
      }

      // 1) Delete Announcement
      let deletePostTx: ContractTransaction;
      if (isLegacyParty) {
        /// @dev [PostMigration] Remove this function
        deletePostTx = await partyContract.deletePost(announcementIdx);
      } else {
        deletePostTx = await partyContract.removeAnnouncement(announcementIdx);
      }
      txHash = deletePostTx.hash;
      addTransaction(TransactionActions.deleteAnnouncement, txHash);

      // 2) Get tx hash
      setStep(1);
      await deletePostTx.wait();
      updateTransaction(txHash, true, true);
      txHash = "";

      setLoading(false);
      closeConfirmationWithSteps();
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.log(err);
      const customError = handleCustomError(err);
      setErrorMsg(customError.message);
      if (txHash) {
        updateTransaction(txHash, false, true);
      }
      setLoading(false);
    }
    return false;
  };

  return {
    createAnnouncement,
    deleteAnnouncement,
    loading,
  };
};

export default usePartyAnnouncements;
