import {
  createElement,
  useCallback,
  useEffect,
  useState,
  type ReactElement,
} from "react";

import { useDisclosure } from "@chakra-ui/react";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { useQuery } from "@tanstack/react-query";
import { type ContractTransaction } from "ethers";
import { useProvider } from "wagmi";

import { ERC20__factory, ERC721__factory } from "@partyfinance/contracts";
import {
  TokenGateTypeEnum,
  fetchPartyTokenGates,
  type TokenGateInfoRes,
} from "@partyfinance/thegraph-queries";

import { GatesRequiredModal } from "~/components/Modals";

import { endOfLegacyContract } from "~/config";
import { usePartyContext } from "~/contexts/PartyContext";
import { useTransactionContext } from "~/contexts/TransactionContext";
import { env } from "~/env.mjs";
import { usePlatformCheck } from "~/hooks/platform";
import { handleCustomError } from "~/modules/party/errors";
import { TransactionActions, type TokenGateReq } from "~/modules/party/types";

import useReadPartyContract from "./read/useReadPartyContract";
import usePartyContract from "./usePartyContract";

const updateTokenGatingSteps = [
  {
    label: "Update Token Gates",
    content: "Confirm updating token gates in your wallet.",
  },
  {
    label: "Awaiting confirmation",
    content: "Please wait until the token gates update has been confirmed.",
    loading: true,
    showGame: true,
  },
];
export const usePartyGating = () => {
  const provider = useProvider();
  const { checkWallet } = usePlatformCheck();
  const { partyAddress, chainId, partyInfo } = usePartyContext();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const partyContract = usePartyContract(partyAddress);
  const readPartyContract = useReadPartyContract(partyAddress);
  const {
    addTransaction,
    updateTransaction,
    openConfirmationWithSteps,
    closeConfirmationWithSteps,
    setStep,
    setErrorMsg,
  } = useTransactionContext();
  const [loading, setLoading] = useState(false);
  const [checkingAccess, setCheckingAccess] = useState(false);
  const [missingTokens, setMissingTokens] = useState<TokenGateInfoRes[]>([]);

  const canFetchTokenGates = !!partyAddress && !!readPartyContract;

  const getTokenGates = async () => {
    setLoading(true);

    try {
      const tokenGates = await fetchPartyTokenGates(
        chainId,
        env.NEXT_PUBLIC_THEGRAPH_API_KEY,
        partyAddress,
      );
      setLoading(false);
      return tokenGates;
    } catch (error) {
      console.log(error);
      setLoading(false);
      return null;
    }
  };

  const { data, error, isFetching, refetch } = useQuery(
    ["token-gates", chainId, partyAddress],
    getTokenGates,
    {
      enabled: canFetchTokenGates,
      refetchOnWindowFocus: false,
    },
  );

  // This function allows us to manipulate the current state of the party's token gates
  // We can use this function to add, update or delete token gates
  // By providing an array of token gates, the SC will overwrite the current token gates with the provided ones
  const editTokenGates = async (tokenGates: TokenGateReq[]) => {
    let txHash = "";
    try {
      const account = checkWallet();
      if (!account) return null;
      const isLegacyParty =
        !!partyInfo && partyInfo.inception < endOfLegacyContract;
      if (isLegacyParty)
        throw Error("Legacy parties do not support the token gating feature.");
      if (!partyAddress || !partyContract)
        throw Error("Cannot update token gates!");
      // Check if user has permission to update token gates
      const isPermitted = await partyContract.managers(account);
      if (!isPermitted)
        throw Error(
          "Only the owner and the managers of the party are allowed to update the token gates!",
        );

      setLoading(true);
      openConfirmationWithSteps(updateTokenGatingSteps);

      // Initiate updating token gates
      const gates =
        tokenGates.length > 0
          ? tokenGates.map((gate) => ({
              token: gate.address,
              amount: parseUnits(String(gate.amount), gate.decimals),
            }))
          : [];

      const gatesTx: ContractTransaction = await partyContract.editTokenGates(
        gates,
      );
      txHash = gatesTx.hash;
      addTransaction(TransactionActions.editTokenGates, txHash);

      // Wait for transaction to be confirmed
      setStep(1);
      await gatesTx.wait();
      updateTransaction(txHash, true, true);
      txHash = "";

      setLoading(false);
      closeConfirmationWithSteps();
      void refetch();
    } catch (error) {
      console.log(error);
      const customError = handleCustomError(error);
      setErrorMsg(customError.message);
      if (txHash) {
        // Abort transaction and show message in toast
        updateTransaction(txHash, false, true);
      }
      setLoading(false);
    }
  };

  const hasGateAccess = async () => {
    const account = checkWallet();
    if (!provider || !data || !account) return false;

    setCheckingAccess(true);

    console.log("checking gate access");

    try {
      // Check if there is sufficient balance for all gates
      const _missingTokens: TokenGateInfoRes[] = [];
      await Promise.all(
        data.map(async (gate) => {
          const contract =
            gate.type === TokenGateTypeEnum.ERC721
              ? ERC721__factory.connect(gate.address, provider)
              : ERC20__factory.connect(gate.address, provider);
          const expectedBalance = parseUnits(
            String(gate.amount),
            gate.decimals,
          );
          const userBalance = await contract.balanceOf(account);

          if (expectedBalance.gt(userBalance)) {
            // add address and missing amount to missingTokens
            const expectedBalNum = Number(
              formatUnits(expectedBalance, gate.decimals),
            );
            const userBalNum = Number(formatUnits(userBalance, gate.decimals));
            const missingAmount = expectedBalNum - userBalNum;
            _missingTokens.push({
              ...gate,
              amount: missingAmount,
            });
          }
        }),
      );
      setMissingTokens(_missingTokens);
      const hasAccess = _missingTokens.length === 0;
      if (hasAccess) {
        setCheckingAccess(false);
        return true;
      }
    } catch (error) {
      console.log("Error while checking gates access", error);
    }
    setCheckingAccess(false);
    onOpen();
    return false;
  };

  const GatesRequired = useCallback(
    (): ReactElement =>
      createElement(GatesRequiredModal, { onClose, isOpen, missingTokens }, []),
    [isOpen, onClose, missingTokens],
  );

  useEffect(() => {
    if (partyAddress && readPartyContract) {
      void getTokenGates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyAddress, readPartyContract]);

  return {
    editTokenGates,
    hasGateAccess,
    reload: () => refetch(),
    GatesRequired,
    tokenGates: data || [],
    loading: loading || isFetching,
    checkingAccess,
    error,
  };
};

export default usePartyGating;
