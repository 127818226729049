import { useEffect, useState } from "react";

import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";

import { Button, CloseButton } from "~/components/Buttons";

import { type IPool, type StakingForm } from "~/modules/staking/types";

interface ISliderModal {
  isOpen: boolean;
  onClose: () => void;
  pool: IPool;
  userBalance: number;
  confirmAction: (amount: number) => Promise<void>;
}

const UnstakeModal = ({
  isOpen,
  onClose,
  userBalance,
  confirmAction,
}: ISliderModal) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [balance, setBalance] = useState<number>(0);
  const [committedValue, setCommittedValue] = useState<number>(0);

  // form
  const { control, handleSubmit } = useForm<StakingForm>({
    defaultValues: {
      amount: Math.round(userBalance / 2),
    },
  });

  const handleOnSubmit = async (form: StakingForm) => {
    await confirmAction(form.amount);
  };

  const onSetAmount = (value: number) => {
    console.log("percent ", value);
    setCommittedValue(value);
  };

  useEffect(() => {
    // TODO: load user balance or claimable amount
    setBalance(userBalance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent my="auto">
        <ModalHeader>
          <CloseButton aria-label={"Close Button"} onClose={onClose} />
          <Text fontSize="xl" mx="auto" width="fit-content">
            Unstake PFi
          </Text>
        </ModalHeader>
        <ModalBody>
          <Box
            as={"form"}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={handleSubmit(handleOnSubmit)}
            p={"1rem"}
          >
            <Flex>
              <Text>Your pFi Balance:</Text>
              <Text ml={"0.5rem"}>{balance}</Text>
            </Flex>
            <Controller
              control={control}
              name="amount"
              render={({ field: { ref, onBlur, onChange, value } }) => {
                return (
                  <>
                    <Slider
                      id="slider"
                      defaultValue={Math.round(userBalance / 2)}
                      min={0}
                      max={userBalance}
                      ref={ref}
                      onBlur={onBlur}
                      mt={"1rem"}
                      color={"gray.500"}
                      value={value}
                      onChange={(v: number) => {
                        onSetAmount(v);
                        onChange(v);
                      }}
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                    >
                      <SliderMark value={0} mt="1rem" ml="0" fontSize="xs">
                        0%
                      </SliderMark>
                      <SliderMark value={25} mt="1rem" ml="-2.5" fontSize="xs">
                        25%
                      </SliderMark>
                      <SliderMark value={50} mt="1rem" ml="-2.5" fontSize="xs">
                        50%
                      </SliderMark>
                      <SliderMark value={75} mt="1rem" ml="-2.5" fontSize="xs">
                        75%
                      </SliderMark>
                      <SliderMark value={100} mt="1rem" ml="-7" fontSize="xs">
                        100%
                      </SliderMark>
                      <SliderTrack boxSize={"1rem"} borderRadius={"full"}>
                        <SliderFilledTrack bg={"#e30ccd"} />
                      </SliderTrack>
                      <Tooltip
                        hasArrow
                        bg="black"
                        color="white"
                        placement="top"
                        isOpen={showTooltip}
                        label={committedValue}
                      >
                        <SliderThumb
                          boxSize={"1.3rem"}
                          bg={"#e30ccd"}
                          borderColor={"white"}
                          borderWidth={"5px"}
                        />
                      </Tooltip>
                    </Slider>
                  </>
                );
              }}
            />
            <Box mx={["auto"]} mt={8} width={"fit-content"}>
              <Button minW={4} type={"submit"}>
                Unstake
              </Button>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UnstakeModal;
