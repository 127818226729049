import { useQuery } from "@tanstack/react-query";

import { type Party } from "@partyfinance/contracts";
import { fetchUserPartyRoles } from "@partyfinance/thegraph-queries";

import { usePartyContext } from "~/contexts/PartyContext";
import { env } from "~/env.mjs";

import { usePartyContract } from ".";

export const usePartyRole = (partyAddress: string, account: string) => {
  const { chainId } = usePartyContext();
  const enabled = !!partyAddress && !!account && !!chainId;
  return useQuery(
    ["party-role", chainId, partyAddress, account],
    () =>
      fetchUserPartyRoles(
        chainId,
        env.NEXT_PUBLIC_THEGRAPH_API_KEY,
        account,
        partyAddress,
      ),
    {
      retry: false,
      enabled,
      staleTime: 5 * 1000,
      cacheTime: 5 * 60 * 1000,
    },
  );
};

async function fetchPartyJoinInfo(contract: Party, account: string) {
  // const partyInfo = await contract.partyInfo()
  // console.log('isPrivate', partyInfo)
  const isPrivate = false; // !(await contract.partyInfo()).isPublic
  let isAllowed = false;
  let hasRequested = false;

  // Check if the party is private
  if (isPrivate) {
    // Check if the user is allowed to join
    isAllowed = await contract.isAcceptedRequest(account);
    if (!isAllowed) {
      // Check if there is a pending request from the user
      const joinRequests = await contract.getJoinRequests();
      hasRequested = joinRequests.indexOf(account) > -1;
    }
  }
  return {
    isPrivate,
    isAllowed,
    hasRequested,
  };
}
export const usePartyJoinAllowance = (
  partyAddress: string,
  account: string,
) => {
  const { chainId } = usePartyContext();
  const contract = usePartyContract(partyAddress);
  const enabled = !!contract && !!account;

  function query() {
    if (!enabled) return null;
    return fetchPartyJoinInfo(contract, account);
  }

  const { data, error, refetch } = useQuery(
    ["party-join-allowance", partyAddress, chainId, account],
    query,
    {
      enabled,
      staleTime: 20 * 1000,
    },
  );

  return {
    partyJoinAllowance: data || null,
    reload: () => refetch(),
    loading: !error && data === undefined && !!account,
    error,
  };
};
