import { env } from "~/env.mjs";

/**
 * Retrieves the Infura Network endpoint for client side operations
 * @dev The production key is restricted to the domain party.finance
 */
export function getClientInfuraRPC(chainId: number) {
  // Return RPC URL
  switch (chainId) {
    case 1:
      return `https://mainnet.infura.io/v3/${env.NEXT_PUBLIC_INFURA_KEY}`;
    case 5:
      return `https://goerli.infura.io/v3/${env.NEXT_PUBLIC_INFURA_KEY}`;
    case 10:
      return `https://optimism-mainnet.infura.io/v3/${env.NEXT_PUBLIC_INFURA_KEY}`;
    case 137:
      return `https://polygon-mainnet.infura.io/v3/${env.NEXT_PUBLIC_INFURA_KEY}`;
    default:
      console.error(`Infura RPC URL for chainId ${chainId} not found`);
      return "";
  }
}
