import { formatUnits } from "@ethersproject/units";

import { type PartySortDirection } from "@partyfinance/thegraph-queries";

import { api } from "~/utils/api";

import { type PartyWithFinancials } from "~/modules/party/types";

interface IPartyFilters {
  sortDirection: PartySortDirection;
  pageIndex: number;
  pageSize: number;
  revalidateOnFocus?: boolean;
  minValue?: number;
}

const usePublicPartiesByRoi = ({
  sortDirection,
  pageIndex,
  pageSize,
  revalidateOnFocus = false,
  minValue,
}: IPartyFilters) => {
  const { data, error, isLoading } = api.party.byRoi.useQuery(
    {
      sortDirection,
      pageIndex,
      pageSize,
      minValue,
    },
    {
      refetchOnWindowFocus: revalidateOnFocus,
      staleTime: 60 * 1000,
      cacheTime: 15 * 60 * 1000,
      select({ parties, ownersNicknames, canFetch }) {
        const partiesWithFinancials: PartyWithFinancials[] = parties.map(
          (p) => ({
            inception: p.inception,
            address: p.address,
            name: p.name,
            img: p.img,
            model: p.model,
            purpose: p.purpose,
            minDeposit: Number(
              formatUnits(String(p.minDeposit), p.denominationAsset.decimals),
            ),
            maxDeposit: Number(
              formatUnits(String(p.maxDeposit), p.denominationAsset.decimals),
            ),
            membersCount: p.membersCount,
            denominationAsset: p.denominationAsset,
            owner: p.owner,
            financials: p.financials,
            chainId: p.chainId,
          }),
        );
        return {
          parties: partiesWithFinancials,
          ownersNicknames,
          canFetch,
        };
      },
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    },
  );

  return {
    ownersNicknames: data?.ownersNicknames || [],
    parties: data?.parties || [],
    canFetch: data?.canFetch || false,
    loading: isLoading,
    error,
  };
};

export default usePublicPartiesByRoi;
