import { isAddress } from "@ethersproject/address";

export { isAddress };

export function isAddressEqual(add1 = "", add2 = "") {
  try {
    return add1.toLowerCase() === add2.toLowerCase();
  } catch (e) {
    return false;
  }
}

export function isUniqueAddressFromArray(address: string, addresses: string[]) {
  return !addresses.find((a) => isAddressEqual(a, address));
}

export function isAddressContained(addr = "", array: string[] = []) {
  try {
    for (let i = 0; i < array.length; i++) {
      if (isAddressEqual(addr, array[i])) return true;
    }
  } catch (e) {
    //
  }
  return false;
}

export const truncateAddress = (address: string) =>
  `${address?.slice(0, 6)}...${address?.slice(-5)}`;

export function shortenHex(hex: string, length = 4) {
  return `${hex.substring(0, length + 2)}…${hex.substring(
    hex.length - length,
  )}`;
}

export function shortenAddress(address: string, chars = 4): string {
  if (!isAddress(address)) {
    console.warn(`Invalid 'address' parameter '${address}'.`);
    return "";
  }
  return `${address.substring(0, chars + 2)}...${address.substring(
    42 - chars,
  )}`;
}

export function getBlockExplorerUrl(network: {
  chainId: number;
  name?: string;
}) {
  switch (network?.chainId) {
    case undefined:
    case null:
    case 1:
      return "https://etherscan.io";
    case 5:
      return "https://goerli.etherscan.io";
    case 10:
      return "https://optimistic.etherscan.io";
    case 56:
      return "https://bscscan.com";
    case 137:
      return "https://polygonscan.com";
    case 420:
      return "https://goerli-optimism.etherscan.io";
    case 42161:
      return "https://arbiscan.io";
    case 43114:
      return "https://snowtrace.io";
    case 80001:
      return "https://mumbai.polygonscan.com";
    default:
      return `https://${network.name}.etherscan.io`;
  }
}
export function getBlockExplorerName(chainId: number) {
  switch (chainId) {
    case undefined:
    case null:
    case 1:
      return "Etherscan";
    case 5:
      return "Etherscan";
    case 10:
      return "Etherscan";
    case 56:
      return "BscScan";
    case 137:
      return "Polygonscan";
    case 420:
      return "Etherscan";
    case 42161:
      return "Arbiscan";
    case 43114:
      return "Snowtrace";
    case 80001:
      return "Polygonscan";
    default:
      return "Etherscan";
  }
}

export function getNetworkTokenSymbol(chainId: number) {
  switch (chainId) {
    case 1:
      return "ETH";
    case 5:
      return "gETH";
    case 10:
      return "ETH";
    case 56:
      return "BNB";
    case 137:
      return "MATIC";
    case 420:
      return "gETH";
    case 42161:
      return "ARB";
    case 43114:
      return "AVAX";
    case 80001:
      return "MATIC";
    default:
      return "";
  }
}
export function getNetworkTokenName(chainId: number) {
  switch (chainId) {
    case 1:
      return "Ether";
    case 5:
      return "Goerli-ether";
    case 10:
      return "Ether";
    case 56:
      return "Binance Coin";
    case 137:
      return "Matic";
    case 420:
      return "Goerli-ether";
    case 42161:
      return "Arbitrum";
    case 43114:
      return "Avalanche";
    case 80001:
      return "Matic";
    default:
      return "";
  }
}

export function formatBlockExplorerLink(
  type: "address" | "tx" | "token",
  data: [number, string],
  tokenId?: number,
) {
  const [chainId, address] = data;
  const blockExplorerUrl = getBlockExplorerUrl({ chainId });
  return `${blockExplorerUrl}/${type}/${address.toLowerCase()}${
    tokenId !== undefined ? `?a=${tokenId}` : ""
  }`;
}

export const getChainName = (id?: number) => {
  switch (id) {
    case 1:
      return "Mainnet";
    case 5:
      return "Goerli";
    case 10:
      return "Optimism";
    case 56:
      return "BSC";
    case 137:
      return "Polygon";
    case 420:
      return "Optimism Goerli";
    case 42161:
      return "Arbitrum";
    case 43114:
      return "Avalanche";
    case 80001:
      return "Mumbai";
    default:
      return "";
  }
};

export const getChainIconColor = (id?: number) => {
  switch (id) {
    case 1:
      return "#617dea";
    case 5:
      return "#617dea";
    case 10:
      return "#FF0420";
    case 56:
      return "#14151A";
    case 137:
      return "#8b55e7";
    case 420:
      return "#FF0420";
    case 42161:
      return "#28A0F0";
    case 43114:
      return "#e84242";
    case 80001:
      return "#8b55e7";
    default:
      return "#ffffff";
  }
};
