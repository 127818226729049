/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type { Party, PartyInterface } from "../Party";

const _abi = [
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        internalType: "struct TokenGate[]",
        name: "_tokenGates",
        type: "tuple[]",
      },
    ],
    name: "editTokenGates",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getTokenGates",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        internalType: "struct TokenGate[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "creator",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "manager",
        type: "address",
      },
      {
        internalType: "bool",
        name: "setManager",
        type: "bool",
      },
    ],
    name: "handleManager",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "title",
        type: "string",
      },
      {
        internalType: "string",
        name: "description",
        type: "string",
      },
      {
        internalType: "string",
        name: "url",
        type: "string",
      },
      {
        internalType: "string",
        name: "img",
        type: "string",
      },
    ],
    name: "createPost",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "title",
        type: "string",
      },
      {
        internalType: "string",
        name: "content",
        type: "string",
      },
      {
        internalType: "string",
        name: "url",
        type: "string",
      },
      {
        internalType: "string",
        name: "img",
        type: "string",
      },
    ],
    name: "createAnnouncement",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "title",
        type: "string",
      },
      {
        internalType: "string",
        name: "description",
        type: "string",
      },
      {
        internalType: "string",
        name: "url",
        type: "string",
      },
      {
        internalType: "string",
        name: "img",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "announcementIdx",
        type: "uint256",
      },
    ],
    name: "editPost",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "title",
        type: "string",
      },
      {
        internalType: "string",
        name: "content",
        type: "string",
      },
      {
        internalType: "string",
        name: "url",
        type: "string",
      },
      {
        internalType: "string",
        name: "img",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "i",
        type: "uint256",
      },
    ],
    name: "editAnnouncement",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getPosts",
    outputs: [
      {
        components: [
          {
            internalType: "string",
            name: "title",
            type: "string",
          },
          {
            internalType: "string",
            name: "description",
            type: "string",
          },
          {
            internalType: "string",
            name: "url",
            type: "string",
          },
          {
            internalType: "string",
            name: "img",
            type: "string",
          },
          {
            internalType: "uint256",
            name: "date",
            type: "uint256",
          },
        ],
        internalType: "struct Announcements.Post[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getAnnouncements",
    outputs: [
      {
        components: [
          {
            internalType: "string",
            name: "title",
            type: "string",
          },
          {
            internalType: "string",
            name: "content",
            type: "string",
          },
          {
            internalType: "string",
            name: "url",
            type: "string",
          },
          {
            internalType: "string",
            name: "img",
            type: "string",
          },
          {
            internalType: "uint256",
            name: "created",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "updated",
            type: "uint256",
          },
        ],
        internalType: "struct Announcement[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "announcementIdx",
        type: "uint256",
      },
    ],
    name: "deletePost",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "i",
        type: "uint256",
      },
    ],
    name: "removeAnnouncement",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "facetAddress",
            type: "address",
          },
          {
            internalType: "enum IDiamondCut.FacetCutAction",
            name: "action",
            type: "uint8",
          },
          {
            internalType: "bytes4[]",
            name: "functionSelectors",
            type: "bytes4[]",
          },
        ],
        indexed: false,
        internalType: "struct IDiamondCut.FacetCut[]",
        name: "_diamondCut",
        type: "tuple[]",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_init",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "_calldata",
        type: "bytes",
      },
    ],
    name: "DiamondCut",
    type: "event",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "facetAddress",
            type: "address",
          },
          {
            internalType: "enum IDiamondCut.FacetCutAction",
            name: "action",
            type: "uint8",
          },
          {
            internalType: "bytes4[]",
            name: "functionSelectors",
            type: "bytes4[]",
          },
        ],
        internalType: "struct IDiamondCut.FacetCut[]",
        name: "_diamondCut",
        type: "tuple[]",
      },
      {
        internalType: "address",
        name: "_init",
        type: "address",
      },
      {
        internalType: "bytes",
        name: "_calldata",
        type: "bytes",
      },
    ],
    name: "diamondCut",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "_functionSelector",
        type: "bytes4",
      },
    ],
    name: "facetAddress",
    outputs: [
      {
        internalType: "address",
        name: "facetAddress_",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "facetAddresses",
    outputs: [
      {
        internalType: "address[]",
        name: "facetAddresses_",
        type: "address[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_facet",
        type: "address",
      },
    ],
    name: "facetFunctionSelectors",
    outputs: [
      {
        internalType: "bytes4[]",
        name: "_facetFunctionSelectors",
        type: "bytes4[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "facets",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "facetAddress",
            type: "address",
          },
          {
            internalType: "bytes4[]",
            name: "functionSelectors",
            type: "bytes4[]",
          },
        ],
        internalType: "struct IDiamondLoupe.Facet[]",
        name: "facets_",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bool",
        name: "accepted",
        type: "bool",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "handleRequest",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "member",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "accepted",
        type: "bool",
      },
    ],
    name: "HandleJoinRequest",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "member",
        type: "address",
      },
    ],
    name: "JoinRequest",
    type: "event",
  },
  {
    inputs: [],
    name: "joinRequest",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "createJoinRequest",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getJoinRequests",
    outputs: [
      {
        internalType: "address[]",
        name: "",
        type: "address[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bool",
        name: "accepted",
        type: "bool",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "handleJoinRequest",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "isAcceptedRequest",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "taker",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "makerAsset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "takerAsset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "makingAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "takingAmount",
        type: "uint256",
      },
    ],
    name: "LimitOrderFilled",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sellToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "sellAmount",
        type: "uint256",
      },
    ],
    name: "approveLimitOrder",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "salt",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "makerAsset",
            type: "address",
          },
          {
            internalType: "address",
            name: "takerAsset",
            type: "address",
          },
          {
            internalType: "address",
            name: "maker",
            type: "address",
          },
          {
            internalType: "address",
            name: "receiver",
            type: "address",
          },
          {
            internalType: "address",
            name: "allowedSender",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "makingAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "takingAmount",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "makerAssetData",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "takerAssetData",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "getMakerAmount",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "getTakerAmount",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "predicate",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "permit",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "interaction",
            type: "bytes",
          },
        ],
        internalType: "struct LimitOrdersFacet.LimitOrder",
        name: "order",
        type: "tuple",
      },
    ],
    name: "cancelLimitOrder",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "_hash",
        type: "bytes32",
      },
      {
        internalType: "bytes",
        name: "_signature",
        type: "bytes",
      },
    ],
    name: "isValidSignature",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "taker",
        type: "address",
      },
      {
        internalType: "address",
        name: "makerAsset",
        type: "address",
      },
      {
        internalType: "address",
        name: "takerAsset",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "makingAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "takingAmount",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "interactiveData",
        type: "bytes",
      },
    ],
    name: "notifyFillOrder",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "member",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address[]",
        name: "sellTokens",
        type: "address[]",
      },
      {
        indexed: false,
        internalType: "address[]",
        name: "buyTokens",
        type: "address[]",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "soldAmounts",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "boughtAmounts",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "partyValueDA",
        type: "uint256",
      },
    ],
    name: "AllocationFilled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "member",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "supply",
        type: "uint256",
      },
    ],
    name: "Close",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "member",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "asset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "fee",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "mintedPT",
        type: "uint256",
      },
    ],
    name: "Deposit",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "member",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "asset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "fee",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "mintedPT",
        type: "uint256",
      },
    ],
    name: "Join",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "kicker",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "kicked",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "burnedPT",
        type: "uint256",
      },
    ],
    name: "Kick",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "member",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "burnedPT",
        type: "uint256",
      },
    ],
    name: "Leave",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "partyCreator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "string",
        name: "partyName",
        type: "string",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "isPublic",
        type: "bool",
      },
      {
        indexed: false,
        internalType: "address",
        name: "dAsset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "minDeposit",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "maxDeposit",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "mintedPT",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "string",
        name: "bio",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "img",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "model",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "purpose",
        type: "string",
      },
    ],
    name: "PartyCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "bio",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "img",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "model",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "purpose",
        type: "string",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "isPublic",
        type: "bool",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "minDeposit",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "maxDeposit",
        type: "uint256",
      },
    ],
    name: "PartyInfoEdit",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "member",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "burnedPT",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "liquidate",
        type: "bool",
      },
      {
        indexed: false,
        internalType: "address[]",
        name: "redeemedAssets",
        type: "address[]",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "redeemedAmounts",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "redeemedFees",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "redeemedNetAmounts",
        type: "uint256[]",
      },
    ],
    name: "RedeemedShares",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "member",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "sellToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "buyToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "soldAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "boughtAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "fee",
        type: "uint256",
      },
    ],
    name: "SwapToken",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "member",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "burnedPT",
        type: "uint256",
      },
    ],
    name: "Withdraw",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "closeParty",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "closed",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [],
    name: "denominationAsset",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "address[]",
            name: "sellTokens",
            type: "address[]",
          },
          {
            internalType: "uint256[]",
            name: "sellAmounts",
            type: "uint256[]",
          },
          {
            internalType: "address[]",
            name: "buyTokens",
            type: "address[]",
          },
          {
            internalType: "address[]",
            name: "spenders",
            type: "address[]",
          },
          {
            internalType: "address payable[]",
            name: "swapsTargets",
            type: "address[]",
          },
          {
            internalType: "bytes[]",
            name: "swapsCallData",
            type: "bytes[]",
          },
          {
            internalType: "uint256",
            name: "partyValueDA",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "partyTotalSupply",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "expiresAt",
            type: "uint256",
          },
        ],
        internalType: "struct LibSignatures.Allocation",
        name: "allocation",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
        ],
        internalType: "struct LibSignatures.Sig",
        name: "approval",
        type: "tuple",
      },
    ],
    name: "deposit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "string",
            name: "bio",
            type: "string",
          },
          {
            internalType: "string",
            name: "img",
            type: "string",
          },
          {
            internalType: "string",
            name: "model",
            type: "string",
          },
          {
            internalType: "string",
            name: "purpose",
            type: "string",
          },
          {
            internalType: "bool",
            name: "isPublic",
            type: "bool",
          },
          {
            internalType: "uint256",
            name: "minDeposit",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxDeposit",
            type: "uint256",
          },
        ],
        internalType: "struct PartyInfo",
        name: "_partyInfo",
        type: "tuple",
      },
    ],
    name: "editPartyInfo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getTokens",
    outputs: [
      {
        internalType: "address[]",
        name: "",
        type: "address[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "address[]",
            name: "sellTokens",
            type: "address[]",
          },
          {
            internalType: "uint256[]",
            name: "sellAmounts",
            type: "uint256[]",
          },
          {
            internalType: "address[]",
            name: "buyTokens",
            type: "address[]",
          },
          {
            internalType: "address[]",
            name: "spenders",
            type: "address[]",
          },
          {
            internalType: "address payable[]",
            name: "swapsTargets",
            type: "address[]",
          },
          {
            internalType: "bytes[]",
            name: "swapsCallData",
            type: "bytes[]",
          },
          {
            internalType: "uint256",
            name: "partyValueDA",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "partyTotalSupply",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "expiresAt",
            type: "uint256",
          },
        ],
        internalType: "struct LibSignatures.Allocation",
        name: "allocation",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
        ],
        internalType: "struct LibSignatures.Sig",
        name: "approval",
        type: "tuple",
      },
    ],
    name: "joinParty",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "kickingMember",
        type: "address",
      },
      {
        components: [
          {
            internalType: "address[]",
            name: "sellTokens",
            type: "address[]",
          },
          {
            internalType: "uint256[]",
            name: "sellAmounts",
            type: "uint256[]",
          },
          {
            internalType: "address[]",
            name: "buyTokens",
            type: "address[]",
          },
          {
            internalType: "address[]",
            name: "spenders",
            type: "address[]",
          },
          {
            internalType: "address payable[]",
            name: "swapsTargets",
            type: "address[]",
          },
          {
            internalType: "bytes[]",
            name: "swapsCallData",
            type: "bytes[]",
          },
          {
            internalType: "uint256",
            name: "partyValueDA",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "partyTotalSupply",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "expiresAt",
            type: "uint256",
          },
        ],
        internalType: "struct LibSignatures.Allocation",
        name: "allocation",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
        ],
        internalType: "struct LibSignatures.Sig",
        name: "approval",
        type: "tuple",
      },
      {
        internalType: "bool",
        name: "liquidate",
        type: "bool",
      },
    ],
    name: "kickMember",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address[]",
            name: "sellTokens",
            type: "address[]",
          },
          {
            internalType: "uint256[]",
            name: "sellAmounts",
            type: "uint256[]",
          },
          {
            internalType: "address[]",
            name: "buyTokens",
            type: "address[]",
          },
          {
            internalType: "address[]",
            name: "spenders",
            type: "address[]",
          },
          {
            internalType: "address payable[]",
            name: "swapsTargets",
            type: "address[]",
          },
          {
            internalType: "bytes[]",
            name: "swapsCallData",
            type: "bytes[]",
          },
          {
            internalType: "uint256",
            name: "partyValueDA",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "partyTotalSupply",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "expiresAt",
            type: "uint256",
          },
        ],
        internalType: "struct LibSignatures.Allocation",
        name: "allocation",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
        ],
        internalType: "struct LibSignatures.Sig",
        name: "approval",
        type: "tuple",
      },
      {
        internalType: "bool",
        name: "liquidate",
        type: "bool",
      },
    ],
    name: "leaveParty",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "managers",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "members",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "partyInfo",
    outputs: [
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        internalType: "string",
        name: "bio",
        type: "string",
      },
      {
        internalType: "string",
        name: "img",
        type: "string",
      },
      {
        internalType: "string",
        name: "model",
        type: "string",
      },
      {
        internalType: "string",
        name: "purpose",
        type: "string",
      },
      {
        internalType: "bool",
        name: "isPublic",
        type: "bool",
      },
      {
        internalType: "uint256",
        name: "minDeposit",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxDeposit",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address[]",
            name: "sellTokens",
            type: "address[]",
          },
          {
            internalType: "uint256[]",
            name: "sellAmounts",
            type: "uint256[]",
          },
          {
            internalType: "address[]",
            name: "buyTokens",
            type: "address[]",
          },
          {
            internalType: "address[]",
            name: "spenders",
            type: "address[]",
          },
          {
            internalType: "address payable[]",
            name: "swapsTargets",
            type: "address[]",
          },
          {
            internalType: "bytes[]",
            name: "swapsCallData",
            type: "bytes[]",
          },
          {
            internalType: "uint256",
            name: "partyValueDA",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "partyTotalSupply",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "expiresAt",
            type: "uint256",
          },
        ],
        internalType: "struct LibSignatures.Allocation",
        name: "allocation",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
        ],
        internalType: "struct LibSignatures.Sig",
        name: "approval",
        type: "tuple",
      },
    ],
    name: "swapToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amountPT",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "address[]",
            name: "sellTokens",
            type: "address[]",
          },
          {
            internalType: "uint256[]",
            name: "sellAmounts",
            type: "uint256[]",
          },
          {
            internalType: "address[]",
            name: "buyTokens",
            type: "address[]",
          },
          {
            internalType: "address[]",
            name: "spenders",
            type: "address[]",
          },
          {
            internalType: "address payable[]",
            name: "swapsTargets",
            type: "address[]",
          },
          {
            internalType: "bytes[]",
            name: "swapsCallData",
            type: "bytes[]",
          },
          {
            internalType: "uint256",
            name: "partyValueDA",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "partyTotalSupply",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "expiresAt",
            type: "uint256",
          },
        ],
        internalType: "struct LibSignatures.Allocation",
        name: "allocation",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
        ],
        internalType: "struct LibSignatures.Sig",
        name: "approval",
        type: "tuple",
      },
      {
        internalType: "bool",
        name: "liquidate",
        type: "bool",
      },
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "facetAddress",
            type: "address",
          },
          {
            internalType: "enum IDiamondCut.FacetCutAction",
            name: "action",
            type: "uint8",
          },
          {
            internalType: "bytes4[]",
            name: "functionSelectors",
            type: "bytes4[]",
          },
        ],
        indexed: false,
        internalType: "struct IDiamondCut.FacetCut[]",
        name: "_diamondCut",
        type: "tuple[]",
      },
      {
        indexed: false,
        internalType: "address",
        name: "updater",
        type: "address",
      },
    ],
    name: "VersionDiamondCut",
    type: "event",
  },
  {
    inputs: [],
    name: "checkEnsureState",
    outputs: [
      {
        internalType: "bool",
        name: "areEqual",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "checkEnsureVersion",
    outputs: [
      {
        internalType: "bool",
        name: "areEqual",
        type: "bool",
      },
      {
        components: [
          {
            internalType: "address",
            name: "facetAddress",
            type: "address",
          },
          {
            internalType: "bytes4[]",
            name: "functionSelectors",
            type: "bytes4[]",
          },
        ],
        internalType: "struct IDiamondLoupe.Facet[]",
        name: "currentFacets",
        type: "tuple[]",
      },
      {
        components: [
          {
            internalType: "address",
            name: "facetAddress",
            type: "address",
          },
          {
            internalType: "enum IDiamondCut.FacetCutAction",
            name: "action",
            type: "uint8",
          },
          {
            internalType: "bytes4[]",
            name: "functionSelectors",
            type: "bytes4[]",
          },
        ],
        internalType: "struct IDiamondCut.FacetCut[]",
        name: "modelDiamondCut",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ensureDiamond",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "ensureState",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class Party__factory {
  static readonly abi = _abi;
  static createInterface(): PartyInterface {
    return new utils.Interface(_abi) as PartyInterface;
  }
  static connect(address: string, signerOrProvider: Signer | Provider): Party {
    return new Contract(address, _abi, signerOrProvider) as Party;
  }
}
