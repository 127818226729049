// import { IPool } from '~/modules/staking/types'
import { useState } from "react";

import { useTransactionContext } from "~/contexts/TransactionContext";
import { usePlatformCheck } from "~/hooks/platform";

const stakeSteps = [
  {
    label: "Check balance",
    content: "Checking LP token balance",
    loading: true,
  },
  {
    label: "Allow spending of LP tokens",
    content: "Confirm the initial LP Token spend allowance in your wallet",
  },
  {
    label: "Allowance confirmation",
    content: "Please wait until the allowance transaction is confirmed",
    loading: true,
    showGame: true,
  },
  {
    label: "Stake",
    content: "Confir the stake transacmtion in your wallet",
  },
  {
    label: "Staking confirmation",
    content: "Please wait until the staking transaction is confirmed",
    loading: true,
    showGame: true,
  },
];

const unstakeSteps = [
  {
    label: "Checking staked balance",
    content: "Please wait while your staked balance is being checked",
    loading: true,
  },
  {
    label: "Unstake tokens",
    content: "Confirm the unstaking transaction in your wallet",
  },
  {
    label: "Unstake confirmation",
    content: "Please wait until the unstaking transaction is confirmed",
    loading: true,
    showGame: true,
  },
];

const claimSteps = [
  {
    label: "Checking rewards",
    content: "Please wait while your rewards are being checked",
    loading: true,
  },
  {
    label: "Unstake tokens",
    content: "Confirm the claiming transaction in your wallet",
  },
  {
    label: "Unstake confirmation",
    content: "Please wait until the claiming transaction is confirmed",
    loading: true,
    showGame: true,
  },
];

const useStake = (/*pool: IPool*/) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { checkWallet } = usePlatformCheck();
  const {
    // addTransaction,
    // updateTransaction,
    openConfirmationWithSteps,
    // closeConfirmationWithSteps,
    // setStep,
    // setErrorMsg,
  } = useTransactionContext();

  const stake = async (amount: number) => {
    console.log(amount);
    if (!checkWallet()) {
      return null;
    }
    setLoading(true);
    openConfirmationWithSteps(stakeSteps);

    try {
      // 1) Check if requested amount is available
      // 2) Get spending allowance
      // 3) Send stake transaction
    } catch (err) {
      console.log(err);
    }
    return Promise.resolve();
  };

  const unstake = async (amount: number) => {
    console.log(amount);
    if (!checkWallet()) {
      return null;
    }
    setLoading(true);
    openConfirmationWithSteps(unstakeSteps);

    try {
      // 1) Check staked balance
      // 2) Send unstake Tx
    } catch (err) {
      console.log(err);
    }
    return Promise.resolve();
  };

  const claimRewards = async () => {
    if (!checkWallet()) {
      return null;
    }
    setLoading(true);
    openConfirmationWithSteps(claimSteps);

    try {
      // 1) Check rewards balance
      // 2) Send claim tx
    } catch (err) {
      console.log(err);
    }
    return Promise.resolve();
  };

  return {
    stake,
    unstake,
    claimRewards,
    loading,
  };
};

export default useStake;
