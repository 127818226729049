/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";

import {
  LimitOrderBuilder,
  LimitOrderPredicateBuilder,
  LimitOrderProtocolFacade,
  type LimitOrderPredicateCallData,
} from "@1inch/limit-order-protocol";
import { formatUnits } from "@ethersproject/units";
import { BigNumber } from "ethers";
import { useSigner } from "wagmi";

import { ERC20__factory } from "@partyfinance/contracts";

import { usePartyContext } from "~/contexts/PartyContext";
import { useTransactionContext } from "~/contexts/TransactionContext";
import { usePlatformCheck } from "~/hooks/platform";
import { postLimitOrder } from "~/modules/1nch/api";
import { type LimitOrderRes } from "~/modules/1nch/types";
import {
  Ethers1nchConnector,
  get1nchLimitOrderProtocol,
} from "~/modules/1nch/utils";
import { handleCustomError } from "~/modules/party/errors";
import { TransactionActions } from "~/modules/party/types";

import { usePartyContract } from ".";

const createSteps = [
  {
    label: "Check balance",
    content:
      "Checking if your Party contains the necessary funds to create the limit order.",
    loading: true,
  },
  {
    label: "Sign limit order",
    content: "Sign the limit order request on your wallet",
  },
  {
    label: "Allow funds spend",
    content:
      "Confirm 1nch LimitOrderProtocol to consume your party's fund to create the limit order. The sell token will only be consumed if the limit order gets filled.",
  },
  {
    label: "Allowance confirmation",
    content: "Please wait until the allowance transaction is confirmed",
    loading: true,
    showGame: true,
  },
  {
    label: "Adding limit order",
    content: "Adding the limit order on 1inch DeFi Orderbooks",
    loading: true,
  },
];
const cancelSteps = [
  {
    label: "Cancel limit order",
    content: "Confirm the cancellation of the limit order in your wallet",
  },
  {
    label: "Cancel order confirmation",
    content: "Please wait until the limit order is cancelled",
    loading: true,
    showGame: true,
  },
];

const usePartyLimitOrder = (partyAddress: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { data: signer } = useSigner();
  const { chainId } = usePartyContext();
  const partyContract = usePartyContract(partyAddress);
  const { checkWallet } = usePlatformCheck();
  const {
    addTransaction,
    updateTransaction,
    openConfirmationWithSteps,
    closeConfirmationWithSteps,
    setStep,
    setErrorMsg,
  } = useTransactionContext();

  const createLimitOrder = async ({
    sellTokenAddress,
    sellAmount,
    buyTokenAddress,
    buyAmount,
    expiration,
  }: {
    sellTokenAddress: string;
    sellAmount: string;
    buyTokenAddress: string;
    buyAmount: string;
    /** Expiration of order in miliseconds */
    expiration: number;
  }) => {
    const account = checkWallet();
    if (!account || !chainId || !partyContract) return false;
    setLoading(true);
    openConfirmationWithSteps(createSteps);
    let txHash = "";

    try {
      // 1) Validate action
      const isOwner = await partyContract.managers(account);
      if (!isOwner) {
        throw Error(`Only Party Owner is allowed to create limit orders`);
      }

      // 2) Check if party has enough balance to create the limit order
      if (!signer) {
        throw Error("Please reconnect your wallet again");
      }
      const desiredSellAmount = BigNumber.from(sellAmount);
      const sellTokenContract = ERC20__factory.connect(
        sellTokenAddress,
        signer,
      );
      const sellTokenPartyBalance = await sellTokenContract.balanceOf(
        partyAddress,
      );
      if (sellTokenPartyBalance.lt(desiredSellAmount)) {
        const sellTokenSymbol = await sellTokenContract.symbol();
        const sellTokenDecimals = await sellTokenContract.decimals();
        throw Error(
          `Not enough ${sellTokenSymbol} balance to create the limit order. Current balance ${formatUnits(
            sellTokenPartyBalance,
            sellTokenDecimals,
          )} vs Desired balance ${formatUnits(
            desiredSellAmount,
            sellTokenDecimals,
          )}`,
        );
      }
      setStep(1);

      // 3) Create the limit order
      // -> 3.1) Set the 1nch provider connector
      const connector = new Ethers1nchConnector(signer);

      // -> 3.2) Set the predicate for the expiration of order
      const ADDRESS_1NCH_PROTOCOL = get1nchLimitOrderProtocol(chainId);
      const limitOrderProtocolFacade = new LimitOrderProtocolFacade(
        ADDRESS_1NCH_PROTOCOL,
        connector,
      );
      const limitOrderPredicateBuilder = new LimitOrderPredicateBuilder(
        limitOrderProtocolFacade,
      );
      const expiresAt = Math.round(Date.now() / 1000) + expiration;
      const partyPredicate: LimitOrderPredicateCallData =
        limitOrderPredicateBuilder.timestampBelow(expiresAt);

      // -> 3.3) Build the order
      const limitOrderBuilder = new LimitOrderBuilder(
        ADDRESS_1NCH_PROTOCOL,
        chainId,
        connector,
      );

      const limitOrder = {
        ...limitOrderBuilder.buildLimitOrder({
          makerAssetAddress: sellTokenAddress,
          takerAssetAddress: buyTokenAddress,
          makerAddress: partyAddress,
          makerAmount: sellAmount,
          takerAmount: buyAmount,
          predicate: partyPredicate,
          interaction: `${partyAddress}${partyAddress.slice(2)}`,
        }),
        salt: expiresAt.toString(), // Force salt to be expiration date to retrieve it in FE
      };
      const limitOrderTypedData =
        limitOrderBuilder.buildLimitOrderTypedData(limitOrder);

      // 4) Sign limit order (EIP-712)
      const limitOrderSignature = await limitOrderBuilder.buildOrderSignature(
        account,
        limitOrderTypedData,
      );

      // 5) Approve sell token to LimitOrderProtocol
      setStep(2);
      const oneInchAllowance = await sellTokenContract.allowance(
        partyAddress,
        ADDRESS_1NCH_PROTOCOL,
      );
      if (oneInchAllowance.lt(desiredSellAmount)) {
        const approveLimitOrderTx = await partyContract.approveLimitOrder(
          sellTokenAddress,
          desiredSellAmount,
        );
        txHash = approveLimitOrderTx.hash;
        addTransaction(TransactionActions.aproveLimitOrder, txHash);
        setStep(3);
        await approveLimitOrderTx.wait(2);
        updateTransaction(txHash, true, true);
        txHash = "";
      }
      setStep(4);

      // 6) Post the limit order on 1nch orderbookGet Limit order hash
      const limitOrderHash =
        limitOrderBuilder.buildLimitOrderHash(limitOrderTypedData);

      await postLimitOrder(
        {
          orderHash: limitOrderHash,
          signature: limitOrderSignature,
          data: limitOrder,
        },
        chainId,
      );

      // 7) Done!
      closeConfirmationWithSteps();
      setLoading(false);
      return true;
    } catch (err: any) {
      console.log(err);
      const customError = handleCustomError(err);
      setErrorMsg(customError.message);
      setLoading(false);
    }
    return false;
  };

  const cancelLimitOrder = async (order: LimitOrderRes) => {
    const account = checkWallet();
    if (!account || !chainId || !partyContract) return false;
    setLoading(true);
    openConfirmationWithSteps(cancelSteps);
    let txHash = "";

    try {
      // 1) Validate action
      const isOwner = await partyContract.managers(account);
      if (!isOwner) {
        throw Error(`Only Party Owner is allowed to cancel limit orders`);
      }

      // 2) Cancel the limit order
      const cancelLimitOrderTx = await partyContract.cancelLimitOrder({
        salt: order.data.salt,
        makerAsset: order.data.makerAsset,
        takerAsset: order.data.takerAsset,
        maker: order.data.maker,
        receiver: order.data.receiver,
        allowedSender: order.data.allowedSender,
        makingAmount: order.data.makingAmount,
        takingAmount: order.data.takingAmount,
        makerAssetData: order.data.makerAssetData,
        takerAssetData: order.data.takerAssetData,
        getMakerAmount: order.data.getMakerAmount,
        getTakerAmount: order.data.getTakerAmount,
        predicate: order.data.predicate,
        permit: order.data.permit,
        interaction: order.data.interaction,
      });
      txHash = cancelLimitOrderTx.hash;
      addTransaction(TransactionActions.cancelLimitOrder, txHash);
      setStep(1);
      await cancelLimitOrderTx.wait(2);
      updateTransaction(txHash, true, true);
      txHash = "";

      closeConfirmationWithSteps();
      setLoading(false);
      return true;
    } catch (err: any) {
      console.log(err);
      const customError = handleCustomError(err);
      setErrorMsg(customError.message);
      setLoading(false);
    }
    return false;
  };

  return {
    createLimitOrder,
    cancelLimitOrder,
    loading,
  };
};

export default usePartyLimitOrder;
