import {
  Avatar,
  Tooltip,
  type AvatarProps,
  type PlacementWithLogical,
} from "@chakra-ui/react";

interface ITooltipAvatar extends AvatarProps {
  tooltipLabel: string;
  placement?: PlacementWithLogical;
}

const TooltipAvatar = ({
  placement = "top",
  tooltipLabel,
  ...props
}: ITooltipAvatar) => {
  return (
    <Tooltip label={tooltipLabel} placement={placement} hasArrow>
      <Avatar {...props} />
    </Tooltip>
  );
};

export default TooltipAvatar;
