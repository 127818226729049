import ReactJazzicon, { jsNumberForAddress } from "react-jazzicon";

interface IJazzicon {
  diameter?: number;
  account: string;
}

export default function Jazzicon({ diameter = 32, account }: IJazzicon) {
  return (
    <ReactJazzicon diameter={diameter} seed={jsNumberForAddress(account)} />
  );
}
