import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";

import { Button, CloseButton } from "~/components/Buttons";

import { type LeavePartyForm } from "~/modules/party/types";

interface IConfirmModalProps {
  isLeaveOpen: boolean;
  onCloseLeave: () => void;
  confirmAction: (form: LeavePartyForm) => Promise<boolean>;
  partyDASymbol?: string;
}

const LeaveParty = ({
  isLeaveOpen,
  onCloseLeave,
  confirmAction,
  partyDASymbol,
}: IConfirmModalProps) => {
  // form
  const { control, handleSubmit } = useForm<LeavePartyForm>({
    defaultValues: {
      liquidate: 0,
    },
  });

  return (
    <>
      <Modal isOpen={isLeaveOpen} onClose={onCloseLeave}>
        <ModalOverlay />
        <ModalContent my="auto" borderRadius="xl">
          <ModalHeader>
            <CloseButton aria-label={"Close Button"} onClose={onCloseLeave} />
            <Text fontSize="xl" mx="auto" width="fit-content">
              Leave Party
            </Text>
          </ModalHeader>
          <ModalBody>
            <Box
              as={"form"}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSubmit={handleSubmit(confirmAction)}
              p={"1rem"}
            >
              <Text>{`Select your withdraw type:`}</Text>
              <Box mt={4} width={"fit-content"}>
                <Controller
                  control={control}
                  name="liquidate"
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      value={String(value)}
                      onChange={(v) => {
                        onChange(Number(v) as 1 | 0);
                      }}
                      colorScheme="brand"
                    >
                      <Stack>
                        <Radio value="1">
                          Withdraw in denomination asset{" "}
                          {partyDASymbol ? `(${partyDASymbol})` : ""}
                        </Radio>
                        <Radio value="0">Withdraw as holdings</Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                />
              </Box>
              <Box mx={["auto"]} mt={8} width={"fit-content"}>
                <Button minW={4} type={"submit"}>
                  Leave
                </Button>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LeaveParty;
