import {
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useColorModeValue,
  type BoxProps,
  type FormLabelProps,
} from "@chakra-ui/react";
import {
  Select,
  type ChakraStylesConfig,
  type Props,
} from "chakra-react-select";

import InputBase from "./Base";

export interface IInputSelectProps extends Props {
  id: string;
  label?: string;
  helper?: string;
  error?: string;
  leftElementIcon?: React.ReactNode;
  rightElementIcon?: React.ReactNode;
  labelOptions?: FormLabelProps;
  containerProps?: BoxProps;
  controlProps?: BoxProps;
}

const InputSelect = ({
  id,
  label,
  helper,
  error,
  value,
  onBlur,
  onChange,
  leftElementIcon,
  rightElementIcon,
  labelOptions,
  containerProps,
  controlProps,
  ...rest
}: IInputSelectProps) => {
  const bg = useColorModeValue("gray.200", "transparent");
  const chakraStyles: ChakraStylesConfig = {
    container: (provided) => ({
      ...provided,
      bg,
      w: "100%",
      ...containerProps,
    }),
    control: (provided) => ({
      ...provided,
      _focus: {
        borderColor: "brand.200",
        borderWidth: 1,
      },
      ...controlProps,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      bg,
      border: "none",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };
  return (
    <InputBase
      id={id}
      label={label}
      error={error}
      labelOptions={labelOptions}
      helper={helper}
    >
      <InputGroup zIndex={10}>
        {leftElementIcon && (
          <InputLeftElement pointerEvents="none">
            {leftElementIcon}
          </InputLeftElement>
        )}

        <Select
          id={id}
          value={value || ""}
          onBlur={onBlur}
          onChange={onChange}
          chakraStyles={chakraStyles}
          colorScheme="pink"
          selectedOptionColorScheme="pink"
          selectedOptionStyle="check"
          {...rest}
        />

        {rightElementIcon && (
          <InputRightElement>{rightElementIcon}</InputRightElement>
        )}
      </InputGroup>
    </InputBase>
  );
};

export default InputSelect;
