import { useEffect } from "react";

import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  type ToastId,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";

import { getErrorMessage } from "~/utils/form/errorMessage";
import { formatNumber } from "~/utils/number";

import { Button, CloseButton } from "~/components/Buttons";
import { InputNumber } from "~/components/Inputs";

import { usePartyContext } from "~/contexts/PartyContext";
import { usePlatformContext } from "~/contexts/PlatformContext";
import { type DepositForm } from "~/modules/party/types";

interface IConfirmModalProps {
  isDepositOpen: boolean;
  onCloseDeposit: () => void;
  joinParty: (
    deposit?: number,
    allocate?: boolean,
  ) => Promise<ToastId | null | undefined>;
}

const JoinDeposit = ({
  isDepositOpen,
  onCloseDeposit,
  joinParty,
}: IConfirmModalProps) => {
  const { reloadRoles } = usePlatformContext();
  const { control, handleSubmit, reset, setValue } = useForm<DepositForm>({
    defaultValues: {
      allocate: 1,
    },
  });
  const { partyInfo, isIndexParty, checkIndexParty } = usePartyContext();

  useEffect(() => {
    if (
      isDepositOpen &&
      !!partyInfo &&
      partyInfo.minDeposit === partyInfo.maxDeposit
    ) {
      setValue("amount", partyInfo.minDeposit);
      checkIndexParty(partyInfo.address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDepositOpen]);

  const handleDeposit = async (form: DepositForm) => {
    try {
      onCloseDeposit();
      reset();
      const allocate = isIndexParty ? true : form.allocate === 1;
      await joinParty(form.amount, allocate);
      await reloadRoles();
    } catch (error) {
      console.log(error);
    }
  };

  // styles
  const minMaxBg = useColorModeValue("gray.200", "#141e2a");
  const minMaxColor = useColorModeValue("gray.800", "gray.200");

  return (
    <>
      {partyInfo && (
        <Modal isOpen={isDepositOpen} onClose={onCloseDeposit}>
          <ModalOverlay />
          <ModalContent my="auto" borderRadius="xl">
            <ModalHeader>
              <CloseButton
                aria-label={"Close Button"}
                onClose={onCloseDeposit}
              />
              <Text fontSize="xl" mx="auto" width="fit-content">
                Join {partyInfo.name}
              </Text>
              <Text fontSize="md" mt={4} mx="auto" width="fit-content">
                Make a deposit in order to join this party
              </Text>
            </ModalHeader>
            <ModalBody>
              <Box
                as={"form"}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSubmit={handleSubmit(handleDeposit)}
              >
                <Box
                  py={"1rem"}
                  mb={"1rem"}
                  bg={minMaxBg}
                  borderRadius={"10px"}
                  color={minMaxColor}
                >
                  <Heading
                    fontWeight={"normal"}
                    size={"xs"}
                    mx={"auto"}
                    width={"10rem"}
                    textAlign={"center"}
                  >
                    Min - Max
                  </Heading>
                  <Text
                    fontWeight={"bold"}
                    width={"10rem"}
                    mx={"auto"}
                    textAlign={"center"}
                  >
                    {`${formatNumber(partyInfo.minDeposit)} - ${
                      partyInfo.maxDeposit
                        ? formatNumber(partyInfo.maxDeposit)
                        : "∞"
                    } USDC`}
                  </Text>
                </Box>
                <Box>
                  <Controller
                    control={control}
                    name="amount"
                    render={({
                      field: { ref, onBlur, onChange, value },
                      formState: { errors },
                    }) => {
                      return (
                        <InputNumber
                          id="amount"
                          setRef={ref}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          placeholder={"Enter USDC Amount"}
                          height={"auto"}
                          paddingTop={"1rem"}
                          paddingBottom={"1rem"}
                          borderRadius={"10px"}
                          background={"white"}
                          _placeholder={{ color: "gray.400" }}
                          exponent={{ max: 6, min: 6 }}
                          error={getErrorMessage(
                            "Min deposit",
                            errors.amount?.type,
                            {
                              moreThan: "minimum deposit",
                              lessThanEqual: "maximum deposit",
                            },
                          )}
                        />
                      );
                    }}
                    rules={{
                      required: true,
                      validate: {
                        moreThanEqual: (value) =>
                          Number(value) >= partyInfo.minDeposit,
                        lessThanEqual: (value) =>
                          partyInfo.maxDeposit
                            ? Number(value) <= partyInfo.maxDeposit
                            : true,
                      },
                    }}
                  />
                </Box>
                {!isIndexParty ? (
                  <Box mt={8} width={"fit-content"}>
                    <Text mb={2}>Your deposit options: </Text>
                    <Controller
                      control={control}
                      name="allocate"
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          value={String(value)}
                          onChange={(v) => {
                            onChange(Number(v) as 0 | 1);
                          }}
                          colorScheme="brand"
                        >
                          <Stack>
                            <Radio value="1">
                              <Flex alignItems="center" gap={2}>
                                Deposit and allocate
                                <Tooltip
                                  fontSize="sm"
                                  hasArrow
                                  placement="right"
                                  label="Your deposit will be transferred to party and allocated into the current party holdings portfolio"
                                >
                                  <InfoIcon />
                                </Tooltip>
                              </Flex>
                            </Radio>
                            <Radio value="0">
                              <Flex alignItems="center" gap={2}>
                                Just deposit
                                <Tooltip
                                  fontSize="sm"
                                  hasArrow
                                  placement="right"
                                  label="Your deposit will be just transferred to the party"
                                >
                                  <InfoIcon />
                                </Tooltip>
                              </Flex>
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </Box>
                ) : (
                  <Box mt={8} width={"fit-content"}>
                    <Text mb={2} fontStyle="italic" color="gray.400">
                      *Your deposit will be automatically allocated to the
                      tokens in this index party.
                    </Text>
                  </Box>
                )}
                <Box width={"fit-content"} mx={["auto"]} mt={6} mb={2}>
                  <Button type="submit" minW="8rem" mb="0.5rem">
                    Deposit
                  </Button>
                </Box>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default JoinDeposit;
