import { type SVGProps } from "react";

import { useColorModeValue } from "@chakra-ui/react";

const PartiesArt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 652.62 529.37"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1={84.21}
        y1={267.39}
        x2={139.68}
        y2={188.33}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#e4208b" />
        <stop offset={1} stopColor="#d91cd1" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1={105.7}
        y1={282.47}
        x2={161.17}
        y2={203.41}
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-3"
        x1={127.19}
        y1={297.55}
        x2={182.67}
        y2={218.49}
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-4"
        x1={76.97}
        y1={397.03}
        x2={87.09}
        y2={321.57}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#a31763" />
        <stop offset={1} stopColor="#981493" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-5"
        x1={82}
        y1={399.05}
        x2={92.12}
        y2={323.59}
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-6"
        x1={309.06}
        y1={203.84}
        x2={381.75}
        y2={203.84}
        xlinkHref="#linear-gradient-4"
      />
      <linearGradient
        id="linear-gradient-7"
        x1={314.34}
        y1={203.84}
        x2={387.03}
        y2={203.84}
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-8"
        x1={499.92}
        y1={404.87}
        x2={602.8}
        y2={404.87}
        xlinkHref="#linear-gradient-4"
      />
      <linearGradient
        id="linear-gradient-9"
        x1={507.71}
        y1={404.87}
        x2={610.59}
        y2={404.87}
        xlinkHref="#linear-gradient"
      />
      <style>
        {`.cls-1{fill:${useColorModeValue(
          "#dedede",
          "#1a232f",
        )}}.cls-2{fill:${useColorModeValue(
          "#bcbcbc",
          "#323d4c",
        )}}.cls-8{fill:#fff}.cls-9{fill:#2d3746}.cls-10{fill:#3a4049}`}
      </style>
    </defs>
    <path
      className="cls-1"
      d="M114.84 457.6c-45.16-13.69-83.55-37.33-104-78.15C-69.85 218.76 322.5-61 480.2 11.88c78.61 36.32 76.86 173 128.93 234.23C812.93 486 241.38 495.93 114.84 457.6Z"
    />
    <rect
      className="cls-2"
      x={69.55}
      y={203.84}
      width={506.36}
      height={325.52}
      rx={27.19}
    />
    <circle
      cx={105.35}
      cy={237.26}
      r={9.99}
      style={{
        fill: "url(#linear-gradient)",
      }}
    />
    <circle
      cx={137.42}
      cy={237.26}
      r={9.99}
      style={{
        fill: "url(#linear-gradient-2)",
      }}
    />
    <circle
      cx={169.5}
      cy={237.26}
      r={9.99}
      style={{
        fill: "url(#linear-gradient-3)",
      }}
    />
    <rect
      className="cls-1"
      x={89.27}
      y={273.09}
      width={111.01}
      height={237.27}
      rx={7.65}
    />
    <rect
      className="cls-1"
      x={210}
      y={273.09}
      width={348.76}
      height={237.27}
      rx={13.56}
    />
    <rect
      className="cls-2"
      x={319.98}
      y={306.14}
      width={120.09}
      height={10.39}
      rx={4.14}
    />
    <rect
      className="cls-2"
      x={319.98}
      y={333.85}
      width={189.53}
      height={10.39}
      rx={5.2}
    />
    <rect
      className="cls-2"
      x={227.96}
      y={385.44}
      width={120.09}
      height={10.39}
      rx={4.14}
    />
    <rect
      className="cls-2"
      x={227.96}
      y={413.15}
      width={189.53}
      height={10.39}
      rx={5.2}
    />
    <rect
      className="cls-2"
      x={226.95}
      y={290.8}
      width={74.72}
      height={67.3}
      rx={9.16}
    />
    <path
      d="M124.91 351.66a42.9 42.9 0 0 1-42 42.71 41.12 41.12 0 0 1-42-41.22 42.87 42.87 0 0 1 42-42.7 41.07 41.07 0 0 1 42 41.21Z"
      style={{
        fill: "url(#linear-gradient-4)",
      }}
    />
    <path
      d="M130 353.67a42.9 42.9 0 0 1-42 42.71 41.11 41.11 0 0 1-42-41.22 42.86 42.86 0 0 1 42-42.69 41.07 41.07 0 0 1 42 41.2Z"
      style={{
        fill: "url(#linear-gradient-5)",
      }}
    />
    <path
      className="cls-8"
      d="m97.36 382.69-18.75.33v-21.18l18.75-.34v21.19z"
    />
    <path
      className="cls-8"
      d="M106.35 346.48a2.12 2.12 0 0 0-3.15-1.21c1.22-11.27-4.5-18.58-15.2-18.39s-16.44 7.71-15.22 18.93a2.16 2.16 0 0 0-3.14 1.32c-1 2.49.85 8.26 2 10.08a12.71 12.71 0 0 0 3.24 3.18 14.62 14.62 0 0 0 .67 3.5c.54 1.43 6.66 7.93 8.46 8.91a8.09 8.09 0 0 0 7.95-.14c1.8-1 7.91-7.77 8.45-9.21a15.14 15.14 0 0 0 .68-3.52 13.35 13.35 0 0 0 3.23-3.3c1.17-1.86 3.06-7.7 2.03-10.15Z"
    />
    <path
      className="cls-9"
      d="M74.28 351.7c3.19 0 .78-10.26 3.28-12.79 1.26-1.29 3.12-1.12 4.7-.74a26.13 26.13 0 0 0 5.74 1.06c2.06 0 4-.78 6-1.29 1.54-.4 3.21-.59 4.43.6 2.49 2.44.09 12.75 3.27 12.67-.22-2.37-.23-5 1.52-5.94 1.2-11.27-4.52-18.58-15.22-18.39s-16.44 7.71-15.22 18.93c1.4.9 1.73 3.4 1.5 5.89ZM117.38 383.83A42.42 42.42 0 0 1 88 396.38a41 41 0 0 1-29.38-11.5 91.15 91.15 0 0 1 17.52-5.54c1.55-.32 2.47-.47 2.47-.47a11.22 11.22 0 0 0 7.59 3.72 13.77 13.77 0 0 0 3.51-.13 13.29 13.29 0 0 0 7.65-3.92s.92.12 2.47.39a88 88 0 0 1 17.55 4.9Z"
    />
    <path
      className="cls-10"
      d="m76.07 379.27 2.54-4.58 8.2 7.21-5.02 5.59-5.72-8.22zM99.88 378.85l-2.53-4.5-8.2 7.51 5.02 5.41 5.71-8.42z"
    />
    <path
      d="M381.75 203.19a36.86 36.86 0 0 1-10.94 26.19 1.64 1.64 0 0 1-.3.29c-.5.5-1 1-1.54 1.43a36.78 36.78 0 0 1-23.56 9.09 35.62 35.62 0 0 1-22.36-7.3c-.63-.49-1.25-1-1.85-1.51-.35-.31-.7-.63-1-1a35.53 35.53 0 0 1-11.1-25.93 37.14 37.14 0 0 1 36.35-37 35.55 35.55 0 0 1 36.3 35.74Z"
      style={{
        fill: "url(#linear-gradient-6)",
      }}
    />
    <path
      d="M387 203.19a36.86 36.86 0 0 1-10.94 26.19 1.64 1.64 0 0 1-.3.29c-.5.5-1 1-1.54 1.43a36.78 36.78 0 0 1-23.56 9.09 35.62 35.62 0 0 1-22.36-7.3c-.63-.49-1.25-1-1.85-1.51-.35-.31-.7-.63-1-1a35.53 35.53 0 0 1-11.1-25.93 37.14 37.14 0 0 1 36.35-37 35.55 35.55 0 0 1 36.3 35.74Z"
      style={{
        fill: "url(#linear-gradient-7)",
      }}
    />
    <path
      className="cls-1"
      d="M376.09 229.38v.39l-.27-.1c-.5.5-1 1-1.54 1.43l-22.26.11h-.92l-22.74 1.66c-.63-.49-1.25-1-1.85-1.51a11.38 11.38 0 0 1-.14-1.25c-.6-9.07 8.91-8.4 6.71-20.26-.88-4.74-.22-18.55 7.65-21.79 2.29-3.26 6.73-5 11.27-4.8 14.25.65 19.43 16.55 17.27 29.18-.95 9.56 7.36 9.46 6.82 16.94Z"
    />
    <path
      className="cls-8"
      d="m358.59 229.33-14.32.26v-18.27l14.32-.25v18.26z"
    />
    <path
      className="cls-9"
      d="M375.79 229.67c-.5.5-1 1-1.54 1.43a36.78 36.78 0 0 1-23.56 9.09 35.62 35.62 0 0 1-22.36-7.3c-.63-.49-1.25-1-1.85-1.51-.35-.31-.7-.63-1-1l.9-.29a151.62 151.62 0 0 1 17.9-4.09c3.68 3.86 9.6 3.8 14.31-.18a.73.73 0 0 1 .09-.08 127.33 127.33 0 0 1 17.11 3.93Z"
    />
    <path
      className="cls-8"
      d="M351.41 189.08c-9.22.18-11.32 7.13-11.13 14.08 0 5 0 8.72.44 10s3.7 8.63 10.69 8.52c7-.13 10.24-7.65 10.7-8.9s.44-4.95.44-10c.19-6.98-1.91-13.85-11.14-13.7Z"
    />
    <path
      className="cls-8"
      d="M340.69 202.92s-1.86-2.7-3.76-1.07-.06 10.67 3.76 9 0-7.93 0-7.93ZM362.18 202.53s1.85-2.76 3.76-1.2.05 10.67-3.76 9.14 0-7.94 0-7.94Z"
    />
    <path
      className="cls-1"
      d="M340.22 202.37a9.48 9.48 0 0 1 3.56-7.5c3.29 5.7 12.35 7.5 20.61 4.49 0 0-.8-13.82-13.72-12.77s-10.45 15.78-10.45 15.78Z"
    />
    <path
      d="M602.8 403.94a52.06 52.06 0 0 1-11.63 32.77s0 0 0 .05a52.2 52.2 0 0 1-39.77 19.55 50.38 50.38 0 0 1-51.45-50.54 52.55 52.55 0 0 1 51.45-52.34 50.32 50.32 0 0 1 51.4 50.51Z"
      style={{
        fill: "url(#linear-gradient-8)",
      }}
    />
    <path
      d="M610.59 403.94A52.06 52.06 0 0 1 599 436.71s0 0 0 .05a52.2 52.2 0 0 1-39.77 19.55 50.38 50.38 0 0 1-51.45-50.54 52.55 52.55 0 0 1 51.45-52.34 50.32 50.32 0 0 1 51.36 50.51Z"
      style={{
        fill: "url(#linear-gradient-9)",
      }}
    />
    <ellipse
      className="cls-1"
      cx={559.03}
      cy={428.83}
      rx={13.13}
      ry={1.86}
      transform="rotate(-1.04 558.63 428.752)"
    />
    <path
      className="cls-8"
      d="m570.8 438.97-23.27.41v-26.27l23.27-.42v26.28z"
    />
    <path
      className="cls-8"
      d="M582.65 395.45a2.7 2.7 0 0 0-4-1.54c1.56-14.41-5.75-23.77-19.47-23.53s-21 9.87-19.46 24.22a2.77 2.77 0 0 0-4 1.68c-1.32 3.19 1.1 10.57 2.59 12.9a16.32 16.32 0 0 0 4.14 4.07 19 19 0 0 0 .87 4.47c.69 1.83 8.51 10.15 10.81 11.4a10.34 10.34 0 0 0 10.17-.18c2.3-1.33 10.12-9.93 10.81-11.79a19.1 19.1 0 0 0 .86-4.5 17 17 0 0 0 4.14-4.21c1.45-2.39 3.89-9.85 2.54-12.99Z"
    />
    <path
      className="cls-9"
      d="M572.53 374.54a16.72 16.72 0 0 0-3-2 21.67 21.67 0 0 0-10.37-2.15c-13.71.25-21 9.87-19.45 24.23 1.46.91 2 3.19 2 5.71 0 .6 0 1.19-.1 1.81 0 0-.08 1.44-.08 3.44A52.7 52.7 0 0 0 542 413a17.62 17.62 0 0 0 1 4c.2.42.4.89.62 1.36a20.42 20.42 0 0 0 3.89 6.09 13 13 0 0 0 4.13 3 4.46 4.46 0 0 0 .55.25 16.77 16.77 0 0 0 6.95 1.17 17.14 17.14 0 0 0 7-1.44 3.64 3.64 0 0 0 .49-.22 14.3 14.3 0 0 0 3.84-2.8c.1-.1.19-.2.29-.32a21.15 21.15 0 0 0 3.89-6.21c.22-.5.42-1 .62-1.44a16.94 16.94 0 0 0 1-4 62.9 62.9 0 0 0 .37-10.84c-.07-.81-.12-1.63-.12-2.42 0-2.23.42-4.26 2.05-5.18.98-8.65-1.22-15.43-6.04-19.46Zm-26.71 11.23c1.63-1.66 4-1.43 6-.94a32.43 32.43 0 0 0 7.3 1.34c2.62 0 5.15-1 7.67-1.63 2-.52 4.11-.77 5.67.76 1.26 1.21 1.54 4 1.66 6.91 0 0-.25 13.21-2.3 16.13s-1.39-3.49-12.7-3.41-10.67 6.7-12.69 3.86-2.31-16-2.31-16c.17-2.96.46-5.79 1.7-7.02ZM599 436.71v.05a52.2 52.2 0 0 1-39.77 19.55 50.41 50.41 0 0 1-39.65-18c10.94-2.8 26.39-4.08 26.39-4.08a15.28 15.28 0 0 0 1.63 1.28c6.73 4.78 15.91 4.46 23.26-.74a16.5 16.5 0 0 0 1.36-1s15.72.46 26.78 2.94Z"
    />
    <path
      className="cls-9"
      d="M545.9 429.06v6.58c9.32 6.71 18.07 6.65 26.25-.46v-6.58c-4.63 3.08-23.28 3-26.25.46Z"
    />
  </svg>
);

export default PartiesArt;
