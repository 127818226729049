/**
 * Formats a balance to a human readable, comma-separated number string
 * @param balance - balance
 * @param maxDecimals - default 5
 * @returns
 */
export function formatBalance(balance: number, maxDecimals = 5) {
  if (typeof balance === "number" && balance === 0) return "0";
  const [left, right] = String(balance).split(".");

  const formattedLeft = [...(left || "")]
    .reverse()
    .map((x, i) => (i && i % 3 === 0 ? `${x},` : x))
    .reverse()
    .join("");

  const formattedRight =
    !right || right === "0"
      ? ""
      : right?.length > maxDecimals
      ? right.slice(0, maxDecimals)
      : right;

  return [formattedLeft, formattedRight].filter(Boolean).join(".");
}
