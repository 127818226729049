import { type ReactNode } from "react";

import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  type UseDisclosureProps,
} from "@chakra-ui/react";

import { Button, CloseButton } from "~/components/Buttons";

interface InsufficientPfiProps extends UseDisclosureProps {
  onClose: () => void;
  isOpen: boolean;
  title: string;
  children: ReactNode;
}

const InsufficientPfi = ({
  isOpen,
  onClose,
  title,
  children,
}: InsufficientPfiProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent my="auto">
        <ModalHeader>
          <CloseButton aria-label={"Close Button"} onClose={onClose} />
          <Text fontSize="xl" mx="auto" width="fit-content">
            {title}
          </Text>
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter my="1rem">
          <Flex flexWrap="wrap" maxW="26rem">
            <Button
              minW="12rem"
              mr={["auto", "0.5rem"]}
              ml={["auto"]}
              mb="0.5rem"
              onClick={onClose}
            >
              <a
                href="https://pancakeswap.finance/swap?outputCurrency=0x6e3bf2fff13e18413d3780f93753d6cff5aee3e1"
                rel="noreferrer"
                target="_blank"
              >
                Buy on Pancakeswap
              </a>
            </Button>
            <Button
              variant="outline"
              bg="transparent"
              minW="12rem"
              mx={["auto"]}
              onClick={onClose}
            >
              <a
                href="https://app.uniswap.org/#/swap?inputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&outputCurrency=0x48592de8cded16f6bb56c896fe1affc37630889c"
                rel="noreferrer"
                target="_blank"
              >
                Buy on Uniswap
              </a>
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InsufficientPfi;
