/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";

import {
  type ContractReceipt,
  type ContractTransaction,
} from "@ethersproject/contracts";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { useSigner } from "wagmi";

import { PartyFactory__factory, Party__factory } from "@partyfinance/contracts";
import { type PartyCreatedEvent } from "@partyfinance/contracts/src/types/PartyFactory";
import { getPFIAddress, getUSDCAddress } from "@partyfinance/core";

import { addPlatformFee } from "~/utils/web3";

import { usePartyContext } from "~/contexts/PartyContext";
import { useTransactionContext } from "~/contexts/TransactionContext";
import { usePlatformCheck } from "~/hooks/platform";
import { useERC20Contract } from "~/hooks/token";
import { handleCustomError } from "~/modules/party/errors";
import {
  TransactionActions,
  type PartyCreateForm,
} from "~/modules/party/types";

import { usePartyFactoryContract } from ".";

export interface CreatePartyHandlerParams {
  partyForm: PartyCreateForm;
}
export interface ICreatedPartyResp {
  address: string;
  name: string;
  image: string;
  chainId: number;
}

const _steps = [
  {
    label: "Check balance",
    content:
      "Fund your wallet with the initial deposit + the platform fee of 0.5%",
    loading: true,
  },
  {
    label: "Allow pFI spend",
    content:
      "Confirm the pFI spend allowance in your wallet. This allows the Party Factory to use your pFI for paying the creation fee",
  },
  {
    label: "Allowance confirmation",
    content: "Please wait until the pFi allowance transaction is confirmed",
    loading: true,
    showGame: true,
  },
  {
    label: "Allow USDC spend",
    content:
      "Confirm the initial USDC spend allowance in your wallet. This allows the Party Factory to use your USDC for depositing into the party",
  },
  {
    label: "Allowance confirmation",
    content: "Please wait until the USDC allowance transaction is confirmed",
    loading: true,
    showGame: true,
  },
  {
    label: "Create party",
    content: "Confirm the party creation transaction in your wallet",
  },
  {
    label: "Party confirmation",
    content: "Please wait until the party creation is confirmed",
    loading: true,
    showGame: true,
  },
];
const stepsWithGates = [
  {
    label: "Add Token Gates",
    content: "Confirm adding token gates in your wallet.",
  },
  {
    label: "Awaiting confirmation",
    content: "Please wait until the token gates update has been confirmed.",
    loading: true,
    showGame: true,
  },
];
const stepsWithoutPfi = _steps.filter((_, i) => i !== 1 && i !== 2);

const useCreateParty = (withoutPfiAllowance = true) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createdParty, setCreatedParty] = useState<ICreatedPartyResp>();
  const { data: signer } = useSigner();
  const { chainId } = usePartyContext();
  const { checkWallet } = usePlatformCheck();
  const pfiContract = useERC20Contract(getPFIAddress(chainId));
  const usdcContract = useERC20Contract(getUSDCAddress(chainId));
  const partyFactoryContract = usePartyFactoryContract();
  const {
    addTransaction,
    updateTransaction,
    openConfirmationWithSteps,
    closeConfirmationWithSteps,
    setStep,
    setErrorMsg,
  } = useTransactionContext();

  function getCreatedPartyAddressFromReceipt(receipt: ContractReceipt) {
    const contractInterface = PartyFactory__factory.createInterface();
    const eventTopic = contractInterface.getEventTopic("PartyCreated");
    const log = receipt.logs.find((log) => log.topics[0] === eventTopic);
    if (!log) return undefined;
    const createEvent = contractInterface.parseLog(log) as unknown as Pick<
      PartyCreatedEvent,
      "args"
    >;
    return createEvent.args.partyAddress;
  }

  const createParty = async ({ partyForm }: CreatePartyHandlerParams) => {
    const account = checkWallet();
    if (!account || !usdcContract || !partyFactoryContract) return null;
    setIsLoading(true);

    // Pre check pFi: Party creation fee
    const creationFee = await partyFactoryContract.PFI_FEE();
    const isCreationFeeEnabled =
      !withoutPfiAllowance || (!creationFee.isZero() && !!pfiContract);
    const steps = [...(isCreationFeeEnabled ? _steps : stepsWithoutPfi)];

    // Extends steps to contain tx steps for adding token gates
    const hasTokenGates = partyForm.tokenGates.length > 0;
    if (hasTokenGates) steps.push(...stepsWithGates);
    console.log(steps);
    openConfirmationWithSteps(steps);
    let txHash = "";
    let withPfiSteps = isCreationFeeEnabled;

    try {
      // Pre check pFi: Party creation fee
      if (pfiContract && !creationFee.isZero()) {
        // Change steps
        openConfirmationWithSteps(steps);
        // Check if account holds enought pFi to pay
        const pfiBalance = await pfiContract.balanceOf(account);
        if (pfiBalance.lt(creationFee)) {
          throw Error(
            `Your pFi balance is not enough. Fund your wallet with at least ${formatUnits(
              pfiBalance,
            )} pFI to pay the fee for creating a party.`,
          );
        }
        // Check pFi allowance
        const pFiAllowance = await pfiContract.allowance(
          account,
          partyFactoryContract.address,
        );
        if (pFiAllowance.lt(creationFee)) {
          console.log("User needs to approve pFi");
          setStep(1);
          const approval: ContractTransaction = await pfiContract.approve(
            partyFactoryContract.address,
            creationFee,
          );
          setStep(2);
          addTransaction(TransactionActions.approvePFI, approval.hash);
          txHash = approval.hash;
          await approval.wait();

          updateTransaction(txHash, true, false);
          txHash = "";
        }
      } else {
        withPfiSteps = false;
      }

      const usdcDecimals = await usdcContract.decimals();

      // 1) Check if user has available funds to create the party (deposit + fee)
      const initialDeposit = parseUnits(
        partyForm.initialDeposit.toString(),
        usdcDecimals,
      );
      const pay = addPlatformFee(initialDeposit);

      const balance = await usdcContract.balanceOf(account);
      const isSufficient = balance.gte(pay);

      if (!isSufficient) {
        const requiredUSDC = formatUnits(pay, "mwei");
        throw Error(
          `Your balance is not enough. Fund your wallet with at least ${requiredUSDC} USDC (initial deposit + 0.5% fee).`,
        );
      }

      // 2) Approve Factory DA consumption (in case it requiers)
      const allowance = await usdcContract.allowance(
        account,
        partyFactoryContract.address,
      );
      if (allowance.lt(pay)) {
        console.log("User needs to approve usdc");
        setStep(1 + (withPfiSteps ? 2 : 0));
        const approval: ContractTransaction = await usdcContract.approve(
          partyFactoryContract.address,
          pay,
        );
        setStep(2 + (withPfiSteps ? 2 : 0));
        addTransaction(TransactionActions.approveUSDC, approval.hash);
        txHash = approval.hash;
        await approval.wait();
        updateTransaction(txHash, true, false);
        txHash = "";
      }

      // 3) Create Party
      setStep(3 + (withPfiSteps ? 2 : 0));
      const newParty: ContractTransaction =
        await partyFactoryContract.createParty(
          {
            name: partyForm.name,
            bio: partyForm.bio,
            img: partyForm.img,
            model: partyForm.type, // Party type
            purpose: partyForm.purpose, // Party purpose
            isPublic: partyForm.isPublic, // isPublic
            minDeposit: parseUnits(
              partyForm.minDeposit.toString(),
              usdcDecimals,
            ), // min deposit
            maxDeposit: partyForm.enable_maxDeposit
              ? parseUnits(partyForm.maxDeposit.toString(), usdcDecimals)
              : 0, // max deposit
          },
          partyForm.symbol || "PARTY", // Party Token symbol
          initialDeposit,
          usdcContract.address,
        );
      setStep(4 + (withPfiSteps ? 2 : 0));
      addTransaction(TransactionActions.createParty, newParty.hash);
      txHash = newParty.hash;

      const confirmedTx: ContractReceipt = await newParty.wait(2);
      updateTransaction(txHash, true, true);
      txHash = "";

      // 4) Get Party address
      const createdPartyAddress =
        getCreatedPartyAddressFromReceipt(confirmedTx);

      // 5) Create Token Gates
      if (hasTokenGates && !!signer && !!account && !!createdPartyAddress) {
        console.log("start: create token gates");
        setStep(5 + (withPfiSteps ? 2 : 0));
        console.log("provider: create token gates");

        const partyContract = Party__factory.connect(
          createdPartyAddress,
          signer,
        );
        const gates = partyForm.tokenGates.map((gate) => ({
          token: gate.address,
          amount: parseUnits(String(gate.amount), gate.decimals),
        }));

        const gatesTx = await partyContract.editTokenGates(gates);
        txHash = gatesTx.hash;
        addTransaction(TransactionActions.addTokenGates, txHash);
        // Wait for transaction to be confirmed
        setStep(6 + (withPfiSteps ? 2 : 0));
        await gatesTx.wait();
        updateTransaction(txHash, true, true);
        txHash = "";
      }

      closeConfirmationWithSteps();
      setCreatedParty({
        address: createdPartyAddress || "",
        name: partyForm.name,
        image: partyForm.img,
        chainId,
      });
      setIsLoading(false);
    } catch (err: any) {
      console.log(err);
      const customError = handleCustomError(err);
      setErrorMsg(customError.message);
      if (txHash) {
        updateTransaction(txHash, false, true);
      }
      setIsLoading(false);
    }
  };

  return {
    createParty,
    createdParty,
    isLoading,
  };
};

export default useCreateParty;
