import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  type ChakraComponent,
  type FormControlProps,
  type FormLabelProps,
} from "@chakra-ui/react";

export interface IInputBaseProps {
  id: string;
  label?: string;
  children: React.ReactNode;
  error?: string | boolean;
  helper?: string | boolean;
  labelOptions?: FormLabelProps;
  formControlProps?: FormControlProps;
}

const InputBase: ChakraComponent<"div", IInputBaseProps> = ({
  id,
  label,
  children,
  error,
  helper,
  labelOptions,
  formControlProps,
}) => {
  return (
    <FormControl id={id} isInvalid={!!error} {...formControlProps}>
      {label && (
        <FormLabel fontFamily="heading" {...labelOptions}>
          {label}
        </FormLabel>
      )}
      {children}
      {error ? (
        <FormErrorMessage>{error}</FormErrorMessage>
      ) : helper ? (
        <FormHelperText>{helper}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default InputBase;
