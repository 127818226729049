import { createIcon } from "@chakra-ui/react";

const PolygonIcon = createIcon({
  displayName: "PolygonIcon",
  viewBox: "0 0 16 13",
  path: (
    <path
      fill="#8247e5"
      d="M11.572 3.888c-.264-.15-.604-.15-.906 0L8.552 5.134l-1.434.793-2.076 1.245c-.265.151-.604.151-.906 0l-1.624-.981a.92.92 0 0 1-.453-.793V3.511c0-.302.151-.604.453-.793l1.624-.944c.264-.15.604-.15.906 0l1.623.982a.92.92 0 0 1 .453.792v1.246l1.434-.83V2.68a.931.931 0 0 0-.453-.793L5.08.113c-.264-.15-.604-.15-.906 0L1.078 1.925a.83.83 0 0 0-.453.755v3.55c0 .302.151.604.453.792l3.058 1.774c.264.151.604.151.906 0l2.076-1.207 1.434-.831 2.076-1.208c.265-.15.604-.15.906 0l1.624.944a.92.92 0 0 1 .453.793v1.887a.931.931 0 0 1-.453.793l-1.586.943c-.264.151-.604.151-.906 0l-1.623-.943a.92.92 0 0 1-.453-.793V7.965l-1.434.83v1.246c0 .302.15.604.453.793l3.057 1.774c.264.151.604.151.906 0l3.058-1.774a.92.92 0 0 0 .453-.793V6.455a.931.931 0 0 0-.453-.793l-3.058-1.774z"
    />
  ),
});

export default PolygonIcon;
