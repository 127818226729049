import { useMemo } from "react";

import { isAddress } from "@ethersproject/address";
import { Contract, type ContractInterface } from "@ethersproject/contracts";
import { JsonRpcProvider } from "@ethersproject/providers";
import { useProvider } from "wagmi";

import { getClientInfuraRPC } from "~/utils/web3/rpc";

import { usePartyContext } from "~/contexts/PartyContext";

// Purpose to be used with any JSON RPC provider rather than the user's wallet connected RPC
export default function useReadContract<T extends Contract = Contract>(
  address: string,
  ABI: ContractInterface,
): T | null {
  const userProvider = useProvider();
  const { chainId } = usePartyContext();

  return useMemo(() => {
    if (!isAddress(address)) return null;
    try {
      if (userProvider) {
        return new Contract(address, ABI, userProvider);
      } else {
        const RPC_URL = getClientInfuraRPC(chainId);
        if (!RPC_URL) {
          console.error("RPC is not defined");
          return null;
        }
        const provider = new JsonRpcProvider(RPC_URL);
        return new Contract(address, ABI, provider);
      }
    } catch (error) {
      console.error("Failed To Get Contract", error);
      return null;
    }
  }, [address, ABI, chainId, userProvider]) as T;
}
