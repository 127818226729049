import NextLink from "next/link";
import { useRouter } from "next/router";

import {
  Box,
  Flex,
  Icon,
  LinkBox,
  LinkOverlay,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { type IconType } from "react-icons";

interface PathItemProps {
  path: string;
  name: string;
  routerPath?: string;
  isSidebarOpen: boolean;
  icon: IconType;
  disabled?: boolean;
  activeBg: string;
  activeIconColor: string;
  activeTextColor: string;
  inactiveBgHover: string;
}

const PathItem = ({
  path,
  name,
  routerPath,
  icon,
  disabled,
  activeBg,
  activeIconColor,
  activeTextColor,
  inactiveBgHover,
  isSidebarOpen,
}: PathItemProps) => {
  const isActive = routerPath === path && routerPath !== "/";
  return (
    <LinkBox>
      <Tooltip
        isDisabled={isSidebarOpen}
        hasArrow
        placement="right"
        label={name}
        shouldWrapChildren={false}
      >
        <Box
          px={4}
          py={"10px"}
          background={isActive ? activeBg : "transparent"}
          justifyContent="center"
          opacity={isActive ? 1 : disabled ? 0.5 : 1}
          _hover={isActive ? {} : { bg: inactiveBgHover }}
          borderLeftWidth={isActive ? 4 : ""}
          borderLeftColor={isActive ? "brand.300" : ""}
        >
          <LinkOverlay as={NextLink} href={path}>
            <Flex
              alignItems="center"
              justifyContent={isSidebarOpen ? "flex-start" : "center"}
            >
              <Icon
                as={icon}
                fontSize={isSidebarOpen ? "18px" : "22px"}
                color={isActive ? activeIconColor : "gray.500"}
              />
              {isSidebarOpen && (
                <Text
                  color={isActive ? activeTextColor : ""}
                  fontSize="15px"
                  ml={4}
                  fontWeight={500}
                >
                  {name}
                </Text>
              )}
            </Flex>
          </LinkOverlay>
        </Box>
      </Tooltip>
    </LinkBox>
  );
};

interface IPopupNavItem {
  modal: {
    onOpen?: () => void;
    onClose: () => void;
    isOpen: boolean;
  };
  inactiveBgHover: string;
  icon: IconType;
  name: string;
  isSidebarOpen: boolean;
}

const PopupNavItem = ({
  modal,
  inactiveBgHover,
  icon,
  name,
  isSidebarOpen,
}: IPopupNavItem) => {
  return (
    <Box onClick={modal.onOpen}>
      <Tooltip
        isDisabled={isSidebarOpen}
        hasArrow
        placement="right"
        label={name}
        shouldWrapChildren={false}
      >
        <Box
          px={4}
          py={"10px"}
          justifyContent="center"
          _hover={{ bg: inactiveBgHover }}
        >
          <Flex
            alignItems="center"
            justifyContent={isSidebarOpen ? "flex-start" : "center"}
          >
            <Icon
              as={icon}
              fontSize={isSidebarOpen ? "18px" : "22px"}
              color="gray.500"
            />
            {isSidebarOpen && (
              <Text fontSize="15px" ml={4} fontWeight={500}>
                {name}
              </Text>
            )}
          </Flex>
        </Box>
      </Tooltip>
    </Box>
  );
};

export interface NavItemProps {
  path?: string;
  name: string;
  routerPath?: string;
  authorized?: boolean;
  icon: IconType;
  modal?: {
    onOpen: () => void;
    onClose: () => void;
    isOpen: boolean;
  };
  disabled?: boolean;
  isSidebarOpen?: boolean;
}

const NavItem = ({
  path,
  routerPath,
  name,
  icon,
  modal,
  disabled,
  isSidebarOpen = true,
}: NavItemProps) => {
  const router = useRouter();
  const { partyId, chain } = router.query;

  // Chakra Color Mode
  const activeBg = useColorModeValue("brand.50", "#303235");
  const inactiveBgHover = useColorModeValue("gray.50", "#303235");
  const activeIconColor = useColorModeValue("brand.500", "white");
  const activeTextColor = useColorModeValue("brand.500", "white");

  if (path && partyId && typeof partyId === "string") {
    path = path.replace("[partyId]", partyId);
  }
  if (path && chain && typeof chain === "string") {
    path = path.replace("[chain]", chain);
  }

  return (
    <Box cursor="pointer" borderRadius="xl" overflow="hidden">
      {path ? (
        <PathItem
          path={path}
          routerPath={routerPath}
          name={name}
          icon={icon}
          disabled={disabled}
          activeBg={activeBg}
          inactiveBgHover={inactiveBgHover}
          activeIconColor={activeIconColor}
          activeTextColor={activeTextColor}
          isSidebarOpen={isSidebarOpen}
        />
      ) : modal ? (
        <PopupNavItem
          icon={icon}
          inactiveBgHover={inactiveBgHover}
          name={name}
          modal={modal}
          isSidebarOpen={isSidebarOpen}
        />
      ) : null}
    </Box>
  );
};

export default NavItem;
