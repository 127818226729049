import { useMemo } from "react";

import { Contract, type ContractInterface } from "@ethersproject/contracts";
import { useSigner } from "wagmi";

import { usePartyContext } from "~/contexts/PartyContext";

export default function useContract<T extends Contract = Contract>(
  address: string,
  ABI: ContractInterface,
): T | null {
  const { chainId } = usePartyContext();
  const { data: signer } = useSigner();

  return useMemo(() => {
    if (!address || !ABI || !signer || !chainId) {
      return null;
    }

    try {
      return new Contract(address, ABI, signer);
    } catch (error) {
      console.error("Failed To Get Contract", error);
      return null;
    }
  }, [address, ABI, signer, chainId]) as T;
}
