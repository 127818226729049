import { createElement, useCallback, useState, type ReactElement } from "react";

import { useDisclosure } from "@chakra-ui/react";
import { type ContractTransaction } from "@ethersproject/contracts";

import { getPartyPunkAddress } from "@partyfinance/core";

import { PunkRequiredModal } from "~/components/Modals";

import toast from "~/compounds/toast";
import { pPunksValidationEnabled } from "~/config";
import { usePartyContext } from "~/contexts/PartyContext";
import { useTransactionContext } from "~/contexts/TransactionContext";
import { useERC721Contract } from "~/hooks/token";
import { handleCustomError } from "~/modules/party/errors";
import { TransactionActions } from "~/modules/party/types";

import usePlatformCheck from "./usePlatformCheck";

const mintPartyPunkSteps = [
  {
    label: "Mint PartyPunk",
    content: "Confirm minting action in your wallet",
  },
  {
    label: "Awaiting confirmation",
    content: "Please wait until the PartyPunk mint is confirmed",
    loading: true,
    showGame: true,
  },
];

export default function usePlatformNFT() {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [holdings, setHoldings] = useState<number[]>([]);
  const [mintPrice, setMintPrice] = useState<number>(0);
  const [remainingPunks, setRemainingPunks] = useState<number>(0);
  const { chainId } = usePartyContext();
  const { checkWallet } = usePlatformCheck();
  const contract = useERC721Contract(getPartyPunkAddress(chainId));
  const {
    addTransaction,
    updateTransaction,
    openConfirmationWithSteps,
    setStep,
    reset,
    setErrorMsg,
    closeConfirmationWithSteps,
  } = useTransactionContext();

  const isPunkHolder = async () => {
    if (!pPunksValidationEnabled) return true;

    const account = checkWallet();
    if (!account || !contract) return false;
    const balance = await contract.balanceOf(account);
    console.log("balance", balance, balance.toNumber());
    const isHolder = balance.toNumber() > 0;
    console.log("isHolder", isHolder);
    if (isHolder) return true;
    onOpen();
    return false;
  };

  const getPunksInfo = () => {
    // Interact with contract.
    // DEMO.
    const _mintPrice = 100;
    const _remainingPunks = 10000;
    setMintPrice(_mintPrice);
    setRemainingPunks(_remainingPunks);
    return { mintPrice: _mintPrice, remainingPunks: _remainingPunks };
  };

  const getHoldings = async () => {
    const account = checkWallet();
    if (!account || !chainId || !contract) return [];
    const balance = await contract.balanceOf(account);
    const promises: Promise<number | null>[] = [];
    // console.log('NFTs holded by user', balance.toNumber())
    for (let i = 0; i < balance.toNumber(); i++) {
      promises.push(
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        new Promise(async (resolve) => {
          try {
            const tokenByIndex = await contract.tokenOfOwnerByIndex(account, i);
            console.log("tokenByIndex", tokenByIndex, "i", i);
            resolve(tokenByIndex.toNumber());
          } catch (error) {
            resolve(null);
          }
        }),
      );
    }
    try {
      const _holdings = await Promise.all(promises);
      const newHoldings = _holdings.filter((x) => x !== null) as number[];
      setHoldings(newHoldings);
      return newHoldings;
    } catch (error) {
      console.log("error fetching users party punks", error);
      return [];
    }
  };

  const mintPartyPunk = async () => {
    const account = checkWallet();
    if (!account || !contract) return false;
    reset();
    openConfirmationWithSteps(mintPartyPunkSteps);
    let txHash = "";

    try {
      const newMint: ContractTransaction = await contract.mint();
      txHash = newMint.hash;
      addTransaction(TransactionActions.mintPartyPunk, txHash);
      setStep(1);
      await newMint.wait();
      updateTransaction(txHash, true, true);
      txHash = "";
      closeConfirmationWithSteps();
      toast.success(
        {
          title: "PartyPunk minted!",
          body: "Please wait a couple of seconds until the PartyPunk appears in your profile",
        },
        { autoClose: 5000 },
      );
    } catch (err) {
      console.log(err);
      const customError = handleCustomError(err);
      setErrorMsg(customError.message);
      if (txHash) {
        updateTransaction(txHash, false, true);
      }
    }
  };

  const PunkRequired = useCallback(
    (): ReactElement =>
      createElement(PunkRequiredModal, { onClose, isOpen }, []),
    [isOpen, onClose],
  );

  return {
    holdings,
    mintPrice,
    remainingPunks,
    getPunksInfo,
    mintPartyPunk,
    partyPunkNFTContract: contract,
    getHoldings,
    isPunkHolder,
    PunkRequired,
  };
}
