/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef } from "react";

import { useRouter } from "next/router";

import { AddIcon, CheckCircleIcon, MinusIcon } from "@chakra-ui/icons";
import {
  Box,
  Button as ChakraButton,
  Heading,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  VStack,
  useDisclosure,
  type ButtonProps,
} from "@chakra-ui/react";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import { api } from "~/utils/api";
import { getErrorMessage } from "~/utils/form/errorMessage";

import { Button } from "~/components/Buttons";
import { InputText, InputTextarea } from "~/components/Inputs";
import { Modal, ShareModal, type ShareModalRef } from "~/components/Modals";

import toast from "~/compounds/toast";
import { useGovernanceContext } from "~/contexts/GovernanceContext";
import { PollTypeEnum, type PollForm } from "~/modules/vote/types";

const CreatePollButton = ({ ...btnProps }: ButtonProps) => {
  const router = useRouter();
  const { isPlatinumApeHolder, userApes } = useGovernanceContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Api hooks
  const voteMutation = api.poll.create.useMutation();

  // Poll Form
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm<PollForm>({
    defaultValues: {
      type: PollTypeEnum.Simple,
    },
  });
  // Poll.options fields
  const {
    fields,
    append: addOption,
    remove: removeOption,
  } = useFieldArray({
    control,
    name: "options",
  });

  // ShareModal
  const shareModalRef = useRef<ShareModalRef>(null);

  const handleOpenCreationModal = () => {
    const isHolder = isPlatinumApeHolder();
    if (!isHolder) return;
    reset();
    onOpen();
  };

  const handleCreatePoll = async (form: PollForm) => {
    if (
      form.options &&
      form.options.filter((x) => x.description.length > 64).length > 0
    ) {
      toast.dismiss();
      toast.info({ title: "Poll options can have max 64 characters" });
      return;
    }
    const createdPollId = await voteMutation.mutateAsync({
      // @dev: for now just use the first plat ape ID to be found.
      // This requirement might change if we decide to restrict platinum holders to only create one poll per platinum ape
      creatorPartyApeId: userApes.platinum.ids[0] || 0,
      name: form.title,
      description: form.description,
      endDate: new Date(form.endDate).getTime(),
      type: form.type,
      options:
        form.type === PollTypeEnum.Simple
          ? ["yes", "no"]
          : form.options?.map((x) => x.description) ?? [],
    });

    if (shareModalRef.current) {
      shareModalRef.current.openModal(
        `${window.location.origin.toString()}/vote/${createdPollId}`,
        () => void router.push(`/vote/${createdPollId}`),
      );
    }
    onClose();
    return true;
  };

  // have to set type default manually, since react-hook-form defaultValue for selects doesn't work
  useEffect(() => {
    setValue("type", PollTypeEnum.Simple);
  }, []);

  useEffect(() => {
    if (watch("type") === PollTypeEnum.Multi) {
      setValue("options", [{ description: "" }, { description: "" }]);
    }
    if (watch("type") === PollTypeEnum.Simple) {
      setValue("options", undefined);
    }
  }, [watch("type")]);

  return (
    <>
      <Button
        w={36}
        onClick={() => void handleOpenCreationModal()}
        {...btnProps}
      >
        Create Poll
      </Button>
      <ShareModal ref={shareModalRef}>
        <CheckCircleIcon boxSize={16} color={"green.500"} />
        <Heading size="md" mt={6} mb={4}>
          Your proposal has been created!
        </Heading>
        <Text mt={4} mb={6}>
          Invite others to vote
        </Text>
      </ShareModal>
      <Modal isOpen={isOpen} onClose={onClose} title={"New Proposal"} size="xl">
        <Box
          as={"form"}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(handleCreatePoll)}
          px={6}
        >
          {/* Title */}
          <Box width={"100%"} mb={4}>
            <Controller
              control={control}
              name="title"
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => {
                return (
                  <InputText
                    id="input-title"
                    label="Title"
                    disabled={isSubmitting}
                    placeholder="Enter the poll title"
                    width="100%"
                    maxLength={64}
                    setRef={ref}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    error={getErrorMessage("Title", errors.title?.type)}
                  />
                );
              }}
              rules={{
                required: {
                  value: true,
                  message: "Title is required",
                },
              }}
            />
          </Box>
          {/* Description */}
          <Box width={"100%"} mb={4}>
            <Controller
              control={control}
              name="description"
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => {
                return (
                  <InputTextarea
                    id="input-description"
                    label="Content"
                    placeholder="Enter the poll content here"
                    disabled={isSubmitting}
                    width="100%"
                    height={40}
                    maxLength={5000}
                    setRef={ref}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    error={getErrorMessage(
                      "Description",
                      errors.description?.type,
                    )}
                  />
                );
              }}
              rules={{
                required: {
                  value: true,
                  message: "Description is required",
                },
              }}
            />
          </Box>

          {/* Poll Type */}
          <Box width={"100%"} mb={4}>
            <Controller
              control={control}
              name="type"
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <VStack align={"start"}>
                    <Text>Vote Options</Text>
                    <Select value={value} onChange={onChange} ref={ref}>
                      <option value={PollTypeEnum.Simple}>Yes / No</option>
                      <option value={PollTypeEnum.Multi}>
                        Multiple Choice
                      </option>
                    </Select>
                  </VStack>
                );
              }}
              rules={{
                required: {
                  value: true,
                  message: "Selection is required",
                },
              }}
            />
            {watch("type") === PollTypeEnum.Multi && (
              <Box width={"100%"} mt={2}>
                <VStack align={"start"} width={"100%"}>
                  {fields.map((item, index) => (
                    <VStack key={item.id} w="100%">
                      <Controller
                        name={`options.${index}.description`}
                        control={control}
                        render={({
                          field: { onChange, value, ref },
                          formState: { errors },
                        }) => {
                          return (
                            <InputGroup>
                              <InputText
                                id="input-option"
                                disabled={isSubmitting}
                                placeholder={`Option ${index + 1}`}
                                maxLength={64}
                                ref={ref}
                                onChange={onChange}
                                value={value}
                                error={getErrorMessage(
                                  `Option ${index + 1}`,
                                  errors.options &&
                                    errors.options[index]?.description?.type,
                                  { minLength: 3 },
                                )}
                              />
                              <InputRightElement>
                                <ChakraButton
                                  disabled={fields.length < 3}
                                  onClick={() => removeOption(index)}
                                  size="sm"
                                  h={8}
                                  w={8}
                                >
                                  <MinusIcon />
                                </ChakraButton>
                              </InputRightElement>
                            </InputGroup>
                          );
                        }}
                        rules={{
                          required: true,
                          minLength: 3,
                        }}
                      />
                    </VStack>
                  ))}

                  {fields.length < 4 && (
                    <ChakraButton
                      h={10}
                      w={8}
                      mx={"auto!important"}
                      onClick={() => addOption({ description: "" })}
                    >
                      <AddIcon />
                    </ChakraButton>
                  )}
                </VStack>
              </Box>
            )}
          </Box>
          {/* End Date */}
          <Box width={"100%"} mb={4}>
            <Controller
              control={control}
              name="endDate"
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => {
                return (
                  <InputText
                    id="endDate"
                    label="End Date"
                    disabled={isSubmitting}
                    width="100%"
                    type="date"
                    min={
                      new Date(Date.now() + 24 * 3600 * 100)
                        .toISOString()
                        .split("T")[0]
                    }
                    setRef={ref}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    error={getErrorMessage("End Date", errors.endDate?.type)}
                  />
                );
              }}
              rules={{
                required: {
                  value: true,
                  message: "End date is required",
                },
              }}
            />
          </Box>
          <Box mx={"auto"} width={"fit-content"} mt={6} mb={4}>
            <Button type={"submit"} minW={40} isLoading={isSubmitting}>
              Create Poll
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CreatePollButton;
