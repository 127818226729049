import { useState } from "react";

import { type ContractTransaction } from "@ethersproject/contracts";

import { useTransactionContext } from "~/contexts/TransactionContext";
import { usePlatformCheck } from "~/hooks/platform";
import { handleCustomError } from "~/modules/party/errors";
import { TransactionActions } from "~/modules/party/types";

import { usePartyContract } from ".";

const steps = [
  {
    label: "Close party",
    content: "Confirm closing party action in your wallet",
  },
  {
    label: "Party confirmation",
    content: "Please wait until the closing party action is confirmed",
    loading: true,
    showGame: true,
  },
];

const usePartyClose = (partyAddress: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { checkWallet } = usePlatformCheck();
  const partyContract = usePartyContract(partyAddress);
  const {
    addTransaction,
    updateTransaction,
    openConfirmationWithSteps,
    closeConfirmationWithSteps,
    setStep,
    setErrorMsg,
  } = useTransactionContext();

  const closeParty = async () => {
    const account = checkWallet();
    if (!account || !partyContract) return null;
    setLoading(true);
    openConfirmationWithSteps(steps);
    let txHash = "";

    try {
      // 1) Check ownership
      const isOwner = await partyContract.managers(account);
      if (!isOwner) {
        throw Error(`Only Party Owner is allowed to kick`);
      }

      // 2) Close the party
      const newClose: ContractTransaction = await partyContract.closeParty();
      txHash = newClose.hash;
      addTransaction(TransactionActions.closeParty, txHash);
      setStep(1);
      await newClose.wait();
      updateTransaction(txHash, true, true);
      txHash = "";

      closeConfirmationWithSteps();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.log(err);
      const customError = handleCustomError(err);
      setErrorMsg(customError.message);
      if (txHash) {
        updateTransaction(txHash, false, true);
      }
    }
    setLoading(false);
  };

  return {
    closeParty,
    loadingCloseParty: loading,
  };
};

export default usePartyClose;
