import { useState } from "react";

import { useRouter } from "next/router";

import { DeleteIcon } from "@chakra-ui/icons";
import { Button, useDisclosure, type ButtonProps } from "@chakra-ui/react";

import { api } from "~/utils/api";

import { ConfirmModal } from "~/components/Modals";

import toast from "~/compounds/toast";
import { useGovernanceContext } from "~/contexts/GovernanceContext";

interface IDeleteButtonProps extends ButtonProps {
  pollId: string;
}

const DeletePollButton = ({ pollId, ...buttonProps }: IDeleteButtonProps) => {
  const router = useRouter();
  const { fetchActivePolls } = useGovernanceContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  // Api hooks
  const deletePoll = api.poll.delete.useMutation();

  const handleDeletePoll = async () => {
    setLoading(true);
    toast.dismiss();

    try {
      await deletePoll.mutateAsync({ id: pollId });
      toast.success({
        title: "Poll was successfully deleted",
      });
      void fetchActivePolls();
      setLoading(false);
      void router.push("/vote");
      return true;
    } catch (error) {
      toast.error({
        title: "An error occurred while deleting the poll",
      });
      setLoading(false);
      console.log(error);
      return false;
    }
  };

  return (
    <>
      <Button
        variant="outline"
        colorScheme="red"
        onClick={onOpen}
        isLoading={loading}
        size="sm"
        leftIcon={<DeleteIcon />}
        {...buttonProps}
      >
        Delete proposal
      </Button>
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        title={"Delete proposal"}
        message={"Are you sure you want to delete this proposal?"}
        confirmAction={() => void handleDeletePoll()}
      />
    </>
  );
};

export default DeletePollButton;
