import { useMemo } from "react";

import NextLink from "next/link";

import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Text,
  VStack,
  useColorModeValue,
  type BoxProps,
} from "@chakra-ui/react";

import { isAddressEqual, shortenHex } from "@partyfinance/core";

import { RemainingTime } from "~/components/Countdown";
import { Jazzicon } from "~/components/Icons";

import { useGovernanceContext } from "~/contexts/GovernanceContext";
import { usePlatformContext } from "~/contexts/PlatformContext";
import { PollStatus } from "~/modules/vote/components";
import { type PollItem } from "~/modules/vote/types";

import Card from "./Card";

interface IPollListItemCard extends BoxProps {
  poll: PollItem;
}

const PollListItemCard = ({ poll }: IPollListItemCard) => {
  const { profile } = usePlatformContext();
  const { userVotes } = useGovernanceContext();

  const userVoted = useMemo(
    () => userVotes.findIndex((v) => v.pollId === poll.id) > -1,
    [userVotes, poll.id],
  );

  const { votingEnded, winner } = useMemo(() => {
    return {
      votingEnded: poll.endDate.getTime() < Date.now(),
      winner: poll.Options.reduce((prev, curr) => {
        if (prev.totalVotes > curr.totalVotes) {
          return prev;
        } else {
          return curr;
        }
      }),
    };
  }, [poll]);

  return (
    <Link
      as={NextLink}
      href={`/vote/${poll.id}`}
      _hover={{ textDecoration: "none" }}
      w="full"
    >
      <Card
        as={Button}
        height="initial"
        display="block"
        whiteSpace="inherit"
        lineHeight="inherit"
        px={4}
        borderWidth={1}
        pos="relative"
        borderColor={useColorModeValue("gray.200", "transparent")}
      >
        <VStack alignItems="stretch">
          <Flex gap={2} alignItems="center">
            {/* Author Jazzicon */}
            <Flex borderRadius="50%" overflow="hidden">
              <Jazzicon account={poll.User.address} diameter={24} />
            </Flex>

            {/* Author */}
            <Flex
              direction="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Text fontSize="sm">
                Created by
                <Text
                  as="span"
                  color="gray.300"
                  fontWeight="bold"
                  textOverflow="ellipsis"
                  maxWidth="130px"
                  ml={1}
                >
                  {isAddressEqual(poll.User.address, profile?.address || "")
                    ? "you"
                    : shortenHex(poll.User.address)}
                </Text>
              </Text>
            </Flex>

            {/* Status */}
            <Flex ml="auto">
              <PollStatus status={poll.status} votingEnded={votingEnded} />
            </Flex>
          </Flex>

          {/* Title */}
          <Heading
            textAlign={{ base: "center", md: "start" }}
            fontSize={{ base: "lg", lg: "2xl" }}
            pt={1}
          >
            {poll.name}
          </Heading>

          {/* Description */}
          <Text
            textAlign="left"
            noOfLines={{ base: 4, sm: 3, md: 2 }}
            fontWeight="normal"
            overflowWrap="break-word"
            wordBreak="break-word"
            w="full"
          >
            {poll.description}
          </Text>

          {/* Countdown or Won option */}
          <Box textAlign="left">
            {!votingEnded && poll.status === 0 ? (
              <Flex>
                <RemainingTime date={poll.endDate} color="gray.400" />
                {profile && userVoted && (
                  <Text color="gray.400" ml={2}>
                    - Already voted
                  </Text>
                )}
              </Flex>
            ) : (
              <Text color="gray.400">
                Won:
                <Text as="span" fontWeight="bold" ml={1}>
                  {winner.value.toUpperCase()}
                </Text>
              </Text>
            )}
          </Box>
        </VStack>
      </Card>
    </Link>
  );
};

export default PollListItemCard;
