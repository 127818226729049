import { useQuery } from "@tanstack/react-query";

import {
  fetchUserParties,
  type UserParty,
} from "@partyfinance/thegraph-queries";

import { supportedChainIds } from "~/config";
import { usePlatformContext } from "~/contexts/PlatformContext";
import { env } from "~/env.mjs";

const useUserClosedParties = (userAddress?: string) => {
  const { profile } = usePlatformContext();
  const user = userAddress
    ? userAddress.toLowerCase()
    : profile?.address.toLowerCase() || "";
  const enabled = !!profile || !!user;

  const { data, error } = useQuery<UserParty[]>(
    ["user-closed-parties", user],
    () =>
      fetchUserParties(
        supportedChainIds,
        env.NEXT_PUBLIC_THEGRAPH_API_KEY,
        user,
        "closed",
      ),
    {
      enabled,
      retry: false,
      staleTime: 15000,
      cacheTime: 24 * 60 * 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );

  return {
    parties: data?.filter((p) => p.isClosed) || [],
    loading: !error && data === undefined,
    error,
  };
};

export default useUserClosedParties;
