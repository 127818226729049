import { type ReactNode } from "react";

import {
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
} from "@chakra-ui/react";

import { CloseButton } from "~/components/Buttons";

interface ICustomModalProps extends ModalProps {
  onClose: () => void;
  isOpen: boolean;
  title?: string | ReactNode;
  children: React.ReactNode;
}

const CustomModal = ({
  isOpen,
  onClose,
  children,
  title,
  ...modalProps
}: ICustomModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} {...modalProps}>
      <ModalOverlay />
      <ModalContent my="auto" borderRadius="xl">
        <ModalHeader>
          <CloseButton aria-label={"Close Button"} onClose={onClose} />
          <Heading fontSize="xl" mx="auto" width="fit-content">
            {title}
          </Heading>
        </ModalHeader>
        <ModalBody px={0}>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
