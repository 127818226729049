import { useEffect, useState } from "react";

import {
  PartyChartView,
  type PartyFinancials,
  type PartyHolding,
} from "~/modules/party/types";

export default function usePartyChart({
  partyFinancials,
}: {
  partyFinancials: PartyFinancials | null;
}) {
  // Chart View Options
  const [chartType, setChartType] = useState<PartyChartView>(
    PartyChartView.PartyTotalValue,
  );

  // Holding Chart Options
  const [selectedHolding, setSelectedHolding] = useState<PartyHolding | null>(
    null,
  );
  useEffect(() => {
    if (chartType === PartyChartView.PartyChartHodlingValue) {
      setSelectedHolding(
        // @ts-expect-error `partyFinancials.holdings[0]` is defined
        partyFinancials && partyFinancials.holdings.length > 0
          ? partyFinancials.holdings[0]
          : null,
      );
    } else {
      setSelectedHolding(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartType]);

  return {
    chartType,
    setChartType,
    selectedHolding,
    setSelectedHolding,
  };
}
