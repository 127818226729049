import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  type FC,
  type ReactNode,
} from "react";

import { useDisclosure } from "@chakra-ui/react";
import {
  type TransactionReceipt,
  type TransactionResponse,
} from "@ethersproject/providers";
import { useSteps } from "chakra-ui-steps";
import { useAccount, useProvider } from "wagmi";

import ConfirmationWithSteps from "~/components/Modals/ConfirmationWithSteps";

import toast from "~/compounds/toast";
import {
  TransactionStatus,
  type ConfirmationStep,
  type ITransaction,
} from "~/modules/party/types";

import { usePartyContext } from "./PartyContext";

interface ITransactionContext {
  transactions: ITransaction[];
  addTransaction: (name: string, txHash: string) => void;
  updateTransaction: (
    _txHash: string,
    _isConfirmed?: boolean,
    _showToast?: boolean,
  ) => void;
  clearTransactions: () => void;
  openConfirmationWithSteps: (
    steps?: ConfirmationStep[],
    reset?: boolean,
  ) => void;
  isConfirmationWithStepsAvailable: boolean;
  closeConfirmationWithSteps: (resetSteps?: boolean) => void;
  setStep: (step: number) => void;
  reset: () => void;
  setErrorMsg: (msg: string) => void;
}

const defaultState = {
  transactions: [],
  addTransaction: () => null,
  updateTransaction: () => null,
  clearTransactions: () => true,
  openConfirmationWithSteps: () => null,
  isConfirmationWithStepsAvailable: false,
  closeConfirmationWithSteps: () => null,
  setStep: () => null,
  reset: () => null,
  setErrorMsg: () => null,
};

const TransactionContext = createContext<ITransactionContext>(defaultState);
interface ITransactionProvider {
  children: ReactNode;
}

export const TransactionProvider: FC<ITransactionProvider> = ({ children }) => {
  const { chainId } = usePartyContext();
  const provider = useProvider();
  const { address } = useAccount();
  const [_oldTxs, setOldTxs] = useState<ITransaction[]>([]);
  const [transactions, setTransactions] = useState<ITransaction[]>(
    defaultState.transactions,
  );
  // For the ConfirmationWithSteps
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });
  const [steps, setSteps] = useState<ConfirmationStep[]>([]);
  const [errorMsg, setErrorMsg] = useState<string>("");
  //

  const checkPendingTxs = async (pendingTxs: ITransaction[]) => {
    // console.log('Checking ??')
    if (!provider) return pendingTxs;
    // console.log('Checking pending txs!')
    const newPendingTxs = await Promise.all(
      pendingTxs.map((tx: ITransaction) =>
        provider
          .getTransactionReceipt(tx.txHash)
          .then((receipt: TransactionReceipt | null) => {
            if (!receipt) {
              // console.log(tx.txHash, 'tx is still pending or not mined!')
              // Check if the tx is pending or replaced:
              return provider
                .getTransaction(tx.txHash)
                .then((transaction: TransactionResponse) => {
                  // console.log('getTransaction response:', transaction)
                  if (transaction) {
                    return tx;
                  }
                  return {
                    ...tx,
                    status: TransactionStatus.dropped,
                  };
                })
                .catch((err) => {
                  console.log(
                    "err on getting the TransactionResponse of ",
                    tx.txHash,
                    err,
                  );
                  return tx;
                });
            } else if (receipt.status === 1) {
              // console.log(tx.txHash, 'tx was confirmed!')
              return {
                ...tx,
                status: TransactionStatus.confirmed,
                confirmedTime: Date.now(),
              };
            } else {
              // console.log(tx.txHash, 'tx was reverted!')
              return {
                ...tx,
                status: TransactionStatus.failed,
                confirmedTime: Date.now(),
              };
            }
          })
          .catch((err) => {
            console.log("err on getting TXReceipt of ", tx.txHash, err);
            return tx;
          }),
      ),
    );
    // console.log('newPendingTxs', newPendingTxs)
    setTransactions((oldTransactions) => {
      const newTransactions = oldTransactions.map((x) => {
        const foundPendingTx = newPendingTxs.find((y) => y.txHash === x.txHash);
        return foundPendingTx || x;
      });
      if (window && window.localStorage) {
        // console.log('saving txs on local')
        window.localStorage.setItem(
          "transactions",
          JSON.stringify([..._oldTxs, ...newTransactions]),
        );
      }
      return newTransactions;
    });
  };

  const loadAccountTxs = (_account: string, _chainId: number) => {
    const lsTransactions = window.localStorage.getItem("transactions");
    if (lsTransactions) {
      // Load here relevant transactions of connected user in connected network chainId
      const _ = JSON.parse(lsTransactions) as ITransaction[];
      const _t: ITransaction[] = [];
      const _o: ITransaction[] = [];
      for (const data of _) {
        if (data.from === _account && data.chainId === _chainId) {
          _t.push(data);
        } else {
          _o.push(data);
        }
      }
      const pendingUserTxs = _t.filter(
        (x) => x.status === TransactionStatus.pending,
      );
      // console.log('Pending txs for the user are:', pendingUserTxs)
      setTransactions(_t);
      setOldTxs(_o);
      void checkPendingTxs(pendingUserTxs);
    }
  };

  useEffect(() => {
    if (address && chainId) {
      if (window && window.localStorage) {
        loadAccountTxs(address, chainId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, chainId]);

  const clearTransactions = () => {
    if (window && window.localStorage) {
      // console.log('saving txs on local')
      window.localStorage.setItem("transactions", JSON.stringify(_oldTxs));
    }
    setTransactions([]);
  };

  const addTransaction = (name: string, txHash: string) => {
    const newTransaction = {
      txHash,
      name,
      status: TransactionStatus.pending,
      chainId,
      from: address || "",
      addedTime: Date.now(),
    };
    if (window && window.localStorage) {
      // console.log('saving txs on local')
      window.localStorage.setItem(
        "transactions",
        JSON.stringify([..._oldTxs, ...transactions, newTransaction]),
      );
    }
    setTransactions((oldTransactions) => [...oldTransactions, newTransaction]);
  };

  const updateTransaction = (
    txHash: string,
    isConfirmed = true,
    showToast = false,
  ) => {
    setTransactions((oldTransactions) => {
      // console.log('Updating tx:', txHash, isConfirmed)
      // console.log('old txs', oldTransactions)
      const updatedTransactions = oldTransactions.map((x: ITransaction) => {
        if (x.txHash === txHash) {
          if (showToast) {
            if (isConfirmed) {
              toast.successTxn(
                { title: x.name, chainId, txHash: x.txHash },
                { autoClose: 15000 },
              );
            } else {
              toast.errorTxn(
                { title: x.name, chainId, txHash: x.txHash },
                { autoClose: 15000 },
              );
            }
          }
          return {
            ...x,
            status: isConfirmed
              ? TransactionStatus.confirmed
              : TransactionStatus.failed,
            confirmedTime: isConfirmed ? Date.now() : undefined,
          };
        }

        return x;
      });
      if (window && window.localStorage) {
        // console.log('saving txs on local')
        window.localStorage.setItem(
          "transactions",
          JSON.stringify([..._oldTxs, ...updatedTransactions]),
        );
      }
      return updatedTransactions;
    });
  };

  const closeConfirmationWithSteps = (resetSteps = true) => {
    onClose();
    if (resetSteps) {
      reset();
      setErrorMsg("");
      setSteps([]);
    }
  };

  const openConfirmationWithSteps = (
    steps: ConfirmationStep[] | undefined,
    resetSteps = true,
  ) => {
    if (steps) {
      if (resetSteps) {
        reset();
      }
      setErrorMsg("");
      setSteps(steps);
    }
    onOpen();
  };

  const isConfirmationWithStepsAvailable = useMemo(() => {
    return steps.length > 0;
  }, [steps]);

  const _setSteps = (_step: number) => {
    setStep(_step);
    onOpen();
  };

  return (
    <TransactionContext.Provider
      value={{
        transactions,
        addTransaction,
        updateTransaction,
        clearTransactions,
        openConfirmationWithSteps,
        isConfirmationWithStepsAvailable,
        closeConfirmationWithSteps,
        setStep: _setSteps,
        reset,
        setErrorMsg,
      }}
    >
      <>
        {children}
        <ConfirmationWithSteps
          isOpen={isOpen}
          onClose={closeConfirmationWithSteps}
          errorMsg={errorMsg}
          steps={steps}
          activeStep={activeStep}
        />
      </>
    </TransactionContext.Provider>
  );
};

export const useTransactionContext = () => useContext(TransactionContext);

export default TransactionContext;
