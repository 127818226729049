/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import {
  Avatar,
  Box,
  CircularProgress,
  Flex,
  HStack,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { GiPartyPopper } from "react-icons/gi";

import { getChainName } from "~/utils/web3/chains";

import { Button, CloseButton } from "~/components/Buttons";
import { Card } from "~/components/Card";

import { usePartyContext } from "~/contexts/PartyContext";
import { usePartyChart, usePartyFinancials } from "~/hooks/party";
import { usePartyInfo } from "~/hooks/theGraph";
import PartyChart from "~/modules/party/components/PartyChart";
import PartyHoldingsList from "~/modules/party/components/PartyHoldingsList";
import {
  type PartyFinancials,
  type PartyWithFinancials,
} from "~/modules/party/types";

export type PartyOverviewState = Pick<
  PartyWithFinancials,
  "address" | "chainId" | "denominationAsset" | "name" | "img" | "inception"
> & {
  financials: PartyFinancials | null | undefined;
};

type Props = {
  onClose: () => void;
  party: PartyOverviewState;
};

const PartyOverview = ({ onClose, party }: Props) => {
  const { isIndexParty, checkIndexParty } = usePartyContext();
  const { partyInfo } = usePartyInfo(party.address, party.chainId, !!party);
  const partyFinancialsQuery = usePartyFinancials(
    party.address,
    party.chainId,
    false,
    true,
    !party.financials,
  );

  useEffect(() => {
    checkIndexParty(party.address);
  }, [partyInfo]);

  // Chart Options
  const { chartType, setChartType, selectedHolding, setSelectedHolding } =
    usePartyChart({
      partyFinancials: party.financials || partyFinancialsQuery.partyFinancials,
    });

  const [redirecting, setRedirecting] = useState<boolean>(false);

  const goToParty = () => {
    setRedirecting(true);
  };

  const handleClose = () => {
    if (redirecting) null;
    onClose();
  };

  const chainName = getChainName(party.chainId, true);
  console.log("address, chainId", party.address, party.chainId);
  console.log("partyInfo", partyInfo);

  return (
    <Modal isOpen onClose={handleClose} size={"5xl"}>
      <ModalOverlay />
      <ModalContent
        my="auto"
        bg={useColorModeValue("gray.100", "brand.main")}
        borderRadius="xl"
        pb={3}
      >
        <ModalHeader>
          <Flex alignItems="center" justifyContent="space-between" pt={2}>
            <Box w={8} />
            <Heading size={"lg"}>Party Overview</Heading>
            <CloseButton
              aria-label={"Close Button"}
              onClose={handleClose}
              ml={0}
            />
          </Flex>
        </ModalHeader>

        <ModalBody px="1rem" width={"100%"} borderRadius="xl" pb={3}>
          <Box>
            <Card>
              <Flex
                py={2}
                direction={{ base: "column", md: "row" }}
                justifyContent="center"
                alignItems="center"
                gap={4}
              >
                <Box>
                  <Avatar icon={<GiPartyPopper />} size="2xl" src={party.img} />
                </Box>
                <Box>
                  <Heading size="md" mb="0.5rem" width={"fit-content"}>
                    {party.name}
                  </Heading>
                  {!partyInfo ? null : (
                    <Text color="gray.400" width={"fit-content"}>
                      {partyInfo.bio}
                    </Text>
                  )}
                </Box>
                <Box>
                  <Link
                    href={
                      isIndexParty
                        ? `/index/${chainName}/${party.address}`
                        : `/parties/${chainName}/${party.address}`
                    }
                    _hover={{ textDecoration: "none" }}
                  >
                    <Button
                      onClick={goToParty}
                      disabled={!party}
                      isLoading={redirecting}
                      borderRadius="lg"
                    >
                      Go to Party
                    </Button>
                  </Link>
                </Box>
              </Flex>
            </Card>
          </Box>
          <Flex flexWrap="wrap">
            <Box
              width={["100%", "100%", "100%", "calc(75% - 0.5rem)"]}
              mr={[0, 0, "0.5rem"]}
              minH="400px"
            >
              <PartyChart
                partyAddress={party.address}
                denominationAsset={party.denominationAsset.id}
                setChartType={setChartType}
                chartType={chartType}
                chainId={party.chainId}
                selectedHolding={selectedHolding}
                inception={party.inception}
              />
            </Box>
            <Box width={["100%", "100%", "100%", "25%"]}>
              <Card mt={4}>
                <Box
                  minH={["fit-content", "fit-content", "fit-content", "355px"]}
                >
                  <HStack
                    justifyContent={
                      party.financials
                        ? "flex-start"
                        : partyFinancialsQuery.isValidating
                        ? "space-between"
                        : "flex-start"
                    }
                    alignItems="flex-start"
                  >
                    <Heading fontSize={{ base: "sm", md: "lg" }} mb={2}>
                      Holdings
                    </Heading>
                    {party.financials
                      ? false
                      : partyFinancialsQuery.isValidating && (
                          <CircularProgress
                            color="brand.300"
                            size={4}
                            isIndeterminate
                            ml={2}
                          />
                        )}
                  </HStack>
                  <PartyHoldingsList
                    loading={
                      party.financials ? false : partyFinancialsQuery.loading
                    }
                    holdings={
                      party.financials
                        ? party.financials.holdings
                        : partyFinancialsQuery.partyFinancials?.holdings ?? []
                    }
                    setChartType={setChartType}
                    setSelectedHolding={setSelectedHolding}
                    selectedHolding={selectedHolding}
                    showMemberHoldings={false}
                    memberShare={null}
                  />
                </Box>
              </Card>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PartyOverview;
