import { useQuery } from "@tanstack/react-query";

import {
  fetchPartyHoldings,
  type Holding,
} from "@partyfinance/thegraph-queries";

import { env } from "~/env.mjs";

interface Data {
  holdings: Holding[];
  updatedAt: number;
}

const usePartyHoldingsBalance = (
  partyAddress: string,
  chainId: number,
  externalDisable = false,
) => {
  const enabled = !!partyAddress && !externalDisable;

  const { data, error, refetch, isFetching } = useQuery<Data>(
    ["party-holdings", chainId, partyAddress.toLowerCase()],
    () =>
      fetchPartyHoldings(
        chainId,
        env.NEXT_PUBLIC_THEGRAPH_API_KEY,
        partyAddress.toLowerCase(),
      ),
    { enabled },
  );

  return {
    partyHoldings: data?.holdings || [],
    updatedAt: data?.updatedAt || null,
    reload: () => refetch(),
    isValidating: isFetching,
    loading: !error && data === undefined,
    error,
  };
};

export default usePartyHoldingsBalance;
