/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Logger } from "ethers/lib/utils";

import { Party__factory } from "@partyfinance/contracts";

const IParty = Party__factory.createInterface();

// Friendly errors to show to user
interface IPartyCustomError {
  name: string;
  message: string;
}
const partyCustomErrors: IPartyCustomError[] = [
  { name: "NotMember", message: "You are not a member of this party" },
  { name: "AlreadyMember", message: "You are already a member of this party" },
  {
    name: "DepositNotEnough",
    message: "Your deposit is below the minimum required by this party",
  },
  {
    name: "DepositExceeded",
    message: "Your deposit exceeds the maximum required by this party",
  },
  { name: "PartyClosed", message: "Party is closed!" },
  { name: "UserBalanceNotEnough", message: "Your balance is not enough" },
  { name: "OwnerNotKickable", message: "You cannot kick yourself" },
  {
    name: "FailedAproveReset",
    message: "Failed reseting the allowance of the token",
  },
  {
    name: "FailedAprove",
    message: "Failed approving the allowance of the token",
  },
  { name: "ZeroXFail", message: "0x Protocol failed to process the swap" },
  { name: "InvalidSignature", message: "Invalid approval signature" },
  { name: "InvalidSwap", message: "Only one swap can be made at a time" },
  {
    name: "AlreadyRequested",
    message:
      "You have already requested to join this party. Please wait until your invitation is accepted by the party owners",
  },
  {
    name: "AlreadyHandled",
    message: "You have already handled this join request",
  },
  {
    name: "NeedsInvitation",
    message: "You need an approved invitation to join this private party",
  },
  {
    name: "AlreadyUpdated",
    message: "This party has been already updated the latest version",
  },
  {
    name: "CallFailed",
    message: "This method cannot be called",
  },
];

interface ICustomError {
  ignore?: boolean;
  message: string;
}

export const handleCustomError = (err: any): ICustomError => {
  const customError = {
    ignore: false,
    message: (err.message as string) || "Oops! Something happened!",
  };
  if (!err) return customError;
  console.group("Tx error");
  console.log(err);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  console.log(Object.keys(err));
  console.log("err.error", err.error);
  console.log("err.code", err.code);
  console.log("err.reason", err.reason);
  console.log("err.data", err.data);
  console.groupEnd();
  if (err.code || err.error || err.reason) {
    customError.message = "Oops! Something happened!";
    if (err.reason) {
      customError.message = err.reason;
    }
  }
  if (err.code === -32603) {
    if (customError.message.includes("transaction underpriced")) {
      customError.message =
        "Transaction underpriced. Try submitting it with higher gas price (aggressive/fast)";
    }
    if (err.data) {
      customError.message = err.data.message;
      if (
        customError.message ===
        "execution reverted: Ownable: caller is not the owner"
      ) {
        customError.message =
          "Only the owner of this party can call this method";
      }
    }
  }
  if (err.error && err.error.code === -32603) {
    if (
      err.error.message &&
      err.error.message ===
        "execution reverted: Ownable: caller is not the owner"
    ) {
      customError.message = "Only the owner of this party can call this method";
    }
    if (
      err.error.message &&
      err.error.message ===
        "execution reverted: Diamond: Function does not exist"
    ) {
      customError.message =
        "This function is not available. The party would need to be patched in order to unlock this feature.";
    }
  }
  const error_data =
    err.error &&
    err.error.data &&
    err.error.data.originalError &&
    err.error.data.originalError.data
      ? err.error.data.originalError.data
      : err.data && err.data.data
      ? err.data.data
      : undefined;
  if (error_data) {
    console.log("TX OriginalError.data is:", error_data);
    try {
      const partyError = IParty.getError(error_data as string);
      console.log("Custom Party Error is:", partyError);
      if (partyError) {
        const foundError = partyCustomErrors.find(
          (x) => x.name === partyError.name,
        );
        console.log("foundError", foundError);
        if (foundError && foundError.message) {
          customError.message = foundError.message;
        }
      }
    } catch (e: any) {
      if (err.error && err.error.message) {
        // customError.message = err.error.message
      }
    }
  } else if (err.error && err.error.message) {
    // customError.message = err.error.message
  }
  if (err.code === 4001 || err === "User canceled") {
    customError.message = "You rejected the transaction";
  }
  if (err.code === Logger.errors.ACTION_REJECTED) {
    customError.message = "You rejected the transaction";
  }

  if (
    err.name === "AxiosError" &&
    err.response &&
    err.response.data &&
    err.response.data.message ===
      "The party you are trying to interact is not whitelisted for development."
  ) {
    customError.message = "Party not whitelisted for development interaction.";
  }

  return customError;
};
