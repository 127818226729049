import { Tag } from "@chakra-ui/react";

export default function PollStatus({
  status,
  votingEnded,
}: {
  status: number;
  votingEnded?: boolean;
}) {
  return (
    <Tag
      color="white"
      borderRadius="lg"
      bg={
        status === 1
          ? "blue.600"
          : status === 2
          ? "red.600"
          : votingEnded
          ? "yellow.600"
          : "green.600"
      }
      px={3}
    >
      {status === 1
        ? "Executed"
        : status === 2
        ? "Discarded"
        : votingEnded
        ? "Pending"
        : "Active"}
    </Tag>
  );
}
