import { getChainName as getNetworkName } from "@partyfinance/core";

import { EthereumIcon, OptimismIcon, PolygonIcon } from "~/components/Icons";

export function getChainIcon(chainId: number | undefined) {
  switch (chainId) {
    case 1:
      return "/assets/icons/chains/ethereum.svg";
    case 5:
      return "/assets/icons/chains/ethereum.svg";
    case 10:
      return "/assets/icons/chains/optimism.svg";
    case 137:
      return "/assets/icons/chains/polygon.svg";
    default:
      return "/assets/icons/chains/ethereum.svg";
  }
}

export function getChainIconSVG(chainId: number | undefined) {
  switch (chainId) {
    case 1:
      return EthereumIcon;
    case 5:
      return EthereumIcon;
    case 10:
      return OptimismIcon;
    case 137:
      return PolygonIcon;
    default:
      return EthereumIcon;
  }
}

export function getChainName(chainId: number | undefined, asLowerCase = false) {
  const chainName = getNetworkName(chainId);
  if (asLowerCase) return chainName.toLowerCase();
  return chainName;
}

export function getChainId(chain: string | undefined) {
  if (!chain) return undefined;
  const value = chain.toLowerCase();
  switch (value) {
    case "mainnet":
      return 1;
    case "ethereum":
      return 1;
    case "optimism":
      return 10;
    case "polygon":
      return 137;
    case "matic":
      return 137;
    default:
      return "";
  }
}
