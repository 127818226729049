import {
  Box,
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import { Button, CloseButton } from "~/components/Buttons";

import { type IPool } from "~/modules/staking/types";

interface ISliderModal {
  isOpen: boolean;
  onClose: () => void;
  pool: IPool;
  userRewards: number;
  confirmAction: (amount: number) => Promise<void>;
}

const ClaimRewardsModal = ({
  isOpen,
  onClose,
  userRewards,
  confirmAction,
}: ISliderModal) => {
  const handleClaim = async () => {
    await confirmAction(userRewards);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent my="auto">
        <ModalHeader>
          <CloseButton aria-label={"Close Button"} onClose={onClose} />
          <Text fontSize="xl" mx="auto" width="fit-content">
            Claim Rewards
          </Text>
        </ModalHeader>
        <ModalBody>
          <Box p={"1rem"}>
            <Center>
              <Heading size={"lg"} ml={"0.5rem"}>
                {userRewards} $PFI
              </Heading>
            </Center>
            <Box mx={["auto"]} mt={10} width={"fit-content"}>
              <Button minW={4} onClick={() => void handleClaim()}>
                Claim
              </Button>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ClaimRewardsModal;
