/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useEffect,
  useRef,
  useState,
  type ChangeEvent,
  type FocusEvent,
  type KeyboardEvent,
} from "react";

import { SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Stack,
  useColorModeValue,
  type InputProps,
} from "@chakra-ui/react";

interface SearchFn {
  (searchedName: string): Promise<any>;
}

interface IInputSearchProps extends InputProps {
  onSearch: SearchFn;
  searchedValue: string;
  searchWhileTyping?: boolean;
  hideFeedback?: boolean;
}

const InputSearch = ({
  onSearch,
  searchedValue,
  searchWhileTyping = false,
  hideFeedback = false,
  ...rest
}: IInputSearchProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [value, setValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (searchWhileTyping) {
      const timeoutId = setTimeout(() => handleSearch(value), 1500);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    setValue(event.target.value);
  };
  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch(value);
    }
  };
  const onBlur = (event: FocusEvent<HTMLInputElement>) => {
    handleSearch(event.target.value);
  };
  const handleSearch = (_value: string) => {
    if (!_value || _value !== searchedValue) {
      setLoading(true);
      onSearch(_value).finally(() => setLoading(false));
    }
  };
  const resetSearch = () => {
    setValue("");
    handleSearch("");
  };

  return (
    <Stack spacing={4}>
      <InputGroup
        size="lg"
        _focus={{
          borderColor: "brand.200",
          borderWidth: 1,
        }}
      >
        <InputLeftElement>
          <Button
            h="1.75rem"
            size="sm"
            bg="transparent"
            onClick={() => handleSearch(value)}
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent", border: "none" }}
            _focus={{ bg: "transparent", border: "none" }}
          >
            <SearchIcon color="brand.500" />
          </Button>
        </InputLeftElement>
        <Input
          ref={ref}
          placeholder="Find a Party"
          borderRadius="full"
          border="none"
          bg={useColorModeValue("gray.200", "brand.nav")}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          {...rest}
        />
        {!hideFeedback ? (
          <InputRightElement width="4rem" mr={"16px"}>
            {loading ? (
              <Spinner color="brand.400" size="sm" />
            ) : !searchedValue && !value ? null : hideFeedback ? null : (
              <Button
                h="1.75rem"
                size="sm"
                onClick={() =>
                  value === searchedValue ? resetSearch() : handleSearch(value)
                }
              >
                {!searchedValue
                  ? "Search"
                  : value === searchedValue
                  ? "Clear"
                  : "Search"}
              </Button>
            )}
          </InputRightElement>
        ) : loading ? (
          <InputRightElement>
            <Spinner color="brand.400" size="sm" />
          </InputRightElement>
        ) : null}
      </InputGroup>
    </Stack>
  );
};

export default InputSearch;
