import { useEffect } from "react";

import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";

import { type IPartyInfo } from "@partyfinance/thegraph-queries";

import { getErrorMessage } from "~/utils/form/errorMessage";
import { formatNumber } from "~/utils/number";

import { Button, CloseButton } from "~/components/Buttons";
import { InputNumber } from "~/components/Inputs";

import { usePartyDeposit } from "~/hooks/party";
import { type DepositForm } from "~/modules/party/types";

interface IConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  party: IPartyInfo;
  confirmAction: (amount: number, allocate: boolean) => Promise<void>;
}

const Deposit = ({
  isOpen,
  onClose,
  party,
  confirmAction,
}: IConfirmModalProps) => {
  const { control, handleSubmit, setValue, reset } = useForm<DepositForm>({
    defaultValues: {
      allocate: 1,
    },
  });
  const { loadingDeposit } = usePartyDeposit(party.address);
  const handleDeposit = async (form: DepositForm) => {
    await confirmAction(form.amount, form.allocate === 1);
    reset();
  };

  useEffect(() => {
    if (isOpen && !!party && party.minDeposit === party.maxDeposit) {
      setValue("amount", party.minDeposit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent my="auto" borderRadius="xl">
          <ModalHeader>
            <CloseButton aria-label={"Close Button"} onClose={onClose} />
            <Text fontSize="xl" mx="auto" width="fit-content">
              {party.name}
            </Text>
            <Text fontSize="md" mt={4} mx="auto" width="fit-content">
              Deposit to party
            </Text>
          </ModalHeader>
          <ModalBody>
            <Box
              as={"form"}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSubmit={handleSubmit(handleDeposit)}
            >
              <Box
                py={"1rem"}
                mb={"1rem"}
                bg={"#141e2a"}
                borderRadius={"5px"}
                color={"gray.400"}
              >
                <Heading
                  fontWeight={"normal"}
                  size={"xs"}
                  mx={"auto"}
                  width={"10rem"}
                  textAlign={"center"}
                >
                  Min - Max
                </Heading>
                <Text
                  fontWeight={"bold"}
                  width={"10rem"}
                  mx={"auto"}
                  textAlign={"center"}
                >
                  {`${formatNumber(party.minDeposit)} - ${
                    party.maxDeposit ? formatNumber(party.maxDeposit) : "∞"
                  } USDC`}
                </Text>
              </Box>
              <Box>
                <Controller
                  control={control}
                  name="amount"
                  render={({
                    field: { ref, onBlur, onChange, value },
                    formState: { errors },
                  }) => {
                    return (
                      <InputNumber
                        id="amount"
                        setRef={ref}
                        onBlur={onBlur}
                        onChange={onChange}
                        disabled={loadingDeposit}
                        value={value}
                        placeholder={"Enter USDC Amount"}
                        height={"auto"}
                        paddingTop={"1rem"}
                        paddingBottom={"1rem"}
                        borderRadius={"10px"}
                        background={"white"}
                        _placeholder={{ color: "gray.400" }}
                        exponent={{ max: 6, min: 6 }}
                        error={getErrorMessage(
                          "Min deposit",
                          errors.amount?.type,
                          {
                            moreThan: "minimum deposit",
                            lessThanEqual: "maximum deposit",
                          },
                        )}
                      />
                    );
                  }}
                  rules={{
                    required: true,
                    validate: {
                      moreThanEqual: (value) =>
                        Number(value) >= party.minDeposit,
                      lessThanEqual: (value) =>
                        party.maxDeposit
                          ? Number(value) <= party.maxDeposit
                          : true,
                    },
                  }}
                />
              </Box>
              <Box mt={8} width={"fit-content"}>
                <Text mb={2}>Your deposit options: </Text>
                <Controller
                  control={control}
                  name="allocate"
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      value={String(value)}
                      onChange={(v) => {
                        onChange(Number(v) as 0 | 1);
                      }}
                      colorScheme="brand"
                    >
                      <Stack>
                        <Radio value="1">
                          <Flex alignItems="center" gap={2}>
                            Deposit and allocate
                            <Tooltip
                              fontSize="sm"
                              hasArrow
                              placement="right"
                              label="Your deposit will be transferred to party and allocated into the current party holdings portfolio"
                            >
                              <InfoIcon />
                            </Tooltip>
                          </Flex>
                        </Radio>
                        <Radio value="0">
                          <Flex alignItems="center" gap={2}>
                            Just deposit
                            <Tooltip
                              fontSize="sm"
                              hasArrow
                              placement="right"
                              label="Your deposit will be just transferred to the party"
                            >
                              <InfoIcon />
                            </Tooltip>
                          </Flex>
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                />
              </Box>
              <Box width={"fit-content"} mx={["auto"]} mt={6} mb={2}>
                <Button
                  type="submit"
                  minW="8rem"
                  mb="0.5rem"
                  isLoading={loadingDeposit}
                >
                  Deposit
                </Button>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Deposit;
