/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ProviderConnector } from "@1inch/limit-order-protocol";
import { defaultAbiCoder } from "@ethersproject/abi";
import { Contract } from "@ethersproject/contracts";
import { type Signer, type VoidSigner } from "ethers";

import { type AbiItem, type EIP712TypedData } from "./types";

export function get1nchLimitOrderProtocol(chainId: number): string {
  switch (chainId) {
    case 1:
      return "0x119c71D3BbAC22029622cbaEc24854d3D32D2828";
    case 5:
      // Not available on Goerli
      return "";
    case 10:
      return "0x11431a89893025D2a48dCA4EddC396f8C8117187";
    case 137:
      return "0x94Bc2a1C732BcAd7343B25af48385Fe76E08734f";
    default:
      return "";
  }
}

export class Ethers1nchConnector implements ProviderConnector {
  protected readonly signer: Signer;
  constructor(signer: Signer) {
    this.signer = signer;
  }
  contractEncodeABI(
    abi: AbiItem[],
    address: string | null,
    methodName: string,
    methodParams: unknown[],
  ) {
    const contract = new Contract(address || "", abi);
    return contract.interface.encodeFunctionData(methodName, methodParams);
  }
  signTypedData(
    _walletAddress: string,
    typedData: EIP712TypedData,
    // _typedDataHash: string
  ) {
    return (this.signer as VoidSigner)._signTypedData(
      typedData.domain,
      {
        Order: typedData.types.Order,
      },
      typedData.message,
    );
  }
  ethCall(contractAddress: string, callData: any) {
    return this.signer.call({
      to: contractAddress,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data: callData,
    });
  }
  decodeABIParameter<T>(type: string, hex: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return defaultAbiCoder.decode([type], hex) as unknown as T;
  }
}
