import { type ReactNode } from "react";

import {
  Box,
  CircularProgress,
  Flex,
  Skeleton,
  Stat,
  StatArrow,
  StatHelpText,
  StatLabel,
  Text,
  Tooltip,
  type BoxProps,
} from "@chakra-ui/react";

interface IStatItem extends BoxProps {
  label: string;
  value: string | number;
  delta?: number;
  loading?: boolean;
  isValidating?: boolean;
  centered?: boolean;
  color?: string;
  tooltip?: string;
  labelTooltip?: ReactNode;
}

const StatItem = ({
  label,
  value,
  delta,
  loading,
  isValidating,
  color,
  tooltip,
  labelTooltip,
  centered,
  ...props
}: IStatItem) => {
  return (
    <Box
      mb={[4, 4, 0]}
      textAlign={centered ? "center" : ["center", "center", "left"]}
      {...props}
    >
      <Stat>
        <StatLabel color="gray.400" fontSize="xs">
          <Tooltip
            hasArrow
            placement="top"
            bg="gray.500"
            label={labelTooltip || ""}
            shouldWrapChildren={false}
          >
            {label}
          </Tooltip>
          {isValidating && (
            <CircularProgress
              top={"3px"}
              left={-4}
              position="absolute"
              color="brand.300"
              size={3}
              isIndeterminate
              mr={1}
            />
          )}
        </StatLabel>

        {loading ? (
          <Skeleton height="12px" my={1} width="40px" />
        ) : (
          <Flex
            gap={2}
            height={6}
            justifyContent={centered ? "center" : "flex-start"}
          >
            {value && (
              <Text fontWeight={"bold"} color={color}>
                {value}
              </Text>
            )}
            {!delta ? null : (
              <Tooltip
                hasArrow
                placement="right"
                label={tooltip}
                shouldWrapChildren={false}
              >
                <StatHelpText alignSelf="center" mb={0} mt={0}>
                  <StatArrow type={delta < 0 ? "decrease" : "increase"} />
                  {delta}%
                </StatHelpText>
              </Tooltip>
            )}
          </Flex>
        )}
      </Stat>
    </Box>
  );
};

export default StatItem;
