import React, { useEffect, useState } from "react";

import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useColorModeValue,
  type ChakraComponent,
  type InputProps,
} from "@chakra-ui/react";
import { BigNumber } from "@ethersproject/bignumber";

import InputBase from "./Base";

interface IInputNumberProps extends InputProps {
  id: string;
  label?: string;
  placeholder?: string;
  error?: string;
  setRef:
    | React.RefObject<HTMLInputElement>
    | React.RefCallback<HTMLInputElement>;
  onBlur?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: any) => void;
  exponent?: {
    min?: number;
    max?: number;
  };
  leftElementIcon?: React.ReactNode;
  rightElementIcon?: React.ReactNode;
}

interface FormatNumberProps {
  prevVal: string;
  newVal: string;
}

const InputNumber: ChakraComponent<"input", IInputNumberProps> = ({
  id,
  label,
  placeholder,
  error,
  setRef,
  value,
  onBlur,
  onChange,
  exponent,
  leftElementIcon,
  rightElementIcon,
  ...rest
}) => {
  const formatNumber = ({ prevVal, newVal }: FormatNumberProps) => {
    const splitNewVal = (Math.abs(Number(newVal)) || "").toString().split(".");
    const splitPrevVal = prevVal.split(".");
    // console.log('splitNewVal', splitNewVal)
    // console.log('splitPrevVal', splitPrevVal)
    if (newVal === "0" || newVal === "" || !splitNewVal[0]) return newVal;
    if (prevVal === "0" && Number(newVal) > 0) {
      return newVal;
    }
    if (
      (BigNumber.from(splitNewVal[0]).eq(
        BigNumber.from(splitPrevVal[0] || "0"),
      ) &&
        newVal.length > prevVal.length &&
        newVal[newVal.length - 1] !== "." &&
        !splitNewVal[1]) ||
      (!exponent?.min && newVal[newVal.length - 1] === ".")
    ) {
      return prevVal;
    }
    return newVal;
  };

  const [_number, setNumber] = useState<string>("");

  useEffect(() => {
    if (value) {
      setNumber((prevVal) =>
        formatNumber({ prevVal, newVal: value.toString() }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onChangeValidate = (number: string) => {
    if (!onChange) return;
    let newVal = number;
    if (newVal !== "-" && isNaN(Number(newVal))) return;

    const splitNewVal = newVal.split(".");
    if (exponent) {
      if (typeof exponent.max === "number") {
        // @ts-expect-error `splitNewVal[0]` is defined
        if (splitNewVal[0].length > exponent?.max)
          // @ts-expect-error `splitNewVal[0]` is defined
          splitNewVal[0] = splitNewVal[0].substring(0, exponent?.max);
      }
      if (typeof exponent.min === "number") {
        if (splitNewVal[1] && splitNewVal[1].length > exponent.min)
          splitNewVal[1] = splitNewVal[1].substring(0, exponent.min);
      }
      newVal = splitNewVal.join(".");
    }

    setNumber((prevVal) => formatNumber({ prevVal, newVal }));
    onChange(!isNaN(Number(newVal)) ? newVal : "0");
  };

  return (
    <InputBase id={id} label={label} error={error}>
      <InputGroup borderRadius="xl">
        {leftElementIcon && (
          <InputLeftElement pointerEvents="none">
            {leftElementIcon}
          </InputLeftElement>
        )}

        <Input
          type="text"
          autoComplete="off"
          ref={setRef}
          pattern="[-?\d*\.?\d*]*"
          id={id}
          value={_number}
          onBlur={onBlur}
          onChange={(newVal) => onChangeValidate(newVal.target.value)}
          placeholder={placeholder}
          _placeholder={{ color: "gray.300" }}
          color={useColorModeValue("black", "gray.600")}
          bg={useColorModeValue("gray.200", "white")}
          _focus={{
            borderColor: "brand.200",
            borderWidth: 1,
          }}
          {...rest}
        />

        {rightElementIcon && (
          <InputRightElement>{rightElementIcon}</InputRightElement>
        )}
      </InputGroup>
    </InputBase>
  );
};
export default InputNumber;
