import {
  extendTheme,
  type ThemeConfig,
  type ThemeOverride,
} from "@chakra-ui/react";
import { StepsTheme } from "chakra-ui-steps";

const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
  cssVarPrefix: "partyfinance",
};

const colors = {
  colors: {
    brand: {
      nav: "#1a1c1e",
      main: "#1c2736",
      light: "#243040",
      darkIcon: "#364457",
      50: "#fee4ff",
      100: "#ffb5f3",
      200: "#fa86e5",
      300: "#f656d3",
      400: "#ee2a7b",
      500: "#d90d9f",
      600: "#a90686",
      700: "#790269",
      800: "#4b0045",
      900: "#1d001d",
    },
  },
};

type CustomColors = typeof colors;

const extendedTheme: ThemeOverride = {
  colors: { ...colors.colors },
  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === "light" ? "white" : "brand.main",
        overflowY: "auto",
        minHeight: "100vh",

        MozOsxFontSmoothing: "grayscale",
        WebkitFontSmoothing: "antialiased",
        textRendering: "optimizeLegibility",
      },
      "::-webkit-scrollbar-track": {
        borderRadius: "10px",
      },
      "::-webkit-scrollbar-thumb": {
        background: props.colorMode === "light" ? "gray.100" : "brand.nav",
        borderRadius: "8px",
      },
    }),
  },
  fonts: {
    heading: "'Inter', sans-serif",
    body: "'Inter', sans-serif",
  },
  components: {
    Menu: {
      baseStyle: ({ colorMode }) => ({
        list: {
          backgroundColor: colorMode === "dark" ? "brand.nav" : "white",
        },
        item: {
          backgroundColor: colorMode === "dark" ? "brand.nav" : "white",
          _hover: {
            backgroundColor: colorMode === "dark" ? "#222629" : "gray.100",
          },
        },
      }),
    },
    Card: {
      baseStyle: ({ colorMode }) => ({
        backgroundColor: colorMode === "dark" ? "dark.700" : "white",
        borderRadius: "xl",
      }),
    },
    Progress: {
      variants: {
        primary: {
          bg: "gradient.primary",
        },
      },
    },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    Steps: {
      ...StepsTheme,
      baseStyle: (props) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          ...StepsTheme.baseStyle(props),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          connector: {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            ...StepsTheme.baseStyle(props).connector,
            borderColor: props.colorMode === "light" ? "gray.200" : "gray.600",
          },
        };
      },
    },
    Table: {
      variants: {
        stripedEven: (props) => ({
          tr: {
            _even: {
              background: props.colorScheme,
            },
          },
        }),
      },
    },
  },
  config,
};

const theme = extendTheme(extendedTheme);

type CustomTheme = typeof theme & CustomColors;
export type { CustomTheme };
export default theme;
