import { useMemo } from "react";

import { useQuery } from "@tanstack/react-query";
import { useSession } from "next-auth/react";

import { fetchPartyMembers } from "@partyfinance/thegraph-queries";

import { api } from "~/utils/api";

import { usePartyContext } from "~/contexts/PartyContext";
import { env } from "~/env.mjs";
import { type Member } from "~/modules/party/types";

const usePartyMembers = (partyAddress: string, available = true) => {
  const { chainId } = usePartyContext();
  const { status } = useSession();
  const enabled = !!chainId && !!partyAddress && available;

  // Fetching party members from TheGraph
  const {
    data: partyMembers,
    error,
    refetch,
  } = useQuery(
    ["fetchPartyMembers", chainId, partyAddress],
    () =>
      fetchPartyMembers(
        chainId,
        env.NEXT_PUBLIC_THEGRAPH_API_KEY,
        partyAddress,
      ),
    { enabled },
  );
  // Fetching party members nicknames
  const { data: membersProfiles } = api.profile.members.useQuery(
    {
      addresses: partyMembers?.map((x) => x.address) ?? [],
    },
    {
      enabled:
        status === "authenticated" && !!partyMembers && partyMembers.length > 0,
      retry: false,
    },
  );

  const members: Member[] = useMemo(() => {
    if (!partyMembers) return [];
    return partyMembers.map((x) => {
      const profile = (membersProfiles || []).find(
        (y) => y.address === x.address,
      );
      const img =
        !!profile && profile.punkId !== null && profile.punkId >= 0
          ? `https://picsum.photos/seed/${profile.punkId + 1}/200`
          : "";
      return {
        ...x,
        nickname: profile?.name || "",
        img,
      };
    });
  }, [partyMembers, membersProfiles]);

  return {
    members,
    loading: !error && partyMembers === undefined,
    reload: () => refetch(),
    error,
  };
};

export default usePartyMembers;
