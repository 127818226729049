import { useMemo } from "react";

import NextLink from "next/link";

import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  HStack,
  Heading,
  Link,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";

import {
  FormatDate,
  formatDate,
  getDateFromNow,
  isAddressEqual,
  shortenHex,
} from "@partyfinance/core";

import { type RouterOutput } from "~/utils/api";

import { Card } from "~/components/Card";
import { Jazzicon } from "~/components/Icons";
import SharePollModal from "~/components/Modals/SharePollModal";

import { useGovernanceContext } from "~/contexts/GovernanceContext";
import { usePlatformContext } from "~/contexts/PlatformContext";

import { PollTypeEnum } from "../types";
import { DeletePollButton, VoteButton } from "./Buttons";
import PollResults from "./PollResults";
import PollStatus from "./PollStatus";
import VotingPower from "./VotingPower";

const PollVote = ({
  poll,
}: {
  poll: NonNullable<RouterOutput["poll"]["byId"]>;
}) => {
  const { profile } = usePlatformContext();
  const { userVotes } = useGovernanceContext();
  const userVoted = useMemo(
    () => userVotes.findIndex((v) => v.pollId === poll.id) > -1,
    [userVotes, poll.id],
  );

  const { votingEnded, winner, totalVotesCast } = useMemo(() => {
    return {
      votingEnded: poll.endDate.getTime() < Date.now(),
      winner: poll.Options.reduce((prev, curr) => {
        if (prev.totalVotes > curr.totalVotes) {
          return prev;
        } else {
          return curr;
        }
      }),
      totalVotesCast: poll.Options.reduce<number>(
        (acc, option) => acc + option.totalVotes,
        0,
      ),
    };
  }, [poll]);

  return (
    <Box w="full" px={{ base: 2, md: 6 }} py={6}>
      {/* Header: Show pApes holding and voting power is user is connected */}
      <VotingPower />
      <Flex py={3} justifyContent="space-between" alignItems="center" gap={4}>
        <Link
          as={NextLink}
          href="/vote"
          color={useColorModeValue("gray.600", "gray.400")}
          _hover={{ textDecoration: "none", opacity: 0.8 }}
        >
          <ArrowBackIcon /> Back to proposals
        </Link>
        <Flex gap={2}>
          {isAddressEqual(profile?.address, poll.User.address) && (
            <DeletePollButton pollId={poll.id} />
          )}
          <SharePollModal poll={poll} />
        </Flex>
      </Flex>
      <Flex
        gap={4}
        alignItems={{ base: "stretch", md: "flex-start" }}
        flexWrap="wrap"
        direction={{ base: "column", md: "row" }}
      >
        <Card
          flex={{ base: 2, md: 2, lg: 3 }}
          px={4}
          borderWidth={1}
          borderColor={useColorModeValue("gray.200", "transparent")}
          maxW="3xl"
        >
          <VStack alignItems="stretch">
            {/* Title */}
            <Heading fontSize={{ base: "lg", lg: "2xl" }}>{poll.name}</Heading>

            {/* Description */}
            <Flex direction="column" gap={1} fontWeight="normal" w="full">
              {poll.description.split("\n").map((text, i) => (
                <Text key={i}>{text}</Text>
              ))}
            </Flex>
          </VStack>
        </Card>
        {/* Poll results */}
        <Flex direction="column" gap={4} flex={2}>
          <Card
            overflowX="auto"
            px={4}
            borderWidth={1}
            borderColor={useColorModeValue("gray.200", "transparent")}
            maxW="3xl"
          >
            <Flex direction="column" w="full" gap={3}>
              {/* Poll author */}
              <Box textAlign="left" color="gray.400">
                <Text fontWeight="bold" pb={1}>
                  Author:
                </Text>
                <Flex gap={2} alignItems="center">
                  <Flex borderRadius="50%" overflow="hidden">
                    <Jazzicon account={poll.User.address} diameter={24} />
                  </Flex>
                  <Text
                    as="span"
                    color="gray.300"
                    fontWeight="bold"
                    textOverflow="ellipsis"
                  >
                    {shortenHex(poll.User.address)}
                    {isAddressEqual(
                      poll.User.address,
                      profile?.address || "",
                    ) && " (you)"}
                  </Text>
                </Flex>
              </Box>

              {/* Poll status */}
              <Box textAlign="left" color="gray.400">
                <Text fontWeight="bold" pb={1}>
                  Status:
                </Text>
                <PollStatus status={poll.status} votingEnded={votingEnded} />
              </Box>

              {/* Poll creation date */}
              {poll.createdAt && (
                <Box textAlign="left" color="gray.400">
                  <Text fontWeight="bold" pb={1}>
                    Published date:
                  </Text>
                  <Text>
                    {formatDate(
                      new Date(poll.createdAt).getTime() / 1000,
                      FormatDate.lll,
                    )}{" "}
                    <Text as="span">
                      (
                      {getDateFromNow(
                        new Date(poll.createdAt).getTime() / 1000,
                      )}
                      )
                    </Text>
                  </Text>
                </Box>
              )}

              {/* Poll end date */}
              <Box textAlign="left" color="gray.400">
                <Text fontWeight="bold" pb={1}>
                  End date:
                </Text>
                <Text>
                  {formatDate(poll.endDate.getTime() / 1000, FormatDate.lll)}{" "}
                  <Text as="span">
                    ({getDateFromNow(poll.endDate.getTime() / 1000)})
                  </Text>
                </Text>
              </Box>

              <Divider />

              {/* Poll results */}
              <PollResults poll={poll} />
            </Flex>
          </Card>

          <Card
            overflowX="auto"
            px={4}
            borderWidth={1}
            borderColor={useColorModeValue("gray.200", "transparent")}
            maxW="3xl"
          >
            <VStack>
              {/* Voting */}
              <Heading
                textAlign={{ base: "center", lg: "start" }}
                wordBreak={"break-all"}
                fontSize={"lg"}
                pb={poll.status > 0 || votingEnded ? 0 : 2}
              >
                {poll.status > 0 || votingEnded
                  ? "Voting phase ended"
                  : "Cast your vote"}
              </Heading>
              {poll.status > 0 || votingEnded
                ? null
                : poll.type === PollTypeEnum.Simple
                ? poll.Options.map((o) => (
                    <VoteButton
                      key={o.id}
                      w="full"
                      poll={poll}
                      option={o}
                      color={
                        userVoted
                          ? winner.value === o.value
                            ? "green.400"
                            : "red.400"
                          : "auto"
                      }
                    >
                      {o.value.toUpperCase() + " "}
                      {userVoted
                        ? o.totalVotes
                          ? `${Math.round(
                              (o.totalVotes / totalVotesCast) * 100,
                            )}%`
                          : "0%"
                        : ""}
                    </VoteButton>
                  ))
                : poll.type === PollTypeEnum.Multi
                ? poll.Options.map((option, i) => (
                    <VoteButton
                      key={option.id}
                      poll={poll}
                      option={option}
                      w="full"
                      h="fit-content"
                    >
                      <VStack p={2} w="full">
                        <HStack
                          w={userVoted ? "full" : "auto"}
                          justify="space-between"
                          color={
                            userVoted
                              ? winner.value === option.value
                                ? "green.400"
                                : "red.400"
                              : "auto"
                          }
                        >
                          <Text size={"xs"}>Option {i + 1}</Text>
                          {userVoted && (
                            <Heading size={"sm"} ml="auto">
                              {option.totalVotes
                                ? Math.round(
                                    ((option.totalVotes || 0) /
                                      totalVotesCast) *
                                      100,
                                  )
                                : 0}
                              %
                            </Heading>
                          )}
                        </HStack>
                        <Text
                          justifyContent={"center"}
                          textAlign="start"
                          fontSize="sm"
                          maxW={"full"}
                          overflow="hidden"
                          whiteSpace="break-spaces"
                          wordBreak="break-word"
                        >
                          {option.value}
                        </Text>
                      </VStack>
                    </VoteButton>
                  ))
                : null}
            </VStack>
          </Card>
        </Flex>
      </Flex>
    </Box>
  );
};

export default PollVote;
