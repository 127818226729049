import {
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  type UseDisclosureProps,
} from "@chakra-ui/react";

import { Button, CloseButton } from "~/components/Buttons";

interface ActionDeniedProps extends UseDisclosureProps {
  onClose: () => void;
  isOpen: boolean;
  title?: string;
  message?: string;
}

const ApeRequiredModal = ({
  onClose,
  isOpen,
  title = "Sorry...",
  message = "You need to be a PartyApe holder to vote on polls.",
}: ActionDeniedProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent my="auto">
        <ModalHeader>
          <CloseButton aria-label={"Close Button"} onClose={onClose} />
        </ModalHeader>
        <ModalBody px="3rem">
          <VStack spacing={4} textAlign="center">
            <Heading size="lg" fontWeight="extrabold">
              {title}
            </Heading>
            <Text width="fit-content" mx="auto" fontWeight="bold">
              {message}
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter mt="1rem">
          <Button minW="12rem" ml={["auto"]} mx="auto" mb="0.5rem">
            <Link
              href="https://opensea.io/collection/partyapes-v4"
              target="_blank"
              rel="noreferrer"
            >
              View on OpenSea
            </Link>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ApeRequiredModal;
