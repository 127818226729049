/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement, useState } from "react";

import { type ContractTransaction } from "@ethersproject/contracts";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { type BigNumber } from "ethers";

import { getUSDCAddress } from "@partyfinance/core";

import { api } from "~/utils/api";
import { addPlatformFee } from "~/utils/web3";

import { usePartyContext } from "~/contexts/PartyContext";
import { useTransactionContext } from "~/contexts/TransactionContext";
import { usePlatformCheck } from "~/hooks/platform";
import { useERC20Contract } from "~/hooks/token";
import AllocationConfirmation from "~/modules/party/components/AllocationConfirmation";
import { handleCustomError } from "~/modules/party/errors";
import {
  TransactionActions,
  type IAllocationSignatureResp,
} from "~/modules/party/types";

import { usePartyContract } from ".";

const steps = [
  {
    label: "Check balance",
    content:
      "Fund your wallet with the initial deposit + the platform fee of 0.5%",
    loading: true,
  },
  {
    label: "Allow USDC spend",
    content: "Confirm the initial USDC spend allowance in your wallet",
  },
  {
    label: "Allowance confirmation",
    content: "Please wait until the allowance transaction is confirmed",
    loading: true,
    showGame: true,
  },
  {
    label: "Allocations",
    content: "Getting the best allocation for your deposit",
    requiresUserAction: true,
    loading: true,
  },
  {
    label: "Deposit",
    content: "Confirm the party deposit transaction in your wallet",
  },
  {
    label: "Party confirmation",
    content: "Please wait until the party deposit is confirmed",
    loading: true,
    showGame: true,
  },
];

const usePartyDeposit = (partyAddress: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { chainId } = usePartyContext();
  const { checkWallet } = usePlatformCheck();
  const usdcContract = useERC20Contract(getUSDCAddress(chainId));
  const partyContract = usePartyContract(partyAddress);
  const {
    addTransaction,
    updateTransaction,
    openConfirmationWithSteps,
    closeConfirmationWithSteps,
    setStep,
    setErrorMsg,
  } = useTransactionContext();
  // Api hooks
  const signatureDeposit = api.signature.deposit.useMutation();

  const depositToParty = async (
    deposit: number,
    allocate: boolean,
    allocationConfirmed?: IAllocationSignatureResp,
    confirmedInitialDeposit?: BigNumber,
  ) => {
    const account = checkWallet();
    if (!account || !usdcContract || !partyContract) return null;
    setLoading(true);
    if (!allocationConfirmed || !confirmedInitialDeposit) {
      openConfirmationWithSteps(steps);
    }
    let txHash = "";

    try {
      if (!allocationConfirmed || !confirmedInitialDeposit) {
        const isMember = await partyContract.members(account);
        if (!isMember) {
          throw Error("You must be a member of this party to deposit funds.");
        }
        const usdcDecimals = await usdcContract.decimals();

        // 1) Check if user has available funds to deposit into the party (deposit + fee)
        const initialDeposit = parseUnits(deposit.toString(), usdcDecimals);
        const pay = addPlatformFee(initialDeposit);

        const balance = await usdcContract.balanceOf(account);
        const isSufficient = balance.gte(pay);

        if (!isSufficient) {
          const requiredUSDC = formatUnits(pay, "mwei");
          throw Error(
            `Your balance is not enough. Fund your wallet with at least ${requiredUSDC} USDC (initial deposit + 0.5% fee).`,
          );
        }

        // 2) Approve Factory DA consumption (in case it requiers)
        const allowance = await usdcContract.allowance(
          account,
          partyContract.address,
        );
        if (allowance.lt(pay)) {
          console.log("User needs to approve usdc");
          setStep(1);
          const approval: ContractTransaction = await usdcContract.approve(
            partyContract.address,
            pay,
          );
          setStep(2);
          txHash = approval.hash;
          addTransaction(TransactionActions.approveUSDC, txHash);
          await approval.wait();
          updateTransaction(txHash, true, false);
          txHash = "";
        }

        // 3) Get allocation signature
        const data = await signatureDeposit.mutateAsync({
          partyAddress: partyContract.address,
          userAddress: account,
          deposit: initialDeposit.toNumber(),
          chainId: chainId,
          allocate,
          action: "deposit",
        });

        // 4) Deposit into party Party
        setStep(3);
        console.log("data.allocationArgs", data.allocationArgs);
        const buyTokens = data.allocationArgs.buyTokens;
        const sellAmounts = data.allocationArgs.sellAmounts;
        const allocation = buyTokens
          .map((token, i) => ({
            token: token,
            sellAmountDA: formatUnits(
              sellAmounts[i] as BigNumber,
              usdcDecimals,
            ),
          }))
          .sort((a, b) => Number(b.sellAmountDA) - Number(a.sellAmountDA));

        // 5) Change the steps to show allocation and callback
        const partyValueDA = formatUnits(
          data.allocationArgs.partyValueDA,
          usdcDecimals,
        );
        openConfirmationWithSteps(
          steps.map((x) => {
            if (x.requiresUserAction) {
              return {
                ...x,
                content: "",
                allocations: data,
                loading: false,
                CustomView: createElement(
                  AllocationConfirmation,
                  {
                    chainId,
                    partyAddress: partyContract.address,
                    partyValueDA:
                      Math.floor(Number(partyValueDA) * 1000) / 1000,
                    deposit,
                    mintedShare:
                      Math.floor(
                        (Number(deposit) /
                          (Number(partyValueDA) + Number(deposit))) *
                          10000,
                      ) / 100,
                    allocation,
                  },
                  [],
                ),
                nextCallback: () => {
                  void depositToParty(deposit, allocate, data, initialDeposit);
                },
              };
            }
            return x;
          }),
          false,
        );
      } else {
        // 6) Deposit into Party (after user manually confirmed allocation)
        setStep(4);
        const newDeposit: ContractTransaction = await partyContract.deposit(
          account,
          confirmedInitialDeposit,
          allocationConfirmed.allocationArgs,
          allocationConfirmed.signature,
        );
        txHash = newDeposit.hash;
        addTransaction(TransactionActions.depositToParty, txHash);

        setStep(5);
        await newDeposit.wait(2);
        updateTransaction(txHash, true, true);
        txHash = "";

        closeConfirmationWithSteps();
      }
    } catch (err: any) {
      console.log(err);
      const customError = handleCustomError(err);
      setErrorMsg(customError.message);
      if (txHash) {
        updateTransaction(txHash, false, true);
      }
    }
    setLoading(false);
  };

  return {
    depositToParty,
    loadingDeposit: loading,
  };
};

export default usePartyDeposit;
