import {
  Textarea,
  useColorModeValue,
  type ChakraComponent,
  type TextareaProps,
} from "@chakra-ui/react";

import InputBase from "./Base";

interface IInputTextareaProps extends TextareaProps {
  id: string;
  label?: string;
  placeholder?: string;
  error?: string;
  setRef:
    | React.RefObject<HTMLTextAreaElement>
    | React.RefCallback<HTMLTextAreaElement>;
}

const InputTextarea: ChakraComponent<"textarea", IInputTextareaProps> = ({
  id,
  label,
  placeholder,
  error,
  setRef,
  value,
  rows,
  onBlur,
  onChange,
  ...rest
}) => {
  return (
    <InputBase id={id} label={label} error={error}>
      <Textarea
        ref={setRef}
        id={id}
        rows={rows}
        value={value || ""}
        onBlur={onBlur}
        onChange={onChange}
        bg={useColorModeValue("gray.200", "transparent")}
        placeholder={placeholder}
        _focus={{
          borderColor: "brand.200",
          borderWidth: 1,
        }}
        {...rest}
      />
    </InputBase>
  );
};

export default InputTextarea;
