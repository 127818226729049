import { type FC, type ReactNode } from "react";

import {
  Box,
  Button as ChakraButton,
  Flex,
  Grid,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "next-share";
import { BsShareFill } from "react-icons/bs";
import { FiCopy } from "react-icons/fi";

import { type RouterOutput } from "~/utils/api";

import { CloseButton } from "~/components/Buttons";

import toast from "~/compounds/toast";
import { baseUrl_App } from "~/config";

const SocialButtonContainer = ({
  children,
  label,
}: {
  children: ReactNode;
  label: string;
}) => {
  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      gap={1}
      textAlign="center"
    >
      {children}
      <Text fontSize="sm">{label}</Text>
    </Flex>
  );
};
interface ISharePollModalProps {
  poll: NonNullable<RouterOutput["poll"]["byId"]>;
}

const SharePollModal: FC<ISharePollModalProps> = ({ poll }) => {
  const SHARE_TITLE = "Checkout this PartyApe proposal";
  const shareText =
    "Share on social media and invite PartyApe holders to vote on this proposal 🎉🎉";
  const { onOpen, onClose, isOpen } = useDisclosure();
  const link = `${baseUrl_App}/vote/${poll.id}`;

  const copyLink = () => {
    void navigator.clipboard.writeText(link);
    toast.dismiss();
    return toast.info({
      title: "Link copied to clipboard",
    });
  };

  return (
    <>
      <ChakraButton onClick={onOpen} leftIcon={<BsShareFill />} size="sm">
        Share
      </ChakraButton>

      <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
        <ModalOverlay />
        <ModalContent my="auto" borderRadius="xl">
          <ModalHeader mb={0} pb={0}>
            <CloseButton aria-label={"Close Button"} onClose={onClose} />
          </ModalHeader>
          <ModalBody mx={{ base: 1, sm: 3, md: 6 }} pt={0}>
            <Text textAlign="center" mb={2} fontSize={"lg"}>
              {shareText}
            </Text>
            <Box
              p={4}
              my={6}
              borderWidth={"2px"}
              borderColor={useColorModeValue("brand.100", "gray.600")}
              borderRadius={"10px"}
            >
              <Grid templateColumns="repeat(4, 1fr)" gap={2}>
                <FacebookShareButton
                  url={link}
                  quote={SHARE_TITLE}
                  hashtag={"#nextshare"}
                >
                  <SocialButtonContainer label="Facebook">
                    <FacebookIcon size={40} round />
                  </SocialButtonContainer>
                </FacebookShareButton>
                <TwitterShareButton url={link} title={SHARE_TITLE}>
                  <SocialButtonContainer label="Twitter">
                    <TwitterIcon size={40} round />
                  </SocialButtonContainer>
                </TwitterShareButton>
                <RedditShareButton url={link} title={SHARE_TITLE}>
                  <SocialButtonContainer label="Reddit">
                    <RedditIcon size={40} round />
                  </SocialButtonContainer>
                </RedditShareButton>
                <WhatsappShareButton
                  url={link}
                  title={SHARE_TITLE}
                  separator=":: "
                >
                  <SocialButtonContainer label="Whatsapp">
                    <WhatsappIcon size={40} round />
                  </SocialButtonContainer>
                </WhatsappShareButton>
              </Grid>
              <Grid templateColumns="repeat(4, 1fr)" gap={3} pt={3}>
                <TelegramShareButton url={link} title={SHARE_TITLE}>
                  <SocialButtonContainer label="Telegram">
                    <TelegramIcon size={40} round />
                  </SocialButtonContainer>
                </TelegramShareButton>
                <FacebookMessengerShareButton url={link} appId={""}>
                  <SocialButtonContainer label="Messenger">
                    <FacebookMessengerIcon size={40} round />
                  </SocialButtonContainer>
                </FacebookMessengerShareButton>
                <LinkedinShareButton url={link}>
                  <SocialButtonContainer label="LinkedIn">
                    <LinkedinIcon size={40} round />
                  </SocialButtonContainer>
                </LinkedinShareButton>
                <SocialButtonContainer label="Copy link">
                  <IconButton
                    aria-label="copy-url"
                    icon={<FiCopy />}
                    colorScheme="pink"
                    borderRadius="full"
                    onClick={copyLink}
                  />
                </SocialButtonContainer>
              </Grid>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SharePollModal;
