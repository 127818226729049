/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement, useCallback, useState, type ReactElement } from "react";

import { useDisclosure } from "@chakra-ui/react";
import { type ContractTransaction } from "@ethersproject/contracts";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { type BigNumber } from "ethers";

import { getUSDCAddress } from "@partyfinance/core";

import { api } from "~/utils/api";
import { addPlatformFee } from "~/utils/web3";

import { JoinDepositModal } from "~/components/Modals";

import toast from "~/compounds/toast";
import { endOfLegacyContract } from "~/config";
import { usePartyContext } from "~/contexts/PartyContext";
import { useTransactionContext } from "~/contexts/TransactionContext";
import { usePlatformCheck } from "~/hooks/platform";
import { useERC20Contract } from "~/hooks/token";
import AllocationConfirmation from "~/modules/party/components/AllocationConfirmation";
import { handleCustomError } from "~/modules/party/errors";
import {
  TransactionActions,
  type ConfirmationStep,
  type IAllocationSignatureResp,
} from "~/modules/party/types";

import { usePartyContract } from ".";

const joinSteps: ConfirmationStep[] = [
  {
    label: "Check balance",
    content:
      "Fund your wallet with the initial deposit + the platform fee of 0.5%",
    loading: true,
  },
  {
    label: "Allow USDC spend",
    content: "Confirm the initial USDC spend allowance in your wallet",
  },
  {
    label: "Allowance confirmation",
    content: "Please wait until the allowance transaction is confirmed",
    loading: true,
    showGame: true,
  },
  {
    label: "Allocations",
    content: "Getting the best allocation for your deposit",
    requiresUserAction: true,
    loading: true,
  },
  {
    label: "Join party",
    content: "Confirm the party deposit transaction in your wallet",
  },
  {
    label: "Party confirmation",
    content: "Please wait until the party deposit is confirmed",
    loading: true,
    showGame: true,
  },
];
const joinRequestSteps = [
  {
    label: "Join party request",
    content: "Confirm your request to join the party in your wallet",
  },
  {
    label: "Join request confirmation",
    content: "Please wait until join request is created",
    loading: true,
    showGame: true,
  },
];

const usePartyJoin = (partyAddress: string) => {
  const {
    isOpen: isDepositOpen,
    onOpen: onOpenDeposit,
    onClose: onCloseDeposit,
  } = useDisclosure();
  const [loading, setLoading] = useState<boolean>(false);
  const { chainId, partyInfo } = usePartyContext();
  const { checkWallet } = usePlatformCheck();
  const usdcContract = useERC20Contract(getUSDCAddress(chainId));
  const partyContract = usePartyContract(partyAddress);
  const {
    addTransaction,
    updateTransaction,
    openConfirmationWithSteps,
    setStep,
    reset,

    setErrorMsg,
    closeConfirmationWithSteps,
  } = useTransactionContext();
  // Api hooks
  const signatureDeposit = api.signature.deposit.useMutation();

  const joinParty = async (
    deposit?: number,
    allocate?: boolean,
    allocationConfirmed?: IAllocationSignatureResp,
    confirmedInitialDeposit?: BigNumber,
  ) => {
    const account = checkWallet();
    if (!account || !usdcContract || !partyContract) return null;
    if (!allocationConfirmed || !confirmedInitialDeposit) {
      reset();
    }
    setLoading(true);
    let txHash = "";

    try {
      // PRE CHECK) In case the party is private, the user must have been requested and accepted in order to join
      if (!allocationConfirmed || !confirmedInitialDeposit) {
        const isPrivate = partyInfo ? !partyInfo.isPublic : false;
        if (isPrivate) {
          // Check if the user is allowed to join
          const isAllowed = await partyContract.isAcceptedRequest(account);
          if (!isAllowed) {
            console.log("User is not allowed to join. Need to request");
            // Check if there is a pending request from the user
            const joinRequests = await partyContract.getJoinRequests();
            const hasRequested = joinRequests.indexOf(account) > -1;
            if (hasRequested) {
              toast.warn(
                {
                  title: `You have already requested to join this party.`,
                  body: `The party owner needs to approve your request.`,
                },
                { autoClose: 5000 },
              );
              setLoading(false);
              throw Error("Already requested");
            }

            // Since the party is private, the user needs to request to join
            setLoading(true);
            openConfirmationWithSteps(joinRequestSteps);

            // Ask to join
            const isLegacyParty =
              !!partyInfo && partyInfo.inception < endOfLegacyContract;
            let requestTx: ContractTransaction;
            if (isLegacyParty) {
              /// @dev [PostMigration] Remove this function
              requestTx = await partyContract.joinRequest();
            } else {
              requestTx = await partyContract.createJoinRequest();
            }
            txHash = requestTx.hash;
            addTransaction(TransactionActions.joinRequest, txHash);
            setStep(1);
            await requestTx.wait();
            updateTransaction(txHash, true, true);
            txHash = "";

            closeConfirmationWithSteps();
            setLoading(false);

            return toast.success(
              {
                title: `You have requested to join this private party.`,
                body: `The party owner would need to approve your request before you join.`,
              },
              { autoClose: 5000 },
            );
          }
        }

        // This is to ensure the user deposits to the party prior to joining it
        if (!deposit) {
          console.log("opening deposit modal");
          onOpenDeposit();
          setLoading(false);
          return;
        }
        onCloseDeposit();

        openConfirmationWithSteps(joinSteps);

        const usdcDecimals = await usdcContract.decimals();

        // 1) Check if user has available funds to deposit into the party (deposit + fee)
        const initialDeposit = parseUnits(String(deposit), usdcDecimals);
        const pay = addPlatformFee(initialDeposit);

        const balance = await usdcContract.balanceOf(account);
        const isSufficient = balance.gte(pay);

        if (!isSufficient) {
          const requiredUSDC = formatUnits(pay, "mwei");
          throw Error(
            `Your balance is not enough. Fund your wallet with at least ${requiredUSDC} USDC (initial deposit + 0.5% fee).`,
          );
        }

        // 2) Approve Factory DA consumption (in case it requiers)
        const allowance = await usdcContract.allowance(
          account,
          partyContract.address,
        );
        if (allowance.lt(pay)) {
          console.log("User needs to approve usdc");
          setStep(1);
          const approval: ContractTransaction = await usdcContract.approve(
            partyContract.address,
            pay,
          );
          txHash = approval.hash;
          addTransaction(TransactionActions.approveUSDC, txHash);
          setStep(2);
          await approval.wait();
          updateTransaction(txHash, true, false);
          txHash = "";
        }

        // 3) Get allocation signature
        const data = await signatureDeposit.mutateAsync({
          partyAddress: partyContract.address,
          userAddress: account,
          deposit: initialDeposit.toNumber(),
          chainId: chainId,
          allocate: allocate === undefined ? false : allocate,
          action: "join",
        });

        // 4) Show allocations and wait for next joinParty invocation with nextCallback passed
        setStep(3);
        console.log("data.allocationArgs", data.allocationArgs);
        const buyTokens = data.allocationArgs.buyTokens;
        const sellAmounts = data.allocationArgs.sellAmounts;
        const allocation = buyTokens
          .map((token, i) => ({
            token: token,
            sellAmountDA: formatUnits(
              sellAmounts[i] as BigNumber,
              usdcDecimals,
            ),
          }))
          .sort((a, b) => Number(b.sellAmountDA) - Number(a.sellAmountDA));

        // 5) Change the steps to show allocation and callback
        const partyValueDA = formatUnits(
          data.allocationArgs.partyValueDA,
          usdcDecimals,
        );
        openConfirmationWithSteps(
          joinSteps.map((x) => {
            if (x.requiresUserAction) {
              return {
                ...x,
                content: "",
                allocations: data,
                loading: false,
                CustomView: createElement(
                  AllocationConfirmation,
                  {
                    chainId,
                    partyAddress: partyContract.address,
                    partyValueDA:
                      Math.floor(Number(partyValueDA) * 1000) / 1000,
                    deposit,
                    mintedShare:
                      Math.floor(
                        (Number(deposit) /
                          (Number(partyValueDA) + Number(deposit))) *
                          10000,
                      ) / 100,
                    allocation,
                  },
                  [],
                ),
                nextCallback: () => {
                  void joinParty(deposit, allocate, data, initialDeposit);
                },
              };
            }
            return x;
          }),
          false,
        );
      } else {
        // 6) Join into Party (after user manually confirmed allocation)
        setStep(4);
        const newJoin: ContractTransaction = await partyContract.joinParty(
          account,
          confirmedInitialDeposit,
          allocationConfirmed.allocationArgs,
          allocationConfirmed.signature,
        );
        txHash = newJoin.hash;
        addTransaction(TransactionActions.joinParty, txHash);

        setStep(5);
        await newJoin.wait(2);
        updateTransaction(txHash, true, true);
        txHash = "";

        closeConfirmationWithSteps();
      }
    } catch (err: any) {
      console.log(err);
      const customError = handleCustomError(err);
      setErrorMsg(customError.message);
      if (txHash) {
        updateTransaction(txHash, false, true);
      }
    }
    setLoading(false);
  };

  const JoinDepositModalRequired = useCallback(
    (): ReactElement =>
      createElement(
        JoinDepositModal,
        {
          isDepositOpen,
          onCloseDeposit,
          joinParty,
        },
        [],
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDepositOpen, onCloseDeposit],
  );

  return {
    joinParty,
    loadingJoin: loading,
    JoinDepositModalRequired,
  };
};

export default usePartyJoin;
