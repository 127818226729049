import { useQuery } from "@tanstack/react-query";

import { PeriodEnum } from "@partyfinance/core";
import {
  fetchPartyMemberHistoricalState,
  type PartyMemberState,
} from "@partyfinance/thegraph-queries";

import { usePlatformContext } from "~/contexts/PlatformContext";
import { env } from "~/env.mjs";

const usePartyMemberHistoricalState = (
  partyAddress: string,
  chainId: number,
  period = PeriodEnum.LastDay,
  available = true,
) => {
  const { profile } = usePlatformContext();
  const enabled = !!partyAddress && available && !!profile;

  const { data, error, isFetching } = useQuery<PartyMemberState[]>(
    [
      "party-member-historical-state",
      chainId,
      partyAddress.toLowerCase(),
      profile?.address.toLowerCase() ?? "",
      period,
    ],
    () =>
      fetchPartyMemberHistoricalState(
        chainId,
        env.NEXT_PUBLIC_THEGRAPH_API_KEY,
        partyAddress.toLowerCase(),
        profile?.address.toLowerCase() ?? "",
        period,
      ),
    {
      enabled,
    },
  );

  return {
    partyMemberHistoricalState: data || [],
    isValidating: isFetching,
    loading: !error && data === undefined,
    error,
  };
};

export default usePartyMemberHistoricalState;
