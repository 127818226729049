import { useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Text,
  useColorModeValue,
  useInterval,
} from "@chakra-ui/react";
import { AiFillStar, AiFillTrophy } from "react-icons/ai";
import {
  FiArrowDown,
  FiArrowLeft,
  FiArrowRight,
  FiArrowUp,
} from "react-icons/fi";

type Apple = {
  x: number;
  y: number;
};

type Velocity = {
  dx: number;
  dy: number;
};

type Coordinate = {
  x: number;
  y: number;
};

// Taken from: https://github.com/marcmll/next-snake

const Snake = () => {
  // Canvas Settings
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const canvasWidth = 500;
  const canvasHeight = 340;
  const canvasGridSize = 20;

  // Game Settings
  const minGameSpeed = 10;
  const maxGameSpeed = 15;

  // Game State
  const [gameDelay, setGameDelay] = useState<number>(1000 / minGameSpeed);
  const [countDown, setCountDown] = useState<number>(4);
  const [running, setRunning] = useState(false);
  const [isLost, setIsLost] = useState(false);
  const [highscore, setHighscore] = useState(0);
  const [newHighscore, setNewHighscore] = useState(false);
  const [score, setScore] = useState(0);
  const [snake, setSnake] = useState<{
    head: Coordinate;
    trail: Coordinate[];
  }>({
    head: { x: 12, y: 9 },
    trail: [],
  });
  const [apple, setApple] = useState<Apple>({ x: -1, y: -1 });
  const [velocity, setVelocity] = useState<Velocity>({ dx: 0, dy: 0 });
  const [previousVelocity, setPreviousVelocity] = useState<Velocity>({
    dx: 0,
    dy: 0,
  });

  const clearCanvas = (ctx: CanvasRenderingContext2D) =>
    ctx.clearRect(-1, -1, canvasWidth + 2, canvasHeight + 2);

  const generateApplePosition = (): Apple => {
    const x = Math.floor(Math.random() * (canvasWidth / canvasGridSize));
    const y = Math.floor(Math.random() * (canvasHeight / canvasGridSize));
    // Check if random position interferes with snake head or trail
    if (
      (snake.head.x === x && snake.head.y === y) ||
      snake.trail.some((snakePart) => snakePart.x === x && snakePart.y === y)
    ) {
      return generateApplePosition();
    }
    return { x, y };
  };

  // Initialise state and start countdown
  const startGame = () => {
    setGameDelay(1000 / minGameSpeed);
    setIsLost(false);
    setScore(0);
    setSnake({
      head: { x: 12, y: 9 },
      trail: [],
    });
    setApple(generateApplePosition());
    setVelocity({ dx: 0, dy: -1 });
    setRunning(true);
    setNewHighscore(false);
    setCountDown(3);
  };

  // Reset state and check for highscore
  const gameOver = () => {
    if (score > highscore) {
      setHighscore(score);
      localStorage.setItem("highscore", score.toString());
      setNewHighscore(true);
    }
    setIsLost(true);
    setRunning(false);
    setVelocity({ dx: 0, dy: 0 });
    setCountDown(4);
  };

  const fillRect = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ) => {
    ctx.fillRect(x, y, w, h);
  };

  const strokeRect = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ) => {
    ctx.strokeRect(x + 0.5, y + 0.5, w, h);
  };

  const drawSnake = (ctx: CanvasRenderingContext2D) => {
    ctx.fillStyle = "green";
    ctx.strokeStyle = "#003779";

    fillRect(
      ctx,
      snake.head.x * canvasGridSize,
      snake.head.y * canvasGridSize,
      canvasGridSize,
      canvasGridSize,
    );

    strokeRect(
      ctx,
      snake.head.x * canvasGridSize,
      snake.head.y * canvasGridSize,
      canvasGridSize,
      canvasGridSize,
    );

    snake.trail.forEach((snakePart) => {
      fillRect(
        ctx,
        snakePart.x * canvasGridSize,
        snakePart.y * canvasGridSize,
        canvasGridSize,
        canvasGridSize,
      );

      strokeRect(
        ctx,
        snakePart.x * canvasGridSize,
        snakePart.y * canvasGridSize,
        canvasGridSize,
        canvasGridSize,
      );
    });
  };

  const drawApple = (ctx: CanvasRenderingContext2D) => {
    ctx.fillStyle = "#e716ad";
    ctx.strokeStyle = "#881A1B";

    if (
      apple &&
      typeof apple.x !== "undefined" &&
      typeof apple.y !== "undefined"
    ) {
      fillRect(
        ctx,
        apple.x * canvasGridSize,
        apple.y * canvasGridSize,
        canvasGridSize,
        canvasGridSize,
      );

      strokeRect(
        ctx,
        apple.x * canvasGridSize,
        apple.y * canvasGridSize,
        canvasGridSize,
        canvasGridSize,
      );
    }
  };

  // Update snake.head, snake.trail and apple positions. Check for collisions.
  const updateSnake = () => {
    // Check for collision with walls
    const nextHeadPosition = {
      x: snake.head.x + velocity.dx,
      y: snake.head.y + velocity.dy,
    };
    if (
      nextHeadPosition.x < 0 ||
      nextHeadPosition.y < 0 ||
      nextHeadPosition.x >= canvasWidth / canvasGridSize ||
      nextHeadPosition.y >= canvasHeight / canvasGridSize
    ) {
      gameOver();
    }

    // Check for collision with apple
    if (nextHeadPosition.x === apple.x && nextHeadPosition.y === apple.y) {
      setScore((prevScore) => prevScore + 1);
      setApple(generateApplePosition());
    }

    const updatedSnakeTrail = [...snake.trail, { ...snake.head }];
    // Remove trail history beyond snake trail length (score + 2)
    while (updatedSnakeTrail.length > score + 2) updatedSnakeTrail.shift();
    // Check for snake colliding with itsself
    if (
      updatedSnakeTrail.some(
        (snakePart) =>
          snakePart.x === nextHeadPosition.x &&
          snakePart.y === nextHeadPosition.y,
      )
    )
      gameOver();

    // Update state
    setPreviousVelocity({ ...velocity });
    setSnake({
      head: { ...nextHeadPosition },
      trail: [...updatedSnakeTrail],
    });
  };

  // Game Hook
  useEffect(() => {
    const canvas = canvasRef?.current;
    const ctx = canvas?.getContext("2d");

    if (ctx && !isLost) {
      clearCanvas(ctx);
      drawApple(ctx);
      drawSnake(ctx);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snake]);

  // Game Update Interval
  useInterval(
    () => {
      if (!isLost) {
        updateSnake();
      }
    },
    running && countDown === 0 ? gameDelay : null,
  );

  // Countdown Interval
  useInterval(
    () => {
      setCountDown((prevCountDown) => prevCountDown - 1);
    },
    countDown > 0 && countDown < 4 ? 800 : null,
  );

  // DidMount Hook for Highscore
  useEffect(() => {
    setHighscore(
      localStorage.getItem("highscore")
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          parseInt(localStorage.getItem("highscore")!)
        : 0,
    );
  }, []);

  // Score Hook: increase game speed starting at 16
  useEffect(() => {
    if (score > minGameSpeed && score <= maxGameSpeed) {
      setGameDelay(1000 / score);
    }
  }, [score]);

  // Event Listener: Key Presses
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        [
          "ArrowUp",
          "ArrowDown",
          "ArrowLeft",
          "ArrowRight",
          "w",
          "a",
          "s",
          "d",
        ].includes(e.key)
      ) {
        let velocity = { dx: 0, dy: 0 };

        switch (e.key) {
          case "ArrowRight":
            velocity = { dx: 1, dy: 0 };
            break;
          case "ArrowLeft":
            velocity = { dx: -1, dy: 0 };
            break;
          case "ArrowDown":
            velocity = { dx: 0, dy: 1 };
            break;
          case "ArrowUp":
            velocity = { dx: 0, dy: -1 };
            break;
          case "d":
            velocity = { dx: 1, dy: 0 };
            break;
          case "a":
            velocity = { dx: -1, dy: 0 };
            break;
          case "s":
            velocity = { dx: 0, dy: 1 };
            break;
          case "w":
            velocity = { dx: 0, dy: -1 };
            break;
          default:
            console.error("Error with handleKeyDown");
        }
        if (
          !(
            previousVelocity.dx + velocity.dx === 0 &&
            previousVelocity.dy + velocity.dy === 0
          )
        ) {
          setVelocity(velocity);
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [previousVelocity]);

  useEffect(() => {
    startGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const overlayBg = useColorModeValue(
    "rgba(255, 255, 255, 0.3)",
    "rgba(255, 255, 255, 0.1)",
  );
  const bannerColor = useColorModeValue("gray.300", "gray.800");
  const textColor = useColorModeValue("gray.500", "gray.400");
  return (
    <>
      <Box
        display={{ base: "none", md: "block" }}
        mx={"auto"}
        width={"fit-content"}
        bg={useColorModeValue("gray.400", "gray.600")}
        borderRadius={"6px"}
        overflow="hidden"
      >
        <Box position="relative">
          <canvas
            ref={canvasRef}
            width={canvasWidth + 1}
            height={canvasHeight + 1}
          />
          <Flex
            justifyContent="space-between"
            alignItems="center"
            background={bannerColor}
            py={2}
            px={3}
          >
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="start"
              gap={0}
            >
              <HStack alignItems="center">
                <AiFillStar />
                <Text fontSize="sm">Score: {score}</Text>
              </HStack>
              <HStack alignItems="center">
                <AiFillTrophy />
                <Text fontSize="sm">
                  Highscore: {highscore > score ? highscore : score}
                </Text>
              </HStack>
            </Flex>
            {!isLost && countDown > 0 ? (
              <Button
                variant="ghost"
                onClick={startGame}
                _focus={{ outline: "none" }}
              >
                {countDown === 4
                  ? "Start Game"
                  : `Game starts in ${countDown}...`}
              </Button>
            ) : (
              <Flex direction="column" gap={1} alignItems="center">
                <Text fontSize="sm" color={textColor}>
                  Play with keyboard arrows
                </Text>
                <HStack gap={0}>
                  <FiArrowLeft />
                  <FiArrowRight />
                  <FiArrowUp />
                  <FiArrowDown />
                </HStack>
              </Flex>
            )}
          </Flex>
          {isLost && (
            <Flex
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height={canvasHeight}
              padding={"20px"}
              boxSizing="border-box"
              bg={overlayBg}
              backdropFilter="blur(6px)"
              justifyContent="center"
              alignItems="center"
              direction="column"
              borderTopRadius={"6px"}
            >
              <Heading>Game Over</Heading>
              <Text pb={6} pt={3}>
                {newHighscore
                  ? `🎉 Congrats! New Highscore 🎉`
                  : `You scored: ${score}`}
              </Text>
              {!running && isLost && (
                <Button onClick={startGame}>
                  {countDown === 4 ? "Restart Game" : countDown}
                </Button>
              )}
            </Flex>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Snake;
