/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";

import { type ContractTransaction } from "@ethersproject/contracts";

import toast from "~/compounds/toast";
import { endOfLegacyContract } from "~/config";
import { usePartyContext } from "~/contexts/PartyContext";
import { useTransactionContext } from "~/contexts/TransactionContext";
import { usePlatformCheck } from "~/hooks/platform";
import { handleCustomError } from "~/modules/party/errors";
import { TransactionActions } from "~/modules/party/types";

import { usePartyContract } from ".";

const declineRequestSteps = [
  {
    label: "Decline party request",
    content: "Confirm that you want to decline the request in your wallet",
  },
  {
    label: "Party confirmation",
    content: "Please wait until the join request is delcined",
    loading: true,
    showGame: true,
  },
];
const acceptRequestSteps = [
  {
    label: "Confirm party request",
    content: "Confirm that you want to confirm the request in your wallet",
  },
  {
    label: "Party confirmation",
    content: "Please wait until the join request is confirmed",
    loading: true,
    showGame: true,
  },
];

const usePartyRequest = (partyAddress: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { checkWallet } = usePlatformCheck();
  const { partyInfo } = usePartyContext();
  const partyContract = usePartyContract(partyAddress);
  const {
    addTransaction,
    updateTransaction,
    openConfirmationWithSteps,
    closeConfirmationWithSteps,
    setStep,
    setErrorMsg,
  } = useTransactionContext();

  const handleRequest = async (
    isAccept: boolean,
    requestedUser: string,
    onSuccess?: () => void,
  ) => {
    const account = checkWallet();
    if (!account || !partyContract) return null;
    setLoading(true);
    openConfirmationWithSteps(
      isAccept ? acceptRequestSteps : declineRequestSteps,
    );
    let txHash = "";

    const isLegacyParty =
      !!partyInfo && partyInfo.inception < endOfLegacyContract;

    try {
      // 1) Check is the user is the owner
      const isOwner = await partyContract.managers(account);
      if (!isOwner) {
        toast.dismiss();
        toast.error({
          title: `Only owners can ${
            isAccept ? "accept" : "reject"
          } join requests`,
        });
        throw Error(
          `Only owners can ${isAccept ? "accept" : "reject"} join request`,
        );
      }

      // 2) Handle join request

      let requestTx: ContractTransaction;
      if (isLegacyParty) {
        /// @dev [PostMigration] Remove this function
        requestTx = await partyContract.handleRequest(isAccept, requestedUser);
      } else {
        requestTx = await partyContract.handleJoinRequest(
          isAccept,
          requestedUser,
        );
      }
      txHash = requestTx.hash;
      addTransaction(
        isAccept
          ? TransactionActions.acceptJoinRequest
          : TransactionActions.declineJoinRequest,
        txHash,
      );
      setStep(1);
      await requestTx.wait();
      updateTransaction(txHash, true, true);
      txHash = "";

      if (onSuccess) {
        onSuccess();
      }
      closeConfirmationWithSteps();
      return toast.success(
        {
          title: `You have successfully ${
            isAccept ? "accepted" : "rejected"
          } the join request.`,
          body: isAccept ? "The user will be able to join the party." : "",
        },
        { autoClose: 5000 },
      );
    } catch (err: any) {
      console.log(err);
      const customError = handleCustomError(err);
      setErrorMsg(customError.message);
      if (txHash) {
        updateTransaction(txHash, false, true);
      }
    }
    setLoading(false);
  };

  return {
    handleRequest,
    loadingRequest: loading,
  };
};

export default usePartyRequest;
