import {
  PartyFactory__factory,
  type PartyFactory,
} from "@partyfinance/contracts";
import { getFactoryAddress } from "@partyfinance/core";

import { usePartyContext } from "~/contexts/PartyContext";
import { useContract } from "~/hooks/web3";

export default function usePartyFactoryContract() {
  const { chainId } = usePartyContext();
  return useContract<PartyFactory>(
    getFactoryAddress(chainId),
    PartyFactory__factory.abi,
  );
}
