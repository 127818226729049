/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Avatar,
  Button as ChakraButton,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  SkeletonCircle,
  Text,
  useDisclosure,
  type UseDisclosureProps,
} from "@chakra-ui/react";
import { HiUserGroup } from "react-icons/hi";

import { getDateFromNow, shortenHex } from "@partyfinance/core";

import { Button, CloseButton } from "~/components/Buttons";
import { Jazzicon } from "~/components/Icons";
import SortableTable from "~/components/Table/SortableTable";

import { usePartyFinancials } from "~/hooks/party";
import { usePartyMembers } from "~/hooks/theGraph";

interface IMembersProps extends UseDisclosureProps {
  partyAddress: string;
  chainId: number;
  asTag?: boolean;
  defaultMembersCount?: number;
}

const MembersModal = ({
  partyAddress,
  chainId,
  asTag = false,
  defaultMembersCount = 0,
}: IMembersProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { members, loading } = usePartyMembers(partyAddress, isOpen);
  const {
    partyFinancials,
    loading: loadingFinancials,
    isValidating: isValidatingFinancials,
  } = usePartyFinancials(partyAddress, chainId, false, true, !isOpen);
  return (
    <>
      {asTag ? (
        <ChakraButton
          height={8}
          gap={2}
          alignItems="center"
          onClick={onOpen}
          justifyContent="center"
          _focus={{ boxShadow: "none", outline: "none" }}
        >
          <HiUserGroup color="white" height="24px" width="24px" />
          {defaultMembersCount > 0 && (
            <Text color="white">{members.length || defaultMembersCount}</Text>
          )}
        </ChakraButton>
      ) : (
        <Button onClick={onOpen} leftIcon={<HiUserGroup />}>
          View Members
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
        <ModalOverlay />
        <ModalContent my="auto">
          <ModalHeader>
            <CloseButton aria-label={"Close Button"} onClose={onClose} />
            <Text fontSize="xl" mx="auto" width="fit-content">
              Party Members {loading ? "" : `(${members.length})`}
            </Text>
          </ModalHeader>
          <ModalBody maxHeight={"80vh"} overflowY={"auto"}>
            <SortableTable
              variant={"stripedEven"}
              whiteSpace={"nowrap"}
              textAlign={"start"}
              data={loading ? [0, 1, 2] : members}
              recordsPerPage={5}
              columns={[
                {
                  Header: "Name",
                  accessor: "name",
                  Cell: (value: any) =>
                    loading ? (
                      <Flex alignItems="center" width="full" gap={2}>
                        <SkeletonCircle />
                        <Flex direction="column">
                          <Skeleton width="60px" height="12px" />
                        </Flex>
                      </Flex>
                    ) : (
                      <Flex alignItems="center" width="full" gap={2}>
                        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                        {value.row.original.img ? (
                          <Avatar
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                            src={value.row.original.img as string}
                            size={"md"}
                          />
                        ) : (
                          <Jazzicon
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                            account={value.row.original.address as string}
                            diameter={48}
                          />
                        )}
                        <Flex direction="column">
                          <Heading size={"sm"}>
                            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                            {value.row.original.nickname as string}
                          </Heading>
                          <Text fontSize={"sm"} color={"gray.500"}>
                            {shortenHex(
                              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                              value.row.original.address as string,
                              4,
                            )}
                          </Text>
                        </Flex>
                      </Flex>
                    ),
                },
                {
                  Header: "Member since",
                  accessor: "joinedSince",
                  Cell: (value: any) =>
                    loading ? (
                      <Skeleton width="100px" height="12px" />
                    ) : (
                      <Text>
                        {getDateFromNow(
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                          value.row.original.joinedSince as number,
                        )}
                      </Text>
                    ),
                },
                {
                  Header: "Weight",
                  accessor: "weight",
                  Cell: (value: any) =>
                    loading ? (
                      <Skeleton width="60px" height="12px" />
                    ) : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    value.row.original.weight === 0 ? (
                      "0%"
                    ) : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    value.row.original.weight ? (
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                      `${Math.round(value.row.original.weight * 10000) / 100}%`
                    ) : (
                      "-"
                    ),
                },
                {
                  Header: "Holdings (USDC)",
                  accessor: "holdings",
                  Cell: (value: any) => {
                    if (
                      loading ||
                      loadingFinancials ||
                      isValidatingFinancials
                    ) {
                      return <Skeleton width="60px" height="12px" />;
                    }
                    if (!partyFinancials) return "";
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    const memberShare = value.row.original.weight as number;
                    return `$${
                      Math.floor(partyFinancials.valueUSD * memberShare * 100) /
                      100
                    }`;
                  },
                },
              ]}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MembersModal;
