import {
  Box,
  Flex,
  Progress,
  useColorModeValue,
  type LayoutProps,
} from "@chakra-ui/react";

import LogoNav from "~/components/Logo/LogoNav";

export default function PageLoader({
  minH = "100vh",
}: {
  minH?: LayoutProps["minH"];
}): JSX.Element {
  return (
    <Box
      as={Flex}
      w="100%"
      minH={minH}
      p={0}
      m={0}
      bg={useColorModeValue("gray.100", "brand.main")}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <LogoNav width="200px" />
      <Progress
        size="xs"
        isIndeterminate
        w="180px"
        colorScheme="brand"
        mt={6}
      />
    </Box>
  );
}
