import { createContext, useContext, type FC, type ReactNode } from "react";

import { useAccount } from "wagmi";

import { isAddressEqual } from "@partyfinance/core";

import { api } from "~/utils/api";

import { usePartyApesVote } from "~/hooks/vote";
import type { IUserApes, PollItem, UserVote } from "~/modules/vote/types";

import { usePlatformContext } from "./PlatformContext";

interface IGovernanceContext {
  fetchUserVotes: () => Promise<unknown>;
  fetchActivePolls: () => Promise<unknown>;
  fetchPastPolls: () => Promise<unknown>;
  isApeHolder: () => Promise<boolean>;
  isPlatinumApeHolder: () => boolean;
  activePolls: PollItem[];
  pastPolls: PollItem[];
  userVotes: UserVote[];
  loadingActivePolls: boolean;
  loadingPastPolls: boolean;
  userApes: IUserApes;
  userVotingPower: number;
  totalVotes: number;
}

const defaultStates = {
  fetchUserVotes: () => Promise.resolve(),
  fetchActivePolls: () => Promise.resolve(),
  fetchPastPolls: () => Promise.resolve(),
  fetchPollsVotes: () => Promise.resolve(),
  isApeHolder: () => Promise.resolve(false),
  isPlatinumApeHolder: () => false,
  reload: () => Promise.resolve(),
  activePolls: [],
  pastPolls: [],
  userVotes: [],
  loadingActivePolls: false,
  loadingPastPolls: false,
  userApes: {
    common: {
      ids: [],
      img: "",
    },
    bronze: {
      ids: [],
      img: "",
    },
    silver: {
      ids: [],
      img: "",
    },
    gold: {
      ids: [],
      img: "",
    },
    platinum: {
      ids: [],
      img: "",
    },
  },
  userVotingPower: 0,
  totalVotes: 0,
};

const GovernanceContext = createContext<IGovernanceContext>(defaultStates);

interface IGovernanceProvider {
  children: ReactNode;
  onPollPage?: boolean;
}

export const GovernanceProvider: FC<IGovernanceProvider> = ({
  children,
  onPollPage,
}) => {
  const { profile } = usePlatformContext();
  const { address: account } = useAccount();
  const isConnectedWallet =
    !!account && !!profile && isAddressEqual(account, profile.address);
  const {
    ApeRequired,
    isApeHolder,
    PlatinumApeRequired,
    isPlatinumApeHolder,
    userApes,
    userVotingPower,
    totalVotes,
  } = usePartyApesVote(isConnectedWallet ? account : "");
  const {
    data: activePolls,
    isLoading: loadingActivePolls,
    refetch: fetchActivePolls,
  } = api.poll.getAll.useQuery(
    {
      status: "active",
    },
    {
      retry: false,
      enabled: !onPollPage && isConnectedWallet,
      staleTime: 5 * 1000,
      cacheTime: 5 * 60 * 1000,
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    },
  );
  const {
    data: pastPolls,
    isLoading: loadingPastPolls,
    refetch: fetchPastPolls,
  } = api.poll.getAll.useQuery(
    {
      status: "past",
    },
    {
      retry: false,
      enabled: !onPollPage && isConnectedWallet,
      staleTime: 15 * 1000,
      cacheTime: 5 * 60 * 1000,
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    },
  );
  const { data: userVotes, refetch: fetchUserVotes } =
    api.poll.userVotes.useQuery(undefined, {
      retry: false,
      staleTime: 15 * 1000,
      cacheTime: 5 * 60 * 1000,
      enabled: isConnectedWallet,
    });

  return (
    <GovernanceContext.Provider
      value={{
        fetchUserVotes,
        fetchActivePolls,
        fetchPastPolls,
        isApeHolder,
        isPlatinumApeHolder,
        activePolls: activePolls || [],
        pastPolls: pastPolls || [],
        userVotes: userVotes || [],
        loadingActivePolls,
        loadingPastPolls,
        userApes,
        userVotingPower,
        totalVotes,
      }}
    >
      <PlatinumApeRequired />
      <ApeRequired />
      {children}
    </GovernanceContext.Provider>
  );
};

export const useGovernanceContext = () => useContext(GovernanceContext);

export default GovernanceContext;
