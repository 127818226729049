import { useMemo } from "react";

import {
  Box,
  Button as ChakraButton,
  CloseButton,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { isHexString } from "@ethersproject/bytes";
import { Controller, useForm } from "react-hook-form";
import { FiCheck } from "react-icons/fi";

import { shortenHex } from "@partyfinance/core";

import { getErrorMessage } from "~/utils/form/errorMessage";

import { Button } from "~/components/Buttons";
import { InputText } from "~/components/Inputs";

import toast from "~/compounds/toast";
import { usePlatformContext } from "~/contexts/PlatformContext";
import { usePartyMembers } from "~/hooks/theGraph";
import { type Manager, type ManagerForm } from "~/modules/party/types";

interface IAddManager {
  isOpen: boolean;
  onClose: () => void;
  partyAddress: string;
  managers: Manager[];
  addManager: (address: string) => Promise<void>;
  loading: boolean;
}

const AddManager = ({
  isOpen,
  onClose,
  partyAddress,
  managers,
  addManager,
  loading,
}: IAddManager) => {
  const { members } = usePartyMembers(partyAddress);
  const { profile } = usePlatformContext();
  const handleClose = () => {
    reset();
    onClose();
  };
  const handleSubmitManager = async (form: ManagerForm) => {
    console.log(`Adding ${form.address} to ${partyAddress}`);
    try {
      await addManager(form.address);
      handleClose();
    } catch (err) {
      console.log(err);
      return toast.error({
        title: "Add Manager",
        body: "An error occurred while trying to add manager. Try again!",
      });
    }
  };
  const { control, handleSubmit, setValue, reset } = useForm<ManagerForm>({
    defaultValues: {
      address: "",
    },
  });

  const filteredMembers = useMemo(() => {
    return members.filter(
      (m) =>
        !managers.find(
          (man) => man.address.toLowerCase() === m.address.toLowerCase(),
        ) && profile?.address.toLowerCase() !== m.address.toLowerCase(),
    );
  }, [members, managers, profile?.address]);

  return (
    <Modal onClose={handleClose} isOpen={isOpen} size="lg">
      <Box
        as="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(handleSubmitManager)}
      >
        <ModalOverlay />
        <ModalContent my="auto" borderRadius="xl">
          <ModalHeader>
            <Flex pos="relative" pt={2}>
              <Text
                fontSize="xl"
                mx="auto"
                width="fit-content"
                alignSelf="center"
              >
                Add Manager
              </Text>
              <CloseButton
                pos="absolute"
                right={0}
                size="lg"
                aria-label={"Close Button"}
                onClick={handleClose}
                alignSelf="center"
              />
            </Flex>
          </ModalHeader>
          <ModalBody>
            <VStack gap={4}>
              {filteredMembers.length > 0 && (
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Address</Th>
                      <Th>Nickname</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody maxH={450} overflowY="auto">
                    {filteredMembers.map((member) => (
                      <Tr key={`member-${member.address}`} color="gray.500">
                        <Td>
                          <Text>{shortenHex(member.address, 6)}</Text>
                        </Td>
                        <Td>
                          <Text>{member.nickname}</Text>
                        </Td>
                        <Td textAlign="end">
                          <ChakraButton
                            color="brand.300"
                            rounded="full"
                            p={2}
                            onClick={() => setValue("address", member.address)}
                          >
                            <FiCheck />
                          </ChakraButton>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}
              <Controller
                control={control}
                name="address"
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => {
                  return (
                    <InputText
                      id="input-address"
                      placeholder={
                        filteredMembers.length > 0
                          ? "Select above or insert address"
                          : "Insert address"
                      }
                      width="100%"
                      maxLength={64}
                      setRef={ref}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={getErrorMessage("Address", errors.address?.type)}
                    />
                  );
                }}
                rules={{
                  required: true,
                  validate: (val) => {
                    return isHexString(val);
                  },
                }}
              />
            </VStack>
          </ModalBody>
          <ModalFooter my="1rem">
            <Flex gap={2} justify="flex-start">
              <ChakraButton rounded="xl" onClick={handleClose}>
                Cancel
              </ChakraButton>
              <Button type="submit" isLoading={loading}>
                Save
              </Button>
            </Flex>
            <Flex></Flex>
          </ModalFooter>
        </ModalContent>
      </Box>
    </Modal>
  );
};

export default AddManager;
