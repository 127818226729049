import { type FC } from "react";

import {
  Avatar,
  Button,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { BiBlock } from "react-icons/bi";
import { FiChevronDown } from "react-icons/fi";

interface ITokenSelectButton {
  disabled?: boolean;
  onClick: () => void;
  logoUri: string | undefined;
  symbol: string | undefined;
}

const TokenSelectButton: FC<ITokenSelectButton> = ({
  disabled,
  onClick,
  logoUri,
  symbol,
}) => {
  const tokenSelectColor = useColorModeValue("gray.900", "white");
  return (
    <Button
      border="none"
      borderRadius="xl"
      px={2}
      // width="fill-available"
      height="36px"
      variant={"outline"}
      disabled={disabled}
      onClick={onClick}
      _focus={{ outline: "none" }}
      leftIcon={
        <Avatar
          width="24px"
          height="24px"
          icon={<BiBlock fontSize="24px" color="white" />}
          src={logoUri}
        />
      }
      rightIcon={<Icon as={FiChevronDown} size={18} color={tokenSelectColor} />}
    >
      <Text color={tokenSelectColor}>{symbol}</Text>
    </Button>
  );
};

export default TokenSelectButton;
