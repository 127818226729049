import { useEffect, useState } from "react";

import { fetchChartData } from "~/modules/staking/api/chart";
import { fetchPools } from "~/modules/staking/api/pools";
import { type IPool } from "~/modules/staking/types";

const useReadStakingPools = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pools, setPools] = useState<IPool[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [chart, setChart] = useState<any[]>([]);

  const getStakingParties = () => {
    const _pools = fetchPools();
    setPools(_pools);
  };

  const getChart = () => {
    const _chart = fetchChartData();
    setChart(_chart);
  };

  useEffect(() => {
    setLoading(true);
    getStakingParties();
    getChart();
    setLoading(false);
  }, []);

  return {
    pools,
    loading,
    chart,
  };
};

export default useReadStakingPools;
