import { type FC, type ReactNode } from "react";

import {
  Avatar,
  Box,
  Button as ChakraButton,
  Flex,
  Grid,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "next-share";
import { AiOutlineUserAdd } from "react-icons/ai";
import { BsShareFill } from "react-icons/bs";
import { FiCopy } from "react-icons/fi";
import { GiPartyPopper } from "react-icons/gi";

import { Button, CloseButton } from "~/components/Buttons";
import NavItem from "~/components/Sidebars/components/NavItem";

import toast from "~/compounds/toast";
import { baseUrl_App } from "~/config";
import { usePartyContext } from "~/contexts/PartyContext";
import { usePartyInfo } from "~/hooks/theGraph";

export enum InviteModalLocationEnum {
  Profile,
  Navbar,
  PartyCreationModal,
  PartyProfile,
}

const SocialButtonContainer = ({
  children,
  label,
}: {
  children: ReactNode;
  label: string;
}) => {
  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      gap={1}
      textAlign="center"
    >
      {children}
      <Text fontSize="sm">{label}</Text>
    </Flex>
  );
};
interface IInviteModalProps {
  location: InviteModalLocationEnum;
  asLink?: boolean;
  address: string;
  image?: string;
  title?: string;
  isSidebarOpen?: boolean;
}

const InviteModal: FC<IInviteModalProps> = ({
  location,
  address,
  image,
  title,
  isSidebarOpen = true,
}) => {
  const SHARE_TITLE = `Check out this amazing ${
    location === InviteModalLocationEnum.Profile ? "profile" : "party"
  } on PartyFinance!`;
  const shareText =
    location === InviteModalLocationEnum.Profile
      ? "Share on social media and show this profile to your friends 🎉🎉"
      : "Share on social media and invite your friends to the party 🎉🎉";
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { chainName, chainId } = usePartyContext();
  const withInfo = !!address && !!image && !!title;
  const { partyInfo } = usePartyInfo(address, chainId, isOpen && !withInfo);
  const link = `${baseUrl_App}/${
    location === InviteModalLocationEnum.Profile
      ? "profile"
      : `parties/${chainName}`
  }/${address}`;

  const copyLink = () => {
    void navigator.clipboard.writeText(link);
    toast.dismiss();
    return toast.info({
      title: "Link copied to clipboard",
    });
  };

  return (
    <>
      {location === InviteModalLocationEnum.Navbar ? (
        <NavItem
          isSidebarOpen={isSidebarOpen}
          name="Invite Friends"
          icon={AiOutlineUserAdd}
          routerPath={""}
          modal={{
            onOpen,
            onClose,
            isOpen,
          }}
        />
      ) : location === InviteModalLocationEnum.PartyCreationModal ? (
        <Button onClick={onOpen} leftIcon={<AiOutlineUserAdd />}>
          Invite Friends
        </Button>
      ) : location === InviteModalLocationEnum.Profile ? (
        <IconButton
          onClick={onOpen}
          borderRadius="full"
          size="sm"
          icon={<BsShareFill />}
          aria-label={"edit-user"}
        />
      ) : (
        <ChakraButton onClick={onOpen} leftIcon={<BsShareFill />} size="sm">
          Share
        </ChakraButton>
      )}
      <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
        <ModalOverlay />
        <ModalContent my="auto" borderRadius="xl">
          <ModalHeader mb={0} pb={0}>
            <CloseButton aria-label={"Close Button"} onClose={onClose} />
          </ModalHeader>
          <ModalBody mx={{ base: 1, sm: 3, md: 6 }} pt={0}>
            <Box mx={"auto"} width={"fit-content"}>
              <Avatar
                icon={<GiPartyPopper />}
                src={image ? image : partyInfo?.img}
                size={"xl"}
              />
            </Box>
            <Heading
              bgGradient={"linear(to-b, brand.300, brand.500)"}
              bgClip={"text"}
              size={"lg"}
              width={"fit-content"}
              mx={"auto"}
              my={4}
            >
              {title ? title : partyInfo?.name}
            </Heading>
            <Text textAlign="center" mb={2} fontSize={"lg"}>
              {shareText}
            </Text>
            <Box
              p={4}
              my={6}
              borderWidth={"2px"}
              borderColor={useColorModeValue("brand.100", "gray.600")}
              borderRadius={"10px"}
            >
              <Grid templateColumns="repeat(4, 1fr)" gap={2}>
                <FacebookShareButton
                  url={link}
                  quote={SHARE_TITLE}
                  hashtag={"#nextshare"}
                >
                  <SocialButtonContainer label="Facebook">
                    <FacebookIcon size={40} round />
                  </SocialButtonContainer>
                </FacebookShareButton>
                <TwitterShareButton url={link} title={SHARE_TITLE}>
                  <SocialButtonContainer label="Twitter">
                    <TwitterIcon size={40} round />
                  </SocialButtonContainer>
                </TwitterShareButton>
                <RedditShareButton url={link} title={SHARE_TITLE}>
                  <SocialButtonContainer label="Reddit">
                    <RedditIcon size={40} round />
                  </SocialButtonContainer>
                </RedditShareButton>
                <WhatsappShareButton
                  url={link}
                  title={SHARE_TITLE}
                  separator=":: "
                >
                  <SocialButtonContainer label="Whatsapp">
                    <WhatsappIcon size={40} round />
                  </SocialButtonContainer>
                </WhatsappShareButton>
              </Grid>
              <Grid templateColumns="repeat(4, 1fr)" gap={3} pt={3}>
                <TelegramShareButton url={link} title={SHARE_TITLE}>
                  <SocialButtonContainer label="Telegram">
                    <TelegramIcon size={40} round />
                  </SocialButtonContainer>
                </TelegramShareButton>
                <FacebookMessengerShareButton url={link} appId={""}>
                  <SocialButtonContainer label="Messenger">
                    <FacebookMessengerIcon size={40} round />
                  </SocialButtonContainer>
                </FacebookMessengerShareButton>
                <LinkedinShareButton url={link}>
                  <SocialButtonContainer label="LinkedIn">
                    <LinkedinIcon size={40} round />
                  </SocialButtonContainer>
                </LinkedinShareButton>
                <SocialButtonContainer label="Copy link">
                  <IconButton
                    aria-label="copy-url"
                    icon={<FiCopy />}
                    colorScheme="pink"
                    borderRadius="full"
                    onClick={copyLink}
                  />
                </SocialButtonContainer>
              </Grid>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InviteModal;
