import { createIcon } from "@chakra-ui/react";

const EthereumIcon = createIcon({
  displayName: "EthereumIcon",
  viewBox: "0 0 12 20",
  path: (
    <path
      fill="#68727f"
      fillRule="evenodd"
      d="M5.955 14.53 0 11.013l5.955 8.393 5.959-8.393-5.96 3.517h.001zM6.045 0 .09 9.882l5.955 3.521L12 9.886 6.045 0z"
      clipRule="evenodd"
    />
  ),
});

export default EthereumIcon;
