import { Box, Kbd, Text } from "@chakra-ui/react";

import { formatBalance } from "@partyfinance/core";

import { type PartyFinancials } from "~/modules/party/types";

import StatItem from "./StatItem";

interface IPartyFinanceStats {
  loadingFinancials: boolean;
  isValidating?: boolean;
  partyFinancials: PartyFinancials | null;
}

const PartyFinanceStats = ({
  loadingFinancials,
  isValidating,
  partyFinancials,
}: IPartyFinanceStats) => {
  return (
    <>
      <StatItem
        label="Total Deposits"
        value={`$${
          partyFinancials === null
            ? "-"
            : formatBalance(
                Math.round(partyFinancials.totalDeposits * 100) / 100,
              )
        }`}
        loading={loadingFinancials}
        isValidating={isValidating}
        labelTooltip={"Total deposit amounts in USD made into the party"}
      />
      <StatItem
        label="Total Withdraws"
        value={`$${
          partyFinancials === null
            ? "-"
            : formatBalance(
                Math.round(partyFinancials.totalWithdrawals * 100) / 100,
              )
        }`}
        loading={loadingFinancials}
        isValidating={isValidating}
        labelTooltip={"Total withdraw amounts in USD"}
      />
      <StatItem
        label="Party Balance"
        value={`$${
          partyFinancials === null
            ? "-"
            : formatBalance(Math.round(partyFinancials.valueUSD * 100) / 100)
        }`}
        delta={undefined}
        tooltip=""
        loading={loadingFinancials}
        isValidating={isValidating}
        labelTooltip={
          "Current Party value in USD. Average sum of all holdings expressed in USD prices"
        }
      />
      <StatItem
        label="Total Profit Loss"
        value={`${Number(partyFinancials?.profitLoss) < 0 ? "-" : ""}$${
          partyFinancials === null
            ? "-"
            : formatBalance(
                Math.abs(Math.round(partyFinancials.profitLoss * 100) / 100),
              )
        }`}
        color={
          Number(partyFinancials?.profitLoss) < 0 ? "red.300" : "green.400"
        }
        loading={loadingFinancials}
        isValidating={isValidating}
        labelTooltip={
          <Box pb={2} textAlign="center">
            The formula to calculate <Kbd bg="gray.400">Profit/Loss</Kbd> is
            <Text>
              <Kbd bg="gray.400">Party Balance</Kbd> -{" "}
              <Kbd bg="gray.400">Total Deposits</Kbd> +{" "}
              <Kbd bg="gray.400">Total Withdraws</Kbd>
            </Text>
          </Box>
        }
      />
      <StatItem
        label="ROI"
        color={
          !partyFinancials || partyFinancials.roi === null
            ? undefined
            : partyFinancials.roi < 0
            ? "red.300"
            : "green.400"
        }
        value={`${
          partyFinancials === null || partyFinancials.roi === null
            ? "-"
            : Math.round(partyFinancials.roi * 100) / 100
        }%`}
        loading={loadingFinancials}
        isValidating={isValidating}
        labelTooltip={
          <Box pb={2} textAlign="center">
            The formula to calculate <Kbd bg="gray.400">ROI</Kbd> is
            <Text>
              (<Kbd bg="gray.400">Party Balance</Kbd> -{" "}
              <Kbd bg="gray.400">Total Deposits</Kbd> +{" "}
              <Kbd bg="gray.400">Total Withdraws</Kbd>) /{" "}
              <Kbd bg="gray.400">Total Deposits</Kbd>
            </Text>
          </Box>
        }
      />
    </>
  );
};

export default PartyFinanceStats;
