import { createIcon } from "@chakra-ui/react";

const Isotype = createIcon({
  displayName: "IsotypeIcon",
  viewBox: "0 0 349 349",
  path: (
    <path
      fill="currentColor"
      d="M246.312.767V0H0v289.733h42.8V42.858H233.256c40.236 0 72.978 32.785 72.978 73.075 0 40.29-32.742 73.075-72.978 73.075H137.828v-46.626h105.32V99.524H95.028V349h42.8V231.833H233.255c63.818 0 115.745-51.997 115.745-115.9-.033-59.5-44.999-108.629-102.688-115.166Z"
    />
  ),
});

export default Isotype;
