import axios from "axios";

import {
  type LimitOrder,
  type LimitOrderRes,
  type LimitOrderStatus,
} from "./types";

const LIMIT_ORDERS_BASE_URL: { [chainId: number]: string } = {
  1: "https://limit-orders.1inch.io/v2.0/1",
  10: "https://limit-orders.1inch.io/v2.0/10",
  137: "https://limit-orders.1inch.io/v2.0/137",
};
const API_BASE_URL: { [chainId: number]: string } = {
  1: "https://api.1inch.io/v4.0/1",
  10: "https://api.1inch.io/v4.0/10",
  137: "https://api.1inch.io/v4.0/137",
};

interface PostLimitOrderPayload {
  /** Hash of the limit order typed data */
  orderHash: string;
  /** Signature of the limit order typed data (using signTypedData_v4) */
  signature: string;
  data: LimitOrder;
}

export const postLimitOrder = async (
  {
    orderHash,
    signature,
    data: {
      makerAsset,
      takerAsset,
      maker,
      allowedSender = "0x0000000000000000000000000000000000000000",
      receiver = "0x0000000000000000000000000000000000000000",
      makingAmount,
      takingAmount,
      makerAssetData = "0x",
      takerAssetData = "0x",
      getMakerAmount = "0x",
      getTakerAmount = "0x",
      salt,
      predicate,
      permit = "0x",
      interaction = "0x",
    },
  }: PostLimitOrderPayload,
  chainId: number,
) => {
  try {
    const payload: PostLimitOrderPayload = {
      orderHash,
      signature,
      data: {
        makerAsset,
        takerAsset,
        maker,
        allowedSender,
        receiver,
        makingAmount,
        takingAmount,
        makerAssetData,
        takerAssetData,
        getMakerAmount,
        getTakerAmount,
        salt,
        predicate,
        permit,
        interaction,
      },
    };
    await axios.post(`${LIMIT_ORDERS_BASE_URL[chainId]}/limit-order`, payload);

    return {
      success: true,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return {
      success: false,
      orders: [] as LimitOrderRes[],
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      error: axios.isAxiosError(error)
        ? error.response?.data
        : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          (error.message as string),
    };
  }
};

export const fetchLimitOrders = async (
  creatorAddress: string,
  statuses: LimitOrderStatus[],
  chainId: number,
) => {
  try {
    const { data }: { data: LimitOrderRes[] } = await axios.get(
      `${LIMIT_ORDERS_BASE_URL[chainId]}/limit-order/address/${creatorAddress}`,
      {
        params: {
          page: 1,
          limit: 100,
          statuses: `[${statuses.join(",")}]`,
          sortBy: "createDateTime",
        },
      },
    );

    return {
      success: true,
      orders: data,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return {
      success: false,
      orders: [] as LimitOrderRes[],
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      error: axios.isAxiosError(error)
        ? error.response?.data
        : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          (error.message as string),
    };
  }
};
type Token = {
  symbol: string;
  name: string;
  address: string;
  decimals: number;
  logoURI: string;
};
type ProtocolPart = {
  name: string;
  part: number;
  fromTokenAddress: string;
  toTokenAddress: string;
};
export interface QuoteResp {
  fromToken: Token;
  toToken: Token;
  toTokenAmount: string;
  fromTokenAmount: string;
  protocols: ProtocolPart[];
  estimatedGas: number;
}

interface IQouteParams {
  fromTokenAddress: string;
  toTokenAddress: string;
  amount: string;
}

export const fetch1nchQuote = async (params: IQouteParams, chainId: number) => {
  try {
    const { data }: { data: QuoteResp } = await axios.get(
      `${API_BASE_URL[chainId]}/quote`,
      {
        params,
      },
    );
    console.log("Received 1nch quote", data);

    return { quote: data, error: null, notEnoughLiquidity: false };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      console.log("fetch1nchQuote error", error.response?.data);
      if (
        error.response &&
        error.response.data &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        error.response.data.description === "Insufficient liquidity"
      ) {
        return { quote: null, error: null, notEnoughLiquidity: true };
      }
    }
    return {
      quote: null,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      error: error.message as string,
      notEnoughLiquidity: false,
    };
  }
};
