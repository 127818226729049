import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  SkeletonCircle,
  SkeletonText,
  useBreakpointValue,
} from "@chakra-ui/react";

import PartySearchLoader from "./PartySearchLoader";

const ProfileLoader = () => {
  const partyLoaders = useBreakpointValue(
    {
      base: [0],
      md: [0, 1, 2],
    },
    "md",
  );

  return (
    <Grid gap={4}>
      <GridItem>
        <Box w="full">
          <Flex justify="center">
            {/* @dev (TODO): Import user image after upload is implemented */}
            <SkeletonCircle
              h={{ base: 100, md: 120 }}
              w={{ base: 100, md: 120 }}
              mt={{ base: -50, md: "-60px" }}
            />
          </Flex>
        </Box>
        <Box mt={2}>
          <Flex justify="center" align="center" mb={2}>
            <SkeletonText noOfLines={1} w={60} />
          </Flex>
          <Flex justify="center" align="center" gap={2}>
            <SkeletonText noOfLines={1} w={40} />
          </Flex>
        </Box>
      </GridItem>
      <GridItem>
        <Flex
          justify="center"
          gap={4}
          textAlign="center"
          color={"gray.400"}
          h={12}
        >
          <Box>
            <SkeletonText noOfLines={2} w={20} />
          </Box>
          <Divider orientation="vertical" h="full" />
          <Box>
            <SkeletonText noOfLines={2} w={20} />
          </Box>
          <Divider orientation="vertical" h="full" />
          <Box>
            <SkeletonText noOfLines={2} w={20} />
          </Box>
        </Flex>
      </GridItem>
      <Divider />
      <GridItem px={8} py={4}>
        <Flex wrap="wrap" gap={4} justify="center">
          {(partyLoaders as number[]).map((x) => (
            <PartySearchLoader key={x} />
          ))}
        </Flex>
      </GridItem>
    </Grid>
  );
};

export default ProfileLoader;
