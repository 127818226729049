export function getUSDCAddress(networkId: number): string {
  switch (networkId) {
    case 1:
      return "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48";
    case 5:
      return "0x07865c6e87b9f70255377e024ace6630c1eaa37f";
    case 10:
      return "0x7f5c764cbc14f9669b88837ca1490cca17c31607";
    case 137:
      return "0x2791bca1f2de4661ed88a30c99a7a9449aa84174";
    default:
      throw new Error(`getUSDCAddress: Invalid networkId ${networkId}`);
  }
}

export function getUSDCDecimals(networkId: number): number {
  switch (networkId) {
    case 1:
      return 6;
    case 5:
      return 6;
    case 10:
      return 6;
    case 137:
      return 6;
    default:
      throw new Error(`getUSDCDecimals: Invalid networkId ${networkId}`);
  }
}

export function getFactoryAddress(networkId: number): string {
  switch (networkId) {
    case 1:
      return "0x193baf5d3a7703c4ef2501c395a6f6d060cb9c68";
    case 5:
      return "0x30A75995816C2CF4d72dffdd6817BB26ddE19441";
    case 10:
      return "0x193baf5d3a7703c4ef2501c395a6f6d060cb9c68";
    case 137:
      return "0x35334f1ce2c6c1e1b174bda35c87ead74865a5f9";
    default:
      throw new Error(`getFactoryAddress: Invalid networkId ${networkId}`);
  }
}

export function getPFIAddress(networkId: number): string {
  switch (networkId) {
    case 137:
      return "0xe46B4A950c389e80621d10Dfc398e91613C7e25E";
    default:
      return "";
  }
}

export function getPartyPunkAddress(networkId: number): string {
  switch (networkId) {
    case 137:
      return "0x4f7747a508b66e2cc8f9e171b5dc177db25801b6";
    default:
      return "";
  }
}

export function getPartyApesAddress(networkId: number): string {
  switch (networkId) {
    case 137:
      return "0x4101f26088b7a58b9f91b475d8cfe8b999be6292";
    default:
      throw new Error(`getPartyApesAddress: Invalid networkId ${networkId}`);
  }
}
