import { useDisclosure } from "@chakra-ui/react";
import { useAccount } from "wagmi";

import { Button } from "~/components/Buttons";
import { StakingModal } from "~/components/Modals";

import { useStake } from "~/hooks/staking";
import { type IPool } from "~/modules/staking/types";

interface IStakeButton {
  pool: IPool;
  userTokens: number;
}

const StakeButton = ({ pool, userTokens, ...btnProps }: IStakeButton) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { address: account } = useAccount();
  const { stake } = useStake();

  const handleStake = async (amount: number) => {
    try {
      onClose();
      await stake(amount);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Button
        w={{ base: 60, md: 40 }}
        onClick={onOpen}
        disabled={!account}
        {...btnProps}
      >
        Stake
      </Button>

      <StakingModal
        isOpen={isOpen}
        onClose={onClose}
        pool={pool}
        userTokens={userTokens}
        confirmAction={handleStake}
      />
    </>
  );
};

export default StakeButton;
