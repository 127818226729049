/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";

import { type ContractTransaction } from "@ethersproject/contracts";

import { type LibSignatures } from "@partyfinance/contracts/src/types/Party";

import { api } from "~/utils/api";
import { emptyAllocationArgs, emptySignature } from "~/utils/web3/args";

import { usePartyContext } from "~/contexts/PartyContext";
import { useTransactionContext } from "~/contexts/TransactionContext";
import { usePlatformCheck } from "~/hooks/platform";
import { handleCustomError } from "~/modules/party/errors";
import { TransactionActions, type WithdrawForm } from "~/modules/party/types";

import { usePartyContract } from ".";

const steps = [
  {
    label: "Checking balance",
    content: "Please wait while your party balance is being checked",
    loading: true,
  },
  {
    label: "Withdraw funds",
    content: "Confirm the party withdraw transaction in your wallet",
  },
  {
    label: "Party confirmation",
    content: "Please wait until the party withdraw is confirmed",
    loading: true,
    showGame: true,
  },
];

const usePartyWithdraw = (partyAddress: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { chainId } = usePartyContext();
  const { checkWallet } = usePlatformCheck();
  const partyContract = usePartyContract(partyAddress);
  const {
    addTransaction,
    updateTransaction,
    openConfirmationWithSteps,
    closeConfirmationWithSteps,
    setErrorMsg,
    setStep,
  } = useTransactionContext();
  // Api hooks
  const signatureWithdraw = api.signature.withdraw.useMutation();

  const withdrawFromParty = async (withdrawForm: WithdrawForm) => {
    const account = checkWallet();
    if (!account || !chainId || !partyContract) return null;
    setLoading(true);
    openConfirmationWithSteps(steps);
    // onOpen()
    let txHash = "";

    try {
      // 1) Check if the Party has available PartyTokens to withdraw
      const balance = await partyContract.balanceOf(account);
      if (balance.isZero()) {
        throw Error(`Your Party Tokens balance is zero`);
      }

      // withdraw is the partyTokens amount to redeem in exchange for funds
      const withdrawPercentBP = Math.floor(
        withdrawForm.withdrawPercent_slider * 100,
      );
      const withdrawPT = balance.mul(withdrawPercentBP).div("10000");
      console.log("withdrawPT", withdrawPT);
      console.log("balance", balance);
      if (!balance.gte(withdrawPT)) {
        throw Error(`Your Party tokens balance is not enough.`);
      }

      // Check if its liquidating or not
      let allocationArgs: LibSignatures.AllocationStruct = emptyAllocationArgs;
      let signature: LibSignatures.SigStruct = emptySignature;
      if (withdrawForm.liquidate === 1) {
        // 2) Get allocation signature
        const data = await signatureWithdraw.mutateAsync({
          partyAddress: partyContract.address,
          userAddress: account,
          withdrawPercentage: withdrawPercentBP, // expressed as bps
          chainId: chainId,
          action: "withdraw",
        });
        allocationArgs = data.allocationArgs;
        signature = data.signature;
      }

      // 3) Withdraw from Party: (redeem PartyTokens)
      setStep(1);
      const newWithdraw: ContractTransaction = await partyContract.withdraw(
        withdrawPT,
        allocationArgs,
        signature,
        withdrawForm.liquidate === 1,
      );
      txHash = newWithdraw.hash;
      addTransaction(TransactionActions.withdrawParty, txHash);

      setStep(2);
      await newWithdraw.wait(2);
      updateTransaction(txHash, true, true);
      txHash = "";

      closeConfirmationWithSteps();
    } catch (err: any) {
      console.log(err);
      const customError = handleCustomError(err);
      setErrorMsg(customError.message);
      if (txHash) {
        updateTransaction(txHash, false, true);
      }
    }
    setLoading(false);
  };

  return {
    withdrawFromParty,
    loadingWithdraw: loading,
  };
};

export default usePartyWithdraw;
