// import HoldingsPieChart from './HoldingsPieChart'
import { useMemo, type Dispatch, type SetStateAction } from "react";

import { Avatar, Box, Flex, Skeleton, Text, Tooltip } from "@chakra-ui/react";
import { BiBlock } from "react-icons/bi";
import { FiChevronRight } from "react-icons/fi";

import { formatBalance } from "@partyfinance/core";

import { getToken } from "~/modules/tokens";

import { PartyChartView, type PartyHolding } from "../types";
import HoldingsPieChart from "./HoldingsPieChart";

const PartyHoldingsList = ({
  loading,
  holdings,
  showMemberHoldings,
  memberShare,
  setChartType,
  setSelectedHolding,
  selectedHolding,
}: {
  loading: boolean;
  holdings: PartyHolding[];
  showMemberHoldings: boolean;
  memberShare: number | null;
  setChartType: Dispatch<SetStateAction<PartyChartView>>;
  setSelectedHolding?: Dispatch<SetStateAction<PartyHolding | null>>;
  selectedHolding: PartyHolding | null;
}): JSX.Element => {
  const shareFactor =
    showMemberHoldings && memberShare !== null ? memberShare : 1;

  const handleShowHoldingValue = (asset: PartyHolding) => {
    if (!setSelectedHolding || !setChartType) return;
    setSelectedHolding(asset);
    setChartType(PartyChartView.PartyChartHodlingValue);
  };

  const data = useMemo(
    () => holdings.filter((asset) => asset.amount > 0).slice(0, 4),
    [holdings],
  );

  return (
    <>
      {loading ? (
        <Box>
          {[0, 0, 0, 0].map((_, i) => {
            return (
              <Flex mb={2} key={i} width="full">
                <Skeleton height="24px" width="24px" borderRadius="full" />
                <Skeleton ml={4} height="16px" width="75px" />
                <Skeleton ml="auto" height="16px" width="50px" />
              </Flex>
            );
          })}
        </Box>
      ) : data.length === 0 ? (
        <Box fontSize="sm" color="gray.500">
          No holdings available to show
        </Box>
      ) : (
        <Box>
          <HoldingsPieChart data={holdings} />
          <Text color="gray.500" pt={1} pb={2}>
            {data.length === 1
              ? "Top holding"
              : `Top ${Math.min(data.length, 4)} holdings`}
          </Text>
          <Box>
            {data.map((asset) => {
              const logoUrl = asset.logoUrl
                ? asset.logoUrl
                : getToken(asset.address)?.logoURI ?? "";
              return (
                <Flex
                  mb="0.25rem"
                  key={asset.address}
                  alignItems="center"
                  cursor={"pointer"}
                  onClick={() => handleShowHoldingValue({ ...asset, logoUrl })}
                >
                  {selectedHolding &&
                    selectedHolding.address === asset.address && (
                      <Box my={"auto"}>
                        <FiChevronRight color={"#f94abf"} />
                      </Box>
                    )}
                  <Avatar
                    size="xs"
                    src={logoUrl}
                    icon={<BiBlock fontSize="22px" color="white" />}
                  />
                  <Box ml={2}>{asset.symbol}</Box>
                  <Tooltip
                    hasArrow
                    placement="bottom-end"
                    label={`Balance: ${formatBalance(asset.amount, 4)}`}
                    shouldWrapChildren={false}
                  >
                    <Box ml="auto" fontSize={["md", "sm", "md", "sm", "md"]}>
                      $
                      {formatBalance(
                        Math.floor(asset.valueUSD * shareFactor * 100) / 100,
                      )}
                    </Box>
                  </Tooltip>
                </Flex>
              );
            })}
          </Box>
        </Box>
      )}
    </>
  );
};

export default PartyHoldingsList;
