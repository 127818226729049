import { Flex, Skeleton, useColorModeValue } from "@chakra-ui/react";

import { Card } from "~/components/Card";

export default function PollLoader(): JSX.Element {
  return (
    <Card
      px={2}
      py={{ base: 3, md: 2, lg: 3, xl: 2 }}
      borderWidth={1}
      borderColor={useColorModeValue("gray.200", "transparent")}
    >
      <Flex
        justify={"space-between"}
        alignItems="center"
        gap={{ base: 2, sm: 4, md: 4, lg: 4, xl: 6 }}
      >
        <Skeleton
          alignSelf="center"
          display={{ base: "none", sm: "block" }}
          height="128px"
          width="128px"
          borderRadius="xl"
        />
        <Flex
          flex={{ base: 1, md: 1, lg: 0.5 }}
          alignItems="stretch"
          gap={{ base: 4, sm: 4, md: 4, lg: 4, xl: 6 }}
        >
          <Flex direction="column" align="left" gap={3}>
            <Skeleton
              alignSelf="center"
              display={{ base: "block", sm: "none" }}
              height="128px"
              width="128px"
              borderRadius="xl"
            />
            <Skeleton width="240px" height="24px" />
            <Skeleton width="240px" height="24px" />
            <Skeleton width="100px" height="16px" />
            <Skeleton width="50px" height="16px" />
          </Flex>
        </Flex>
        <Flex direction="column" gap={2} justifyContent="center">
          <Skeleton width="116px" borderRadius="lg" height="32px" />
          <Skeleton width="116px" borderRadius="lg" height="32px" />
        </Flex>
      </Flex>
    </Card>
  );
}
