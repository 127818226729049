import NextLink from "next/link";

import {
  Box,
  CloseButton,
  Divider,
  Flex,
  Heading,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";

import { Button } from "~/components/Buttons";

interface IPartyWarsRulesModal {
  isOpen: boolean;
  onClose: () => void;
}

const PartyWarsRulesModal = ({ isOpen, onClose }: IPartyWarsRulesModal) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justify="space-between" align="center">
            <Heading size="md" alignSelf="center" h="fit-content">
              Participation Rules
            </Heading>
            <CloseButton onClick={onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody pb={4}>
          <VStack gap={0} w="fit-content" h="full" borderRadius="xl">
            <Box>
              <Text>
                Each party that meets the following conditions is considered
                eligible for the PartyWars:
              </Text>
              <UnorderedList px={4} itemType="disc">
                <ListItem>
                  Party must be{" "}
                  <Text display="inline" fontWeight="bold">
                    public
                  </Text>
                </ListItem>
                <ListItem>
                  Total of deposit is at least{" "}
                  <Text display="inline" fontWeight="bold">
                    $150 USDC
                  </Text>
                </ListItem>
                <ListItem>
                  Only{" "}
                  <Text display="inline" fontWeight="bold">
                    Ethereum & Polygon assets listed on CoinGecko
                  </Text>{" "}
                  can be traded
                </ListItem>
              </UnorderedList>
            </Box>
          </VStack>
        </ModalBody>
        <Divider />
        <ModalFooter justifyContent="center" py={4}>
          <Flex w="full" justify="space-between">
            <Box
              fontSize="xs"
              alignSelf="center"
              color={useColorModeValue("gray.700", "gray.400")}
            >
              <Text>
                You must be a{" "}
                <Link
                  color={"brand.200"}
                  href="https://discord.gg/NRQD4RFxdt"
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  PartyFinance Discord
                </Link>{" "}
                member to take part
              </Text>
              <Text>
                Any form of cheating will result in a disqualification!
              </Text>
            </Box>
            <Button
              as={NextLink}
              href={"/parties/create?partywars=true"}
              size="sm"
            >
              Participate
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PartyWarsRulesModal;
