// @ts-nocheck
import { GraphQLResolveInfo, SelectionSetNode, FieldNode, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { gql } from '@graphql-mesh/utils';

import type { GetMeshOptions } from '@graphql-mesh/runtime';
import type { YamlConfig } from '@graphql-mesh/types';
import { PubSub } from '@graphql-mesh/utils';
import { DefaultLogger } from '@graphql-mesh/utils';
import MeshCache from "@graphql-mesh/cache-localforage";
import { fetch as fetchFn } from '@whatwg-node/fetch';

import { MeshResolvedSource } from '@graphql-mesh/runtime';
import { MeshTransform, MeshPlugin } from '@graphql-mesh/types';
import GraphqlHandler from "@graphql-mesh/graphql"
import { parse } from 'graphql';
import BareMerger from "@graphql-mesh/merger-bare";
import { printWithCache } from '@graphql-mesh/utils';
import { createMeshHTTPHandler, MeshHTTPHandler } from '@graphql-mesh/http';
import { getMesh, ExecuteMeshFn, SubscribeMeshFn, MeshContext as BaseMeshContext, MeshInstance } from '@graphql-mesh/runtime';
import { MeshStore, FsStoreStorageAdapter } from '@graphql-mesh/store';
import { path as pathModule } from '@graphql-mesh/cross-helpers';
import { ImportFn } from '@graphql-mesh/types';
import type { PartyFinanceTypes } from './sources/PartyFinance/types';
import * as importedModule$0 from "./sources/PartyFinance/introspectionSchema";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };



/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
  Int8: any;
};

export type Query = {
  assetAmount?: Maybe<AssetAmount>;
  assetAmounts: Array<AssetAmount>;
  sharesBoughtEvent?: Maybe<SharesBoughtEvent>;
  sharesBoughtEvents: Array<SharesBoughtEvent>;
  sharesRedeemedEvent?: Maybe<SharesRedeemedEvent>;
  sharesRedeemedEvents: Array<SharesRedeemedEvent>;
  party?: Maybe<Party>;
  parties: Array<Party>;
  user?: Maybe<User>;
  users: Array<User>;
  userParty?: Maybe<UserParty>;
  userParties: Array<UserParty>;
  partyTokenGate?: Maybe<PartyTokenGate>;
  partyTokenGates: Array<PartyTokenGate>;
  tokenGate?: Maybe<TokenGate>;
  tokenGates: Array<TokenGate>;
  investment?: Maybe<Investment>;
  investments: Array<Investment>;
  investmentState?: Maybe<InvestmentState>;
  investmentStates: Array<InvestmentState>;
  shareState?: Maybe<ShareState>;
  shareStates: Array<ShareState>;
  portfolioState?: Maybe<PortfolioState>;
  portfolioStates: Array<PortfolioState>;
  holdingState?: Maybe<HoldingState>;
  holdingStates: Array<HoldingState>;
  partyState?: Maybe<PartyState>;
  partyStates: Array<PartyState>;
  investmentCost?: Maybe<InvestmentCost>;
  investmentCosts: Array<InvestmentCost>;
  asset?: Maybe<Asset>;
  assets: Array<Asset>;
  tokenSwap?: Maybe<TokenSwap>;
  tokenSwaps: Array<TokenSwap>;
  limitOrderFill?: Maybe<LimitOrderFill>;
  limitOrderFills: Array<LimitOrderFill>;
  joinRequest?: Maybe<JoinRequest>;
  joinRequests: Array<JoinRequest>;
  partyActivity?: Maybe<PartyActivity>;
  partyActivities: Array<PartyActivity>;
  network?: Maybe<Network>;
  networks: Array<Network>;
  networkState?: Maybe<NetworkState>;
  networkStates: Array<NetworkState>;
  networkAssetHolding?: Maybe<NetworkAssetHolding>;
  networkAssetHoldings: Array<NetworkAssetHolding>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type QueryassetAmountArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryassetAmountsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AssetAmount_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AssetAmount_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerysharesBoughtEventArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerysharesBoughtEventsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SharesBoughtEvent_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SharesBoughtEvent_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerysharesRedeemedEventArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerysharesRedeemedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SharesRedeemedEvent_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SharesRedeemedEvent_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerypartyArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerypartiesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Party_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Party_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryuserArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryusersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<User_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<User_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryuserPartyArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryuserPartiesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserParty_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UserParty_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerypartyTokenGateArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerypartyTokenGatesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PartyTokenGate_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PartyTokenGate_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytokenGateArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytokenGatesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TokenGate_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenGate_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryinvestmentArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryinvestmentsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Investment_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Investment_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryinvestmentStateArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryinvestmentStatesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<InvestmentState_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<InvestmentState_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryshareStateArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryshareStatesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ShareState_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ShareState_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryportfolioStateArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryportfolioStatesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PortfolioState_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PortfolioState_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryholdingStateArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryholdingStatesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HoldingState_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<HoldingState_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerypartyStateArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerypartyStatesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PartyState_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PartyState_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryinvestmentCostArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryinvestmentCostsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<InvestmentCost_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<InvestmentCost_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryassetArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryassetsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Asset_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Asset_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytokenSwapArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytokenSwapsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TokenSwap_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenSwap_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerylimitOrderFillArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerylimitOrderFillsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LimitOrderFill_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<LimitOrderFill_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryjoinRequestArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryjoinRequestsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<JoinRequest_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<JoinRequest_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerypartyActivityArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerypartyActivitiesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PartyActivity_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PartyActivity_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynetworkArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynetworksArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Network_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Network_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynetworkStateArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynetworkStatesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NetworkState_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NetworkState_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynetworkAssetHoldingArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynetworkAssetHoldingsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NetworkAssetHolding_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NetworkAssetHolding_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Query_metaArgs = {
  block?: InputMaybe<Block_height>;
};

export type Subscription = {
  assetAmount?: Maybe<AssetAmount>;
  assetAmounts: Array<AssetAmount>;
  sharesBoughtEvent?: Maybe<SharesBoughtEvent>;
  sharesBoughtEvents: Array<SharesBoughtEvent>;
  sharesRedeemedEvent?: Maybe<SharesRedeemedEvent>;
  sharesRedeemedEvents: Array<SharesRedeemedEvent>;
  party?: Maybe<Party>;
  parties: Array<Party>;
  user?: Maybe<User>;
  users: Array<User>;
  userParty?: Maybe<UserParty>;
  userParties: Array<UserParty>;
  partyTokenGate?: Maybe<PartyTokenGate>;
  partyTokenGates: Array<PartyTokenGate>;
  tokenGate?: Maybe<TokenGate>;
  tokenGates: Array<TokenGate>;
  investment?: Maybe<Investment>;
  investments: Array<Investment>;
  investmentState?: Maybe<InvestmentState>;
  investmentStates: Array<InvestmentState>;
  shareState?: Maybe<ShareState>;
  shareStates: Array<ShareState>;
  portfolioState?: Maybe<PortfolioState>;
  portfolioStates: Array<PortfolioState>;
  holdingState?: Maybe<HoldingState>;
  holdingStates: Array<HoldingState>;
  partyState?: Maybe<PartyState>;
  partyStates: Array<PartyState>;
  investmentCost?: Maybe<InvestmentCost>;
  investmentCosts: Array<InvestmentCost>;
  asset?: Maybe<Asset>;
  assets: Array<Asset>;
  tokenSwap?: Maybe<TokenSwap>;
  tokenSwaps: Array<TokenSwap>;
  limitOrderFill?: Maybe<LimitOrderFill>;
  limitOrderFills: Array<LimitOrderFill>;
  joinRequest?: Maybe<JoinRequest>;
  joinRequests: Array<JoinRequest>;
  partyActivity?: Maybe<PartyActivity>;
  partyActivities: Array<PartyActivity>;
  network?: Maybe<Network>;
  networks: Array<Network>;
  networkState?: Maybe<NetworkState>;
  networkStates: Array<NetworkState>;
  networkAssetHolding?: Maybe<NetworkAssetHolding>;
  networkAssetHoldings: Array<NetworkAssetHolding>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type SubscriptionassetAmountArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionassetAmountsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AssetAmount_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AssetAmount_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionsharesBoughtEventArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionsharesBoughtEventsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SharesBoughtEvent_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SharesBoughtEvent_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionsharesRedeemedEventArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionsharesRedeemedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SharesRedeemedEvent_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SharesRedeemedEvent_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionpartyArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionpartiesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Party_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Party_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionuserArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionusersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<User_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<User_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionuserPartyArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionuserPartiesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserParty_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UserParty_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionpartyTokenGateArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionpartyTokenGatesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PartyTokenGate_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PartyTokenGate_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontokenGateArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontokenGatesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TokenGate_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenGate_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioninvestmentArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioninvestmentsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Investment_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Investment_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioninvestmentStateArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioninvestmentStatesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<InvestmentState_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<InvestmentState_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionshareStateArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionshareStatesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ShareState_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ShareState_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionportfolioStateArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionportfolioStatesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PortfolioState_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PortfolioState_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionholdingStateArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionholdingStatesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HoldingState_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<HoldingState_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionpartyStateArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionpartyStatesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PartyState_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PartyState_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioninvestmentCostArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioninvestmentCostsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<InvestmentCost_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<InvestmentCost_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionassetArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionassetsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Asset_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Asset_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontokenSwapArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontokenSwapsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TokenSwap_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenSwap_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionlimitOrderFillArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionlimitOrderFillsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LimitOrderFill_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<LimitOrderFill_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionjoinRequestArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionjoinRequestsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<JoinRequest_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<JoinRequest_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionpartyActivityArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionpartyActivitiesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PartyActivity_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PartyActivity_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnetworkArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnetworksArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Network_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Network_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnetworkStateArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnetworkStatesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NetworkState_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NetworkState_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnetworkAssetHoldingArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnetworkAssetHoldingsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NetworkAssetHolding_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NetworkAssetHolding_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscription_metaArgs = {
  block?: InputMaybe<Block_height>;
};

export type Asset = {
  id: Scalars['ID'];
  name: Scalars['String'];
  symbol: Scalars['String'];
  decimals: Scalars['Int'];
  type: AssetType;
  firstSeen: Scalars['BigInt'];
  networkAssetHolding: NetworkAssetHolding;
  networkAssetHoldingHistory: Array<NetworkAssetHolding>;
  networkId: Scalars['Int'];
  slug: Scalars['String'];
};


export type AssetnetworkAssetHoldingHistoryArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NetworkAssetHolding_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NetworkAssetHolding_filter>;
};

export type AssetAmount = {
  id: Scalars['ID'];
  asset: Asset;
  amount: Scalars['BigDecimal'];
  price?: Maybe<Scalars['BigDecimal']>;
};

export type AssetAmount_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  asset?: InputMaybe<Scalars['String']>;
  asset_not?: InputMaybe<Scalars['String']>;
  asset_gt?: InputMaybe<Scalars['String']>;
  asset_lt?: InputMaybe<Scalars['String']>;
  asset_gte?: InputMaybe<Scalars['String']>;
  asset_lte?: InputMaybe<Scalars['String']>;
  asset_in?: InputMaybe<Array<Scalars['String']>>;
  asset_not_in?: InputMaybe<Array<Scalars['String']>>;
  asset_contains?: InputMaybe<Scalars['String']>;
  asset_contains_nocase?: InputMaybe<Scalars['String']>;
  asset_not_contains?: InputMaybe<Scalars['String']>;
  asset_not_contains_nocase?: InputMaybe<Scalars['String']>;
  asset_starts_with?: InputMaybe<Scalars['String']>;
  asset_starts_with_nocase?: InputMaybe<Scalars['String']>;
  asset_not_starts_with?: InputMaybe<Scalars['String']>;
  asset_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  asset_ends_with?: InputMaybe<Scalars['String']>;
  asset_ends_with_nocase?: InputMaybe<Scalars['String']>;
  asset_not_ends_with?: InputMaybe<Scalars['String']>;
  asset_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  asset_?: InputMaybe<Asset_filter>;
  amount?: InputMaybe<Scalars['BigDecimal']>;
  amount_not?: InputMaybe<Scalars['BigDecimal']>;
  amount_gt?: InputMaybe<Scalars['BigDecimal']>;
  amount_lt?: InputMaybe<Scalars['BigDecimal']>;
  amount_gte?: InputMaybe<Scalars['BigDecimal']>;
  amount_lte?: InputMaybe<Scalars['BigDecimal']>;
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  price?: InputMaybe<Scalars['BigDecimal']>;
  price_not?: InputMaybe<Scalars['BigDecimal']>;
  price_gt?: InputMaybe<Scalars['BigDecimal']>;
  price_lt?: InputMaybe<Scalars['BigDecimal']>;
  price_gte?: InputMaybe<Scalars['BigDecimal']>;
  price_lte?: InputMaybe<Scalars['BigDecimal']>;
  price_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  price_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<AssetAmount_filter>>>;
  or?: InputMaybe<Array<InputMaybe<AssetAmount_filter>>>;
};

export type AssetAmount_orderBy =
  | 'id'
  | 'asset'
  | 'asset__id'
  | 'asset__name'
  | 'asset__symbol'
  | 'asset__decimals'
  | 'asset__type'
  | 'asset__firstSeen'
  | 'amount'
  | 'price';

export type AssetType =
  | 'ERC20'
  | 'ERC721';

export type Asset_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_contains_nocase?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  symbol_not?: InputMaybe<Scalars['String']>;
  symbol_gt?: InputMaybe<Scalars['String']>;
  symbol_lt?: InputMaybe<Scalars['String']>;
  symbol_gte?: InputMaybe<Scalars['String']>;
  symbol_lte?: InputMaybe<Scalars['String']>;
  symbol_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_contains?: InputMaybe<Scalars['String']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_contains?: InputMaybe<Scalars['String']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_starts_with?: InputMaybe<Scalars['String']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_ends_with?: InputMaybe<Scalars['String']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  decimals?: InputMaybe<Scalars['Int']>;
  decimals_not?: InputMaybe<Scalars['Int']>;
  decimals_gt?: InputMaybe<Scalars['Int']>;
  decimals_lt?: InputMaybe<Scalars['Int']>;
  decimals_gte?: InputMaybe<Scalars['Int']>;
  decimals_lte?: InputMaybe<Scalars['Int']>;
  decimals_in?: InputMaybe<Array<Scalars['Int']>>;
  decimals_not_in?: InputMaybe<Array<Scalars['Int']>>;
  type?: InputMaybe<AssetType>;
  type_not?: InputMaybe<AssetType>;
  type_in?: InputMaybe<Array<AssetType>>;
  type_not_in?: InputMaybe<Array<AssetType>>;
  firstSeen?: InputMaybe<Scalars['BigInt']>;
  firstSeen_not?: InputMaybe<Scalars['BigInt']>;
  firstSeen_gt?: InputMaybe<Scalars['BigInt']>;
  firstSeen_lt?: InputMaybe<Scalars['BigInt']>;
  firstSeen_gte?: InputMaybe<Scalars['BigInt']>;
  firstSeen_lte?: InputMaybe<Scalars['BigInt']>;
  firstSeen_in?: InputMaybe<Array<Scalars['BigInt']>>;
  firstSeen_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  networkAssetHolding?: InputMaybe<Scalars['String']>;
  networkAssetHolding_not?: InputMaybe<Scalars['String']>;
  networkAssetHolding_gt?: InputMaybe<Scalars['String']>;
  networkAssetHolding_lt?: InputMaybe<Scalars['String']>;
  networkAssetHolding_gte?: InputMaybe<Scalars['String']>;
  networkAssetHolding_lte?: InputMaybe<Scalars['String']>;
  networkAssetHolding_in?: InputMaybe<Array<Scalars['String']>>;
  networkAssetHolding_not_in?: InputMaybe<Array<Scalars['String']>>;
  networkAssetHolding_contains?: InputMaybe<Scalars['String']>;
  networkAssetHolding_contains_nocase?: InputMaybe<Scalars['String']>;
  networkAssetHolding_not_contains?: InputMaybe<Scalars['String']>;
  networkAssetHolding_not_contains_nocase?: InputMaybe<Scalars['String']>;
  networkAssetHolding_starts_with?: InputMaybe<Scalars['String']>;
  networkAssetHolding_starts_with_nocase?: InputMaybe<Scalars['String']>;
  networkAssetHolding_not_starts_with?: InputMaybe<Scalars['String']>;
  networkAssetHolding_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  networkAssetHolding_ends_with?: InputMaybe<Scalars['String']>;
  networkAssetHolding_ends_with_nocase?: InputMaybe<Scalars['String']>;
  networkAssetHolding_not_ends_with?: InputMaybe<Scalars['String']>;
  networkAssetHolding_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  networkAssetHolding_?: InputMaybe<NetworkAssetHolding_filter>;
  networkAssetHoldingHistory_?: InputMaybe<NetworkAssetHolding_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Asset_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Asset_filter>>>;
};

export type Asset_orderBy =
  | 'id'
  | 'name'
  | 'symbol'
  | 'decimals'
  | 'type'
  | 'firstSeen'
  | 'networkAssetHolding'
  | 'networkAssetHolding__id'
  | 'networkAssetHolding__timestamp'
  | 'networkAssetHolding__blockNumber'
  | 'networkAssetHolding__amount'
  | 'networkAssetHolding__price'
  | 'networkAssetHoldingHistory';

export type BlockChangedFilter = {
  number_gte: Scalars['Int'];
};

export type Block_height = {
  hash?: InputMaybe<Scalars['Bytes']>;
  number?: InputMaybe<Scalars['Int']>;
  number_gte?: InputMaybe<Scalars['Int']>;
};

export type HoldingState = {
  id: Scalars['ID'];
  party: Party;
  timestamp: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
  asset: Asset;
  amount: Scalars['BigDecimal'];
  price?: Maybe<Scalars['BigDecimal']>;
};

export type HoldingState_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  party?: InputMaybe<Scalars['String']>;
  party_not?: InputMaybe<Scalars['String']>;
  party_gt?: InputMaybe<Scalars['String']>;
  party_lt?: InputMaybe<Scalars['String']>;
  party_gte?: InputMaybe<Scalars['String']>;
  party_lte?: InputMaybe<Scalars['String']>;
  party_in?: InputMaybe<Array<Scalars['String']>>;
  party_not_in?: InputMaybe<Array<Scalars['String']>>;
  party_contains?: InputMaybe<Scalars['String']>;
  party_contains_nocase?: InputMaybe<Scalars['String']>;
  party_not_contains?: InputMaybe<Scalars['String']>;
  party_not_contains_nocase?: InputMaybe<Scalars['String']>;
  party_starts_with?: InputMaybe<Scalars['String']>;
  party_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_starts_with?: InputMaybe<Scalars['String']>;
  party_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_ends_with?: InputMaybe<Scalars['String']>;
  party_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_ends_with?: InputMaybe<Scalars['String']>;
  party_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_?: InputMaybe<Party_filter>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  asset?: InputMaybe<Scalars['String']>;
  asset_not?: InputMaybe<Scalars['String']>;
  asset_gt?: InputMaybe<Scalars['String']>;
  asset_lt?: InputMaybe<Scalars['String']>;
  asset_gte?: InputMaybe<Scalars['String']>;
  asset_lte?: InputMaybe<Scalars['String']>;
  asset_in?: InputMaybe<Array<Scalars['String']>>;
  asset_not_in?: InputMaybe<Array<Scalars['String']>>;
  asset_contains?: InputMaybe<Scalars['String']>;
  asset_contains_nocase?: InputMaybe<Scalars['String']>;
  asset_not_contains?: InputMaybe<Scalars['String']>;
  asset_not_contains_nocase?: InputMaybe<Scalars['String']>;
  asset_starts_with?: InputMaybe<Scalars['String']>;
  asset_starts_with_nocase?: InputMaybe<Scalars['String']>;
  asset_not_starts_with?: InputMaybe<Scalars['String']>;
  asset_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  asset_ends_with?: InputMaybe<Scalars['String']>;
  asset_ends_with_nocase?: InputMaybe<Scalars['String']>;
  asset_not_ends_with?: InputMaybe<Scalars['String']>;
  asset_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  asset_?: InputMaybe<Asset_filter>;
  amount?: InputMaybe<Scalars['BigDecimal']>;
  amount_not?: InputMaybe<Scalars['BigDecimal']>;
  amount_gt?: InputMaybe<Scalars['BigDecimal']>;
  amount_lt?: InputMaybe<Scalars['BigDecimal']>;
  amount_gte?: InputMaybe<Scalars['BigDecimal']>;
  amount_lte?: InputMaybe<Scalars['BigDecimal']>;
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  price?: InputMaybe<Scalars['BigDecimal']>;
  price_not?: InputMaybe<Scalars['BigDecimal']>;
  price_gt?: InputMaybe<Scalars['BigDecimal']>;
  price_lt?: InputMaybe<Scalars['BigDecimal']>;
  price_gte?: InputMaybe<Scalars['BigDecimal']>;
  price_lte?: InputMaybe<Scalars['BigDecimal']>;
  price_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  price_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<HoldingState_filter>>>;
  or?: InputMaybe<Array<InputMaybe<HoldingState_filter>>>;
};

export type HoldingState_orderBy =
  | 'id'
  | 'party'
  | 'party__id'
  | 'party__name'
  | 'party__bio'
  | 'party__img'
  | 'party__model'
  | 'party__purpose'
  | 'party__isPublic'
  | 'party__minDeposit'
  | 'party__maxDeposit'
  | 'party__inception'
  | 'party__infoUpdatedAt'
  | 'party__isClosed'
  | 'party__membersCount'
  | 'party__lastActivity'
  | 'timestamp'
  | 'blockNumber'
  | 'asset'
  | 'asset__id'
  | 'asset__name'
  | 'asset__symbol'
  | 'asset__decimals'
  | 'asset__type'
  | 'asset__firstSeen'
  | 'amount'
  | 'price';

export type Investment = {
  id: Scalars['ID'];
  since: Scalars['BigInt'];
  party: Party;
  investor: User;
  shares: Scalars['BigDecimal'];
  state: InvestmentState;
  stateHistory: Array<InvestmentState>;
};


export type InvestmentstateHistoryArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<InvestmentState_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<InvestmentState_filter>;
};

export type InvestmentCost = {
  id: Scalars['ID'];
  party: Party;
  latestTimestamp: Scalars['BigInt'];
  latestBlockNumber: Scalars['BigInt'];
  depositCount: Scalars['Int'];
  totalDeposits: Scalars['BigDecimal'];
  withdrawalCount: Scalars['Int'];
  totalWithdrawals: Scalars['BigDecimal'];
  value: Scalars['BigDecimal'];
  withUncalculatedPrices: Scalars['Boolean'];
  deposits: Array<SharesBoughtEvent>;
  withdrawals: Array<SharesRedeemedEvent>;
  networkId: Scalars['Int'];
  partySlug: Scalars['String'];
};


export type InvestmentCostdepositsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SharesBoughtEvent_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SharesBoughtEvent_filter>;
};


export type InvestmentCostwithdrawalsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SharesRedeemedEvent_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SharesRedeemedEvent_filter>;
};

export type InvestmentCost_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  party?: InputMaybe<Scalars['String']>;
  party_not?: InputMaybe<Scalars['String']>;
  party_gt?: InputMaybe<Scalars['String']>;
  party_lt?: InputMaybe<Scalars['String']>;
  party_gte?: InputMaybe<Scalars['String']>;
  party_lte?: InputMaybe<Scalars['String']>;
  party_in?: InputMaybe<Array<Scalars['String']>>;
  party_not_in?: InputMaybe<Array<Scalars['String']>>;
  party_contains?: InputMaybe<Scalars['String']>;
  party_contains_nocase?: InputMaybe<Scalars['String']>;
  party_not_contains?: InputMaybe<Scalars['String']>;
  party_not_contains_nocase?: InputMaybe<Scalars['String']>;
  party_starts_with?: InputMaybe<Scalars['String']>;
  party_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_starts_with?: InputMaybe<Scalars['String']>;
  party_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_ends_with?: InputMaybe<Scalars['String']>;
  party_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_ends_with?: InputMaybe<Scalars['String']>;
  party_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_?: InputMaybe<Party_filter>;
  latestTimestamp?: InputMaybe<Scalars['BigInt']>;
  latestTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  latestTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  latestTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  latestTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  latestTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  latestTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  latestTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  latestBlockNumber?: InputMaybe<Scalars['BigInt']>;
  latestBlockNumber_not?: InputMaybe<Scalars['BigInt']>;
  latestBlockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  latestBlockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  latestBlockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  latestBlockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  latestBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  latestBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  depositCount?: InputMaybe<Scalars['Int']>;
  depositCount_not?: InputMaybe<Scalars['Int']>;
  depositCount_gt?: InputMaybe<Scalars['Int']>;
  depositCount_lt?: InputMaybe<Scalars['Int']>;
  depositCount_gte?: InputMaybe<Scalars['Int']>;
  depositCount_lte?: InputMaybe<Scalars['Int']>;
  depositCount_in?: InputMaybe<Array<Scalars['Int']>>;
  depositCount_not_in?: InputMaybe<Array<Scalars['Int']>>;
  totalDeposits?: InputMaybe<Scalars['BigDecimal']>;
  totalDeposits_not?: InputMaybe<Scalars['BigDecimal']>;
  totalDeposits_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalDeposits_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalDeposits_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalDeposits_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalDeposits_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalDeposits_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  withdrawalCount?: InputMaybe<Scalars['Int']>;
  withdrawalCount_not?: InputMaybe<Scalars['Int']>;
  withdrawalCount_gt?: InputMaybe<Scalars['Int']>;
  withdrawalCount_lt?: InputMaybe<Scalars['Int']>;
  withdrawalCount_gte?: InputMaybe<Scalars['Int']>;
  withdrawalCount_lte?: InputMaybe<Scalars['Int']>;
  withdrawalCount_in?: InputMaybe<Array<Scalars['Int']>>;
  withdrawalCount_not_in?: InputMaybe<Array<Scalars['Int']>>;
  totalWithdrawals?: InputMaybe<Scalars['BigDecimal']>;
  totalWithdrawals_not?: InputMaybe<Scalars['BigDecimal']>;
  totalWithdrawals_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalWithdrawals_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalWithdrawals_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalWithdrawals_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalWithdrawals_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalWithdrawals_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  value?: InputMaybe<Scalars['BigDecimal']>;
  value_not?: InputMaybe<Scalars['BigDecimal']>;
  value_gt?: InputMaybe<Scalars['BigDecimal']>;
  value_lt?: InputMaybe<Scalars['BigDecimal']>;
  value_gte?: InputMaybe<Scalars['BigDecimal']>;
  value_lte?: InputMaybe<Scalars['BigDecimal']>;
  value_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  value_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  withUncalculatedPrices?: InputMaybe<Scalars['Boolean']>;
  withUncalculatedPrices_not?: InputMaybe<Scalars['Boolean']>;
  withUncalculatedPrices_in?: InputMaybe<Array<Scalars['Boolean']>>;
  withUncalculatedPrices_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  deposits_?: InputMaybe<SharesBoughtEvent_filter>;
  withdrawals_?: InputMaybe<SharesRedeemedEvent_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<InvestmentCost_filter>>>;
  or?: InputMaybe<Array<InputMaybe<InvestmentCost_filter>>>;
};

export type InvestmentCost_orderBy =
  | 'id'
  | 'party'
  | 'party__id'
  | 'party__name'
  | 'party__bio'
  | 'party__img'
  | 'party__model'
  | 'party__purpose'
  | 'party__isPublic'
  | 'party__minDeposit'
  | 'party__maxDeposit'
  | 'party__inception'
  | 'party__infoUpdatedAt'
  | 'party__isClosed'
  | 'party__membersCount'
  | 'party__lastActivity'
  | 'latestTimestamp'
  | 'latestBlockNumber'
  | 'depositCount'
  | 'totalDeposits'
  | 'withdrawalCount'
  | 'totalWithdrawals'
  | 'value'
  | 'withUncalculatedPrices'
  | 'deposits'
  | 'withdrawals';

export type InvestmentState = {
  id: Scalars['ID'];
  timestamp: Scalars['BigInt'];
  party: Party;
  investor: User;
  investment: Investment;
  shares: Scalars['BigDecimal'];
  partyState: PartyState;
};

export type InvestmentState_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  party?: InputMaybe<Scalars['String']>;
  party_not?: InputMaybe<Scalars['String']>;
  party_gt?: InputMaybe<Scalars['String']>;
  party_lt?: InputMaybe<Scalars['String']>;
  party_gte?: InputMaybe<Scalars['String']>;
  party_lte?: InputMaybe<Scalars['String']>;
  party_in?: InputMaybe<Array<Scalars['String']>>;
  party_not_in?: InputMaybe<Array<Scalars['String']>>;
  party_contains?: InputMaybe<Scalars['String']>;
  party_contains_nocase?: InputMaybe<Scalars['String']>;
  party_not_contains?: InputMaybe<Scalars['String']>;
  party_not_contains_nocase?: InputMaybe<Scalars['String']>;
  party_starts_with?: InputMaybe<Scalars['String']>;
  party_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_starts_with?: InputMaybe<Scalars['String']>;
  party_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_ends_with?: InputMaybe<Scalars['String']>;
  party_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_ends_with?: InputMaybe<Scalars['String']>;
  party_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_?: InputMaybe<Party_filter>;
  investor?: InputMaybe<Scalars['String']>;
  investor_not?: InputMaybe<Scalars['String']>;
  investor_gt?: InputMaybe<Scalars['String']>;
  investor_lt?: InputMaybe<Scalars['String']>;
  investor_gte?: InputMaybe<Scalars['String']>;
  investor_lte?: InputMaybe<Scalars['String']>;
  investor_in?: InputMaybe<Array<Scalars['String']>>;
  investor_not_in?: InputMaybe<Array<Scalars['String']>>;
  investor_contains?: InputMaybe<Scalars['String']>;
  investor_contains_nocase?: InputMaybe<Scalars['String']>;
  investor_not_contains?: InputMaybe<Scalars['String']>;
  investor_not_contains_nocase?: InputMaybe<Scalars['String']>;
  investor_starts_with?: InputMaybe<Scalars['String']>;
  investor_starts_with_nocase?: InputMaybe<Scalars['String']>;
  investor_not_starts_with?: InputMaybe<Scalars['String']>;
  investor_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  investor_ends_with?: InputMaybe<Scalars['String']>;
  investor_ends_with_nocase?: InputMaybe<Scalars['String']>;
  investor_not_ends_with?: InputMaybe<Scalars['String']>;
  investor_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  investor_?: InputMaybe<User_filter>;
  investment?: InputMaybe<Scalars['String']>;
  investment_not?: InputMaybe<Scalars['String']>;
  investment_gt?: InputMaybe<Scalars['String']>;
  investment_lt?: InputMaybe<Scalars['String']>;
  investment_gte?: InputMaybe<Scalars['String']>;
  investment_lte?: InputMaybe<Scalars['String']>;
  investment_in?: InputMaybe<Array<Scalars['String']>>;
  investment_not_in?: InputMaybe<Array<Scalars['String']>>;
  investment_contains?: InputMaybe<Scalars['String']>;
  investment_contains_nocase?: InputMaybe<Scalars['String']>;
  investment_not_contains?: InputMaybe<Scalars['String']>;
  investment_not_contains_nocase?: InputMaybe<Scalars['String']>;
  investment_starts_with?: InputMaybe<Scalars['String']>;
  investment_starts_with_nocase?: InputMaybe<Scalars['String']>;
  investment_not_starts_with?: InputMaybe<Scalars['String']>;
  investment_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  investment_ends_with?: InputMaybe<Scalars['String']>;
  investment_ends_with_nocase?: InputMaybe<Scalars['String']>;
  investment_not_ends_with?: InputMaybe<Scalars['String']>;
  investment_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  investment_?: InputMaybe<Investment_filter>;
  shares?: InputMaybe<Scalars['BigDecimal']>;
  shares_not?: InputMaybe<Scalars['BigDecimal']>;
  shares_gt?: InputMaybe<Scalars['BigDecimal']>;
  shares_lt?: InputMaybe<Scalars['BigDecimal']>;
  shares_gte?: InputMaybe<Scalars['BigDecimal']>;
  shares_lte?: InputMaybe<Scalars['BigDecimal']>;
  shares_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  shares_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  partyState?: InputMaybe<Scalars['String']>;
  partyState_not?: InputMaybe<Scalars['String']>;
  partyState_gt?: InputMaybe<Scalars['String']>;
  partyState_lt?: InputMaybe<Scalars['String']>;
  partyState_gte?: InputMaybe<Scalars['String']>;
  partyState_lte?: InputMaybe<Scalars['String']>;
  partyState_in?: InputMaybe<Array<Scalars['String']>>;
  partyState_not_in?: InputMaybe<Array<Scalars['String']>>;
  partyState_contains?: InputMaybe<Scalars['String']>;
  partyState_contains_nocase?: InputMaybe<Scalars['String']>;
  partyState_not_contains?: InputMaybe<Scalars['String']>;
  partyState_not_contains_nocase?: InputMaybe<Scalars['String']>;
  partyState_starts_with?: InputMaybe<Scalars['String']>;
  partyState_starts_with_nocase?: InputMaybe<Scalars['String']>;
  partyState_not_starts_with?: InputMaybe<Scalars['String']>;
  partyState_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  partyState_ends_with?: InputMaybe<Scalars['String']>;
  partyState_ends_with_nocase?: InputMaybe<Scalars['String']>;
  partyState_not_ends_with?: InputMaybe<Scalars['String']>;
  partyState_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  partyState_?: InputMaybe<PartyState_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<InvestmentState_filter>>>;
  or?: InputMaybe<Array<InputMaybe<InvestmentState_filter>>>;
};

export type InvestmentState_orderBy =
  | 'id'
  | 'timestamp'
  | 'party'
  | 'party__id'
  | 'party__name'
  | 'party__bio'
  | 'party__img'
  | 'party__model'
  | 'party__purpose'
  | 'party__isPublic'
  | 'party__minDeposit'
  | 'party__maxDeposit'
  | 'party__inception'
  | 'party__infoUpdatedAt'
  | 'party__isClosed'
  | 'party__membersCount'
  | 'party__lastActivity'
  | 'investor'
  | 'investor__id'
  | 'investor__firstSeen'
  | 'investor__partyOwner'
  | 'investor__partyOwnerSince'
  | 'investor__investor'
  | 'investor__investorSince'
  | 'investment'
  | 'investment__id'
  | 'investment__since'
  | 'investment__shares'
  | 'shares'
  | 'partyState'
  | 'partyState__id'
  | 'partyState__timestamp';

export type Investment_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  since?: InputMaybe<Scalars['BigInt']>;
  since_not?: InputMaybe<Scalars['BigInt']>;
  since_gt?: InputMaybe<Scalars['BigInt']>;
  since_lt?: InputMaybe<Scalars['BigInt']>;
  since_gte?: InputMaybe<Scalars['BigInt']>;
  since_lte?: InputMaybe<Scalars['BigInt']>;
  since_in?: InputMaybe<Array<Scalars['BigInt']>>;
  since_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  party?: InputMaybe<Scalars['String']>;
  party_not?: InputMaybe<Scalars['String']>;
  party_gt?: InputMaybe<Scalars['String']>;
  party_lt?: InputMaybe<Scalars['String']>;
  party_gte?: InputMaybe<Scalars['String']>;
  party_lte?: InputMaybe<Scalars['String']>;
  party_in?: InputMaybe<Array<Scalars['String']>>;
  party_not_in?: InputMaybe<Array<Scalars['String']>>;
  party_contains?: InputMaybe<Scalars['String']>;
  party_contains_nocase?: InputMaybe<Scalars['String']>;
  party_not_contains?: InputMaybe<Scalars['String']>;
  party_not_contains_nocase?: InputMaybe<Scalars['String']>;
  party_starts_with?: InputMaybe<Scalars['String']>;
  party_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_starts_with?: InputMaybe<Scalars['String']>;
  party_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_ends_with?: InputMaybe<Scalars['String']>;
  party_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_ends_with?: InputMaybe<Scalars['String']>;
  party_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_?: InputMaybe<Party_filter>;
  investor?: InputMaybe<Scalars['String']>;
  investor_not?: InputMaybe<Scalars['String']>;
  investor_gt?: InputMaybe<Scalars['String']>;
  investor_lt?: InputMaybe<Scalars['String']>;
  investor_gte?: InputMaybe<Scalars['String']>;
  investor_lte?: InputMaybe<Scalars['String']>;
  investor_in?: InputMaybe<Array<Scalars['String']>>;
  investor_not_in?: InputMaybe<Array<Scalars['String']>>;
  investor_contains?: InputMaybe<Scalars['String']>;
  investor_contains_nocase?: InputMaybe<Scalars['String']>;
  investor_not_contains?: InputMaybe<Scalars['String']>;
  investor_not_contains_nocase?: InputMaybe<Scalars['String']>;
  investor_starts_with?: InputMaybe<Scalars['String']>;
  investor_starts_with_nocase?: InputMaybe<Scalars['String']>;
  investor_not_starts_with?: InputMaybe<Scalars['String']>;
  investor_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  investor_ends_with?: InputMaybe<Scalars['String']>;
  investor_ends_with_nocase?: InputMaybe<Scalars['String']>;
  investor_not_ends_with?: InputMaybe<Scalars['String']>;
  investor_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  investor_?: InputMaybe<User_filter>;
  shares?: InputMaybe<Scalars['BigDecimal']>;
  shares_not?: InputMaybe<Scalars['BigDecimal']>;
  shares_gt?: InputMaybe<Scalars['BigDecimal']>;
  shares_lt?: InputMaybe<Scalars['BigDecimal']>;
  shares_gte?: InputMaybe<Scalars['BigDecimal']>;
  shares_lte?: InputMaybe<Scalars['BigDecimal']>;
  shares_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  shares_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  state?: InputMaybe<Scalars['String']>;
  state_not?: InputMaybe<Scalars['String']>;
  state_gt?: InputMaybe<Scalars['String']>;
  state_lt?: InputMaybe<Scalars['String']>;
  state_gte?: InputMaybe<Scalars['String']>;
  state_lte?: InputMaybe<Scalars['String']>;
  state_in?: InputMaybe<Array<Scalars['String']>>;
  state_not_in?: InputMaybe<Array<Scalars['String']>>;
  state_contains?: InputMaybe<Scalars['String']>;
  state_contains_nocase?: InputMaybe<Scalars['String']>;
  state_not_contains?: InputMaybe<Scalars['String']>;
  state_not_contains_nocase?: InputMaybe<Scalars['String']>;
  state_starts_with?: InputMaybe<Scalars['String']>;
  state_starts_with_nocase?: InputMaybe<Scalars['String']>;
  state_not_starts_with?: InputMaybe<Scalars['String']>;
  state_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  state_ends_with?: InputMaybe<Scalars['String']>;
  state_ends_with_nocase?: InputMaybe<Scalars['String']>;
  state_not_ends_with?: InputMaybe<Scalars['String']>;
  state_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  state_?: InputMaybe<InvestmentState_filter>;
  stateHistory_?: InputMaybe<InvestmentState_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Investment_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Investment_filter>>>;
};

export type Investment_orderBy =
  | 'id'
  | 'since'
  | 'party'
  | 'party__id'
  | 'party__name'
  | 'party__bio'
  | 'party__img'
  | 'party__model'
  | 'party__purpose'
  | 'party__isPublic'
  | 'party__minDeposit'
  | 'party__maxDeposit'
  | 'party__inception'
  | 'party__infoUpdatedAt'
  | 'party__isClosed'
  | 'party__membersCount'
  | 'party__lastActivity'
  | 'investor'
  | 'investor__id'
  | 'investor__firstSeen'
  | 'investor__partyOwner'
  | 'investor__partyOwnerSince'
  | 'investor__investor'
  | 'investor__investorSince'
  | 'shares'
  | 'state'
  | 'state__id'
  | 'state__timestamp'
  | 'state__shares'
  | 'stateHistory';

export type JoinRequest = {
  id: Scalars['ID'];
  party: Party;
  user: User;
  requestedSince: Scalars['BigInt'];
  handledAt?: Maybe<Scalars['BigInt']>;
  status: JoinRequestStatus;
};

export type JoinRequestStatus =
  | 'Requested'
  | 'Accepted'
  | 'Rejected';

export type JoinRequest_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  party?: InputMaybe<Scalars['String']>;
  party_not?: InputMaybe<Scalars['String']>;
  party_gt?: InputMaybe<Scalars['String']>;
  party_lt?: InputMaybe<Scalars['String']>;
  party_gte?: InputMaybe<Scalars['String']>;
  party_lte?: InputMaybe<Scalars['String']>;
  party_in?: InputMaybe<Array<Scalars['String']>>;
  party_not_in?: InputMaybe<Array<Scalars['String']>>;
  party_contains?: InputMaybe<Scalars['String']>;
  party_contains_nocase?: InputMaybe<Scalars['String']>;
  party_not_contains?: InputMaybe<Scalars['String']>;
  party_not_contains_nocase?: InputMaybe<Scalars['String']>;
  party_starts_with?: InputMaybe<Scalars['String']>;
  party_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_starts_with?: InputMaybe<Scalars['String']>;
  party_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_ends_with?: InputMaybe<Scalars['String']>;
  party_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_ends_with?: InputMaybe<Scalars['String']>;
  party_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_?: InputMaybe<Party_filter>;
  user?: InputMaybe<Scalars['String']>;
  user_not?: InputMaybe<Scalars['String']>;
  user_gt?: InputMaybe<Scalars['String']>;
  user_lt?: InputMaybe<Scalars['String']>;
  user_gte?: InputMaybe<Scalars['String']>;
  user_lte?: InputMaybe<Scalars['String']>;
  user_in?: InputMaybe<Array<Scalars['String']>>;
  user_not_in?: InputMaybe<Array<Scalars['String']>>;
  user_contains?: InputMaybe<Scalars['String']>;
  user_contains_nocase?: InputMaybe<Scalars['String']>;
  user_not_contains?: InputMaybe<Scalars['String']>;
  user_not_contains_nocase?: InputMaybe<Scalars['String']>;
  user_starts_with?: InputMaybe<Scalars['String']>;
  user_starts_with_nocase?: InputMaybe<Scalars['String']>;
  user_not_starts_with?: InputMaybe<Scalars['String']>;
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  user_ends_with?: InputMaybe<Scalars['String']>;
  user_ends_with_nocase?: InputMaybe<Scalars['String']>;
  user_not_ends_with?: InputMaybe<Scalars['String']>;
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  user_?: InputMaybe<User_filter>;
  requestedSince?: InputMaybe<Scalars['BigInt']>;
  requestedSince_not?: InputMaybe<Scalars['BigInt']>;
  requestedSince_gt?: InputMaybe<Scalars['BigInt']>;
  requestedSince_lt?: InputMaybe<Scalars['BigInt']>;
  requestedSince_gte?: InputMaybe<Scalars['BigInt']>;
  requestedSince_lte?: InputMaybe<Scalars['BigInt']>;
  requestedSince_in?: InputMaybe<Array<Scalars['BigInt']>>;
  requestedSince_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  handledAt?: InputMaybe<Scalars['BigInt']>;
  handledAt_not?: InputMaybe<Scalars['BigInt']>;
  handledAt_gt?: InputMaybe<Scalars['BigInt']>;
  handledAt_lt?: InputMaybe<Scalars['BigInt']>;
  handledAt_gte?: InputMaybe<Scalars['BigInt']>;
  handledAt_lte?: InputMaybe<Scalars['BigInt']>;
  handledAt_in?: InputMaybe<Array<Scalars['BigInt']>>;
  handledAt_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  status?: InputMaybe<JoinRequestStatus>;
  status_not?: InputMaybe<JoinRequestStatus>;
  status_in?: InputMaybe<Array<JoinRequestStatus>>;
  status_not_in?: InputMaybe<Array<JoinRequestStatus>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<JoinRequest_filter>>>;
  or?: InputMaybe<Array<InputMaybe<JoinRequest_filter>>>;
};

export type JoinRequest_orderBy =
  | 'id'
  | 'party'
  | 'party__id'
  | 'party__name'
  | 'party__bio'
  | 'party__img'
  | 'party__model'
  | 'party__purpose'
  | 'party__isPublic'
  | 'party__minDeposit'
  | 'party__maxDeposit'
  | 'party__inception'
  | 'party__infoUpdatedAt'
  | 'party__isClosed'
  | 'party__membersCount'
  | 'party__lastActivity'
  | 'user'
  | 'user__id'
  | 'user__firstSeen'
  | 'user__partyOwner'
  | 'user__partyOwnerSince'
  | 'user__investor'
  | 'user__investorSince'
  | 'requestedSince'
  | 'handledAt'
  | 'status';

export type LimitOrderFill = {
  id: Scalars['ID'];
  party: Party;
  transaction: Scalars['Bytes'];
  sellToken: Asset;
  buyToken: Asset;
  soldAmount: Scalars['BigDecimal'];
  boughtAmount: Scalars['BigDecimal'];
  fee: Scalars['BigDecimal'];
  timestamp: Scalars['BigInt'];
};

export type LimitOrderFill_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  party?: InputMaybe<Scalars['String']>;
  party_not?: InputMaybe<Scalars['String']>;
  party_gt?: InputMaybe<Scalars['String']>;
  party_lt?: InputMaybe<Scalars['String']>;
  party_gte?: InputMaybe<Scalars['String']>;
  party_lte?: InputMaybe<Scalars['String']>;
  party_in?: InputMaybe<Array<Scalars['String']>>;
  party_not_in?: InputMaybe<Array<Scalars['String']>>;
  party_contains?: InputMaybe<Scalars['String']>;
  party_contains_nocase?: InputMaybe<Scalars['String']>;
  party_not_contains?: InputMaybe<Scalars['String']>;
  party_not_contains_nocase?: InputMaybe<Scalars['String']>;
  party_starts_with?: InputMaybe<Scalars['String']>;
  party_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_starts_with?: InputMaybe<Scalars['String']>;
  party_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_ends_with?: InputMaybe<Scalars['String']>;
  party_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_ends_with?: InputMaybe<Scalars['String']>;
  party_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_?: InputMaybe<Party_filter>;
  transaction?: InputMaybe<Scalars['Bytes']>;
  transaction_not?: InputMaybe<Scalars['Bytes']>;
  transaction_gt?: InputMaybe<Scalars['Bytes']>;
  transaction_lt?: InputMaybe<Scalars['Bytes']>;
  transaction_gte?: InputMaybe<Scalars['Bytes']>;
  transaction_lte?: InputMaybe<Scalars['Bytes']>;
  transaction_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transaction_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transaction_contains?: InputMaybe<Scalars['Bytes']>;
  transaction_not_contains?: InputMaybe<Scalars['Bytes']>;
  sellToken?: InputMaybe<Scalars['String']>;
  sellToken_not?: InputMaybe<Scalars['String']>;
  sellToken_gt?: InputMaybe<Scalars['String']>;
  sellToken_lt?: InputMaybe<Scalars['String']>;
  sellToken_gte?: InputMaybe<Scalars['String']>;
  sellToken_lte?: InputMaybe<Scalars['String']>;
  sellToken_in?: InputMaybe<Array<Scalars['String']>>;
  sellToken_not_in?: InputMaybe<Array<Scalars['String']>>;
  sellToken_contains?: InputMaybe<Scalars['String']>;
  sellToken_contains_nocase?: InputMaybe<Scalars['String']>;
  sellToken_not_contains?: InputMaybe<Scalars['String']>;
  sellToken_not_contains_nocase?: InputMaybe<Scalars['String']>;
  sellToken_starts_with?: InputMaybe<Scalars['String']>;
  sellToken_starts_with_nocase?: InputMaybe<Scalars['String']>;
  sellToken_not_starts_with?: InputMaybe<Scalars['String']>;
  sellToken_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  sellToken_ends_with?: InputMaybe<Scalars['String']>;
  sellToken_ends_with_nocase?: InputMaybe<Scalars['String']>;
  sellToken_not_ends_with?: InputMaybe<Scalars['String']>;
  sellToken_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  sellToken_?: InputMaybe<Asset_filter>;
  buyToken?: InputMaybe<Scalars['String']>;
  buyToken_not?: InputMaybe<Scalars['String']>;
  buyToken_gt?: InputMaybe<Scalars['String']>;
  buyToken_lt?: InputMaybe<Scalars['String']>;
  buyToken_gte?: InputMaybe<Scalars['String']>;
  buyToken_lte?: InputMaybe<Scalars['String']>;
  buyToken_in?: InputMaybe<Array<Scalars['String']>>;
  buyToken_not_in?: InputMaybe<Array<Scalars['String']>>;
  buyToken_contains?: InputMaybe<Scalars['String']>;
  buyToken_contains_nocase?: InputMaybe<Scalars['String']>;
  buyToken_not_contains?: InputMaybe<Scalars['String']>;
  buyToken_not_contains_nocase?: InputMaybe<Scalars['String']>;
  buyToken_starts_with?: InputMaybe<Scalars['String']>;
  buyToken_starts_with_nocase?: InputMaybe<Scalars['String']>;
  buyToken_not_starts_with?: InputMaybe<Scalars['String']>;
  buyToken_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  buyToken_ends_with?: InputMaybe<Scalars['String']>;
  buyToken_ends_with_nocase?: InputMaybe<Scalars['String']>;
  buyToken_not_ends_with?: InputMaybe<Scalars['String']>;
  buyToken_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  buyToken_?: InputMaybe<Asset_filter>;
  soldAmount?: InputMaybe<Scalars['BigDecimal']>;
  soldAmount_not?: InputMaybe<Scalars['BigDecimal']>;
  soldAmount_gt?: InputMaybe<Scalars['BigDecimal']>;
  soldAmount_lt?: InputMaybe<Scalars['BigDecimal']>;
  soldAmount_gte?: InputMaybe<Scalars['BigDecimal']>;
  soldAmount_lte?: InputMaybe<Scalars['BigDecimal']>;
  soldAmount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  soldAmount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  boughtAmount?: InputMaybe<Scalars['BigDecimal']>;
  boughtAmount_not?: InputMaybe<Scalars['BigDecimal']>;
  boughtAmount_gt?: InputMaybe<Scalars['BigDecimal']>;
  boughtAmount_lt?: InputMaybe<Scalars['BigDecimal']>;
  boughtAmount_gte?: InputMaybe<Scalars['BigDecimal']>;
  boughtAmount_lte?: InputMaybe<Scalars['BigDecimal']>;
  boughtAmount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  boughtAmount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fee?: InputMaybe<Scalars['BigDecimal']>;
  fee_not?: InputMaybe<Scalars['BigDecimal']>;
  fee_gt?: InputMaybe<Scalars['BigDecimal']>;
  fee_lt?: InputMaybe<Scalars['BigDecimal']>;
  fee_gte?: InputMaybe<Scalars['BigDecimal']>;
  fee_lte?: InputMaybe<Scalars['BigDecimal']>;
  fee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<LimitOrderFill_filter>>>;
  or?: InputMaybe<Array<InputMaybe<LimitOrderFill_filter>>>;
};

export type LimitOrderFill_orderBy =
  | 'id'
  | 'party'
  | 'party__id'
  | 'party__name'
  | 'party__bio'
  | 'party__img'
  | 'party__model'
  | 'party__purpose'
  | 'party__isPublic'
  | 'party__minDeposit'
  | 'party__maxDeposit'
  | 'party__inception'
  | 'party__infoUpdatedAt'
  | 'party__isClosed'
  | 'party__membersCount'
  | 'party__lastActivity'
  | 'transaction'
  | 'sellToken'
  | 'sellToken__id'
  | 'sellToken__name'
  | 'sellToken__symbol'
  | 'sellToken__decimals'
  | 'sellToken__type'
  | 'sellToken__firstSeen'
  | 'buyToken'
  | 'buyToken__id'
  | 'buyToken__name'
  | 'buyToken__symbol'
  | 'buyToken__decimals'
  | 'buyToken__type'
  | 'buyToken__firstSeen'
  | 'soldAmount'
  | 'boughtAmount'
  | 'fee'
  | 'timestamp';

export type Network = {
  id: Scalars['ID'];
  timestamp: Scalars['BigInt'];
  state: NetworkState;
  stateHistory: Array<NetworkState>;
};


export type NetworkstateHistoryArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NetworkState_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NetworkState_filter>;
};

export type NetworkAssetHolding = {
  id: Scalars['ID'];
  timestamp: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
  asset: Asset;
  amount: Scalars['BigDecimal'];
  price?: Maybe<Scalars['BigDecimal']>;
};

export type NetworkAssetHolding_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  asset?: InputMaybe<Scalars['String']>;
  asset_not?: InputMaybe<Scalars['String']>;
  asset_gt?: InputMaybe<Scalars['String']>;
  asset_lt?: InputMaybe<Scalars['String']>;
  asset_gte?: InputMaybe<Scalars['String']>;
  asset_lte?: InputMaybe<Scalars['String']>;
  asset_in?: InputMaybe<Array<Scalars['String']>>;
  asset_not_in?: InputMaybe<Array<Scalars['String']>>;
  asset_contains?: InputMaybe<Scalars['String']>;
  asset_contains_nocase?: InputMaybe<Scalars['String']>;
  asset_not_contains?: InputMaybe<Scalars['String']>;
  asset_not_contains_nocase?: InputMaybe<Scalars['String']>;
  asset_starts_with?: InputMaybe<Scalars['String']>;
  asset_starts_with_nocase?: InputMaybe<Scalars['String']>;
  asset_not_starts_with?: InputMaybe<Scalars['String']>;
  asset_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  asset_ends_with?: InputMaybe<Scalars['String']>;
  asset_ends_with_nocase?: InputMaybe<Scalars['String']>;
  asset_not_ends_with?: InputMaybe<Scalars['String']>;
  asset_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  asset_?: InputMaybe<Asset_filter>;
  amount?: InputMaybe<Scalars['BigDecimal']>;
  amount_not?: InputMaybe<Scalars['BigDecimal']>;
  amount_gt?: InputMaybe<Scalars['BigDecimal']>;
  amount_lt?: InputMaybe<Scalars['BigDecimal']>;
  amount_gte?: InputMaybe<Scalars['BigDecimal']>;
  amount_lte?: InputMaybe<Scalars['BigDecimal']>;
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  price?: InputMaybe<Scalars['BigDecimal']>;
  price_not?: InputMaybe<Scalars['BigDecimal']>;
  price_gt?: InputMaybe<Scalars['BigDecimal']>;
  price_lt?: InputMaybe<Scalars['BigDecimal']>;
  price_gte?: InputMaybe<Scalars['BigDecimal']>;
  price_lte?: InputMaybe<Scalars['BigDecimal']>;
  price_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  price_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<NetworkAssetHolding_filter>>>;
  or?: InputMaybe<Array<InputMaybe<NetworkAssetHolding_filter>>>;
};

export type NetworkAssetHolding_orderBy =
  | 'id'
  | 'timestamp'
  | 'blockNumber'
  | 'asset'
  | 'asset__id'
  | 'asset__name'
  | 'asset__symbol'
  | 'asset__decimals'
  | 'asset__type'
  | 'asset__firstSeen'
  | 'amount'
  | 'price';

export type NetworkState = {
  id: Scalars['ID'];
  network: Network;
  timestamp: Scalars['BigInt'];
  assetHoldings: Array<NetworkAssetHolding>;
  parties: Scalars['Int'];
  investors: Scalars['Int'];
  deposits: Scalars['Int'];
  totalDeposits: Scalars['BigDecimal'];
  withdraws: Scalars['Int'];
  totalWithdrawals: Scalars['BigDecimal'];
  withUncalculatedPrices: Scalars['Boolean'];
};


export type NetworkStateassetHoldingsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NetworkAssetHolding_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NetworkAssetHolding_filter>;
};

export type NetworkState_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  network?: InputMaybe<Scalars['String']>;
  network_not?: InputMaybe<Scalars['String']>;
  network_gt?: InputMaybe<Scalars['String']>;
  network_lt?: InputMaybe<Scalars['String']>;
  network_gte?: InputMaybe<Scalars['String']>;
  network_lte?: InputMaybe<Scalars['String']>;
  network_in?: InputMaybe<Array<Scalars['String']>>;
  network_not_in?: InputMaybe<Array<Scalars['String']>>;
  network_contains?: InputMaybe<Scalars['String']>;
  network_contains_nocase?: InputMaybe<Scalars['String']>;
  network_not_contains?: InputMaybe<Scalars['String']>;
  network_not_contains_nocase?: InputMaybe<Scalars['String']>;
  network_starts_with?: InputMaybe<Scalars['String']>;
  network_starts_with_nocase?: InputMaybe<Scalars['String']>;
  network_not_starts_with?: InputMaybe<Scalars['String']>;
  network_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  network_ends_with?: InputMaybe<Scalars['String']>;
  network_ends_with_nocase?: InputMaybe<Scalars['String']>;
  network_not_ends_with?: InputMaybe<Scalars['String']>;
  network_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  network_?: InputMaybe<Network_filter>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  assetHoldings?: InputMaybe<Array<Scalars['String']>>;
  assetHoldings_not?: InputMaybe<Array<Scalars['String']>>;
  assetHoldings_contains?: InputMaybe<Array<Scalars['String']>>;
  assetHoldings_contains_nocase?: InputMaybe<Array<Scalars['String']>>;
  assetHoldings_not_contains?: InputMaybe<Array<Scalars['String']>>;
  assetHoldings_not_contains_nocase?: InputMaybe<Array<Scalars['String']>>;
  assetHoldings_?: InputMaybe<NetworkAssetHolding_filter>;
  parties?: InputMaybe<Scalars['Int']>;
  parties_not?: InputMaybe<Scalars['Int']>;
  parties_gt?: InputMaybe<Scalars['Int']>;
  parties_lt?: InputMaybe<Scalars['Int']>;
  parties_gte?: InputMaybe<Scalars['Int']>;
  parties_lte?: InputMaybe<Scalars['Int']>;
  parties_in?: InputMaybe<Array<Scalars['Int']>>;
  parties_not_in?: InputMaybe<Array<Scalars['Int']>>;
  investors?: InputMaybe<Scalars['Int']>;
  investors_not?: InputMaybe<Scalars['Int']>;
  investors_gt?: InputMaybe<Scalars['Int']>;
  investors_lt?: InputMaybe<Scalars['Int']>;
  investors_gte?: InputMaybe<Scalars['Int']>;
  investors_lte?: InputMaybe<Scalars['Int']>;
  investors_in?: InputMaybe<Array<Scalars['Int']>>;
  investors_not_in?: InputMaybe<Array<Scalars['Int']>>;
  deposits?: InputMaybe<Scalars['Int']>;
  deposits_not?: InputMaybe<Scalars['Int']>;
  deposits_gt?: InputMaybe<Scalars['Int']>;
  deposits_lt?: InputMaybe<Scalars['Int']>;
  deposits_gte?: InputMaybe<Scalars['Int']>;
  deposits_lte?: InputMaybe<Scalars['Int']>;
  deposits_in?: InputMaybe<Array<Scalars['Int']>>;
  deposits_not_in?: InputMaybe<Array<Scalars['Int']>>;
  totalDeposits?: InputMaybe<Scalars['BigDecimal']>;
  totalDeposits_not?: InputMaybe<Scalars['BigDecimal']>;
  totalDeposits_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalDeposits_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalDeposits_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalDeposits_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalDeposits_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalDeposits_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  withdraws?: InputMaybe<Scalars['Int']>;
  withdraws_not?: InputMaybe<Scalars['Int']>;
  withdraws_gt?: InputMaybe<Scalars['Int']>;
  withdraws_lt?: InputMaybe<Scalars['Int']>;
  withdraws_gte?: InputMaybe<Scalars['Int']>;
  withdraws_lte?: InputMaybe<Scalars['Int']>;
  withdraws_in?: InputMaybe<Array<Scalars['Int']>>;
  withdraws_not_in?: InputMaybe<Array<Scalars['Int']>>;
  totalWithdrawals?: InputMaybe<Scalars['BigDecimal']>;
  totalWithdrawals_not?: InputMaybe<Scalars['BigDecimal']>;
  totalWithdrawals_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalWithdrawals_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalWithdrawals_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalWithdrawals_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalWithdrawals_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalWithdrawals_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  withUncalculatedPrices?: InputMaybe<Scalars['Boolean']>;
  withUncalculatedPrices_not?: InputMaybe<Scalars['Boolean']>;
  withUncalculatedPrices_in?: InputMaybe<Array<Scalars['Boolean']>>;
  withUncalculatedPrices_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<NetworkState_filter>>>;
  or?: InputMaybe<Array<InputMaybe<NetworkState_filter>>>;
};

export type NetworkState_orderBy =
  | 'id'
  | 'network'
  | 'network__id'
  | 'network__timestamp'
  | 'timestamp'
  | 'assetHoldings'
  | 'parties'
  | 'investors'
  | 'deposits'
  | 'totalDeposits'
  | 'withdraws'
  | 'totalWithdrawals'
  | 'withUncalculatedPrices';

export type Network_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  state?: InputMaybe<Scalars['String']>;
  state_not?: InputMaybe<Scalars['String']>;
  state_gt?: InputMaybe<Scalars['String']>;
  state_lt?: InputMaybe<Scalars['String']>;
  state_gte?: InputMaybe<Scalars['String']>;
  state_lte?: InputMaybe<Scalars['String']>;
  state_in?: InputMaybe<Array<Scalars['String']>>;
  state_not_in?: InputMaybe<Array<Scalars['String']>>;
  state_contains?: InputMaybe<Scalars['String']>;
  state_contains_nocase?: InputMaybe<Scalars['String']>;
  state_not_contains?: InputMaybe<Scalars['String']>;
  state_not_contains_nocase?: InputMaybe<Scalars['String']>;
  state_starts_with?: InputMaybe<Scalars['String']>;
  state_starts_with_nocase?: InputMaybe<Scalars['String']>;
  state_not_starts_with?: InputMaybe<Scalars['String']>;
  state_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  state_ends_with?: InputMaybe<Scalars['String']>;
  state_ends_with_nocase?: InputMaybe<Scalars['String']>;
  state_not_ends_with?: InputMaybe<Scalars['String']>;
  state_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  state_?: InputMaybe<NetworkState_filter>;
  stateHistory_?: InputMaybe<NetworkState_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Network_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Network_filter>>>;
};

export type Network_orderBy =
  | 'id'
  | 'timestamp'
  | 'state'
  | 'state__id'
  | 'state__timestamp'
  | 'state__parties'
  | 'state__investors'
  | 'state__deposits'
  | 'state__totalDeposits'
  | 'state__withdraws'
  | 'state__totalWithdrawals'
  | 'state__withUncalculatedPrices'
  | 'stateHistory';

/** Defines the order direction, either ascending or descending */
export type OrderDirection =
  | 'asc'
  | 'desc';

export type Party = {
  id: Scalars['ID'];
  creator: User;
  name: Scalars['String'];
  bio?: Maybe<Scalars['String']>;
  img?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  minDeposit?: Maybe<Scalars['BigInt']>;
  maxDeposit?: Maybe<Scalars['BigInt']>;
  inception: Scalars['BigInt'];
  denominationAsset: Asset;
  infoUpdatedAt: Scalars['BigInt'];
  isClosed?: Maybe<Scalars['Boolean']>;
  members: Array<UserParty>;
  membersCount: Scalars['Int'];
  requests: Array<JoinRequest>;
  partyTokenGate?: Maybe<PartyTokenGate>;
  trackedAssets: Array<Asset>;
  investments: Array<Investment>;
  investmentCost?: Maybe<InvestmentCost>;
  shares: ShareState;
  sharesHistory: Array<ShareState>;
  portfolio: PortfolioState;
  portfolioHistory: Array<PortfolioState>;
  state: PartyState;
  stateHistory: Array<PartyState>;
  firstInvestmentState?: Maybe<PartyState>;
  lastActivity: Scalars['BigInt'];
  networkId: Scalars['Int'];
  slug: Scalars['String'];
};


export type PartymembersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserParty_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UserParty_filter>;
};


export type PartyrequestsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<JoinRequest_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<JoinRequest_filter>;
};


export type PartytrackedAssetsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Asset_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Asset_filter>;
};


export type PartyinvestmentsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Investment_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Investment_filter>;
};


export type PartysharesHistoryArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ShareState_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ShareState_filter>;
};


export type PartyportfolioHistoryArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PortfolioState_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PortfolioState_filter>;
};


export type PartystateHistoryArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PartyState_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PartyState_filter>;
};

export type PartyActivity = {
  id: Scalars['ID'];
  txHash: Scalars['String'];
  event: PartyEventType;
  timestamp: Scalars['BigInt'];
  incomingAssets?: Maybe<Array<AssetAmount>>;
  outcomingAssets?: Maybe<Array<AssetAmount>>;
  incomingShares?: Maybe<Scalars['BigDecimal']>;
  outcomingShares?: Maybe<Scalars['BigDecimal']>;
  party: Party;
  user: User;
};


export type PartyActivityincomingAssetsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AssetAmount_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AssetAmount_filter>;
};


export type PartyActivityoutcomingAssetsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AssetAmount_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AssetAmount_filter>;
};

export type PartyActivity_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  txHash?: InputMaybe<Scalars['String']>;
  txHash_not?: InputMaybe<Scalars['String']>;
  txHash_gt?: InputMaybe<Scalars['String']>;
  txHash_lt?: InputMaybe<Scalars['String']>;
  txHash_gte?: InputMaybe<Scalars['String']>;
  txHash_lte?: InputMaybe<Scalars['String']>;
  txHash_in?: InputMaybe<Array<Scalars['String']>>;
  txHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  txHash_contains?: InputMaybe<Scalars['String']>;
  txHash_contains_nocase?: InputMaybe<Scalars['String']>;
  txHash_not_contains?: InputMaybe<Scalars['String']>;
  txHash_not_contains_nocase?: InputMaybe<Scalars['String']>;
  txHash_starts_with?: InputMaybe<Scalars['String']>;
  txHash_starts_with_nocase?: InputMaybe<Scalars['String']>;
  txHash_not_starts_with?: InputMaybe<Scalars['String']>;
  txHash_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  txHash_ends_with?: InputMaybe<Scalars['String']>;
  txHash_ends_with_nocase?: InputMaybe<Scalars['String']>;
  txHash_not_ends_with?: InputMaybe<Scalars['String']>;
  txHash_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<PartyEventType>;
  event_not?: InputMaybe<PartyEventType>;
  event_in?: InputMaybe<Array<PartyEventType>>;
  event_not_in?: InputMaybe<Array<PartyEventType>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  incomingAssets?: InputMaybe<Array<Scalars['String']>>;
  incomingAssets_not?: InputMaybe<Array<Scalars['String']>>;
  incomingAssets_contains?: InputMaybe<Array<Scalars['String']>>;
  incomingAssets_contains_nocase?: InputMaybe<Array<Scalars['String']>>;
  incomingAssets_not_contains?: InputMaybe<Array<Scalars['String']>>;
  incomingAssets_not_contains_nocase?: InputMaybe<Array<Scalars['String']>>;
  incomingAssets_?: InputMaybe<AssetAmount_filter>;
  outcomingAssets?: InputMaybe<Array<Scalars['String']>>;
  outcomingAssets_not?: InputMaybe<Array<Scalars['String']>>;
  outcomingAssets_contains?: InputMaybe<Array<Scalars['String']>>;
  outcomingAssets_contains_nocase?: InputMaybe<Array<Scalars['String']>>;
  outcomingAssets_not_contains?: InputMaybe<Array<Scalars['String']>>;
  outcomingAssets_not_contains_nocase?: InputMaybe<Array<Scalars['String']>>;
  outcomingAssets_?: InputMaybe<AssetAmount_filter>;
  incomingShares?: InputMaybe<Scalars['BigDecimal']>;
  incomingShares_not?: InputMaybe<Scalars['BigDecimal']>;
  incomingShares_gt?: InputMaybe<Scalars['BigDecimal']>;
  incomingShares_lt?: InputMaybe<Scalars['BigDecimal']>;
  incomingShares_gte?: InputMaybe<Scalars['BigDecimal']>;
  incomingShares_lte?: InputMaybe<Scalars['BigDecimal']>;
  incomingShares_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  incomingShares_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  outcomingShares?: InputMaybe<Scalars['BigDecimal']>;
  outcomingShares_not?: InputMaybe<Scalars['BigDecimal']>;
  outcomingShares_gt?: InputMaybe<Scalars['BigDecimal']>;
  outcomingShares_lt?: InputMaybe<Scalars['BigDecimal']>;
  outcomingShares_gte?: InputMaybe<Scalars['BigDecimal']>;
  outcomingShares_lte?: InputMaybe<Scalars['BigDecimal']>;
  outcomingShares_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  outcomingShares_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  party?: InputMaybe<Scalars['String']>;
  party_not?: InputMaybe<Scalars['String']>;
  party_gt?: InputMaybe<Scalars['String']>;
  party_lt?: InputMaybe<Scalars['String']>;
  party_gte?: InputMaybe<Scalars['String']>;
  party_lte?: InputMaybe<Scalars['String']>;
  party_in?: InputMaybe<Array<Scalars['String']>>;
  party_not_in?: InputMaybe<Array<Scalars['String']>>;
  party_contains?: InputMaybe<Scalars['String']>;
  party_contains_nocase?: InputMaybe<Scalars['String']>;
  party_not_contains?: InputMaybe<Scalars['String']>;
  party_not_contains_nocase?: InputMaybe<Scalars['String']>;
  party_starts_with?: InputMaybe<Scalars['String']>;
  party_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_starts_with?: InputMaybe<Scalars['String']>;
  party_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_ends_with?: InputMaybe<Scalars['String']>;
  party_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_ends_with?: InputMaybe<Scalars['String']>;
  party_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_?: InputMaybe<Party_filter>;
  user?: InputMaybe<Scalars['String']>;
  user_not?: InputMaybe<Scalars['String']>;
  user_gt?: InputMaybe<Scalars['String']>;
  user_lt?: InputMaybe<Scalars['String']>;
  user_gte?: InputMaybe<Scalars['String']>;
  user_lte?: InputMaybe<Scalars['String']>;
  user_in?: InputMaybe<Array<Scalars['String']>>;
  user_not_in?: InputMaybe<Array<Scalars['String']>>;
  user_contains?: InputMaybe<Scalars['String']>;
  user_contains_nocase?: InputMaybe<Scalars['String']>;
  user_not_contains?: InputMaybe<Scalars['String']>;
  user_not_contains_nocase?: InputMaybe<Scalars['String']>;
  user_starts_with?: InputMaybe<Scalars['String']>;
  user_starts_with_nocase?: InputMaybe<Scalars['String']>;
  user_not_starts_with?: InputMaybe<Scalars['String']>;
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  user_ends_with?: InputMaybe<Scalars['String']>;
  user_ends_with_nocase?: InputMaybe<Scalars['String']>;
  user_not_ends_with?: InputMaybe<Scalars['String']>;
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  user_?: InputMaybe<User_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PartyActivity_filter>>>;
  or?: InputMaybe<Array<InputMaybe<PartyActivity_filter>>>;
};

export type PartyActivity_orderBy =
  | 'id'
  | 'txHash'
  | 'event'
  | 'timestamp'
  | 'incomingAssets'
  | 'outcomingAssets'
  | 'incomingShares'
  | 'outcomingShares'
  | 'party'
  | 'party__id'
  | 'party__name'
  | 'party__bio'
  | 'party__img'
  | 'party__model'
  | 'party__purpose'
  | 'party__isPublic'
  | 'party__minDeposit'
  | 'party__maxDeposit'
  | 'party__inception'
  | 'party__infoUpdatedAt'
  | 'party__isClosed'
  | 'party__membersCount'
  | 'party__lastActivity'
  | 'user'
  | 'user__id'
  | 'user__firstSeen'
  | 'user__partyOwner'
  | 'user__partyOwnerSince'
  | 'user__investor'
  | 'user__investorSince';

export type PartyEventType =
  | 'PartyCreated'
  | 'PartyClosed'
  | 'MemberJoined'
  | 'MemberKicked'
  | 'MemberLeft'
  | 'TokenSwap'
  | 'Deposit'
  | 'Withdraw'
  | 'LimitOrderFill';

export type PartyState = {
  id: Scalars['ID'];
  party: Party;
  timestamp: Scalars['BigInt'];
  shares: ShareState;
  portfolio: PortfolioState;
};

export type PartyState_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  party?: InputMaybe<Scalars['String']>;
  party_not?: InputMaybe<Scalars['String']>;
  party_gt?: InputMaybe<Scalars['String']>;
  party_lt?: InputMaybe<Scalars['String']>;
  party_gte?: InputMaybe<Scalars['String']>;
  party_lte?: InputMaybe<Scalars['String']>;
  party_in?: InputMaybe<Array<Scalars['String']>>;
  party_not_in?: InputMaybe<Array<Scalars['String']>>;
  party_contains?: InputMaybe<Scalars['String']>;
  party_contains_nocase?: InputMaybe<Scalars['String']>;
  party_not_contains?: InputMaybe<Scalars['String']>;
  party_not_contains_nocase?: InputMaybe<Scalars['String']>;
  party_starts_with?: InputMaybe<Scalars['String']>;
  party_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_starts_with?: InputMaybe<Scalars['String']>;
  party_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_ends_with?: InputMaybe<Scalars['String']>;
  party_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_ends_with?: InputMaybe<Scalars['String']>;
  party_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_?: InputMaybe<Party_filter>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  shares?: InputMaybe<Scalars['String']>;
  shares_not?: InputMaybe<Scalars['String']>;
  shares_gt?: InputMaybe<Scalars['String']>;
  shares_lt?: InputMaybe<Scalars['String']>;
  shares_gte?: InputMaybe<Scalars['String']>;
  shares_lte?: InputMaybe<Scalars['String']>;
  shares_in?: InputMaybe<Array<Scalars['String']>>;
  shares_not_in?: InputMaybe<Array<Scalars['String']>>;
  shares_contains?: InputMaybe<Scalars['String']>;
  shares_contains_nocase?: InputMaybe<Scalars['String']>;
  shares_not_contains?: InputMaybe<Scalars['String']>;
  shares_not_contains_nocase?: InputMaybe<Scalars['String']>;
  shares_starts_with?: InputMaybe<Scalars['String']>;
  shares_starts_with_nocase?: InputMaybe<Scalars['String']>;
  shares_not_starts_with?: InputMaybe<Scalars['String']>;
  shares_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  shares_ends_with?: InputMaybe<Scalars['String']>;
  shares_ends_with_nocase?: InputMaybe<Scalars['String']>;
  shares_not_ends_with?: InputMaybe<Scalars['String']>;
  shares_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  shares_?: InputMaybe<ShareState_filter>;
  portfolio?: InputMaybe<Scalars['String']>;
  portfolio_not?: InputMaybe<Scalars['String']>;
  portfolio_gt?: InputMaybe<Scalars['String']>;
  portfolio_lt?: InputMaybe<Scalars['String']>;
  portfolio_gte?: InputMaybe<Scalars['String']>;
  portfolio_lte?: InputMaybe<Scalars['String']>;
  portfolio_in?: InputMaybe<Array<Scalars['String']>>;
  portfolio_not_in?: InputMaybe<Array<Scalars['String']>>;
  portfolio_contains?: InputMaybe<Scalars['String']>;
  portfolio_contains_nocase?: InputMaybe<Scalars['String']>;
  portfolio_not_contains?: InputMaybe<Scalars['String']>;
  portfolio_not_contains_nocase?: InputMaybe<Scalars['String']>;
  portfolio_starts_with?: InputMaybe<Scalars['String']>;
  portfolio_starts_with_nocase?: InputMaybe<Scalars['String']>;
  portfolio_not_starts_with?: InputMaybe<Scalars['String']>;
  portfolio_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  portfolio_ends_with?: InputMaybe<Scalars['String']>;
  portfolio_ends_with_nocase?: InputMaybe<Scalars['String']>;
  portfolio_not_ends_with?: InputMaybe<Scalars['String']>;
  portfolio_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  portfolio_?: InputMaybe<PortfolioState_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PartyState_filter>>>;
  or?: InputMaybe<Array<InputMaybe<PartyState_filter>>>;
};

export type PartyState_orderBy =
  | 'id'
  | 'party'
  | 'party__id'
  | 'party__name'
  | 'party__bio'
  | 'party__img'
  | 'party__model'
  | 'party__purpose'
  | 'party__isPublic'
  | 'party__minDeposit'
  | 'party__maxDeposit'
  | 'party__inception'
  | 'party__infoUpdatedAt'
  | 'party__isClosed'
  | 'party__membersCount'
  | 'party__lastActivity'
  | 'timestamp'
  | 'shares'
  | 'shares__id'
  | 'shares__isLatest'
  | 'shares__timestamp'
  | 'shares__blockNumber'
  | 'shares__totalSupply'
  | 'portfolio'
  | 'portfolio__id'
  | 'portfolio__isLatest'
  | 'portfolio__timestamp'
  | 'portfolio__blockNumber'
  | 'portfolio__value'
  | 'portfolio__withUncalculatedPrices';

export type PartyTokenGate = {
  id: Scalars['Bytes'];
  party: Party;
  tokens?: Maybe<Array<TokenGate>>;
  updatedAt: Scalars['BigInt'];
};


export type PartyTokenGatetokensArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TokenGate_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenGate_filter>;
};

export type PartyTokenGate_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  party?: InputMaybe<Scalars['String']>;
  party_not?: InputMaybe<Scalars['String']>;
  party_gt?: InputMaybe<Scalars['String']>;
  party_lt?: InputMaybe<Scalars['String']>;
  party_gte?: InputMaybe<Scalars['String']>;
  party_lte?: InputMaybe<Scalars['String']>;
  party_in?: InputMaybe<Array<Scalars['String']>>;
  party_not_in?: InputMaybe<Array<Scalars['String']>>;
  party_contains?: InputMaybe<Scalars['String']>;
  party_contains_nocase?: InputMaybe<Scalars['String']>;
  party_not_contains?: InputMaybe<Scalars['String']>;
  party_not_contains_nocase?: InputMaybe<Scalars['String']>;
  party_starts_with?: InputMaybe<Scalars['String']>;
  party_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_starts_with?: InputMaybe<Scalars['String']>;
  party_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_ends_with?: InputMaybe<Scalars['String']>;
  party_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_ends_with?: InputMaybe<Scalars['String']>;
  party_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_?: InputMaybe<Party_filter>;
  tokens_?: InputMaybe<TokenGate_filter>;
  updatedAt?: InputMaybe<Scalars['BigInt']>;
  updatedAt_not?: InputMaybe<Scalars['BigInt']>;
  updatedAt_gt?: InputMaybe<Scalars['BigInt']>;
  updatedAt_lt?: InputMaybe<Scalars['BigInt']>;
  updatedAt_gte?: InputMaybe<Scalars['BigInt']>;
  updatedAt_lte?: InputMaybe<Scalars['BigInt']>;
  updatedAt_in?: InputMaybe<Array<Scalars['BigInt']>>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PartyTokenGate_filter>>>;
  or?: InputMaybe<Array<InputMaybe<PartyTokenGate_filter>>>;
};

export type PartyTokenGate_orderBy =
  | 'id'
  | 'party'
  | 'party__id'
  | 'party__name'
  | 'party__bio'
  | 'party__img'
  | 'party__model'
  | 'party__purpose'
  | 'party__isPublic'
  | 'party__minDeposit'
  | 'party__maxDeposit'
  | 'party__inception'
  | 'party__infoUpdatedAt'
  | 'party__isClosed'
  | 'party__membersCount'
  | 'party__lastActivity'
  | 'tokens'
  | 'updatedAt';

export type Party_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  creator?: InputMaybe<Scalars['String']>;
  creator_not?: InputMaybe<Scalars['String']>;
  creator_gt?: InputMaybe<Scalars['String']>;
  creator_lt?: InputMaybe<Scalars['String']>;
  creator_gte?: InputMaybe<Scalars['String']>;
  creator_lte?: InputMaybe<Scalars['String']>;
  creator_in?: InputMaybe<Array<Scalars['String']>>;
  creator_not_in?: InputMaybe<Array<Scalars['String']>>;
  creator_contains?: InputMaybe<Scalars['String']>;
  creator_contains_nocase?: InputMaybe<Scalars['String']>;
  creator_not_contains?: InputMaybe<Scalars['String']>;
  creator_not_contains_nocase?: InputMaybe<Scalars['String']>;
  creator_starts_with?: InputMaybe<Scalars['String']>;
  creator_starts_with_nocase?: InputMaybe<Scalars['String']>;
  creator_not_starts_with?: InputMaybe<Scalars['String']>;
  creator_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  creator_ends_with?: InputMaybe<Scalars['String']>;
  creator_ends_with_nocase?: InputMaybe<Scalars['String']>;
  creator_not_ends_with?: InputMaybe<Scalars['String']>;
  creator_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  creator_?: InputMaybe<User_filter>;
  name?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_contains_nocase?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  bio_not?: InputMaybe<Scalars['String']>;
  bio_gt?: InputMaybe<Scalars['String']>;
  bio_lt?: InputMaybe<Scalars['String']>;
  bio_gte?: InputMaybe<Scalars['String']>;
  bio_lte?: InputMaybe<Scalars['String']>;
  bio_in?: InputMaybe<Array<Scalars['String']>>;
  bio_not_in?: InputMaybe<Array<Scalars['String']>>;
  bio_contains?: InputMaybe<Scalars['String']>;
  bio_contains_nocase?: InputMaybe<Scalars['String']>;
  bio_not_contains?: InputMaybe<Scalars['String']>;
  bio_not_contains_nocase?: InputMaybe<Scalars['String']>;
  bio_starts_with?: InputMaybe<Scalars['String']>;
  bio_starts_with_nocase?: InputMaybe<Scalars['String']>;
  bio_not_starts_with?: InputMaybe<Scalars['String']>;
  bio_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  bio_ends_with?: InputMaybe<Scalars['String']>;
  bio_ends_with_nocase?: InputMaybe<Scalars['String']>;
  bio_not_ends_with?: InputMaybe<Scalars['String']>;
  bio_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  img?: InputMaybe<Scalars['String']>;
  img_not?: InputMaybe<Scalars['String']>;
  img_gt?: InputMaybe<Scalars['String']>;
  img_lt?: InputMaybe<Scalars['String']>;
  img_gte?: InputMaybe<Scalars['String']>;
  img_lte?: InputMaybe<Scalars['String']>;
  img_in?: InputMaybe<Array<Scalars['String']>>;
  img_not_in?: InputMaybe<Array<Scalars['String']>>;
  img_contains?: InputMaybe<Scalars['String']>;
  img_contains_nocase?: InputMaybe<Scalars['String']>;
  img_not_contains?: InputMaybe<Scalars['String']>;
  img_not_contains_nocase?: InputMaybe<Scalars['String']>;
  img_starts_with?: InputMaybe<Scalars['String']>;
  img_starts_with_nocase?: InputMaybe<Scalars['String']>;
  img_not_starts_with?: InputMaybe<Scalars['String']>;
  img_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  img_ends_with?: InputMaybe<Scalars['String']>;
  img_ends_with_nocase?: InputMaybe<Scalars['String']>;
  img_not_ends_with?: InputMaybe<Scalars['String']>;
  img_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  model_not?: InputMaybe<Scalars['String']>;
  model_gt?: InputMaybe<Scalars['String']>;
  model_lt?: InputMaybe<Scalars['String']>;
  model_gte?: InputMaybe<Scalars['String']>;
  model_lte?: InputMaybe<Scalars['String']>;
  model_in?: InputMaybe<Array<Scalars['String']>>;
  model_not_in?: InputMaybe<Array<Scalars['String']>>;
  model_contains?: InputMaybe<Scalars['String']>;
  model_contains_nocase?: InputMaybe<Scalars['String']>;
  model_not_contains?: InputMaybe<Scalars['String']>;
  model_not_contains_nocase?: InputMaybe<Scalars['String']>;
  model_starts_with?: InputMaybe<Scalars['String']>;
  model_starts_with_nocase?: InputMaybe<Scalars['String']>;
  model_not_starts_with?: InputMaybe<Scalars['String']>;
  model_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  model_ends_with?: InputMaybe<Scalars['String']>;
  model_ends_with_nocase?: InputMaybe<Scalars['String']>;
  model_not_ends_with?: InputMaybe<Scalars['String']>;
  model_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  purpose?: InputMaybe<Scalars['String']>;
  purpose_not?: InputMaybe<Scalars['String']>;
  purpose_gt?: InputMaybe<Scalars['String']>;
  purpose_lt?: InputMaybe<Scalars['String']>;
  purpose_gte?: InputMaybe<Scalars['String']>;
  purpose_lte?: InputMaybe<Scalars['String']>;
  purpose_in?: InputMaybe<Array<Scalars['String']>>;
  purpose_not_in?: InputMaybe<Array<Scalars['String']>>;
  purpose_contains?: InputMaybe<Scalars['String']>;
  purpose_contains_nocase?: InputMaybe<Scalars['String']>;
  purpose_not_contains?: InputMaybe<Scalars['String']>;
  purpose_not_contains_nocase?: InputMaybe<Scalars['String']>;
  purpose_starts_with?: InputMaybe<Scalars['String']>;
  purpose_starts_with_nocase?: InputMaybe<Scalars['String']>;
  purpose_not_starts_with?: InputMaybe<Scalars['String']>;
  purpose_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  purpose_ends_with?: InputMaybe<Scalars['String']>;
  purpose_ends_with_nocase?: InputMaybe<Scalars['String']>;
  purpose_not_ends_with?: InputMaybe<Scalars['String']>;
  purpose_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  isPublic_not?: InputMaybe<Scalars['Boolean']>;
  isPublic_in?: InputMaybe<Array<Scalars['Boolean']>>;
  isPublic_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  minDeposit?: InputMaybe<Scalars['BigInt']>;
  minDeposit_not?: InputMaybe<Scalars['BigInt']>;
  minDeposit_gt?: InputMaybe<Scalars['BigInt']>;
  minDeposit_lt?: InputMaybe<Scalars['BigInt']>;
  minDeposit_gte?: InputMaybe<Scalars['BigInt']>;
  minDeposit_lte?: InputMaybe<Scalars['BigInt']>;
  minDeposit_in?: InputMaybe<Array<Scalars['BigInt']>>;
  minDeposit_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxDeposit?: InputMaybe<Scalars['BigInt']>;
  maxDeposit_not?: InputMaybe<Scalars['BigInt']>;
  maxDeposit_gt?: InputMaybe<Scalars['BigInt']>;
  maxDeposit_lt?: InputMaybe<Scalars['BigInt']>;
  maxDeposit_gte?: InputMaybe<Scalars['BigInt']>;
  maxDeposit_lte?: InputMaybe<Scalars['BigInt']>;
  maxDeposit_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxDeposit_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  inception?: InputMaybe<Scalars['BigInt']>;
  inception_not?: InputMaybe<Scalars['BigInt']>;
  inception_gt?: InputMaybe<Scalars['BigInt']>;
  inception_lt?: InputMaybe<Scalars['BigInt']>;
  inception_gte?: InputMaybe<Scalars['BigInt']>;
  inception_lte?: InputMaybe<Scalars['BigInt']>;
  inception_in?: InputMaybe<Array<Scalars['BigInt']>>;
  inception_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  denominationAsset?: InputMaybe<Scalars['String']>;
  denominationAsset_not?: InputMaybe<Scalars['String']>;
  denominationAsset_gt?: InputMaybe<Scalars['String']>;
  denominationAsset_lt?: InputMaybe<Scalars['String']>;
  denominationAsset_gte?: InputMaybe<Scalars['String']>;
  denominationAsset_lte?: InputMaybe<Scalars['String']>;
  denominationAsset_in?: InputMaybe<Array<Scalars['String']>>;
  denominationAsset_not_in?: InputMaybe<Array<Scalars['String']>>;
  denominationAsset_contains?: InputMaybe<Scalars['String']>;
  denominationAsset_contains_nocase?: InputMaybe<Scalars['String']>;
  denominationAsset_not_contains?: InputMaybe<Scalars['String']>;
  denominationAsset_not_contains_nocase?: InputMaybe<Scalars['String']>;
  denominationAsset_starts_with?: InputMaybe<Scalars['String']>;
  denominationAsset_starts_with_nocase?: InputMaybe<Scalars['String']>;
  denominationAsset_not_starts_with?: InputMaybe<Scalars['String']>;
  denominationAsset_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  denominationAsset_ends_with?: InputMaybe<Scalars['String']>;
  denominationAsset_ends_with_nocase?: InputMaybe<Scalars['String']>;
  denominationAsset_not_ends_with?: InputMaybe<Scalars['String']>;
  denominationAsset_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  denominationAsset_?: InputMaybe<Asset_filter>;
  infoUpdatedAt?: InputMaybe<Scalars['BigInt']>;
  infoUpdatedAt_not?: InputMaybe<Scalars['BigInt']>;
  infoUpdatedAt_gt?: InputMaybe<Scalars['BigInt']>;
  infoUpdatedAt_lt?: InputMaybe<Scalars['BigInt']>;
  infoUpdatedAt_gte?: InputMaybe<Scalars['BigInt']>;
  infoUpdatedAt_lte?: InputMaybe<Scalars['BigInt']>;
  infoUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']>>;
  infoUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  isClosed_not?: InputMaybe<Scalars['Boolean']>;
  isClosed_in?: InputMaybe<Array<Scalars['Boolean']>>;
  isClosed_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  members_?: InputMaybe<UserParty_filter>;
  membersCount?: InputMaybe<Scalars['Int']>;
  membersCount_not?: InputMaybe<Scalars['Int']>;
  membersCount_gt?: InputMaybe<Scalars['Int']>;
  membersCount_lt?: InputMaybe<Scalars['Int']>;
  membersCount_gte?: InputMaybe<Scalars['Int']>;
  membersCount_lte?: InputMaybe<Scalars['Int']>;
  membersCount_in?: InputMaybe<Array<Scalars['Int']>>;
  membersCount_not_in?: InputMaybe<Array<Scalars['Int']>>;
  requests_?: InputMaybe<JoinRequest_filter>;
  partyTokenGate_?: InputMaybe<PartyTokenGate_filter>;
  trackedAssets?: InputMaybe<Array<Scalars['String']>>;
  trackedAssets_not?: InputMaybe<Array<Scalars['String']>>;
  trackedAssets_contains?: InputMaybe<Array<Scalars['String']>>;
  trackedAssets_contains_nocase?: InputMaybe<Array<Scalars['String']>>;
  trackedAssets_not_contains?: InputMaybe<Array<Scalars['String']>>;
  trackedAssets_not_contains_nocase?: InputMaybe<Array<Scalars['String']>>;
  trackedAssets_?: InputMaybe<Asset_filter>;
  investments_?: InputMaybe<Investment_filter>;
  investmentCost_?: InputMaybe<InvestmentCost_filter>;
  shares?: InputMaybe<Scalars['String']>;
  shares_not?: InputMaybe<Scalars['String']>;
  shares_gt?: InputMaybe<Scalars['String']>;
  shares_lt?: InputMaybe<Scalars['String']>;
  shares_gte?: InputMaybe<Scalars['String']>;
  shares_lte?: InputMaybe<Scalars['String']>;
  shares_in?: InputMaybe<Array<Scalars['String']>>;
  shares_not_in?: InputMaybe<Array<Scalars['String']>>;
  shares_contains?: InputMaybe<Scalars['String']>;
  shares_contains_nocase?: InputMaybe<Scalars['String']>;
  shares_not_contains?: InputMaybe<Scalars['String']>;
  shares_not_contains_nocase?: InputMaybe<Scalars['String']>;
  shares_starts_with?: InputMaybe<Scalars['String']>;
  shares_starts_with_nocase?: InputMaybe<Scalars['String']>;
  shares_not_starts_with?: InputMaybe<Scalars['String']>;
  shares_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  shares_ends_with?: InputMaybe<Scalars['String']>;
  shares_ends_with_nocase?: InputMaybe<Scalars['String']>;
  shares_not_ends_with?: InputMaybe<Scalars['String']>;
  shares_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  shares_?: InputMaybe<ShareState_filter>;
  sharesHistory_?: InputMaybe<ShareState_filter>;
  portfolio?: InputMaybe<Scalars['String']>;
  portfolio_not?: InputMaybe<Scalars['String']>;
  portfolio_gt?: InputMaybe<Scalars['String']>;
  portfolio_lt?: InputMaybe<Scalars['String']>;
  portfolio_gte?: InputMaybe<Scalars['String']>;
  portfolio_lte?: InputMaybe<Scalars['String']>;
  portfolio_in?: InputMaybe<Array<Scalars['String']>>;
  portfolio_not_in?: InputMaybe<Array<Scalars['String']>>;
  portfolio_contains?: InputMaybe<Scalars['String']>;
  portfolio_contains_nocase?: InputMaybe<Scalars['String']>;
  portfolio_not_contains?: InputMaybe<Scalars['String']>;
  portfolio_not_contains_nocase?: InputMaybe<Scalars['String']>;
  portfolio_starts_with?: InputMaybe<Scalars['String']>;
  portfolio_starts_with_nocase?: InputMaybe<Scalars['String']>;
  portfolio_not_starts_with?: InputMaybe<Scalars['String']>;
  portfolio_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  portfolio_ends_with?: InputMaybe<Scalars['String']>;
  portfolio_ends_with_nocase?: InputMaybe<Scalars['String']>;
  portfolio_not_ends_with?: InputMaybe<Scalars['String']>;
  portfolio_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  portfolio_?: InputMaybe<PortfolioState_filter>;
  portfolioHistory_?: InputMaybe<PortfolioState_filter>;
  state?: InputMaybe<Scalars['String']>;
  state_not?: InputMaybe<Scalars['String']>;
  state_gt?: InputMaybe<Scalars['String']>;
  state_lt?: InputMaybe<Scalars['String']>;
  state_gte?: InputMaybe<Scalars['String']>;
  state_lte?: InputMaybe<Scalars['String']>;
  state_in?: InputMaybe<Array<Scalars['String']>>;
  state_not_in?: InputMaybe<Array<Scalars['String']>>;
  state_contains?: InputMaybe<Scalars['String']>;
  state_contains_nocase?: InputMaybe<Scalars['String']>;
  state_not_contains?: InputMaybe<Scalars['String']>;
  state_not_contains_nocase?: InputMaybe<Scalars['String']>;
  state_starts_with?: InputMaybe<Scalars['String']>;
  state_starts_with_nocase?: InputMaybe<Scalars['String']>;
  state_not_starts_with?: InputMaybe<Scalars['String']>;
  state_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  state_ends_with?: InputMaybe<Scalars['String']>;
  state_ends_with_nocase?: InputMaybe<Scalars['String']>;
  state_not_ends_with?: InputMaybe<Scalars['String']>;
  state_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  state_?: InputMaybe<PartyState_filter>;
  stateHistory_?: InputMaybe<PartyState_filter>;
  firstInvestmentState?: InputMaybe<Scalars['String']>;
  firstInvestmentState_not?: InputMaybe<Scalars['String']>;
  firstInvestmentState_gt?: InputMaybe<Scalars['String']>;
  firstInvestmentState_lt?: InputMaybe<Scalars['String']>;
  firstInvestmentState_gte?: InputMaybe<Scalars['String']>;
  firstInvestmentState_lte?: InputMaybe<Scalars['String']>;
  firstInvestmentState_in?: InputMaybe<Array<Scalars['String']>>;
  firstInvestmentState_not_in?: InputMaybe<Array<Scalars['String']>>;
  firstInvestmentState_contains?: InputMaybe<Scalars['String']>;
  firstInvestmentState_contains_nocase?: InputMaybe<Scalars['String']>;
  firstInvestmentState_not_contains?: InputMaybe<Scalars['String']>;
  firstInvestmentState_not_contains_nocase?: InputMaybe<Scalars['String']>;
  firstInvestmentState_starts_with?: InputMaybe<Scalars['String']>;
  firstInvestmentState_starts_with_nocase?: InputMaybe<Scalars['String']>;
  firstInvestmentState_not_starts_with?: InputMaybe<Scalars['String']>;
  firstInvestmentState_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  firstInvestmentState_ends_with?: InputMaybe<Scalars['String']>;
  firstInvestmentState_ends_with_nocase?: InputMaybe<Scalars['String']>;
  firstInvestmentState_not_ends_with?: InputMaybe<Scalars['String']>;
  firstInvestmentState_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  firstInvestmentState_?: InputMaybe<PartyState_filter>;
  lastActivity?: InputMaybe<Scalars['BigInt']>;
  lastActivity_not?: InputMaybe<Scalars['BigInt']>;
  lastActivity_gt?: InputMaybe<Scalars['BigInt']>;
  lastActivity_lt?: InputMaybe<Scalars['BigInt']>;
  lastActivity_gte?: InputMaybe<Scalars['BigInt']>;
  lastActivity_lte?: InputMaybe<Scalars['BigInt']>;
  lastActivity_in?: InputMaybe<Array<Scalars['BigInt']>>;
  lastActivity_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Party_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Party_filter>>>;
};

export type Party_orderBy =
  | 'id'
  | 'creator'
  | 'creator__id'
  | 'creator__firstSeen'
  | 'creator__partyOwner'
  | 'creator__partyOwnerSince'
  | 'creator__investor'
  | 'creator__investorSince'
  | 'name'
  | 'bio'
  | 'img'
  | 'model'
  | 'purpose'
  | 'isPublic'
  | 'minDeposit'
  | 'maxDeposit'
  | 'inception'
  | 'denominationAsset'
  | 'denominationAsset__id'
  | 'denominationAsset__name'
  | 'denominationAsset__symbol'
  | 'denominationAsset__decimals'
  | 'denominationAsset__type'
  | 'denominationAsset__firstSeen'
  | 'infoUpdatedAt'
  | 'isClosed'
  | 'members'
  | 'membersCount'
  | 'requests'
  | 'partyTokenGate'
  | 'partyTokenGate__id'
  | 'partyTokenGate__updatedAt'
  | 'trackedAssets'
  | 'investments'
  | 'investmentCost'
  | 'investmentCost__id'
  | 'investmentCost__latestTimestamp'
  | 'investmentCost__latestBlockNumber'
  | 'investmentCost__depositCount'
  | 'investmentCost__totalDeposits'
  | 'investmentCost__withdrawalCount'
  | 'investmentCost__totalWithdrawals'
  | 'investmentCost__value'
  | 'investmentCost__withUncalculatedPrices'
  | 'shares'
  | 'shares__id'
  | 'shares__isLatest'
  | 'shares__timestamp'
  | 'shares__blockNumber'
  | 'shares__totalSupply'
  | 'sharesHistory'
  | 'portfolio'
  | 'portfolio__id'
  | 'portfolio__isLatest'
  | 'portfolio__timestamp'
  | 'portfolio__blockNumber'
  | 'portfolio__value'
  | 'portfolio__withUncalculatedPrices'
  | 'portfolioHistory'
  | 'state'
  | 'state__id'
  | 'state__timestamp'
  | 'stateHistory'
  | 'firstInvestmentState'
  | 'firstInvestmentState__id'
  | 'firstInvestmentState__timestamp'
  | 'lastActivity';

export type PortfolioState = {
  id: Scalars['ID'];
  party: Party;
  isLatest: Scalars['Boolean'];
  timestamp: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
  holdings: Array<HoldingState>;
  value: Scalars['BigDecimal'];
  withUncalculatedPrices: Scalars['Boolean'];
  networkId: Scalars['Int'];
  partySlug: Scalars['String'];
};


export type PortfolioStateholdingsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HoldingState_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<HoldingState_filter>;
};

export type PortfolioState_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  party?: InputMaybe<Scalars['String']>;
  party_not?: InputMaybe<Scalars['String']>;
  party_gt?: InputMaybe<Scalars['String']>;
  party_lt?: InputMaybe<Scalars['String']>;
  party_gte?: InputMaybe<Scalars['String']>;
  party_lte?: InputMaybe<Scalars['String']>;
  party_in?: InputMaybe<Array<Scalars['String']>>;
  party_not_in?: InputMaybe<Array<Scalars['String']>>;
  party_contains?: InputMaybe<Scalars['String']>;
  party_contains_nocase?: InputMaybe<Scalars['String']>;
  party_not_contains?: InputMaybe<Scalars['String']>;
  party_not_contains_nocase?: InputMaybe<Scalars['String']>;
  party_starts_with?: InputMaybe<Scalars['String']>;
  party_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_starts_with?: InputMaybe<Scalars['String']>;
  party_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_ends_with?: InputMaybe<Scalars['String']>;
  party_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_ends_with?: InputMaybe<Scalars['String']>;
  party_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_?: InputMaybe<Party_filter>;
  isLatest?: InputMaybe<Scalars['Boolean']>;
  isLatest_not?: InputMaybe<Scalars['Boolean']>;
  isLatest_in?: InputMaybe<Array<Scalars['Boolean']>>;
  isLatest_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  holdings?: InputMaybe<Array<Scalars['String']>>;
  holdings_not?: InputMaybe<Array<Scalars['String']>>;
  holdings_contains?: InputMaybe<Array<Scalars['String']>>;
  holdings_contains_nocase?: InputMaybe<Array<Scalars['String']>>;
  holdings_not_contains?: InputMaybe<Array<Scalars['String']>>;
  holdings_not_contains_nocase?: InputMaybe<Array<Scalars['String']>>;
  holdings_?: InputMaybe<HoldingState_filter>;
  value?: InputMaybe<Scalars['BigDecimal']>;
  value_not?: InputMaybe<Scalars['BigDecimal']>;
  value_gt?: InputMaybe<Scalars['BigDecimal']>;
  value_lt?: InputMaybe<Scalars['BigDecimal']>;
  value_gte?: InputMaybe<Scalars['BigDecimal']>;
  value_lte?: InputMaybe<Scalars['BigDecimal']>;
  value_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  value_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  withUncalculatedPrices?: InputMaybe<Scalars['Boolean']>;
  withUncalculatedPrices_not?: InputMaybe<Scalars['Boolean']>;
  withUncalculatedPrices_in?: InputMaybe<Array<Scalars['Boolean']>>;
  withUncalculatedPrices_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PortfolioState_filter>>>;
  or?: InputMaybe<Array<InputMaybe<PortfolioState_filter>>>;
};

export type PortfolioState_orderBy =
  | 'id'
  | 'party'
  | 'party__id'
  | 'party__name'
  | 'party__bio'
  | 'party__img'
  | 'party__model'
  | 'party__purpose'
  | 'party__isPublic'
  | 'party__minDeposit'
  | 'party__maxDeposit'
  | 'party__inception'
  | 'party__infoUpdatedAt'
  | 'party__isClosed'
  | 'party__membersCount'
  | 'party__lastActivity'
  | 'isLatest'
  | 'timestamp'
  | 'blockNumber'
  | 'holdings'
  | 'value'
  | 'withUncalculatedPrices';

export type ShareChangeType =
  | 'SharesBought'
  | 'SharesRedeemed';

export type ShareState = {
  id: Scalars['ID'];
  party: Party;
  isLatest: Scalars['Boolean'];
  timestamp: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
  totalSupply: Scalars['BigDecimal'];
};

export type ShareState_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  party?: InputMaybe<Scalars['String']>;
  party_not?: InputMaybe<Scalars['String']>;
  party_gt?: InputMaybe<Scalars['String']>;
  party_lt?: InputMaybe<Scalars['String']>;
  party_gte?: InputMaybe<Scalars['String']>;
  party_lte?: InputMaybe<Scalars['String']>;
  party_in?: InputMaybe<Array<Scalars['String']>>;
  party_not_in?: InputMaybe<Array<Scalars['String']>>;
  party_contains?: InputMaybe<Scalars['String']>;
  party_contains_nocase?: InputMaybe<Scalars['String']>;
  party_not_contains?: InputMaybe<Scalars['String']>;
  party_not_contains_nocase?: InputMaybe<Scalars['String']>;
  party_starts_with?: InputMaybe<Scalars['String']>;
  party_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_starts_with?: InputMaybe<Scalars['String']>;
  party_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_ends_with?: InputMaybe<Scalars['String']>;
  party_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_ends_with?: InputMaybe<Scalars['String']>;
  party_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_?: InputMaybe<Party_filter>;
  isLatest?: InputMaybe<Scalars['Boolean']>;
  isLatest_not?: InputMaybe<Scalars['Boolean']>;
  isLatest_in?: InputMaybe<Array<Scalars['Boolean']>>;
  isLatest_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  totalSupply?: InputMaybe<Scalars['BigDecimal']>;
  totalSupply_not?: InputMaybe<Scalars['BigDecimal']>;
  totalSupply_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalSupply_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalSupply_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalSupply_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalSupply_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalSupply_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ShareState_filter>>>;
  or?: InputMaybe<Array<InputMaybe<ShareState_filter>>>;
};

export type ShareState_orderBy =
  | 'id'
  | 'party'
  | 'party__id'
  | 'party__name'
  | 'party__bio'
  | 'party__img'
  | 'party__model'
  | 'party__purpose'
  | 'party__isPublic'
  | 'party__minDeposit'
  | 'party__maxDeposit'
  | 'party__inception'
  | 'party__infoUpdatedAt'
  | 'party__isClosed'
  | 'party__membersCount'
  | 'party__lastActivity'
  | 'isLatest'
  | 'timestamp'
  | 'blockNumber'
  | 'totalSupply';

export type SharesBoughtEvent = {
  id: Scalars['ID'];
  party: Party;
  investmentCost: InvestmentCost;
  type: ShareChangeType;
  investor: User;
  investmentState: InvestmentState;
  shares: Scalars['BigDecimal'];
  asset: Asset;
  value: Scalars['BigDecimal'];
  fee: Scalars['BigDecimal'];
  withUncalculatedPrices: Scalars['Boolean'];
  investmentAmount: Scalars['BigDecimal'];
  feeAmount: Scalars['BigDecimal'];
  sharesIssued: Scalars['BigDecimal'];
  timestamp: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
  partyState: PartyState;
};

export type SharesBoughtEvent_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  party?: InputMaybe<Scalars['String']>;
  party_not?: InputMaybe<Scalars['String']>;
  party_gt?: InputMaybe<Scalars['String']>;
  party_lt?: InputMaybe<Scalars['String']>;
  party_gte?: InputMaybe<Scalars['String']>;
  party_lte?: InputMaybe<Scalars['String']>;
  party_in?: InputMaybe<Array<Scalars['String']>>;
  party_not_in?: InputMaybe<Array<Scalars['String']>>;
  party_contains?: InputMaybe<Scalars['String']>;
  party_contains_nocase?: InputMaybe<Scalars['String']>;
  party_not_contains?: InputMaybe<Scalars['String']>;
  party_not_contains_nocase?: InputMaybe<Scalars['String']>;
  party_starts_with?: InputMaybe<Scalars['String']>;
  party_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_starts_with?: InputMaybe<Scalars['String']>;
  party_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_ends_with?: InputMaybe<Scalars['String']>;
  party_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_ends_with?: InputMaybe<Scalars['String']>;
  party_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_?: InputMaybe<Party_filter>;
  investmentCost?: InputMaybe<Scalars['String']>;
  investmentCost_not?: InputMaybe<Scalars['String']>;
  investmentCost_gt?: InputMaybe<Scalars['String']>;
  investmentCost_lt?: InputMaybe<Scalars['String']>;
  investmentCost_gte?: InputMaybe<Scalars['String']>;
  investmentCost_lte?: InputMaybe<Scalars['String']>;
  investmentCost_in?: InputMaybe<Array<Scalars['String']>>;
  investmentCost_not_in?: InputMaybe<Array<Scalars['String']>>;
  investmentCost_contains?: InputMaybe<Scalars['String']>;
  investmentCost_contains_nocase?: InputMaybe<Scalars['String']>;
  investmentCost_not_contains?: InputMaybe<Scalars['String']>;
  investmentCost_not_contains_nocase?: InputMaybe<Scalars['String']>;
  investmentCost_starts_with?: InputMaybe<Scalars['String']>;
  investmentCost_starts_with_nocase?: InputMaybe<Scalars['String']>;
  investmentCost_not_starts_with?: InputMaybe<Scalars['String']>;
  investmentCost_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  investmentCost_ends_with?: InputMaybe<Scalars['String']>;
  investmentCost_ends_with_nocase?: InputMaybe<Scalars['String']>;
  investmentCost_not_ends_with?: InputMaybe<Scalars['String']>;
  investmentCost_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  investmentCost_?: InputMaybe<InvestmentCost_filter>;
  type?: InputMaybe<ShareChangeType>;
  type_not?: InputMaybe<ShareChangeType>;
  type_in?: InputMaybe<Array<ShareChangeType>>;
  type_not_in?: InputMaybe<Array<ShareChangeType>>;
  investor?: InputMaybe<Scalars['String']>;
  investor_not?: InputMaybe<Scalars['String']>;
  investor_gt?: InputMaybe<Scalars['String']>;
  investor_lt?: InputMaybe<Scalars['String']>;
  investor_gte?: InputMaybe<Scalars['String']>;
  investor_lte?: InputMaybe<Scalars['String']>;
  investor_in?: InputMaybe<Array<Scalars['String']>>;
  investor_not_in?: InputMaybe<Array<Scalars['String']>>;
  investor_contains?: InputMaybe<Scalars['String']>;
  investor_contains_nocase?: InputMaybe<Scalars['String']>;
  investor_not_contains?: InputMaybe<Scalars['String']>;
  investor_not_contains_nocase?: InputMaybe<Scalars['String']>;
  investor_starts_with?: InputMaybe<Scalars['String']>;
  investor_starts_with_nocase?: InputMaybe<Scalars['String']>;
  investor_not_starts_with?: InputMaybe<Scalars['String']>;
  investor_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  investor_ends_with?: InputMaybe<Scalars['String']>;
  investor_ends_with_nocase?: InputMaybe<Scalars['String']>;
  investor_not_ends_with?: InputMaybe<Scalars['String']>;
  investor_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  investor_?: InputMaybe<User_filter>;
  investmentState?: InputMaybe<Scalars['String']>;
  investmentState_not?: InputMaybe<Scalars['String']>;
  investmentState_gt?: InputMaybe<Scalars['String']>;
  investmentState_lt?: InputMaybe<Scalars['String']>;
  investmentState_gte?: InputMaybe<Scalars['String']>;
  investmentState_lte?: InputMaybe<Scalars['String']>;
  investmentState_in?: InputMaybe<Array<Scalars['String']>>;
  investmentState_not_in?: InputMaybe<Array<Scalars['String']>>;
  investmentState_contains?: InputMaybe<Scalars['String']>;
  investmentState_contains_nocase?: InputMaybe<Scalars['String']>;
  investmentState_not_contains?: InputMaybe<Scalars['String']>;
  investmentState_not_contains_nocase?: InputMaybe<Scalars['String']>;
  investmentState_starts_with?: InputMaybe<Scalars['String']>;
  investmentState_starts_with_nocase?: InputMaybe<Scalars['String']>;
  investmentState_not_starts_with?: InputMaybe<Scalars['String']>;
  investmentState_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  investmentState_ends_with?: InputMaybe<Scalars['String']>;
  investmentState_ends_with_nocase?: InputMaybe<Scalars['String']>;
  investmentState_not_ends_with?: InputMaybe<Scalars['String']>;
  investmentState_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  investmentState_?: InputMaybe<InvestmentState_filter>;
  shares?: InputMaybe<Scalars['BigDecimal']>;
  shares_not?: InputMaybe<Scalars['BigDecimal']>;
  shares_gt?: InputMaybe<Scalars['BigDecimal']>;
  shares_lt?: InputMaybe<Scalars['BigDecimal']>;
  shares_gte?: InputMaybe<Scalars['BigDecimal']>;
  shares_lte?: InputMaybe<Scalars['BigDecimal']>;
  shares_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  shares_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  asset?: InputMaybe<Scalars['String']>;
  asset_not?: InputMaybe<Scalars['String']>;
  asset_gt?: InputMaybe<Scalars['String']>;
  asset_lt?: InputMaybe<Scalars['String']>;
  asset_gte?: InputMaybe<Scalars['String']>;
  asset_lte?: InputMaybe<Scalars['String']>;
  asset_in?: InputMaybe<Array<Scalars['String']>>;
  asset_not_in?: InputMaybe<Array<Scalars['String']>>;
  asset_contains?: InputMaybe<Scalars['String']>;
  asset_contains_nocase?: InputMaybe<Scalars['String']>;
  asset_not_contains?: InputMaybe<Scalars['String']>;
  asset_not_contains_nocase?: InputMaybe<Scalars['String']>;
  asset_starts_with?: InputMaybe<Scalars['String']>;
  asset_starts_with_nocase?: InputMaybe<Scalars['String']>;
  asset_not_starts_with?: InputMaybe<Scalars['String']>;
  asset_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  asset_ends_with?: InputMaybe<Scalars['String']>;
  asset_ends_with_nocase?: InputMaybe<Scalars['String']>;
  asset_not_ends_with?: InputMaybe<Scalars['String']>;
  asset_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  asset_?: InputMaybe<Asset_filter>;
  value?: InputMaybe<Scalars['BigDecimal']>;
  value_not?: InputMaybe<Scalars['BigDecimal']>;
  value_gt?: InputMaybe<Scalars['BigDecimal']>;
  value_lt?: InputMaybe<Scalars['BigDecimal']>;
  value_gte?: InputMaybe<Scalars['BigDecimal']>;
  value_lte?: InputMaybe<Scalars['BigDecimal']>;
  value_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  value_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fee?: InputMaybe<Scalars['BigDecimal']>;
  fee_not?: InputMaybe<Scalars['BigDecimal']>;
  fee_gt?: InputMaybe<Scalars['BigDecimal']>;
  fee_lt?: InputMaybe<Scalars['BigDecimal']>;
  fee_gte?: InputMaybe<Scalars['BigDecimal']>;
  fee_lte?: InputMaybe<Scalars['BigDecimal']>;
  fee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  withUncalculatedPrices?: InputMaybe<Scalars['Boolean']>;
  withUncalculatedPrices_not?: InputMaybe<Scalars['Boolean']>;
  withUncalculatedPrices_in?: InputMaybe<Array<Scalars['Boolean']>>;
  withUncalculatedPrices_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  investmentAmount?: InputMaybe<Scalars['BigDecimal']>;
  investmentAmount_not?: InputMaybe<Scalars['BigDecimal']>;
  investmentAmount_gt?: InputMaybe<Scalars['BigDecimal']>;
  investmentAmount_lt?: InputMaybe<Scalars['BigDecimal']>;
  investmentAmount_gte?: InputMaybe<Scalars['BigDecimal']>;
  investmentAmount_lte?: InputMaybe<Scalars['BigDecimal']>;
  investmentAmount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  investmentAmount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  feeAmount?: InputMaybe<Scalars['BigDecimal']>;
  feeAmount_not?: InputMaybe<Scalars['BigDecimal']>;
  feeAmount_gt?: InputMaybe<Scalars['BigDecimal']>;
  feeAmount_lt?: InputMaybe<Scalars['BigDecimal']>;
  feeAmount_gte?: InputMaybe<Scalars['BigDecimal']>;
  feeAmount_lte?: InputMaybe<Scalars['BigDecimal']>;
  feeAmount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  feeAmount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  sharesIssued?: InputMaybe<Scalars['BigDecimal']>;
  sharesIssued_not?: InputMaybe<Scalars['BigDecimal']>;
  sharesIssued_gt?: InputMaybe<Scalars['BigDecimal']>;
  sharesIssued_lt?: InputMaybe<Scalars['BigDecimal']>;
  sharesIssued_gte?: InputMaybe<Scalars['BigDecimal']>;
  sharesIssued_lte?: InputMaybe<Scalars['BigDecimal']>;
  sharesIssued_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  sharesIssued_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  partyState?: InputMaybe<Scalars['String']>;
  partyState_not?: InputMaybe<Scalars['String']>;
  partyState_gt?: InputMaybe<Scalars['String']>;
  partyState_lt?: InputMaybe<Scalars['String']>;
  partyState_gte?: InputMaybe<Scalars['String']>;
  partyState_lte?: InputMaybe<Scalars['String']>;
  partyState_in?: InputMaybe<Array<Scalars['String']>>;
  partyState_not_in?: InputMaybe<Array<Scalars['String']>>;
  partyState_contains?: InputMaybe<Scalars['String']>;
  partyState_contains_nocase?: InputMaybe<Scalars['String']>;
  partyState_not_contains?: InputMaybe<Scalars['String']>;
  partyState_not_contains_nocase?: InputMaybe<Scalars['String']>;
  partyState_starts_with?: InputMaybe<Scalars['String']>;
  partyState_starts_with_nocase?: InputMaybe<Scalars['String']>;
  partyState_not_starts_with?: InputMaybe<Scalars['String']>;
  partyState_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  partyState_ends_with?: InputMaybe<Scalars['String']>;
  partyState_ends_with_nocase?: InputMaybe<Scalars['String']>;
  partyState_not_ends_with?: InputMaybe<Scalars['String']>;
  partyState_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  partyState_?: InputMaybe<PartyState_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<SharesBoughtEvent_filter>>>;
  or?: InputMaybe<Array<InputMaybe<SharesBoughtEvent_filter>>>;
};

export type SharesBoughtEvent_orderBy =
  | 'id'
  | 'party'
  | 'party__id'
  | 'party__name'
  | 'party__bio'
  | 'party__img'
  | 'party__model'
  | 'party__purpose'
  | 'party__isPublic'
  | 'party__minDeposit'
  | 'party__maxDeposit'
  | 'party__inception'
  | 'party__infoUpdatedAt'
  | 'party__isClosed'
  | 'party__membersCount'
  | 'party__lastActivity'
  | 'investmentCost'
  | 'investmentCost__id'
  | 'investmentCost__latestTimestamp'
  | 'investmentCost__latestBlockNumber'
  | 'investmentCost__depositCount'
  | 'investmentCost__totalDeposits'
  | 'investmentCost__withdrawalCount'
  | 'investmentCost__totalWithdrawals'
  | 'investmentCost__value'
  | 'investmentCost__withUncalculatedPrices'
  | 'type'
  | 'investor'
  | 'investor__id'
  | 'investor__firstSeen'
  | 'investor__partyOwner'
  | 'investor__partyOwnerSince'
  | 'investor__investor'
  | 'investor__investorSince'
  | 'investmentState'
  | 'investmentState__id'
  | 'investmentState__timestamp'
  | 'investmentState__shares'
  | 'shares'
  | 'asset'
  | 'asset__id'
  | 'asset__name'
  | 'asset__symbol'
  | 'asset__decimals'
  | 'asset__type'
  | 'asset__firstSeen'
  | 'value'
  | 'fee'
  | 'withUncalculatedPrices'
  | 'investmentAmount'
  | 'feeAmount'
  | 'sharesIssued'
  | 'timestamp'
  | 'blockNumber'
  | 'partyState'
  | 'partyState__id'
  | 'partyState__timestamp';

export type SharesRedeemedEvent = {
  id: Scalars['ID'];
  party: Party;
  investmentCost: InvestmentCost;
  type: ShareChangeType;
  investor: User;
  investmentState: InvestmentState;
  shares: Scalars['BigDecimal'];
  redeemedAmount: Scalars['BigDecimal'];
  fee: Scalars['BigDecimal'];
  redeemedAmounts: Array<AssetAmount>;
  payoutAssetFees: Array<AssetAmount>;
  withLiquidation: Scalars['Boolean'];
  withUncalculatedPrices: Scalars['Boolean'];
  timestamp: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
  partyState: PartyState;
  networkId: Scalars['Int'];
  partySlug: Scalars['String'];
};


export type SharesRedeemedEventredeemedAmountsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AssetAmount_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AssetAmount_filter>;
};


export type SharesRedeemedEventpayoutAssetFeesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AssetAmount_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AssetAmount_filter>;
};

export type SharesRedeemedEvent_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  party?: InputMaybe<Scalars['String']>;
  party_not?: InputMaybe<Scalars['String']>;
  party_gt?: InputMaybe<Scalars['String']>;
  party_lt?: InputMaybe<Scalars['String']>;
  party_gte?: InputMaybe<Scalars['String']>;
  party_lte?: InputMaybe<Scalars['String']>;
  party_in?: InputMaybe<Array<Scalars['String']>>;
  party_not_in?: InputMaybe<Array<Scalars['String']>>;
  party_contains?: InputMaybe<Scalars['String']>;
  party_contains_nocase?: InputMaybe<Scalars['String']>;
  party_not_contains?: InputMaybe<Scalars['String']>;
  party_not_contains_nocase?: InputMaybe<Scalars['String']>;
  party_starts_with?: InputMaybe<Scalars['String']>;
  party_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_starts_with?: InputMaybe<Scalars['String']>;
  party_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_ends_with?: InputMaybe<Scalars['String']>;
  party_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_ends_with?: InputMaybe<Scalars['String']>;
  party_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_?: InputMaybe<Party_filter>;
  investmentCost?: InputMaybe<Scalars['String']>;
  investmentCost_not?: InputMaybe<Scalars['String']>;
  investmentCost_gt?: InputMaybe<Scalars['String']>;
  investmentCost_lt?: InputMaybe<Scalars['String']>;
  investmentCost_gte?: InputMaybe<Scalars['String']>;
  investmentCost_lte?: InputMaybe<Scalars['String']>;
  investmentCost_in?: InputMaybe<Array<Scalars['String']>>;
  investmentCost_not_in?: InputMaybe<Array<Scalars['String']>>;
  investmentCost_contains?: InputMaybe<Scalars['String']>;
  investmentCost_contains_nocase?: InputMaybe<Scalars['String']>;
  investmentCost_not_contains?: InputMaybe<Scalars['String']>;
  investmentCost_not_contains_nocase?: InputMaybe<Scalars['String']>;
  investmentCost_starts_with?: InputMaybe<Scalars['String']>;
  investmentCost_starts_with_nocase?: InputMaybe<Scalars['String']>;
  investmentCost_not_starts_with?: InputMaybe<Scalars['String']>;
  investmentCost_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  investmentCost_ends_with?: InputMaybe<Scalars['String']>;
  investmentCost_ends_with_nocase?: InputMaybe<Scalars['String']>;
  investmentCost_not_ends_with?: InputMaybe<Scalars['String']>;
  investmentCost_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  investmentCost_?: InputMaybe<InvestmentCost_filter>;
  type?: InputMaybe<ShareChangeType>;
  type_not?: InputMaybe<ShareChangeType>;
  type_in?: InputMaybe<Array<ShareChangeType>>;
  type_not_in?: InputMaybe<Array<ShareChangeType>>;
  investor?: InputMaybe<Scalars['String']>;
  investor_not?: InputMaybe<Scalars['String']>;
  investor_gt?: InputMaybe<Scalars['String']>;
  investor_lt?: InputMaybe<Scalars['String']>;
  investor_gte?: InputMaybe<Scalars['String']>;
  investor_lte?: InputMaybe<Scalars['String']>;
  investor_in?: InputMaybe<Array<Scalars['String']>>;
  investor_not_in?: InputMaybe<Array<Scalars['String']>>;
  investor_contains?: InputMaybe<Scalars['String']>;
  investor_contains_nocase?: InputMaybe<Scalars['String']>;
  investor_not_contains?: InputMaybe<Scalars['String']>;
  investor_not_contains_nocase?: InputMaybe<Scalars['String']>;
  investor_starts_with?: InputMaybe<Scalars['String']>;
  investor_starts_with_nocase?: InputMaybe<Scalars['String']>;
  investor_not_starts_with?: InputMaybe<Scalars['String']>;
  investor_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  investor_ends_with?: InputMaybe<Scalars['String']>;
  investor_ends_with_nocase?: InputMaybe<Scalars['String']>;
  investor_not_ends_with?: InputMaybe<Scalars['String']>;
  investor_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  investor_?: InputMaybe<User_filter>;
  investmentState?: InputMaybe<Scalars['String']>;
  investmentState_not?: InputMaybe<Scalars['String']>;
  investmentState_gt?: InputMaybe<Scalars['String']>;
  investmentState_lt?: InputMaybe<Scalars['String']>;
  investmentState_gte?: InputMaybe<Scalars['String']>;
  investmentState_lte?: InputMaybe<Scalars['String']>;
  investmentState_in?: InputMaybe<Array<Scalars['String']>>;
  investmentState_not_in?: InputMaybe<Array<Scalars['String']>>;
  investmentState_contains?: InputMaybe<Scalars['String']>;
  investmentState_contains_nocase?: InputMaybe<Scalars['String']>;
  investmentState_not_contains?: InputMaybe<Scalars['String']>;
  investmentState_not_contains_nocase?: InputMaybe<Scalars['String']>;
  investmentState_starts_with?: InputMaybe<Scalars['String']>;
  investmentState_starts_with_nocase?: InputMaybe<Scalars['String']>;
  investmentState_not_starts_with?: InputMaybe<Scalars['String']>;
  investmentState_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  investmentState_ends_with?: InputMaybe<Scalars['String']>;
  investmentState_ends_with_nocase?: InputMaybe<Scalars['String']>;
  investmentState_not_ends_with?: InputMaybe<Scalars['String']>;
  investmentState_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  investmentState_?: InputMaybe<InvestmentState_filter>;
  shares?: InputMaybe<Scalars['BigDecimal']>;
  shares_not?: InputMaybe<Scalars['BigDecimal']>;
  shares_gt?: InputMaybe<Scalars['BigDecimal']>;
  shares_lt?: InputMaybe<Scalars['BigDecimal']>;
  shares_gte?: InputMaybe<Scalars['BigDecimal']>;
  shares_lte?: InputMaybe<Scalars['BigDecimal']>;
  shares_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  shares_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  redeemedAmount?: InputMaybe<Scalars['BigDecimal']>;
  redeemedAmount_not?: InputMaybe<Scalars['BigDecimal']>;
  redeemedAmount_gt?: InputMaybe<Scalars['BigDecimal']>;
  redeemedAmount_lt?: InputMaybe<Scalars['BigDecimal']>;
  redeemedAmount_gte?: InputMaybe<Scalars['BigDecimal']>;
  redeemedAmount_lte?: InputMaybe<Scalars['BigDecimal']>;
  redeemedAmount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  redeemedAmount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fee?: InputMaybe<Scalars['BigDecimal']>;
  fee_not?: InputMaybe<Scalars['BigDecimal']>;
  fee_gt?: InputMaybe<Scalars['BigDecimal']>;
  fee_lt?: InputMaybe<Scalars['BigDecimal']>;
  fee_gte?: InputMaybe<Scalars['BigDecimal']>;
  fee_lte?: InputMaybe<Scalars['BigDecimal']>;
  fee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  redeemedAmounts?: InputMaybe<Array<Scalars['String']>>;
  redeemedAmounts_not?: InputMaybe<Array<Scalars['String']>>;
  redeemedAmounts_contains?: InputMaybe<Array<Scalars['String']>>;
  redeemedAmounts_contains_nocase?: InputMaybe<Array<Scalars['String']>>;
  redeemedAmounts_not_contains?: InputMaybe<Array<Scalars['String']>>;
  redeemedAmounts_not_contains_nocase?: InputMaybe<Array<Scalars['String']>>;
  redeemedAmounts_?: InputMaybe<AssetAmount_filter>;
  payoutAssetFees?: InputMaybe<Array<Scalars['String']>>;
  payoutAssetFees_not?: InputMaybe<Array<Scalars['String']>>;
  payoutAssetFees_contains?: InputMaybe<Array<Scalars['String']>>;
  payoutAssetFees_contains_nocase?: InputMaybe<Array<Scalars['String']>>;
  payoutAssetFees_not_contains?: InputMaybe<Array<Scalars['String']>>;
  payoutAssetFees_not_contains_nocase?: InputMaybe<Array<Scalars['String']>>;
  payoutAssetFees_?: InputMaybe<AssetAmount_filter>;
  withLiquidation?: InputMaybe<Scalars['Boolean']>;
  withLiquidation_not?: InputMaybe<Scalars['Boolean']>;
  withLiquidation_in?: InputMaybe<Array<Scalars['Boolean']>>;
  withLiquidation_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  withUncalculatedPrices?: InputMaybe<Scalars['Boolean']>;
  withUncalculatedPrices_not?: InputMaybe<Scalars['Boolean']>;
  withUncalculatedPrices_in?: InputMaybe<Array<Scalars['Boolean']>>;
  withUncalculatedPrices_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  partyState?: InputMaybe<Scalars['String']>;
  partyState_not?: InputMaybe<Scalars['String']>;
  partyState_gt?: InputMaybe<Scalars['String']>;
  partyState_lt?: InputMaybe<Scalars['String']>;
  partyState_gte?: InputMaybe<Scalars['String']>;
  partyState_lte?: InputMaybe<Scalars['String']>;
  partyState_in?: InputMaybe<Array<Scalars['String']>>;
  partyState_not_in?: InputMaybe<Array<Scalars['String']>>;
  partyState_contains?: InputMaybe<Scalars['String']>;
  partyState_contains_nocase?: InputMaybe<Scalars['String']>;
  partyState_not_contains?: InputMaybe<Scalars['String']>;
  partyState_not_contains_nocase?: InputMaybe<Scalars['String']>;
  partyState_starts_with?: InputMaybe<Scalars['String']>;
  partyState_starts_with_nocase?: InputMaybe<Scalars['String']>;
  partyState_not_starts_with?: InputMaybe<Scalars['String']>;
  partyState_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  partyState_ends_with?: InputMaybe<Scalars['String']>;
  partyState_ends_with_nocase?: InputMaybe<Scalars['String']>;
  partyState_not_ends_with?: InputMaybe<Scalars['String']>;
  partyState_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  partyState_?: InputMaybe<PartyState_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<SharesRedeemedEvent_filter>>>;
  or?: InputMaybe<Array<InputMaybe<SharesRedeemedEvent_filter>>>;
};

export type SharesRedeemedEvent_orderBy =
  | 'id'
  | 'party'
  | 'party__id'
  | 'party__name'
  | 'party__bio'
  | 'party__img'
  | 'party__model'
  | 'party__purpose'
  | 'party__isPublic'
  | 'party__minDeposit'
  | 'party__maxDeposit'
  | 'party__inception'
  | 'party__infoUpdatedAt'
  | 'party__isClosed'
  | 'party__membersCount'
  | 'party__lastActivity'
  | 'investmentCost'
  | 'investmentCost__id'
  | 'investmentCost__latestTimestamp'
  | 'investmentCost__latestBlockNumber'
  | 'investmentCost__depositCount'
  | 'investmentCost__totalDeposits'
  | 'investmentCost__withdrawalCount'
  | 'investmentCost__totalWithdrawals'
  | 'investmentCost__value'
  | 'investmentCost__withUncalculatedPrices'
  | 'type'
  | 'investor'
  | 'investor__id'
  | 'investor__firstSeen'
  | 'investor__partyOwner'
  | 'investor__partyOwnerSince'
  | 'investor__investor'
  | 'investor__investorSince'
  | 'investmentState'
  | 'investmentState__id'
  | 'investmentState__timestamp'
  | 'investmentState__shares'
  | 'shares'
  | 'redeemedAmount'
  | 'fee'
  | 'redeemedAmounts'
  | 'payoutAssetFees'
  | 'withLiquidation'
  | 'withUncalculatedPrices'
  | 'timestamp'
  | 'blockNumber'
  | 'partyState'
  | 'partyState__id'
  | 'partyState__timestamp';

export type TokenGate = {
  id: Scalars['ID'];
  partyTokenGate: PartyTokenGate;
  token: Scalars['Bytes'];
  name: Scalars['String'];
  symbol: Scalars['String'];
  decimals: Scalars['Int'];
  type: AssetType;
  value: Scalars['BigInt'];
};

export type TokenGate_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  partyTokenGate?: InputMaybe<Scalars['String']>;
  partyTokenGate_not?: InputMaybe<Scalars['String']>;
  partyTokenGate_gt?: InputMaybe<Scalars['String']>;
  partyTokenGate_lt?: InputMaybe<Scalars['String']>;
  partyTokenGate_gte?: InputMaybe<Scalars['String']>;
  partyTokenGate_lte?: InputMaybe<Scalars['String']>;
  partyTokenGate_in?: InputMaybe<Array<Scalars['String']>>;
  partyTokenGate_not_in?: InputMaybe<Array<Scalars['String']>>;
  partyTokenGate_contains?: InputMaybe<Scalars['String']>;
  partyTokenGate_contains_nocase?: InputMaybe<Scalars['String']>;
  partyTokenGate_not_contains?: InputMaybe<Scalars['String']>;
  partyTokenGate_not_contains_nocase?: InputMaybe<Scalars['String']>;
  partyTokenGate_starts_with?: InputMaybe<Scalars['String']>;
  partyTokenGate_starts_with_nocase?: InputMaybe<Scalars['String']>;
  partyTokenGate_not_starts_with?: InputMaybe<Scalars['String']>;
  partyTokenGate_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  partyTokenGate_ends_with?: InputMaybe<Scalars['String']>;
  partyTokenGate_ends_with_nocase?: InputMaybe<Scalars['String']>;
  partyTokenGate_not_ends_with?: InputMaybe<Scalars['String']>;
  partyTokenGate_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  partyTokenGate_?: InputMaybe<PartyTokenGate_filter>;
  token?: InputMaybe<Scalars['Bytes']>;
  token_not?: InputMaybe<Scalars['Bytes']>;
  token_gt?: InputMaybe<Scalars['Bytes']>;
  token_lt?: InputMaybe<Scalars['Bytes']>;
  token_gte?: InputMaybe<Scalars['Bytes']>;
  token_lte?: InputMaybe<Scalars['Bytes']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  token_contains?: InputMaybe<Scalars['Bytes']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']>;
  name?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_contains_nocase?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  symbol_not?: InputMaybe<Scalars['String']>;
  symbol_gt?: InputMaybe<Scalars['String']>;
  symbol_lt?: InputMaybe<Scalars['String']>;
  symbol_gte?: InputMaybe<Scalars['String']>;
  symbol_lte?: InputMaybe<Scalars['String']>;
  symbol_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_contains?: InputMaybe<Scalars['String']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_contains?: InputMaybe<Scalars['String']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_starts_with?: InputMaybe<Scalars['String']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_ends_with?: InputMaybe<Scalars['String']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  decimals?: InputMaybe<Scalars['Int']>;
  decimals_not?: InputMaybe<Scalars['Int']>;
  decimals_gt?: InputMaybe<Scalars['Int']>;
  decimals_lt?: InputMaybe<Scalars['Int']>;
  decimals_gte?: InputMaybe<Scalars['Int']>;
  decimals_lte?: InputMaybe<Scalars['Int']>;
  decimals_in?: InputMaybe<Array<Scalars['Int']>>;
  decimals_not_in?: InputMaybe<Array<Scalars['Int']>>;
  type?: InputMaybe<AssetType>;
  type_not?: InputMaybe<AssetType>;
  type_in?: InputMaybe<Array<AssetType>>;
  type_not_in?: InputMaybe<Array<AssetType>>;
  value?: InputMaybe<Scalars['BigInt']>;
  value_not?: InputMaybe<Scalars['BigInt']>;
  value_gt?: InputMaybe<Scalars['BigInt']>;
  value_lt?: InputMaybe<Scalars['BigInt']>;
  value_gte?: InputMaybe<Scalars['BigInt']>;
  value_lte?: InputMaybe<Scalars['BigInt']>;
  value_in?: InputMaybe<Array<Scalars['BigInt']>>;
  value_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TokenGate_filter>>>;
  or?: InputMaybe<Array<InputMaybe<TokenGate_filter>>>;
};

export type TokenGate_orderBy =
  | 'id'
  | 'partyTokenGate'
  | 'partyTokenGate__id'
  | 'partyTokenGate__updatedAt'
  | 'token'
  | 'name'
  | 'symbol'
  | 'decimals'
  | 'type'
  | 'value';

export type TokenSwap = {
  id: Scalars['ID'];
  party: Party;
  transaction: Scalars['Bytes'];
  sellToken: Asset;
  buyToken: Asset;
  soldAmount: Scalars['BigDecimal'];
  boughtAmount: Scalars['BigDecimal'];
  fee: Scalars['BigDecimal'];
  timestamp: Scalars['BigInt'];
};

export type TokenSwap_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  party?: InputMaybe<Scalars['String']>;
  party_not?: InputMaybe<Scalars['String']>;
  party_gt?: InputMaybe<Scalars['String']>;
  party_lt?: InputMaybe<Scalars['String']>;
  party_gte?: InputMaybe<Scalars['String']>;
  party_lte?: InputMaybe<Scalars['String']>;
  party_in?: InputMaybe<Array<Scalars['String']>>;
  party_not_in?: InputMaybe<Array<Scalars['String']>>;
  party_contains?: InputMaybe<Scalars['String']>;
  party_contains_nocase?: InputMaybe<Scalars['String']>;
  party_not_contains?: InputMaybe<Scalars['String']>;
  party_not_contains_nocase?: InputMaybe<Scalars['String']>;
  party_starts_with?: InputMaybe<Scalars['String']>;
  party_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_starts_with?: InputMaybe<Scalars['String']>;
  party_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_ends_with?: InputMaybe<Scalars['String']>;
  party_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_ends_with?: InputMaybe<Scalars['String']>;
  party_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_?: InputMaybe<Party_filter>;
  transaction?: InputMaybe<Scalars['Bytes']>;
  transaction_not?: InputMaybe<Scalars['Bytes']>;
  transaction_gt?: InputMaybe<Scalars['Bytes']>;
  transaction_lt?: InputMaybe<Scalars['Bytes']>;
  transaction_gte?: InputMaybe<Scalars['Bytes']>;
  transaction_lte?: InputMaybe<Scalars['Bytes']>;
  transaction_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transaction_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transaction_contains?: InputMaybe<Scalars['Bytes']>;
  transaction_not_contains?: InputMaybe<Scalars['Bytes']>;
  sellToken?: InputMaybe<Scalars['String']>;
  sellToken_not?: InputMaybe<Scalars['String']>;
  sellToken_gt?: InputMaybe<Scalars['String']>;
  sellToken_lt?: InputMaybe<Scalars['String']>;
  sellToken_gte?: InputMaybe<Scalars['String']>;
  sellToken_lte?: InputMaybe<Scalars['String']>;
  sellToken_in?: InputMaybe<Array<Scalars['String']>>;
  sellToken_not_in?: InputMaybe<Array<Scalars['String']>>;
  sellToken_contains?: InputMaybe<Scalars['String']>;
  sellToken_contains_nocase?: InputMaybe<Scalars['String']>;
  sellToken_not_contains?: InputMaybe<Scalars['String']>;
  sellToken_not_contains_nocase?: InputMaybe<Scalars['String']>;
  sellToken_starts_with?: InputMaybe<Scalars['String']>;
  sellToken_starts_with_nocase?: InputMaybe<Scalars['String']>;
  sellToken_not_starts_with?: InputMaybe<Scalars['String']>;
  sellToken_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  sellToken_ends_with?: InputMaybe<Scalars['String']>;
  sellToken_ends_with_nocase?: InputMaybe<Scalars['String']>;
  sellToken_not_ends_with?: InputMaybe<Scalars['String']>;
  sellToken_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  sellToken_?: InputMaybe<Asset_filter>;
  buyToken?: InputMaybe<Scalars['String']>;
  buyToken_not?: InputMaybe<Scalars['String']>;
  buyToken_gt?: InputMaybe<Scalars['String']>;
  buyToken_lt?: InputMaybe<Scalars['String']>;
  buyToken_gte?: InputMaybe<Scalars['String']>;
  buyToken_lte?: InputMaybe<Scalars['String']>;
  buyToken_in?: InputMaybe<Array<Scalars['String']>>;
  buyToken_not_in?: InputMaybe<Array<Scalars['String']>>;
  buyToken_contains?: InputMaybe<Scalars['String']>;
  buyToken_contains_nocase?: InputMaybe<Scalars['String']>;
  buyToken_not_contains?: InputMaybe<Scalars['String']>;
  buyToken_not_contains_nocase?: InputMaybe<Scalars['String']>;
  buyToken_starts_with?: InputMaybe<Scalars['String']>;
  buyToken_starts_with_nocase?: InputMaybe<Scalars['String']>;
  buyToken_not_starts_with?: InputMaybe<Scalars['String']>;
  buyToken_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  buyToken_ends_with?: InputMaybe<Scalars['String']>;
  buyToken_ends_with_nocase?: InputMaybe<Scalars['String']>;
  buyToken_not_ends_with?: InputMaybe<Scalars['String']>;
  buyToken_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  buyToken_?: InputMaybe<Asset_filter>;
  soldAmount?: InputMaybe<Scalars['BigDecimal']>;
  soldAmount_not?: InputMaybe<Scalars['BigDecimal']>;
  soldAmount_gt?: InputMaybe<Scalars['BigDecimal']>;
  soldAmount_lt?: InputMaybe<Scalars['BigDecimal']>;
  soldAmount_gte?: InputMaybe<Scalars['BigDecimal']>;
  soldAmount_lte?: InputMaybe<Scalars['BigDecimal']>;
  soldAmount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  soldAmount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  boughtAmount?: InputMaybe<Scalars['BigDecimal']>;
  boughtAmount_not?: InputMaybe<Scalars['BigDecimal']>;
  boughtAmount_gt?: InputMaybe<Scalars['BigDecimal']>;
  boughtAmount_lt?: InputMaybe<Scalars['BigDecimal']>;
  boughtAmount_gte?: InputMaybe<Scalars['BigDecimal']>;
  boughtAmount_lte?: InputMaybe<Scalars['BigDecimal']>;
  boughtAmount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  boughtAmount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fee?: InputMaybe<Scalars['BigDecimal']>;
  fee_not?: InputMaybe<Scalars['BigDecimal']>;
  fee_gt?: InputMaybe<Scalars['BigDecimal']>;
  fee_lt?: InputMaybe<Scalars['BigDecimal']>;
  fee_gte?: InputMaybe<Scalars['BigDecimal']>;
  fee_lte?: InputMaybe<Scalars['BigDecimal']>;
  fee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TokenSwap_filter>>>;
  or?: InputMaybe<Array<InputMaybe<TokenSwap_filter>>>;
};

export type TokenSwap_orderBy =
  | 'id'
  | 'party'
  | 'party__id'
  | 'party__name'
  | 'party__bio'
  | 'party__img'
  | 'party__model'
  | 'party__purpose'
  | 'party__isPublic'
  | 'party__minDeposit'
  | 'party__maxDeposit'
  | 'party__inception'
  | 'party__infoUpdatedAt'
  | 'party__isClosed'
  | 'party__membersCount'
  | 'party__lastActivity'
  | 'transaction'
  | 'sellToken'
  | 'sellToken__id'
  | 'sellToken__name'
  | 'sellToken__symbol'
  | 'sellToken__decimals'
  | 'sellToken__type'
  | 'sellToken__firstSeen'
  | 'buyToken'
  | 'buyToken__id'
  | 'buyToken__name'
  | 'buyToken__symbol'
  | 'buyToken__decimals'
  | 'buyToken__type'
  | 'buyToken__firstSeen'
  | 'soldAmount'
  | 'boughtAmount'
  | 'fee'
  | 'timestamp';

export type User = {
  id: Scalars['ID'];
  firstSeen: Scalars['BigInt'];
  partyOwner: Scalars['Boolean'];
  partyOwnerSince?: Maybe<Scalars['BigInt']>;
  partyOwning: Array<Party>;
  investor: Scalars['Boolean'];
  investorSince?: Maybe<Scalars['BigInt']>;
  investments: Array<Investment>;
  parties?: Maybe<Array<UserParty>>;
  requests?: Maybe<Array<JoinRequest>>;
};


export type UserpartyOwningArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Party_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Party_filter>;
};


export type UserinvestmentsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Investment_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Investment_filter>;
};


export type UserpartiesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserParty_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UserParty_filter>;
};


export type UserrequestsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<JoinRequest_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<JoinRequest_filter>;
};

export type UserParty = {
  id: Scalars['ID'];
  joinedSince?: Maybe<Scalars['BigInt']>;
  leftAt?: Maybe<Scalars['BigInt']>;
  user: User;
  party: Party;
  status?: Maybe<UserPartyStatus>;
  isCreator: Scalars['Boolean'];
  creatorSince?: Maybe<Scalars['BigInt']>;
  isManager: Scalars['Boolean'];
  managerSince?: Maybe<Scalars['BigInt']>;
};

export type UserPartyStatus =
  | 'Joined'
  | 'Left'
  | 'Kicked';

export type UserParty_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  joinedSince?: InputMaybe<Scalars['BigInt']>;
  joinedSince_not?: InputMaybe<Scalars['BigInt']>;
  joinedSince_gt?: InputMaybe<Scalars['BigInt']>;
  joinedSince_lt?: InputMaybe<Scalars['BigInt']>;
  joinedSince_gte?: InputMaybe<Scalars['BigInt']>;
  joinedSince_lte?: InputMaybe<Scalars['BigInt']>;
  joinedSince_in?: InputMaybe<Array<Scalars['BigInt']>>;
  joinedSince_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  leftAt?: InputMaybe<Scalars['BigInt']>;
  leftAt_not?: InputMaybe<Scalars['BigInt']>;
  leftAt_gt?: InputMaybe<Scalars['BigInt']>;
  leftAt_lt?: InputMaybe<Scalars['BigInt']>;
  leftAt_gte?: InputMaybe<Scalars['BigInt']>;
  leftAt_lte?: InputMaybe<Scalars['BigInt']>;
  leftAt_in?: InputMaybe<Array<Scalars['BigInt']>>;
  leftAt_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  user?: InputMaybe<Scalars['String']>;
  user_not?: InputMaybe<Scalars['String']>;
  user_gt?: InputMaybe<Scalars['String']>;
  user_lt?: InputMaybe<Scalars['String']>;
  user_gte?: InputMaybe<Scalars['String']>;
  user_lte?: InputMaybe<Scalars['String']>;
  user_in?: InputMaybe<Array<Scalars['String']>>;
  user_not_in?: InputMaybe<Array<Scalars['String']>>;
  user_contains?: InputMaybe<Scalars['String']>;
  user_contains_nocase?: InputMaybe<Scalars['String']>;
  user_not_contains?: InputMaybe<Scalars['String']>;
  user_not_contains_nocase?: InputMaybe<Scalars['String']>;
  user_starts_with?: InputMaybe<Scalars['String']>;
  user_starts_with_nocase?: InputMaybe<Scalars['String']>;
  user_not_starts_with?: InputMaybe<Scalars['String']>;
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  user_ends_with?: InputMaybe<Scalars['String']>;
  user_ends_with_nocase?: InputMaybe<Scalars['String']>;
  user_not_ends_with?: InputMaybe<Scalars['String']>;
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  user_?: InputMaybe<User_filter>;
  party?: InputMaybe<Scalars['String']>;
  party_not?: InputMaybe<Scalars['String']>;
  party_gt?: InputMaybe<Scalars['String']>;
  party_lt?: InputMaybe<Scalars['String']>;
  party_gte?: InputMaybe<Scalars['String']>;
  party_lte?: InputMaybe<Scalars['String']>;
  party_in?: InputMaybe<Array<Scalars['String']>>;
  party_not_in?: InputMaybe<Array<Scalars['String']>>;
  party_contains?: InputMaybe<Scalars['String']>;
  party_contains_nocase?: InputMaybe<Scalars['String']>;
  party_not_contains?: InputMaybe<Scalars['String']>;
  party_not_contains_nocase?: InputMaybe<Scalars['String']>;
  party_starts_with?: InputMaybe<Scalars['String']>;
  party_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_starts_with?: InputMaybe<Scalars['String']>;
  party_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  party_ends_with?: InputMaybe<Scalars['String']>;
  party_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_not_ends_with?: InputMaybe<Scalars['String']>;
  party_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  party_?: InputMaybe<Party_filter>;
  status?: InputMaybe<UserPartyStatus>;
  status_not?: InputMaybe<UserPartyStatus>;
  status_in?: InputMaybe<Array<UserPartyStatus>>;
  status_not_in?: InputMaybe<Array<UserPartyStatus>>;
  isCreator?: InputMaybe<Scalars['Boolean']>;
  isCreator_not?: InputMaybe<Scalars['Boolean']>;
  isCreator_in?: InputMaybe<Array<Scalars['Boolean']>>;
  isCreator_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  creatorSince?: InputMaybe<Scalars['BigInt']>;
  creatorSince_not?: InputMaybe<Scalars['BigInt']>;
  creatorSince_gt?: InputMaybe<Scalars['BigInt']>;
  creatorSince_lt?: InputMaybe<Scalars['BigInt']>;
  creatorSince_gte?: InputMaybe<Scalars['BigInt']>;
  creatorSince_lte?: InputMaybe<Scalars['BigInt']>;
  creatorSince_in?: InputMaybe<Array<Scalars['BigInt']>>;
  creatorSince_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  isManager?: InputMaybe<Scalars['Boolean']>;
  isManager_not?: InputMaybe<Scalars['Boolean']>;
  isManager_in?: InputMaybe<Array<Scalars['Boolean']>>;
  isManager_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  managerSince?: InputMaybe<Scalars['BigInt']>;
  managerSince_not?: InputMaybe<Scalars['BigInt']>;
  managerSince_gt?: InputMaybe<Scalars['BigInt']>;
  managerSince_lt?: InputMaybe<Scalars['BigInt']>;
  managerSince_gte?: InputMaybe<Scalars['BigInt']>;
  managerSince_lte?: InputMaybe<Scalars['BigInt']>;
  managerSince_in?: InputMaybe<Array<Scalars['BigInt']>>;
  managerSince_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<UserParty_filter>>>;
  or?: InputMaybe<Array<InputMaybe<UserParty_filter>>>;
};

export type UserParty_orderBy =
  | 'id'
  | 'joinedSince'
  | 'leftAt'
  | 'user'
  | 'user__id'
  | 'user__firstSeen'
  | 'user__partyOwner'
  | 'user__partyOwnerSince'
  | 'user__investor'
  | 'user__investorSince'
  | 'party'
  | 'party__id'
  | 'party__name'
  | 'party__bio'
  | 'party__img'
  | 'party__model'
  | 'party__purpose'
  | 'party__isPublic'
  | 'party__minDeposit'
  | 'party__maxDeposit'
  | 'party__inception'
  | 'party__infoUpdatedAt'
  | 'party__isClosed'
  | 'party__membersCount'
  | 'party__lastActivity'
  | 'status'
  | 'isCreator'
  | 'creatorSince'
  | 'isManager'
  | 'managerSince';

export type User_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  firstSeen?: InputMaybe<Scalars['BigInt']>;
  firstSeen_not?: InputMaybe<Scalars['BigInt']>;
  firstSeen_gt?: InputMaybe<Scalars['BigInt']>;
  firstSeen_lt?: InputMaybe<Scalars['BigInt']>;
  firstSeen_gte?: InputMaybe<Scalars['BigInt']>;
  firstSeen_lte?: InputMaybe<Scalars['BigInt']>;
  firstSeen_in?: InputMaybe<Array<Scalars['BigInt']>>;
  firstSeen_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  partyOwner?: InputMaybe<Scalars['Boolean']>;
  partyOwner_not?: InputMaybe<Scalars['Boolean']>;
  partyOwner_in?: InputMaybe<Array<Scalars['Boolean']>>;
  partyOwner_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  partyOwnerSince?: InputMaybe<Scalars['BigInt']>;
  partyOwnerSince_not?: InputMaybe<Scalars['BigInt']>;
  partyOwnerSince_gt?: InputMaybe<Scalars['BigInt']>;
  partyOwnerSince_lt?: InputMaybe<Scalars['BigInt']>;
  partyOwnerSince_gte?: InputMaybe<Scalars['BigInt']>;
  partyOwnerSince_lte?: InputMaybe<Scalars['BigInt']>;
  partyOwnerSince_in?: InputMaybe<Array<Scalars['BigInt']>>;
  partyOwnerSince_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  partyOwning_?: InputMaybe<Party_filter>;
  investor?: InputMaybe<Scalars['Boolean']>;
  investor_not?: InputMaybe<Scalars['Boolean']>;
  investor_in?: InputMaybe<Array<Scalars['Boolean']>>;
  investor_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  investorSince?: InputMaybe<Scalars['BigInt']>;
  investorSince_not?: InputMaybe<Scalars['BigInt']>;
  investorSince_gt?: InputMaybe<Scalars['BigInt']>;
  investorSince_lt?: InputMaybe<Scalars['BigInt']>;
  investorSince_gte?: InputMaybe<Scalars['BigInt']>;
  investorSince_lte?: InputMaybe<Scalars['BigInt']>;
  investorSince_in?: InputMaybe<Array<Scalars['BigInt']>>;
  investorSince_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  investments_?: InputMaybe<Investment_filter>;
  parties_?: InputMaybe<UserParty_filter>;
  requests_?: InputMaybe<JoinRequest_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<User_filter>>>;
  or?: InputMaybe<Array<InputMaybe<User_filter>>>;
};

export type User_orderBy =
  | 'id'
  | 'firstSeen'
  | 'partyOwner'
  | 'partyOwnerSince'
  | 'partyOwning'
  | 'investor'
  | 'investorSince'
  | 'investments'
  | 'parties'
  | 'requests';

export type _Block_ = {
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export type _SubgraphErrorPolicy_ =
  /** Data will be returned even if the subgraph has indexing errors */
  | 'allow'
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  | 'deny';

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string | ((fieldNode: FieldNode) => SelectionSetNode);
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  Query: ResolverTypeWrapper<{}>;
  Subscription: ResolverTypeWrapper<{}>;
  Asset: ResolverTypeWrapper<Asset>;
  AssetAmount: ResolverTypeWrapper<AssetAmount>;
  AssetAmount_filter: AssetAmount_filter;
  AssetAmount_orderBy: AssetAmount_orderBy;
  AssetType: AssetType;
  Asset_filter: Asset_filter;
  Asset_orderBy: Asset_orderBy;
  BigDecimal: ResolverTypeWrapper<Scalars['BigDecimal']>;
  BigInt: ResolverTypeWrapper<Scalars['BigInt']>;
  BlockChangedFilter: BlockChangedFilter;
  Block_height: Block_height;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Bytes: ResolverTypeWrapper<Scalars['Bytes']>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  HoldingState: ResolverTypeWrapper<HoldingState>;
  HoldingState_filter: HoldingState_filter;
  HoldingState_orderBy: HoldingState_orderBy;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Int8: ResolverTypeWrapper<Scalars['Int8']>;
  Investment: ResolverTypeWrapper<Investment>;
  InvestmentCost: ResolverTypeWrapper<InvestmentCost>;
  InvestmentCost_filter: InvestmentCost_filter;
  InvestmentCost_orderBy: InvestmentCost_orderBy;
  InvestmentState: ResolverTypeWrapper<InvestmentState>;
  InvestmentState_filter: InvestmentState_filter;
  InvestmentState_orderBy: InvestmentState_orderBy;
  Investment_filter: Investment_filter;
  Investment_orderBy: Investment_orderBy;
  JoinRequest: ResolverTypeWrapper<JoinRequest>;
  JoinRequestStatus: JoinRequestStatus;
  JoinRequest_filter: JoinRequest_filter;
  JoinRequest_orderBy: JoinRequest_orderBy;
  LimitOrderFill: ResolverTypeWrapper<LimitOrderFill>;
  LimitOrderFill_filter: LimitOrderFill_filter;
  LimitOrderFill_orderBy: LimitOrderFill_orderBy;
  Network: ResolverTypeWrapper<Network>;
  NetworkAssetHolding: ResolverTypeWrapper<NetworkAssetHolding>;
  NetworkAssetHolding_filter: NetworkAssetHolding_filter;
  NetworkAssetHolding_orderBy: NetworkAssetHolding_orderBy;
  NetworkState: ResolverTypeWrapper<NetworkState>;
  NetworkState_filter: NetworkState_filter;
  NetworkState_orderBy: NetworkState_orderBy;
  Network_filter: Network_filter;
  Network_orderBy: Network_orderBy;
  OrderDirection: OrderDirection;
  Party: ResolverTypeWrapper<Party>;
  PartyActivity: ResolverTypeWrapper<PartyActivity>;
  PartyActivity_filter: PartyActivity_filter;
  PartyActivity_orderBy: PartyActivity_orderBy;
  PartyEventType: PartyEventType;
  PartyState: ResolverTypeWrapper<PartyState>;
  PartyState_filter: PartyState_filter;
  PartyState_orderBy: PartyState_orderBy;
  PartyTokenGate: ResolverTypeWrapper<PartyTokenGate>;
  PartyTokenGate_filter: PartyTokenGate_filter;
  PartyTokenGate_orderBy: PartyTokenGate_orderBy;
  Party_filter: Party_filter;
  Party_orderBy: Party_orderBy;
  PortfolioState: ResolverTypeWrapper<PortfolioState>;
  PortfolioState_filter: PortfolioState_filter;
  PortfolioState_orderBy: PortfolioState_orderBy;
  ShareChangeType: ShareChangeType;
  ShareState: ResolverTypeWrapper<ShareState>;
  ShareState_filter: ShareState_filter;
  ShareState_orderBy: ShareState_orderBy;
  SharesBoughtEvent: ResolverTypeWrapper<SharesBoughtEvent>;
  SharesBoughtEvent_filter: SharesBoughtEvent_filter;
  SharesBoughtEvent_orderBy: SharesBoughtEvent_orderBy;
  SharesRedeemedEvent: ResolverTypeWrapper<SharesRedeemedEvent>;
  SharesRedeemedEvent_filter: SharesRedeemedEvent_filter;
  SharesRedeemedEvent_orderBy: SharesRedeemedEvent_orderBy;
  String: ResolverTypeWrapper<Scalars['String']>;
  TokenGate: ResolverTypeWrapper<TokenGate>;
  TokenGate_filter: TokenGate_filter;
  TokenGate_orderBy: TokenGate_orderBy;
  TokenSwap: ResolverTypeWrapper<TokenSwap>;
  TokenSwap_filter: TokenSwap_filter;
  TokenSwap_orderBy: TokenSwap_orderBy;
  User: ResolverTypeWrapper<User>;
  UserParty: ResolverTypeWrapper<UserParty>;
  UserPartyStatus: UserPartyStatus;
  UserParty_filter: UserParty_filter;
  UserParty_orderBy: UserParty_orderBy;
  User_filter: User_filter;
  User_orderBy: User_orderBy;
  _Block_: ResolverTypeWrapper<_Block_>;
  _Meta_: ResolverTypeWrapper<_Meta_>;
  _SubgraphErrorPolicy_: _SubgraphErrorPolicy_;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  Query: {};
  Subscription: {};
  Asset: Asset;
  AssetAmount: AssetAmount;
  AssetAmount_filter: AssetAmount_filter;
  Asset_filter: Asset_filter;
  BigDecimal: Scalars['BigDecimal'];
  BigInt: Scalars['BigInt'];
  BlockChangedFilter: BlockChangedFilter;
  Block_height: Block_height;
  Boolean: Scalars['Boolean'];
  Bytes: Scalars['Bytes'];
  Float: Scalars['Float'];
  HoldingState: HoldingState;
  HoldingState_filter: HoldingState_filter;
  ID: Scalars['ID'];
  Int: Scalars['Int'];
  Int8: Scalars['Int8'];
  Investment: Investment;
  InvestmentCost: InvestmentCost;
  InvestmentCost_filter: InvestmentCost_filter;
  InvestmentState: InvestmentState;
  InvestmentState_filter: InvestmentState_filter;
  Investment_filter: Investment_filter;
  JoinRequest: JoinRequest;
  JoinRequest_filter: JoinRequest_filter;
  LimitOrderFill: LimitOrderFill;
  LimitOrderFill_filter: LimitOrderFill_filter;
  Network: Network;
  NetworkAssetHolding: NetworkAssetHolding;
  NetworkAssetHolding_filter: NetworkAssetHolding_filter;
  NetworkState: NetworkState;
  NetworkState_filter: NetworkState_filter;
  Network_filter: Network_filter;
  Party: Party;
  PartyActivity: PartyActivity;
  PartyActivity_filter: PartyActivity_filter;
  PartyState: PartyState;
  PartyState_filter: PartyState_filter;
  PartyTokenGate: PartyTokenGate;
  PartyTokenGate_filter: PartyTokenGate_filter;
  Party_filter: Party_filter;
  PortfolioState: PortfolioState;
  PortfolioState_filter: PortfolioState_filter;
  ShareState: ShareState;
  ShareState_filter: ShareState_filter;
  SharesBoughtEvent: SharesBoughtEvent;
  SharesBoughtEvent_filter: SharesBoughtEvent_filter;
  SharesRedeemedEvent: SharesRedeemedEvent;
  SharesRedeemedEvent_filter: SharesRedeemedEvent_filter;
  String: Scalars['String'];
  TokenGate: TokenGate;
  TokenGate_filter: TokenGate_filter;
  TokenSwap: TokenSwap;
  TokenSwap_filter: TokenSwap_filter;
  User: User;
  UserParty: UserParty;
  UserParty_filter: UserParty_filter;
  User_filter: User_filter;
  _Block_: _Block_;
  _Meta_: _Meta_;
}>;

export type entityDirectiveArgs = { };

export type entityDirectiveResolver<Result, Parent, ContextType = MeshContext & { networkId: number }, Args = entityDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type subgraphIdDirectiveArgs = {
  id: Scalars['String'];
};

export type subgraphIdDirectiveResolver<Result, Parent, ContextType = MeshContext & { networkId: number }, Args = subgraphIdDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type derivedFromDirectiveArgs = {
  field: Scalars['String'];
};

export type derivedFromDirectiveResolver<Result, Parent, ContextType = MeshContext & { networkId: number }, Args = derivedFromDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type QueryResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  assetAmount?: Resolver<Maybe<ResolversTypes['AssetAmount']>, ParentType, ContextType, RequireFields<QueryassetAmountArgs, 'id' | 'subgraphError'>>;
  assetAmounts?: Resolver<Array<ResolversTypes['AssetAmount']>, ParentType, ContextType, RequireFields<QueryassetAmountsArgs, 'skip' | 'first' | 'subgraphError'>>;
  sharesBoughtEvent?: Resolver<Maybe<ResolversTypes['SharesBoughtEvent']>, ParentType, ContextType, RequireFields<QuerysharesBoughtEventArgs, 'id' | 'subgraphError'>>;
  sharesBoughtEvents?: Resolver<Array<ResolversTypes['SharesBoughtEvent']>, ParentType, ContextType, RequireFields<QuerysharesBoughtEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  sharesRedeemedEvent?: Resolver<Maybe<ResolversTypes['SharesRedeemedEvent']>, ParentType, ContextType, RequireFields<QuerysharesRedeemedEventArgs, 'id' | 'subgraphError'>>;
  sharesRedeemedEvents?: Resolver<Array<ResolversTypes['SharesRedeemedEvent']>, ParentType, ContextType, RequireFields<QuerysharesRedeemedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  party?: Resolver<Maybe<ResolversTypes['Party']>, ParentType, ContextType, RequireFields<QuerypartyArgs, 'id' | 'subgraphError'>>;
  parties?: Resolver<Array<ResolversTypes['Party']>, ParentType, ContextType, RequireFields<QuerypartiesArgs, 'skip' | 'first' | 'subgraphError'>>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryuserArgs, 'id' | 'subgraphError'>>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryusersArgs, 'skip' | 'first' | 'subgraphError'>>;
  userParty?: Resolver<Maybe<ResolversTypes['UserParty']>, ParentType, ContextType, RequireFields<QueryuserPartyArgs, 'id' | 'subgraphError'>>;
  userParties?: Resolver<Array<ResolversTypes['UserParty']>, ParentType, ContextType, RequireFields<QueryuserPartiesArgs, 'skip' | 'first' | 'subgraphError'>>;
  partyTokenGate?: Resolver<Maybe<ResolversTypes['PartyTokenGate']>, ParentType, ContextType, RequireFields<QuerypartyTokenGateArgs, 'id' | 'subgraphError'>>;
  partyTokenGates?: Resolver<Array<ResolversTypes['PartyTokenGate']>, ParentType, ContextType, RequireFields<QuerypartyTokenGatesArgs, 'skip' | 'first' | 'subgraphError'>>;
  tokenGate?: Resolver<Maybe<ResolversTypes['TokenGate']>, ParentType, ContextType, RequireFields<QuerytokenGateArgs, 'id' | 'subgraphError'>>;
  tokenGates?: Resolver<Array<ResolversTypes['TokenGate']>, ParentType, ContextType, RequireFields<QuerytokenGatesArgs, 'skip' | 'first' | 'subgraphError'>>;
  investment?: Resolver<Maybe<ResolversTypes['Investment']>, ParentType, ContextType, RequireFields<QueryinvestmentArgs, 'id' | 'subgraphError'>>;
  investments?: Resolver<Array<ResolversTypes['Investment']>, ParentType, ContextType, RequireFields<QueryinvestmentsArgs, 'skip' | 'first' | 'subgraphError'>>;
  investmentState?: Resolver<Maybe<ResolversTypes['InvestmentState']>, ParentType, ContextType, RequireFields<QueryinvestmentStateArgs, 'id' | 'subgraphError'>>;
  investmentStates?: Resolver<Array<ResolversTypes['InvestmentState']>, ParentType, ContextType, RequireFields<QueryinvestmentStatesArgs, 'skip' | 'first' | 'subgraphError'>>;
  shareState?: Resolver<Maybe<ResolversTypes['ShareState']>, ParentType, ContextType, RequireFields<QueryshareStateArgs, 'id' | 'subgraphError'>>;
  shareStates?: Resolver<Array<ResolversTypes['ShareState']>, ParentType, ContextType, RequireFields<QueryshareStatesArgs, 'skip' | 'first' | 'subgraphError'>>;
  portfolioState?: Resolver<Maybe<ResolversTypes['PortfolioState']>, ParentType, ContextType, RequireFields<QueryportfolioStateArgs, 'id' | 'subgraphError'>>;
  portfolioStates?: Resolver<Array<ResolversTypes['PortfolioState']>, ParentType, ContextType, RequireFields<QueryportfolioStatesArgs, 'skip' | 'first' | 'subgraphError'>>;
  holdingState?: Resolver<Maybe<ResolversTypes['HoldingState']>, ParentType, ContextType, RequireFields<QueryholdingStateArgs, 'id' | 'subgraphError'>>;
  holdingStates?: Resolver<Array<ResolversTypes['HoldingState']>, ParentType, ContextType, RequireFields<QueryholdingStatesArgs, 'skip' | 'first' | 'subgraphError'>>;
  partyState?: Resolver<Maybe<ResolversTypes['PartyState']>, ParentType, ContextType, RequireFields<QuerypartyStateArgs, 'id' | 'subgraphError'>>;
  partyStates?: Resolver<Array<ResolversTypes['PartyState']>, ParentType, ContextType, RequireFields<QuerypartyStatesArgs, 'skip' | 'first' | 'subgraphError'>>;
  investmentCost?: Resolver<Maybe<ResolversTypes['InvestmentCost']>, ParentType, ContextType, RequireFields<QueryinvestmentCostArgs, 'id' | 'subgraphError'>>;
  investmentCosts?: Resolver<Array<ResolversTypes['InvestmentCost']>, ParentType, ContextType, RequireFields<QueryinvestmentCostsArgs, 'skip' | 'first' | 'subgraphError'>>;
  asset?: Resolver<Maybe<ResolversTypes['Asset']>, ParentType, ContextType, RequireFields<QueryassetArgs, 'id' | 'subgraphError'>>;
  assets?: Resolver<Array<ResolversTypes['Asset']>, ParentType, ContextType, RequireFields<QueryassetsArgs, 'skip' | 'first' | 'subgraphError'>>;
  tokenSwap?: Resolver<Maybe<ResolversTypes['TokenSwap']>, ParentType, ContextType, RequireFields<QuerytokenSwapArgs, 'id' | 'subgraphError'>>;
  tokenSwaps?: Resolver<Array<ResolversTypes['TokenSwap']>, ParentType, ContextType, RequireFields<QuerytokenSwapsArgs, 'skip' | 'first' | 'subgraphError'>>;
  limitOrderFill?: Resolver<Maybe<ResolversTypes['LimitOrderFill']>, ParentType, ContextType, RequireFields<QuerylimitOrderFillArgs, 'id' | 'subgraphError'>>;
  limitOrderFills?: Resolver<Array<ResolversTypes['LimitOrderFill']>, ParentType, ContextType, RequireFields<QuerylimitOrderFillsArgs, 'skip' | 'first' | 'subgraphError'>>;
  joinRequest?: Resolver<Maybe<ResolversTypes['JoinRequest']>, ParentType, ContextType, RequireFields<QueryjoinRequestArgs, 'id' | 'subgraphError'>>;
  joinRequests?: Resolver<Array<ResolversTypes['JoinRequest']>, ParentType, ContextType, RequireFields<QueryjoinRequestsArgs, 'skip' | 'first' | 'subgraphError'>>;
  partyActivity?: Resolver<Maybe<ResolversTypes['PartyActivity']>, ParentType, ContextType, RequireFields<QuerypartyActivityArgs, 'id' | 'subgraphError'>>;
  partyActivities?: Resolver<Array<ResolversTypes['PartyActivity']>, ParentType, ContextType, RequireFields<QuerypartyActivitiesArgs, 'skip' | 'first' | 'subgraphError'>>;
  network?: Resolver<Maybe<ResolversTypes['Network']>, ParentType, ContextType, RequireFields<QuerynetworkArgs, 'id' | 'subgraphError'>>;
  networks?: Resolver<Array<ResolversTypes['Network']>, ParentType, ContextType, RequireFields<QuerynetworksArgs, 'skip' | 'first' | 'subgraphError'>>;
  networkState?: Resolver<Maybe<ResolversTypes['NetworkState']>, ParentType, ContextType, RequireFields<QuerynetworkStateArgs, 'id' | 'subgraphError'>>;
  networkStates?: Resolver<Array<ResolversTypes['NetworkState']>, ParentType, ContextType, RequireFields<QuerynetworkStatesArgs, 'skip' | 'first' | 'subgraphError'>>;
  networkAssetHolding?: Resolver<Maybe<ResolversTypes['NetworkAssetHolding']>, ParentType, ContextType, RequireFields<QuerynetworkAssetHoldingArgs, 'id' | 'subgraphError'>>;
  networkAssetHoldings?: Resolver<Array<ResolversTypes['NetworkAssetHolding']>, ParentType, ContextType, RequireFields<QuerynetworkAssetHoldingsArgs, 'skip' | 'first' | 'subgraphError'>>;
  _meta?: Resolver<Maybe<ResolversTypes['_Meta_']>, ParentType, ContextType, Partial<Query_metaArgs>>;
}>;

export type SubscriptionResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = ResolversObject<{
  assetAmount?: SubscriptionResolver<Maybe<ResolversTypes['AssetAmount']>, "assetAmount", ParentType, ContextType, RequireFields<SubscriptionassetAmountArgs, 'id' | 'subgraphError'>>;
  assetAmounts?: SubscriptionResolver<Array<ResolversTypes['AssetAmount']>, "assetAmounts", ParentType, ContextType, RequireFields<SubscriptionassetAmountsArgs, 'skip' | 'first' | 'subgraphError'>>;
  sharesBoughtEvent?: SubscriptionResolver<Maybe<ResolversTypes['SharesBoughtEvent']>, "sharesBoughtEvent", ParentType, ContextType, RequireFields<SubscriptionsharesBoughtEventArgs, 'id' | 'subgraphError'>>;
  sharesBoughtEvents?: SubscriptionResolver<Array<ResolversTypes['SharesBoughtEvent']>, "sharesBoughtEvents", ParentType, ContextType, RequireFields<SubscriptionsharesBoughtEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  sharesRedeemedEvent?: SubscriptionResolver<Maybe<ResolversTypes['SharesRedeemedEvent']>, "sharesRedeemedEvent", ParentType, ContextType, RequireFields<SubscriptionsharesRedeemedEventArgs, 'id' | 'subgraphError'>>;
  sharesRedeemedEvents?: SubscriptionResolver<Array<ResolversTypes['SharesRedeemedEvent']>, "sharesRedeemedEvents", ParentType, ContextType, RequireFields<SubscriptionsharesRedeemedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  party?: SubscriptionResolver<Maybe<ResolversTypes['Party']>, "party", ParentType, ContextType, RequireFields<SubscriptionpartyArgs, 'id' | 'subgraphError'>>;
  parties?: SubscriptionResolver<Array<ResolversTypes['Party']>, "parties", ParentType, ContextType, RequireFields<SubscriptionpartiesArgs, 'skip' | 'first' | 'subgraphError'>>;
  user?: SubscriptionResolver<Maybe<ResolversTypes['User']>, "user", ParentType, ContextType, RequireFields<SubscriptionuserArgs, 'id' | 'subgraphError'>>;
  users?: SubscriptionResolver<Array<ResolversTypes['User']>, "users", ParentType, ContextType, RequireFields<SubscriptionusersArgs, 'skip' | 'first' | 'subgraphError'>>;
  userParty?: SubscriptionResolver<Maybe<ResolversTypes['UserParty']>, "userParty", ParentType, ContextType, RequireFields<SubscriptionuserPartyArgs, 'id' | 'subgraphError'>>;
  userParties?: SubscriptionResolver<Array<ResolversTypes['UserParty']>, "userParties", ParentType, ContextType, RequireFields<SubscriptionuserPartiesArgs, 'skip' | 'first' | 'subgraphError'>>;
  partyTokenGate?: SubscriptionResolver<Maybe<ResolversTypes['PartyTokenGate']>, "partyTokenGate", ParentType, ContextType, RequireFields<SubscriptionpartyTokenGateArgs, 'id' | 'subgraphError'>>;
  partyTokenGates?: SubscriptionResolver<Array<ResolversTypes['PartyTokenGate']>, "partyTokenGates", ParentType, ContextType, RequireFields<SubscriptionpartyTokenGatesArgs, 'skip' | 'first' | 'subgraphError'>>;
  tokenGate?: SubscriptionResolver<Maybe<ResolversTypes['TokenGate']>, "tokenGate", ParentType, ContextType, RequireFields<SubscriptiontokenGateArgs, 'id' | 'subgraphError'>>;
  tokenGates?: SubscriptionResolver<Array<ResolversTypes['TokenGate']>, "tokenGates", ParentType, ContextType, RequireFields<SubscriptiontokenGatesArgs, 'skip' | 'first' | 'subgraphError'>>;
  investment?: SubscriptionResolver<Maybe<ResolversTypes['Investment']>, "investment", ParentType, ContextType, RequireFields<SubscriptioninvestmentArgs, 'id' | 'subgraphError'>>;
  investments?: SubscriptionResolver<Array<ResolversTypes['Investment']>, "investments", ParentType, ContextType, RequireFields<SubscriptioninvestmentsArgs, 'skip' | 'first' | 'subgraphError'>>;
  investmentState?: SubscriptionResolver<Maybe<ResolversTypes['InvestmentState']>, "investmentState", ParentType, ContextType, RequireFields<SubscriptioninvestmentStateArgs, 'id' | 'subgraphError'>>;
  investmentStates?: SubscriptionResolver<Array<ResolversTypes['InvestmentState']>, "investmentStates", ParentType, ContextType, RequireFields<SubscriptioninvestmentStatesArgs, 'skip' | 'first' | 'subgraphError'>>;
  shareState?: SubscriptionResolver<Maybe<ResolversTypes['ShareState']>, "shareState", ParentType, ContextType, RequireFields<SubscriptionshareStateArgs, 'id' | 'subgraphError'>>;
  shareStates?: SubscriptionResolver<Array<ResolversTypes['ShareState']>, "shareStates", ParentType, ContextType, RequireFields<SubscriptionshareStatesArgs, 'skip' | 'first' | 'subgraphError'>>;
  portfolioState?: SubscriptionResolver<Maybe<ResolversTypes['PortfolioState']>, "portfolioState", ParentType, ContextType, RequireFields<SubscriptionportfolioStateArgs, 'id' | 'subgraphError'>>;
  portfolioStates?: SubscriptionResolver<Array<ResolversTypes['PortfolioState']>, "portfolioStates", ParentType, ContextType, RequireFields<SubscriptionportfolioStatesArgs, 'skip' | 'first' | 'subgraphError'>>;
  holdingState?: SubscriptionResolver<Maybe<ResolversTypes['HoldingState']>, "holdingState", ParentType, ContextType, RequireFields<SubscriptionholdingStateArgs, 'id' | 'subgraphError'>>;
  holdingStates?: SubscriptionResolver<Array<ResolversTypes['HoldingState']>, "holdingStates", ParentType, ContextType, RequireFields<SubscriptionholdingStatesArgs, 'skip' | 'first' | 'subgraphError'>>;
  partyState?: SubscriptionResolver<Maybe<ResolversTypes['PartyState']>, "partyState", ParentType, ContextType, RequireFields<SubscriptionpartyStateArgs, 'id' | 'subgraphError'>>;
  partyStates?: SubscriptionResolver<Array<ResolversTypes['PartyState']>, "partyStates", ParentType, ContextType, RequireFields<SubscriptionpartyStatesArgs, 'skip' | 'first' | 'subgraphError'>>;
  investmentCost?: SubscriptionResolver<Maybe<ResolversTypes['InvestmentCost']>, "investmentCost", ParentType, ContextType, RequireFields<SubscriptioninvestmentCostArgs, 'id' | 'subgraphError'>>;
  investmentCosts?: SubscriptionResolver<Array<ResolversTypes['InvestmentCost']>, "investmentCosts", ParentType, ContextType, RequireFields<SubscriptioninvestmentCostsArgs, 'skip' | 'first' | 'subgraphError'>>;
  asset?: SubscriptionResolver<Maybe<ResolversTypes['Asset']>, "asset", ParentType, ContextType, RequireFields<SubscriptionassetArgs, 'id' | 'subgraphError'>>;
  assets?: SubscriptionResolver<Array<ResolversTypes['Asset']>, "assets", ParentType, ContextType, RequireFields<SubscriptionassetsArgs, 'skip' | 'first' | 'subgraphError'>>;
  tokenSwap?: SubscriptionResolver<Maybe<ResolversTypes['TokenSwap']>, "tokenSwap", ParentType, ContextType, RequireFields<SubscriptiontokenSwapArgs, 'id' | 'subgraphError'>>;
  tokenSwaps?: SubscriptionResolver<Array<ResolversTypes['TokenSwap']>, "tokenSwaps", ParentType, ContextType, RequireFields<SubscriptiontokenSwapsArgs, 'skip' | 'first' | 'subgraphError'>>;
  limitOrderFill?: SubscriptionResolver<Maybe<ResolversTypes['LimitOrderFill']>, "limitOrderFill", ParentType, ContextType, RequireFields<SubscriptionlimitOrderFillArgs, 'id' | 'subgraphError'>>;
  limitOrderFills?: SubscriptionResolver<Array<ResolversTypes['LimitOrderFill']>, "limitOrderFills", ParentType, ContextType, RequireFields<SubscriptionlimitOrderFillsArgs, 'skip' | 'first' | 'subgraphError'>>;
  joinRequest?: SubscriptionResolver<Maybe<ResolversTypes['JoinRequest']>, "joinRequest", ParentType, ContextType, RequireFields<SubscriptionjoinRequestArgs, 'id' | 'subgraphError'>>;
  joinRequests?: SubscriptionResolver<Array<ResolversTypes['JoinRequest']>, "joinRequests", ParentType, ContextType, RequireFields<SubscriptionjoinRequestsArgs, 'skip' | 'first' | 'subgraphError'>>;
  partyActivity?: SubscriptionResolver<Maybe<ResolversTypes['PartyActivity']>, "partyActivity", ParentType, ContextType, RequireFields<SubscriptionpartyActivityArgs, 'id' | 'subgraphError'>>;
  partyActivities?: SubscriptionResolver<Array<ResolversTypes['PartyActivity']>, "partyActivities", ParentType, ContextType, RequireFields<SubscriptionpartyActivitiesArgs, 'skip' | 'first' | 'subgraphError'>>;
  network?: SubscriptionResolver<Maybe<ResolversTypes['Network']>, "network", ParentType, ContextType, RequireFields<SubscriptionnetworkArgs, 'id' | 'subgraphError'>>;
  networks?: SubscriptionResolver<Array<ResolversTypes['Network']>, "networks", ParentType, ContextType, RequireFields<SubscriptionnetworksArgs, 'skip' | 'first' | 'subgraphError'>>;
  networkState?: SubscriptionResolver<Maybe<ResolversTypes['NetworkState']>, "networkState", ParentType, ContextType, RequireFields<SubscriptionnetworkStateArgs, 'id' | 'subgraphError'>>;
  networkStates?: SubscriptionResolver<Array<ResolversTypes['NetworkState']>, "networkStates", ParentType, ContextType, RequireFields<SubscriptionnetworkStatesArgs, 'skip' | 'first' | 'subgraphError'>>;
  networkAssetHolding?: SubscriptionResolver<Maybe<ResolversTypes['NetworkAssetHolding']>, "networkAssetHolding", ParentType, ContextType, RequireFields<SubscriptionnetworkAssetHoldingArgs, 'id' | 'subgraphError'>>;
  networkAssetHoldings?: SubscriptionResolver<Array<ResolversTypes['NetworkAssetHolding']>, "networkAssetHoldings", ParentType, ContextType, RequireFields<SubscriptionnetworkAssetHoldingsArgs, 'skip' | 'first' | 'subgraphError'>>;
  _meta?: SubscriptionResolver<Maybe<ResolversTypes['_Meta_']>, "_meta", ParentType, ContextType, Partial<Subscription_metaArgs>>;
}>;

export type AssetResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['Asset'] = ResolversParentTypes['Asset']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  decimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AssetType'], ParentType, ContextType>;
  firstSeen?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  networkAssetHolding?: Resolver<ResolversTypes['NetworkAssetHolding'], ParentType, ContextType>;
  networkAssetHoldingHistory?: Resolver<Array<ResolversTypes['NetworkAssetHolding']>, ParentType, ContextType, RequireFields<AssetnetworkAssetHoldingHistoryArgs, 'skip' | 'first'>>;
  networkId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AssetAmountResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['AssetAmount'] = ResolversParentTypes['AssetAmount']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  asset?: Resolver<ResolversTypes['Asset'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface BigDecimalScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigDecimal'], any> {
  name: 'BigDecimal';
}

export interface BigIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt';
}

export interface BytesScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Bytes'], any> {
  name: 'Bytes';
}

export type HoldingStateResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['HoldingState'] = ResolversParentTypes['HoldingState']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  party?: Resolver<ResolversTypes['Party'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  asset?: Resolver<ResolversTypes['Asset'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface Int8ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Int8'], any> {
  name: 'Int8';
}

export type InvestmentResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['Investment'] = ResolversParentTypes['Investment']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  since?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  party?: Resolver<ResolversTypes['Party'], ParentType, ContextType>;
  investor?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  shares?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['InvestmentState'], ParentType, ContextType>;
  stateHistory?: Resolver<Array<ResolversTypes['InvestmentState']>, ParentType, ContextType, RequireFields<InvestmentstateHistoryArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InvestmentCostResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['InvestmentCost'] = ResolversParentTypes['InvestmentCost']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  party?: Resolver<ResolversTypes['Party'], ParentType, ContextType>;
  latestTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  latestBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  depositCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDeposits?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  withdrawalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalWithdrawals?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  withUncalculatedPrices?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  deposits?: Resolver<Array<ResolversTypes['SharesBoughtEvent']>, ParentType, ContextType, RequireFields<InvestmentCostdepositsArgs, 'skip' | 'first'>>;
  withdrawals?: Resolver<Array<ResolversTypes['SharesRedeemedEvent']>, ParentType, ContextType, RequireFields<InvestmentCostwithdrawalsArgs, 'skip' | 'first'>>;
  networkId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  partySlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InvestmentStateResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['InvestmentState'] = ResolversParentTypes['InvestmentState']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  party?: Resolver<ResolversTypes['Party'], ParentType, ContextType>;
  investor?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  investment?: Resolver<ResolversTypes['Investment'], ParentType, ContextType>;
  shares?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  partyState?: Resolver<ResolversTypes['PartyState'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JoinRequestResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['JoinRequest'] = ResolversParentTypes['JoinRequest']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  party?: Resolver<ResolversTypes['Party'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  requestedSince?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  handledAt?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['JoinRequestStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LimitOrderFillResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['LimitOrderFill'] = ResolversParentTypes['LimitOrderFill']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  party?: Resolver<ResolversTypes['Party'], ParentType, ContextType>;
  transaction?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  sellToken?: Resolver<ResolversTypes['Asset'], ParentType, ContextType>;
  buyToken?: Resolver<ResolversTypes['Asset'], ParentType, ContextType>;
  soldAmount?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  boughtAmount?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  fee?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NetworkResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['Network'] = ResolversParentTypes['Network']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['NetworkState'], ParentType, ContextType>;
  stateHistory?: Resolver<Array<ResolversTypes['NetworkState']>, ParentType, ContextType, RequireFields<NetworkstateHistoryArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NetworkAssetHoldingResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['NetworkAssetHolding'] = ResolversParentTypes['NetworkAssetHolding']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  asset?: Resolver<ResolversTypes['Asset'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NetworkStateResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['NetworkState'] = ResolversParentTypes['NetworkState']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  network?: Resolver<ResolversTypes['Network'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  assetHoldings?: Resolver<Array<ResolversTypes['NetworkAssetHolding']>, ParentType, ContextType, RequireFields<NetworkStateassetHoldingsArgs, 'skip' | 'first'>>;
  parties?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  investors?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  deposits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDeposits?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  withdraws?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalWithdrawals?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  withUncalculatedPrices?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PartyResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['Party'] = ResolversParentTypes['Party']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  img?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  model?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  purpose?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isPublic?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  minDeposit?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  maxDeposit?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  inception?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  denominationAsset?: Resolver<ResolversTypes['Asset'], ParentType, ContextType>;
  infoUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  isClosed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  members?: Resolver<Array<ResolversTypes['UserParty']>, ParentType, ContextType, RequireFields<PartymembersArgs, 'skip' | 'first'>>;
  membersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  requests?: Resolver<Array<ResolversTypes['JoinRequest']>, ParentType, ContextType, RequireFields<PartyrequestsArgs, 'skip' | 'first'>>;
  partyTokenGate?: Resolver<Maybe<ResolversTypes['PartyTokenGate']>, ParentType, ContextType>;
  trackedAssets?: Resolver<Array<ResolversTypes['Asset']>, ParentType, ContextType, RequireFields<PartytrackedAssetsArgs, 'skip' | 'first'>>;
  investments?: Resolver<Array<ResolversTypes['Investment']>, ParentType, ContextType, RequireFields<PartyinvestmentsArgs, 'skip' | 'first'>>;
  investmentCost?: Resolver<Maybe<ResolversTypes['InvestmentCost']>, ParentType, ContextType>;
  shares?: Resolver<ResolversTypes['ShareState'], ParentType, ContextType>;
  sharesHistory?: Resolver<Array<ResolversTypes['ShareState']>, ParentType, ContextType, RequireFields<PartysharesHistoryArgs, 'skip' | 'first'>>;
  portfolio?: Resolver<ResolversTypes['PortfolioState'], ParentType, ContextType>;
  portfolioHistory?: Resolver<Array<ResolversTypes['PortfolioState']>, ParentType, ContextType, RequireFields<PartyportfolioHistoryArgs, 'skip' | 'first'>>;
  state?: Resolver<ResolversTypes['PartyState'], ParentType, ContextType>;
  stateHistory?: Resolver<Array<ResolversTypes['PartyState']>, ParentType, ContextType, RequireFields<PartystateHistoryArgs, 'skip' | 'first'>>;
  firstInvestmentState?: Resolver<Maybe<ResolversTypes['PartyState']>, ParentType, ContextType>;
  lastActivity?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  networkId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PartyActivityResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['PartyActivity'] = ResolversParentTypes['PartyActivity']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  txHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  event?: Resolver<ResolversTypes['PartyEventType'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  incomingAssets?: Resolver<Maybe<Array<ResolversTypes['AssetAmount']>>, ParentType, ContextType, RequireFields<PartyActivityincomingAssetsArgs, 'skip' | 'first'>>;
  outcomingAssets?: Resolver<Maybe<Array<ResolversTypes['AssetAmount']>>, ParentType, ContextType, RequireFields<PartyActivityoutcomingAssetsArgs, 'skip' | 'first'>>;
  incomingShares?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>;
  outcomingShares?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>;
  party?: Resolver<ResolversTypes['Party'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PartyStateResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['PartyState'] = ResolversParentTypes['PartyState']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  party?: Resolver<ResolversTypes['Party'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  shares?: Resolver<ResolversTypes['ShareState'], ParentType, ContextType>;
  portfolio?: Resolver<ResolversTypes['PortfolioState'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PartyTokenGateResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['PartyTokenGate'] = ResolversParentTypes['PartyTokenGate']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  party?: Resolver<ResolversTypes['Party'], ParentType, ContextType>;
  tokens?: Resolver<Maybe<Array<ResolversTypes['TokenGate']>>, ParentType, ContextType, RequireFields<PartyTokenGatetokensArgs, 'skip' | 'first'>>;
  updatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PortfolioStateResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['PortfolioState'] = ResolversParentTypes['PortfolioState']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  party?: Resolver<ResolversTypes['Party'], ParentType, ContextType>;
  isLatest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  holdings?: Resolver<Array<ResolversTypes['HoldingState']>, ParentType, ContextType, RequireFields<PortfolioStateholdingsArgs, 'skip' | 'first'>>;
  value?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  withUncalculatedPrices?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  networkId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  partySlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ShareStateResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['ShareState'] = ResolversParentTypes['ShareState']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  party?: Resolver<ResolversTypes['Party'], ParentType, ContextType>;
  isLatest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalSupply?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SharesBoughtEventResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['SharesBoughtEvent'] = ResolversParentTypes['SharesBoughtEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  party?: Resolver<ResolversTypes['Party'], ParentType, ContextType>;
  investmentCost?: Resolver<ResolversTypes['InvestmentCost'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ShareChangeType'], ParentType, ContextType>;
  investor?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  investmentState?: Resolver<ResolversTypes['InvestmentState'], ParentType, ContextType>;
  shares?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  asset?: Resolver<ResolversTypes['Asset'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  fee?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  withUncalculatedPrices?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  investmentAmount?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  feeAmount?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  sharesIssued?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  partyState?: Resolver<ResolversTypes['PartyState'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SharesRedeemedEventResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['SharesRedeemedEvent'] = ResolversParentTypes['SharesRedeemedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  party?: Resolver<ResolversTypes['Party'], ParentType, ContextType>;
  investmentCost?: Resolver<ResolversTypes['InvestmentCost'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ShareChangeType'], ParentType, ContextType>;
  investor?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  investmentState?: Resolver<ResolversTypes['InvestmentState'], ParentType, ContextType>;
  shares?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  redeemedAmount?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  fee?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  redeemedAmounts?: Resolver<Array<ResolversTypes['AssetAmount']>, ParentType, ContextType, RequireFields<SharesRedeemedEventredeemedAmountsArgs, 'skip' | 'first'>>;
  payoutAssetFees?: Resolver<Array<ResolversTypes['AssetAmount']>, ParentType, ContextType, RequireFields<SharesRedeemedEventpayoutAssetFeesArgs, 'skip' | 'first'>>;
  withLiquidation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  withUncalculatedPrices?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  partyState?: Resolver<ResolversTypes['PartyState'], ParentType, ContextType>;
  networkId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  partySlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenGateResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['TokenGate'] = ResolversParentTypes['TokenGate']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  partyTokenGate?: Resolver<ResolversTypes['PartyTokenGate'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  decimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AssetType'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenSwapResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['TokenSwap'] = ResolversParentTypes['TokenSwap']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  party?: Resolver<ResolversTypes['Party'], ParentType, ContextType>;
  transaction?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  sellToken?: Resolver<ResolversTypes['Asset'], ParentType, ContextType>;
  buyToken?: Resolver<ResolversTypes['Asset'], ParentType, ContextType>;
  soldAmount?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  boughtAmount?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  fee?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  firstSeen?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  partyOwner?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  partyOwnerSince?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  partyOwning?: Resolver<Array<ResolversTypes['Party']>, ParentType, ContextType, RequireFields<UserpartyOwningArgs, 'skip' | 'first'>>;
  investor?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  investorSince?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  investments?: Resolver<Array<ResolversTypes['Investment']>, ParentType, ContextType, RequireFields<UserinvestmentsArgs, 'skip' | 'first'>>;
  parties?: Resolver<Maybe<Array<ResolversTypes['UserParty']>>, ParentType, ContextType, RequireFields<UserpartiesArgs, 'skip' | 'first'>>;
  requests?: Resolver<Maybe<Array<ResolversTypes['JoinRequest']>>, ParentType, ContextType, RequireFields<UserrequestsArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserPartyResolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['UserParty'] = ResolversParentTypes['UserParty']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  joinedSince?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  leftAt?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  party?: Resolver<ResolversTypes['Party'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['UserPartyStatus']>, ParentType, ContextType>;
  isCreator?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  creatorSince?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  isManager?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  managerSince?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _Block_Resolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['_Block_'] = ResolversParentTypes['_Block_']> = ResolversObject<{
  hash?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _Meta_Resolvers<ContextType = MeshContext & { networkId: number }, ParentType extends ResolversParentTypes['_Meta_'] = ResolversParentTypes['_Meta_']> = ResolversObject<{
  block?: Resolver<ResolversTypes['_Block_'], ParentType, ContextType>;
  deployment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasIndexingErrors?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = MeshContext & { networkId: number }> = ResolversObject<{
  Query?: QueryResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  Asset?: AssetResolvers<ContextType>;
  AssetAmount?: AssetAmountResolvers<ContextType>;
  BigDecimal?: GraphQLScalarType;
  BigInt?: GraphQLScalarType;
  Bytes?: GraphQLScalarType;
  HoldingState?: HoldingStateResolvers<ContextType>;
  Int8?: GraphQLScalarType;
  Investment?: InvestmentResolvers<ContextType>;
  InvestmentCost?: InvestmentCostResolvers<ContextType>;
  InvestmentState?: InvestmentStateResolvers<ContextType>;
  JoinRequest?: JoinRequestResolvers<ContextType>;
  LimitOrderFill?: LimitOrderFillResolvers<ContextType>;
  Network?: NetworkResolvers<ContextType>;
  NetworkAssetHolding?: NetworkAssetHoldingResolvers<ContextType>;
  NetworkState?: NetworkStateResolvers<ContextType>;
  Party?: PartyResolvers<ContextType>;
  PartyActivity?: PartyActivityResolvers<ContextType>;
  PartyState?: PartyStateResolvers<ContextType>;
  PartyTokenGate?: PartyTokenGateResolvers<ContextType>;
  PortfolioState?: PortfolioStateResolvers<ContextType>;
  ShareState?: ShareStateResolvers<ContextType>;
  SharesBoughtEvent?: SharesBoughtEventResolvers<ContextType>;
  SharesRedeemedEvent?: SharesRedeemedEventResolvers<ContextType>;
  TokenGate?: TokenGateResolvers<ContextType>;
  TokenSwap?: TokenSwapResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserParty?: UserPartyResolvers<ContextType>;
  _Block_?: _Block_Resolvers<ContextType>;
  _Meta_?: _Meta_Resolvers<ContextType>;
}>;

export type DirectiveResolvers<ContextType = MeshContext & { networkId: number }> = ResolversObject<{
  entity?: entityDirectiveResolver<any, any, ContextType>;
  subgraphId?: subgraphIdDirectiveResolver<any, any, ContextType>;
  derivedFrom?: derivedFromDirectiveResolver<any, any, ContextType>;
}>;

export type MeshContext = PartyFinanceTypes.Context & BaseMeshContext;


const baseDir = pathModule.join(typeof __dirname === 'string' ? __dirname : '/', '..');

const importFn: ImportFn = <T>(moduleId: string) => {
  const relativeModuleId = (pathModule.isAbsolute(moduleId) ? pathModule.relative(baseDir, moduleId) : moduleId).split('\\').join('/').replace(baseDir + '/', '');
  switch(relativeModuleId) {
    case ".graphclient/sources/PartyFinance/introspectionSchema":
      return Promise.resolve(importedModule$0) as T;
    
    default:
      return Promise.reject(new Error(`Cannot find module '${relativeModuleId}'.`));
  }
};

const rootStore = new MeshStore('.graphclient', new FsStoreStorageAdapter({
  cwd: baseDir,
  importFn,
  fileType: "ts",
}), {
  readonly: true,
  validate: false
});

export const rawServeConfig: YamlConfig.Config['serve'] = undefined as any
export async function getMeshOptions(): Promise<GetMeshOptions> {
const pubsub = new PubSub();
const sourcesStore = rootStore.child('sources');
const logger = new DefaultLogger("GraphClient");
const cache = new (MeshCache as any)({
      ...({} as any),
      importFn,
      store: rootStore.child('cache'),
      pubsub,
      logger,
    } as any)

const sources: MeshResolvedSource[] = [];
const transforms: MeshTransform[] = [];
const additionalEnvelopPlugins: MeshPlugin<any>[] = [];
const partyFinanceTransforms = [];
const partyFinanceHandler = new GraphqlHandler({
              name: "PartyFinance",
              config: {"endpoint":"https://{context.baseUrl:api.thegraph.com/subgraphs/name/party-finance}/{context.subgraphId:partyfinance-optimism}"},
              baseDir,
              cache,
              pubsub,
              store: sourcesStore.child("PartyFinance"),
              logger: logger.child("PartyFinance"),
              importFn,
            });
sources[0] = {
          name: 'PartyFinance',
          handler: partyFinanceHandler,
          transforms: partyFinanceTransforms
        }
const additionalTypeDefs = [parse("extend type Party {\n  networkId: Int!\n  slug: String!\n}\n\nextend type SharesRedeemedEvent {\n  networkId: Int!\n  partySlug: String!\n}\n\nextend type InvestmentCost {\n  networkId: Int!\n  partySlug: String!\n}\n\nextend type PortfolioState {\n  networkId: Int!\n  partySlug: String!\n}\n\nextend type Asset {\n  networkId: Int!\n  slug: String!\n}"),] as any[];
const additionalResolvers = await Promise.all([
        import("../resolvers.ts")
            .then(m => m.resolvers || m.default || m)
      ]);
const merger = new(BareMerger as any)({
        cache,
        pubsub,
        logger: logger.child('bareMerger'),
        store: rootStore.child('bareMerger')
      })

  return {
    sources,
    transforms,
    additionalTypeDefs,
    additionalResolvers,
    cache,
    pubsub,
    merger,
    logger,
    additionalEnvelopPlugins,
    get documents() {
      return [
      {
        document: AssetsDocument,
        get rawSDL() {
          return printWithCache(AssetsDocument);
        },
        location: 'AssetsDocument.graphql'
      },{
        document: BlockDocument,
        get rawSDL() {
          return printWithCache(BlockDocument);
        },
        location: 'BlockDocument.graphql'
      },{
        document: InvestmentCostsDocument,
        get rawSDL() {
          return printWithCache(InvestmentCostsDocument);
        },
        location: 'InvestmentCostsDocument.graphql'
      },{
        document: PartiesDocument,
        get rawSDL() {
          return printWithCache(PartiesDocument);
        },
        location: 'PartiesDocument.graphql'
      },{
        document: PartiesInfoDocument,
        get rawSDL() {
          return printWithCache(PartiesInfoDocument);
        },
        location: 'PartiesInfoDocument.graphql'
      },{
        document: PartiesWithFiltersDocument,
        get rawSDL() {
          return printWithCache(PartiesWithFiltersDocument);
        },
        location: 'PartiesWithFiltersDocument.graphql'
      },{
        document: PartyActivityDocument,
        get rawSDL() {
          return printWithCache(PartyActivityDocument);
        },
        location: 'PartyActivityDocument.graphql'
      },{
        document: PartyHoldingsDocument,
        get rawSDL() {
          return printWithCache(PartyHoldingsDocument);
        },
        location: 'PartyHoldingsDocument.graphql'
      },{
        document: PartyInfoDocument,
        get rawSDL() {
          return printWithCache(PartyInfoDocument);
        },
        location: 'PartyInfoDocument.graphql'
      },{
        document: PartyJoinRequestsDocument,
        get rawSDL() {
          return printWithCache(PartyJoinRequestsDocument);
        },
        location: 'PartyJoinRequestsDocument.graphql'
      },{
        document: PartyManagersDocument,
        get rawSDL() {
          return printWithCache(PartyManagersDocument);
        },
        location: 'PartyManagersDocument.graphql'
      },{
        document: PartyMemberHistoricalStateDocument,
        get rawSDL() {
          return printWithCache(PartyMemberHistoricalStateDocument);
        },
        location: 'PartyMemberHistoricalStateDocument.graphql'
      },{
        document: PartyMembersDocument,
        get rawSDL() {
          return printWithCache(PartyMembersDocument);
        },
        location: 'PartyMembersDocument.graphql'
      },{
        document: PartyPortfolioDocument,
        get rawSDL() {
          return printWithCache(PartyPortfolioDocument);
        },
        location: 'PartyPortfolioDocument.graphql'
      },{
        document: PartyTokenGateDocument,
        get rawSDL() {
          return printWithCache(PartyTokenGateDocument);
        },
        location: 'PartyTokenGateDocument.graphql'
      },{
        document: PartyTransactionsDocument,
        get rawSDL() {
          return printWithCache(PartyTransactionsDocument);
        },
        location: 'PartyTransactionsDocument.graphql'
      },{
        document: PortfoliosDocument,
        get rawSDL() {
          return printWithCache(PortfoliosDocument);
        },
        location: 'PortfoliosDocument.graphql'
      },{
        document: PortfolioSharesHistoryDocument,
        get rawSDL() {
          return printWithCache(PortfolioSharesHistoryDocument);
        },
        location: 'PortfolioSharesHistoryDocument.graphql'
      },{
        document: PortfolioSharesSnapshotDocument,
        get rawSDL() {
          return printWithCache(PortfolioSharesSnapshotDocument);
        },
        location: 'PortfolioSharesSnapshotDocument.graphql'
      },{
        document: PublicPartiesDocument,
        get rawSDL() {
          return printWithCache(PublicPartiesDocument);
        },
        location: 'PublicPartiesDocument.graphql'
      },{
        document: UserPartiesDocument,
        get rawSDL() {
          return printWithCache(UserPartiesDocument);
        },
        location: 'UserPartiesDocument.graphql'
      },{
        document: UserPartyDepositsDocument,
        get rawSDL() {
          return printWithCache(UserPartyDepositsDocument);
        },
        location: 'UserPartyDepositsDocument.graphql'
      },{
        document: UserPartyRoleDocument,
        get rawSDL() {
          return printWithCache(UserPartyRoleDocument);
        },
        location: 'UserPartyRoleDocument.graphql'
      },{
        document: UserPartySharesDocument,
        get rawSDL() {
          return printWithCache(UserPartySharesDocument);
        },
        location: 'UserPartySharesDocument.graphql'
      },{
        document: UserPartyWithdrawalsDocument,
        get rawSDL() {
          return printWithCache(UserPartyWithdrawalsDocument);
        },
        location: 'UserPartyWithdrawalsDocument.graphql'
      },{
        document: UncalculatedWithdrawalsDocument,
        get rawSDL() {
          return printWithCache(UncalculatedWithdrawalsDocument);
        },
        location: 'UncalculatedWithdrawalsDocument.graphql'
      }
    ];
    },
    fetchFn,
  };
}

export function createBuiltMeshHTTPHandler<TServerContext = {}>(): MeshHTTPHandler<TServerContext> {
  return createMeshHTTPHandler<TServerContext>({
    baseDir,
    getBuiltMesh: getBuiltGraphClient,
    rawServeConfig: undefined,
  })
}


let meshInstance$: Promise<MeshInstance> | undefined;

export function getBuiltGraphClient(): Promise<MeshInstance> {
  if (meshInstance$ == null) {
    meshInstance$ = getMeshOptions().then(meshOptions => getMesh(meshOptions)).then(mesh => {
      const id = mesh.pubsub.subscribe('destroy', () => {
        meshInstance$ = undefined;
        mesh.pubsub.unsubscribe(id);
      });
      return mesh;
    });
  }
  return meshInstance$;
}

export const execute: ExecuteMeshFn = (...args) => getBuiltGraphClient().then(({ execute }) => execute(...args));

export const subscribe: SubscribeMeshFn = (...args) => getBuiltGraphClient().then(({ subscribe }) => subscribe(...args));
export function getBuiltGraphSDK<TGlobalContext = any, TOperationContext = any>(globalContext?: TGlobalContext) {
  const sdkRequester$ = getBuiltGraphClient().then(({ sdkRequesterFactory }) => sdkRequesterFactory(globalContext));
  return getSdk<TOperationContext, TGlobalContext>((...args) => sdkRequester$.then(sdkRequester => sdkRequester(...args)));
}
export type AssetsQueryVariables = Exact<{
  firstSeen: Scalars['BigInt'];
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type AssetsQuery = { assets: Array<Pick<Asset, 'networkId' | 'slug' | 'id' | 'name' | 'symbol' | 'decimals' | 'firstSeen'>> };

export type BlockQueryVariables = Exact<{ [key: string]: never; }>;


export type BlockQuery = { _meta?: Maybe<{ block: Pick<_Block_, 'hash' | 'timestamp' | 'number'> }> };

export type InvestmentCostsQueryVariables = Exact<{
  latestTimestamp: Scalars['BigInt'];
  ids: Array<Scalars['ID']> | Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type InvestmentCostsQuery = { investmentCosts: Array<(
    Pick<InvestmentCost, 'networkId' | 'partySlug' | 'depositCount' | 'totalDeposits' | 'withdrawalCount' | 'totalWithdrawals' | 'latestTimestamp' | 'latestBlockNumber'>
    & { party: Pick<Party, 'id'> }
  )> };

export type PartiesQueryVariables = Exact<{
  infoUpdatedAt: Scalars['BigInt'];
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type PartiesQuery = { parties: Array<(
    Pick<Party, 'networkId' | 'slug' | 'id' | 'name' | 'isPublic' | 'isClosed' | 'inception' | 'infoUpdatedAt'>
    & { denominationAsset: Pick<Asset, 'id'> }
  )> };

export type PartiesInfoQueryVariables = Exact<{
  partyAddresses: Array<Scalars['ID']> | Scalars['ID'];
  isClosed?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type PartiesInfoQuery = { parties: Array<(
    Pick<Party, 'inception' | 'id' | 'name' | 'img' | 'model' | 'purpose' | 'minDeposit' | 'maxDeposit' | 'membersCount'>
    & { denominationAsset: Pick<Asset, 'id' | 'decimals' | 'symbol'>, creator: Pick<User, 'id'> }
  )> };

export type PartiesWithFiltersQueryVariables = Exact<{
  partyAddresses: Array<Scalars['ID']> | Scalars['ID'];
  inception: Scalars['BigInt'];
  isPublic: Scalars['Boolean'];
  isClosed?: InputMaybe<Scalars['Boolean']>;
}>;


export type PartiesWithFiltersQuery = { parties: Array<(
    Pick<Party, 'inception' | 'id' | 'name' | 'img' | 'model' | 'purpose' | 'minDeposit' | 'maxDeposit' | 'membersCount'>
    & { denominationAsset: Pick<Asset, 'id' | 'decimals' | 'symbol'>, creator: Pick<User, 'id'> }
  )> };

export type PartyActivityQueryVariables = Exact<{
  partyAddressId: Scalars['ID'];
  partyAddress: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type PartyActivityQuery = { party?: Maybe<{ creator: Pick<User, 'id'> }>, partyActivities: Array<(
    Pick<PartyActivity, 'id' | 'timestamp' | 'txHash' | 'event' | 'incomingShares' | 'outcomingShares'>
    & { incomingAssets?: Maybe<Array<(
      Pick<AssetAmount, 'amount'>
      & { asset: Pick<Asset, 'id' | 'symbol' | 'decimals'> }
    )>>, outcomingAssets?: Maybe<Array<(
      Pick<AssetAmount, 'amount'>
      & { asset: Pick<Asset, 'id' | 'symbol' | 'decimals'> }
    )>>, user: Pick<User, 'id'> }
  )> };

export type PartyHoldingsQueryVariables = Exact<{
  partyAddress: Scalars['ID'];
}>;


export type PartyHoldingsQuery = { party?: Maybe<{ portfolio: (
      Pick<PortfolioState, 'timestamp'>
      & { holdings: Array<(
        Pick<HoldingState, 'amount'>
        & { asset: (
          Pick<Asset, 'name' | 'symbol' | 'decimals'>
          & { address: Asset['id'] }
        ) }
      )> }
    ) }> };

export type PartyInfoQueryVariables = Exact<{
  partyAddress: Scalars['ID'];
}>;


export type PartyInfoQuery = { party?: Maybe<(
    Pick<Party, 'id' | 'inception' | 'name' | 'bio' | 'img' | 'model' | 'purpose' | 'isPublic' | 'isClosed' | 'minDeposit' | 'maxDeposit' | 'membersCount'>
    & { denominationAsset: Pick<Asset, 'id' | 'symbol' | 'decimals'>, creator: Pick<User, 'id'> }
  )> };

export type PartyJoinRequestsQueryVariables = Exact<{
  partyAddress?: InputMaybe<Scalars['String']>;
}>;


export type PartyJoinRequestsQuery = { joinRequests: Array<(
    Pick<JoinRequest, 'requestedSince'>
    & { user: Pick<User, 'id'> }
  )> };

export type PartyManagersQueryVariables = Exact<{
  partyAddress?: InputMaybe<Scalars['String']>;
}>;


export type PartyManagersQuery = { userParties: Array<(
    Pick<UserParty, 'managerSince'>
    & { user: Pick<User, 'id'> }
  )> };

export type PartyMemberHistoricalStateQueryVariables = Exact<{
  partyAddress: Scalars['String'];
  memberAddress: Scalars['String'];
  startTimestamp: Scalars['BigInt'];
}>;


export type PartyMemberHistoricalStateQuery = { historical: Array<Pick<InvestmentState, 'timestamp' | 'shares'>>, snapshot: Array<Pick<InvestmentState, 'timestamp' | 'shares'>> };

export type PartyMembersQueryVariables = Exact<{
  partyId: Scalars['ID'];
  partyAddress: Scalars['String'];
}>;


export type PartyMembersQuery = { userParties: Array<(
    Pick<UserParty, 'joinedSince'>
    & { user: (
      Pick<User, 'id'>
      & { investments: Array<Pick<Investment, 'shares'>> }
    ) }
  )>, party?: Maybe<{ shares: Pick<ShareState, 'totalSupply' | 'timestamp'> }> };

export type PartyPortfolioQueryVariables = Exact<{
  partyAddress: Scalars['String'];
}>;


export type PartyPortfolioQuery = { portfolioStates: Array<(
    Pick<PortfolioState, 'networkId' | 'partySlug' | 'value' | 'withUncalculatedPrices' | 'timestamp' | 'blockNumber'>
    & { party: (
      Pick<Party, 'id'>
      & { shares: Pick<ShareState, 'totalSupply'> }
    ), holdings: Array<(
      Pick<HoldingState, 'amount' | 'price'>
      & { asset: Pick<Asset, 'id' | 'networkId' | 'slug' | 'name' | 'symbol' | 'decimals' | 'firstSeen'> }
    )> }
  )> };

export type PartyTokenGateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PartyTokenGateQuery = { partyTokenGate?: Maybe<{ tokens?: Maybe<Array<Pick<TokenGate, 'token' | 'name' | 'symbol' | 'decimals' | 'type' | 'value'>>> }> };

export type PartyTransactionsQueryVariables = Exact<{
  partyAddress?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type PartyTransactionsQuery = { tokenSwaps: Array<(
    Pick<TokenSwap, 'soldAmount' | 'boughtAmount' | 'transaction' | 'timestamp'>
    & { sellToken: (
      Pick<Asset, 'name' | 'symbol' | 'decimals'>
      & { address: Asset['id'] }
    ), buyToken: (
      Pick<Asset, 'name' | 'symbol' | 'decimals'>
      & { address: Asset['id'] }
    ) }
  )> };

export type PortfoliosQueryVariables = Exact<{
  ignoredParties: Array<Scalars['String']> | Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type PortfoliosQuery = { portfolioStates: Array<(
    Pick<PortfolioState, 'networkId' | 'partySlug' | 'value' | 'withUncalculatedPrices' | 'timestamp' | 'blockNumber'>
    & { party: (
      Pick<Party, 'id'>
      & { shares: Pick<ShareState, 'totalSupply'> }
    ), holdings: Array<(
      Pick<HoldingState, 'amount' | 'price'>
      & { asset: Pick<Asset, 'id' | 'networkId' | 'slug' | 'name' | 'symbol' | 'decimals' | 'firstSeen'> }
    )> }
  )> };

export type PortfolioSharesHistoryQueryVariables = Exact<{
  partyAddress: Scalars['ID'];
  timestamp?: InputMaybe<Scalars['BigInt']>;
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
}>;


export type PortfolioSharesHistoryQuery = { party?: Maybe<(
    Pick<Party, 'inception'>
    & { portfolioHistory: Array<(
      Pick<PortfolioState, 'timestamp'>
      & { holdings: Array<(
        Pick<HoldingState, 'timestamp' | 'amount'>
        & { asset: Pick<Asset, 'id' | 'symbol' | 'decimals'> }
      )> }
    )>, sharesHistory: Array<Pick<ShareState, 'timestamp' | 'totalSupply'>> }
  )> };

export type PortfolioSharesSnapshotQueryVariables = Exact<{
  partyAddress: Scalars['ID'];
  timestamp?: InputMaybe<Scalars['BigInt']>;
}>;


export type PortfolioSharesSnapshotQuery = { party?: Maybe<{ portfolioHistory: Array<(
      Pick<PortfolioState, 'timestamp'>
      & { holdings: Array<(
        Pick<HoldingState, 'timestamp' | 'amount'>
        & { asset: Pick<Asset, 'id' | 'symbol' | 'decimals'> }
      )> }
    )>, sharesHistory: Array<Pick<ShareState, 'timestamp' | 'totalSupply'>> }> };

export type PublicPartiesQueryVariables = Exact<{
  partyNameSearch?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
  orderBy?: InputMaybe<Party_orderBy>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type PublicPartiesQuery = { parties: Array<(
    Pick<Party, 'inception' | 'id' | 'name' | 'img' | 'model' | 'purpose' | 'minDeposit' | 'maxDeposit' | 'membersCount'>
    & { denominationAsset: Pick<Asset, 'id' | 'decimals' | 'symbol'>, creator: Pick<User, 'id'> }
  )> };

export type UserPartiesQueryVariables = Exact<{
  userAddress?: InputMaybe<Scalars['String']>;
}>;


export type UserPartiesQuery = { userParties: Array<{ party: Pick<Party, 'id' | 'name' | 'img' | 'isClosed'> }> };

export type UserPartyDepositsQueryVariables = Exact<{
  userAddress: Scalars['String'];
  partyAddress: Scalars['String'];
  latestTimestamp: Scalars['BigInt'];
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type UserPartyDepositsQuery = { deposits: Array<Pick<SharesBoughtEvent, 'value' | 'timestamp' | 'blockNumber'>> };

export type UserPartyRoleQueryVariables = Exact<{
  userPartyId: Scalars['ID'];
}>;


export type UserPartyRoleQuery = { userParty?: Maybe<Pick<UserParty, 'status' | 'leftAt' | 'isCreator' | 'isManager'>> };

export type UserPartySharesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserPartySharesQuery = { investment?: Maybe<Pick<Investment, 'shares'>> };

export type UserPartyWithdrawalsQueryVariables = Exact<{
  userAddress: Scalars['String'];
  partyAddress: Scalars['String'];
  latestTimestamp: Scalars['BigInt'];
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type UserPartyWithdrawalsQuery = { withdrawals: Array<Pick<SharesRedeemedEvent, 'redeemedAmount' | 'timestamp' | 'blockNumber'>> };

export type UncalculatedWithdrawalsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type UncalculatedWithdrawalsQuery = { sharesRedeemedEvents: Array<(
    Pick<SharesRedeemedEvent, 'networkId' | 'partySlug' | 'id' | 'redeemedAmount' | 'fee' | 'withLiquidation' | 'timestamp' | 'blockNumber'>
    & { party: Pick<Party, 'id'>, investor: Pick<User, 'id'>, redeemedAmounts: Array<(
      Pick<AssetAmount, 'amount' | 'price'>
      & { asset: Pick<Asset, 'id' | 'networkId' | 'slug' | 'name' | 'symbol' | 'decimals' | 'firstSeen'> }
    )> }
  )> };


export const AssetsDocument = gql`
    query Assets($firstSeen: BigInt!, $first: Int = 1000, $skip: Int = 0) {
  assets(
    first: $first
    skip: $skip
    orderBy: firstSeen
    orderDirection: asc
    where: {firstSeen_gt: $firstSeen}
  ) {
    networkId
    slug
    id
    name
    symbol
    decimals
    firstSeen
  }
}
    ` as unknown as DocumentNode<AssetsQuery, AssetsQueryVariables>;
export const BlockDocument = gql`
    query Block {
  _meta {
    block {
      hash
      timestamp
      number
    }
  }
}
    ` as unknown as DocumentNode<BlockQuery, BlockQueryVariables>;
export const InvestmentCostsDocument = gql`
    query InvestmentCosts($latestTimestamp: BigInt!, $ids: [ID!]!, $first: Int = 1000, $skip: Int = 0) {
  investmentCosts(
    first: $first
    skip: $skip
    orderBy: latestTimestamp
    orderDirection: asc
    where: {or: [{latestTimestamp_gt: $latestTimestamp}, {id_in: $ids}]}
  ) {
    networkId
    partySlug
    party {
      id
    }
    depositCount
    totalDeposits
    withdrawalCount
    totalWithdrawals
    latestTimestamp
    latestBlockNumber
  }
}
    ` as unknown as DocumentNode<InvestmentCostsQuery, InvestmentCostsQueryVariables>;
export const PartiesDocument = gql`
    query Parties($infoUpdatedAt: BigInt!, $first: Int = 1000, $skip: Int = 0) {
  parties(
    first: $first
    skip: $skip
    orderBy: infoUpdatedAt
    orderDirection: asc
    where: {infoUpdatedAt_gt: $infoUpdatedAt}
  ) {
    networkId
    slug
    id
    name
    denominationAsset {
      id
    }
    isPublic
    isClosed
    inception
    infoUpdatedAt
  }
}
    ` as unknown as DocumentNode<PartiesQuery, PartiesQueryVariables>;
export const PartiesInfoDocument = gql`
    query PartiesInfo($partyAddresses: [ID!]!, $isClosed: Boolean, $first: Int = 1000, $skip: Int = 0) {
  parties(
    where: {id_in: $partyAddresses, isClosed: $isClosed}
    first: $first
    skip: $skip
  ) {
    inception
    id
    name
    img
    model
    purpose
    minDeposit
    maxDeposit
    membersCount
    denominationAsset {
      id
      decimals
      symbol
    }
    creator {
      id
    }
  }
}
    ` as unknown as DocumentNode<PartiesInfoQuery, PartiesInfoQueryVariables>;
export const PartiesWithFiltersDocument = gql`
    query PartiesWithFilters($partyAddresses: [ID!]!, $inception: BigInt!, $isPublic: Boolean!, $isClosed: Boolean) {
  parties(
    where: {id_in: $partyAddresses, inception_gte: $inception, isPublic: $isPublic, isClosed: $isClosed}
  ) {
    inception
    id
    name
    img
    model
    purpose
    minDeposit
    maxDeposit
    membersCount
    denominationAsset {
      id
      decimals
      symbol
    }
    creator {
      id
    }
  }
}
    ` as unknown as DocumentNode<PartiesWithFiltersQuery, PartiesWithFiltersQueryVariables>;
export const PartyActivityDocument = gql`
    query PartyActivity($partyAddressId: ID!, $partyAddress: String!, $first: Int, $skip: Int) {
  party(id: $partyAddressId) {
    creator {
      id
    }
  }
  partyActivities(
    where: {party: $partyAddress}
    orderBy: timestamp
    orderDirection: desc
    first: $first
    skip: $skip
  ) {
    id
    timestamp
    txHash
    event
    incomingAssets {
      asset {
        id
        symbol
        decimals
      }
      amount
    }
    outcomingAssets {
      asset {
        id
        symbol
        decimals
      }
      amount
    }
    incomingShares
    outcomingShares
    user {
      id
    }
  }
}
    ` as unknown as DocumentNode<PartyActivityQuery, PartyActivityQueryVariables>;
export const PartyHoldingsDocument = gql`
    query PartyHoldings($partyAddress: ID!) {
  party(id: $partyAddress) {
    portfolio {
      timestamp
      holdings {
        asset {
          address: id
          name
          symbol
          decimals
        }
        amount
      }
    }
  }
}
    ` as unknown as DocumentNode<PartyHoldingsQuery, PartyHoldingsQueryVariables>;
export const PartyInfoDocument = gql`
    query PartyInfo($partyAddress: ID!) {
  party(id: $partyAddress) {
    id
    inception
    name
    bio
    img
    model
    purpose
    isPublic
    isClosed
    minDeposit
    maxDeposit
    membersCount
    denominationAsset {
      id
      symbol
      decimals
    }
    creator {
      id
    }
  }
}
    ` as unknown as DocumentNode<PartyInfoQuery, PartyInfoQueryVariables>;
export const PartyJoinRequestsDocument = gql`
    query PartyJoinRequests($partyAddress: String) {
  joinRequests(where: {party: $partyAddress, handledAt: null, status: Requested}) {
    user {
      id
    }
    requestedSince
  }
}
    ` as unknown as DocumentNode<PartyJoinRequestsQuery, PartyJoinRequestsQueryVariables>;
export const PartyManagersDocument = gql`
    query PartyManagers($partyAddress: String) {
  userParties(where: {party: $partyAddress, isManager: true}) {
    user {
      id
    }
    managerSince
  }
}
    ` as unknown as DocumentNode<PartyManagersQuery, PartyManagersQueryVariables>;
export const PartyMemberHistoricalStateDocument = gql`
    query PartyMemberHistoricalState($partyAddress: String!, $memberAddress: String!, $startTimestamp: BigInt!) {
  historical: investmentStates(
    where: {party: $partyAddress, investor: $memberAddress, timestamp_gt: $startTimestamp}
    orderBy: timestamp
    orderDirection: asc
  ) {
    timestamp
    shares
  }
  snapshot: investmentStates(
    where: {party: $partyAddress, investor: $memberAddress, timestamp_lte: $startTimestamp}
    orderBy: timestamp
    orderDirection: desc
    first: 1
  ) {
    timestamp
    shares
  }
}
    ` as unknown as DocumentNode<PartyMemberHistoricalStateQuery, PartyMemberHistoricalStateQueryVariables>;
export const PartyMembersDocument = gql`
    query PartyMembers($partyId: ID!, $partyAddress: String!) {
  userParties(
    where: {status: Joined, leftAt: null, party: $partyAddress}
    orderBy: joinedSince
    orderDirection: desc
  ) {
    joinedSince
    user {
      id
      investments(where: {party: $partyAddress}) {
        shares
      }
    }
  }
  party(id: $partyId) {
    shares {
      totalSupply
      timestamp
    }
  }
}
    ` as unknown as DocumentNode<PartyMembersQuery, PartyMembersQueryVariables>;
export const PartyPortfolioDocument = gql`
    query PartyPortfolio($partyAddress: String!) {
  portfolioStates(where: {party: $partyAddress, isLatest: true}) {
    networkId
    partySlug
    party {
      id
      shares {
        totalSupply
      }
    }
    value
    withUncalculatedPrices
    timestamp
    blockNumber
    holdings {
      asset {
        id
        networkId
        slug
        name
        symbol
        decimals
        firstSeen
      }
      amount
      price
    }
  }
}
    ` as unknown as DocumentNode<PartyPortfolioQuery, PartyPortfolioQueryVariables>;
export const PartyTokenGateDocument = gql`
    query PartyTokenGate($id: ID!) {
  partyTokenGate(id: $id) {
    tokens {
      token
      name
      symbol
      decimals
      type
      value
    }
  }
}
    ` as unknown as DocumentNode<PartyTokenGateQuery, PartyTokenGateQueryVariables>;
export const PartyTransactionsDocument = gql`
    query PartyTransactions($partyAddress: String, $first: Int, $skip: Int) {
  tokenSwaps(
    where: {party: $partyAddress}
    orderBy: timestamp
    orderDirection: desc
    first: $first
    skip: $skip
  ) {
    sellToken {
      address: id
      name
      symbol
      decimals
    }
    buyToken {
      address: id
      name
      symbol
      decimals
    }
    soldAmount
    boughtAmount
    transaction
    timestamp
  }
}
    ` as unknown as DocumentNode<PartyTransactionsQuery, PartyTransactionsQueryVariables>;
export const PortfoliosDocument = gql`
    query Portfolios($ignoredParties: [String!]!, $first: Int = 1000, $skip: Int = 0) {
  portfolioStates(
    first: $first
    skip: $skip
    orderBy: timestamp
    orderDirection: asc
    where: {isLatest: true, party_not_in: $ignoredParties}
  ) {
    networkId
    partySlug
    party {
      id
      shares {
        totalSupply
      }
    }
    value
    withUncalculatedPrices
    timestamp
    blockNumber
    holdings {
      asset {
        id
        networkId
        slug
        name
        symbol
        decimals
        firstSeen
      }
      amount
      price
    }
  }
}
    ` as unknown as DocumentNode<PortfoliosQuery, PortfoliosQueryVariables>;
export const PortfolioSharesHistoryDocument = gql`
    query PortfolioSharesHistory($partyAddress: ID!, $timestamp: BigInt, $skip: Int, $first: Int) {
  party(id: $partyAddress) {
    portfolioHistory(
      where: {timestamp_gt: $timestamp}
      orderBy: timestamp
      orderDirection: asc
      first: $first
      skip: $skip
    ) {
      timestamp
      holdings {
        timestamp
        asset {
          id
          symbol
          decimals
        }
        amount
      }
    }
    sharesHistory(
      where: {timestamp_gt: $timestamp}
      orderBy: timestamp
      orderDirection: asc
      first: $first
      skip: $skip
    ) {
      timestamp
      totalSupply
    }
    inception
  }
}
    ` as unknown as DocumentNode<PortfolioSharesHistoryQuery, PortfolioSharesHistoryQueryVariables>;
export const PortfolioSharesSnapshotDocument = gql`
    query PortfolioSharesSnapshot($partyAddress: ID!, $timestamp: BigInt) {
  party(id: $partyAddress) {
    portfolioHistory(
      where: {timestamp_lte: $timestamp}
      orderBy: timestamp
      orderDirection: desc
      first: 1
    ) {
      timestamp
      holdings {
        timestamp
        asset {
          id
          symbol
          decimals
        }
        amount
      }
    }
    sharesHistory(
      where: {timestamp_lte: $timestamp}
      orderBy: timestamp
      orderDirection: desc
      first: 1
    ) {
      timestamp
      totalSupply
    }
  }
}
    ` as unknown as DocumentNode<PortfolioSharesSnapshotQuery, PortfolioSharesSnapshotQueryVariables>;
export const PublicPartiesDocument = gql`
    query PublicParties($partyNameSearch: String, $orderDirection: OrderDirection, $orderBy: Party_orderBy, $first: Int, $skip: Int) {
  parties(
    where: {isPublic: true, isClosed: null, name_contains_nocase: $partyNameSearch}
    orderBy: $orderBy
    orderDirection: $orderDirection
    first: $first
    skip: $skip
  ) {
    inception
    id
    name
    img
    model
    purpose
    minDeposit
    maxDeposit
    membersCount
    denominationAsset {
      id
      decimals
      symbol
    }
    creator {
      id
    }
  }
}
    ` as unknown as DocumentNode<PublicPartiesQuery, PublicPartiesQueryVariables>;
export const UserPartiesDocument = gql`
    query UserParties($userAddress: String) {
  userParties(where: {user: $userAddress, status: Joined}) {
    party {
      id
      name
      img
      isClosed
    }
  }
}
    ` as unknown as DocumentNode<UserPartiesQuery, UserPartiesQueryVariables>;
export const UserPartyDepositsDocument = gql`
    query UserPartyDeposits($userAddress: String!, $partyAddress: String!, $latestTimestamp: BigInt!, $first: Int = 1000, $skip: Int = 0) {
  deposits: sharesBoughtEvents(
    first: $first
    skip: $skip
    orderBy: timestamp
    orderDirection: asc
    where: {investor: $userAddress, party: $partyAddress, withUncalculatedPrices: false, timestamp_gt: $latestTimestamp}
  ) {
    value
    timestamp
    blockNumber
  }
}
    ` as unknown as DocumentNode<UserPartyDepositsQuery, UserPartyDepositsQueryVariables>;
export const UserPartyRoleDocument = gql`
    query UserPartyRole($userPartyId: ID!) {
  userParty(id: $userPartyId) {
    status
    leftAt
    isCreator
    isManager
  }
}
    ` as unknown as DocumentNode<UserPartyRoleQuery, UserPartyRoleQueryVariables>;
export const UserPartySharesDocument = gql`
    query UserPartyShares($id: ID!) {
  investment(id: $id) {
    shares
  }
}
    ` as unknown as DocumentNode<UserPartySharesQuery, UserPartySharesQueryVariables>;
export const UserPartyWithdrawalsDocument = gql`
    query UserPartyWithdrawals($userAddress: String!, $partyAddress: String!, $latestTimestamp: BigInt!, $first: Int = 1000, $skip: Int = 0) {
  withdrawals: sharesRedeemedEvents(
    first: $first
    skip: $skip
    orderBy: timestamp
    orderDirection: asc
    where: {investor: $userAddress, party: $partyAddress, withUncalculatedPrices: false, timestamp_gt: $latestTimestamp}
  ) {
    redeemedAmount
    timestamp
    blockNumber
  }
}
    ` as unknown as DocumentNode<UserPartyWithdrawalsQuery, UserPartyWithdrawalsQueryVariables>;
export const UncalculatedWithdrawalsDocument = gql`
    query UncalculatedWithdrawals($first: Int = 1000, $skip: Int = 0) {
  sharesRedeemedEvents(
    first: $first
    skip: $skip
    orderBy: timestamp
    orderDirection: asc
    where: {withUncalculatedPrices: true}
  ) {
    networkId
    partySlug
    id
    party {
      id
    }
    investor {
      id
    }
    redeemedAmount
    fee
    redeemedAmounts {
      asset {
        id
        networkId
        slug
        name
        symbol
        decimals
        firstSeen
      }
      amount
      price
    }
    withLiquidation
    timestamp
    blockNumber
  }
}
    ` as unknown as DocumentNode<UncalculatedWithdrawalsQuery, UncalculatedWithdrawalsQueryVariables>;



























export type Requester<C = {}, E = unknown> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C, E>(requester: Requester<C, E>) {
  return {
    Assets(variables: AssetsQueryVariables, options?: C): Promise<AssetsQuery> {
      return requester<AssetsQuery, AssetsQueryVariables>(AssetsDocument, variables, options) as Promise<AssetsQuery>;
    },
    Block(variables?: BlockQueryVariables, options?: C): Promise<BlockQuery> {
      return requester<BlockQuery, BlockQueryVariables>(BlockDocument, variables, options) as Promise<BlockQuery>;
    },
    InvestmentCosts(variables: InvestmentCostsQueryVariables, options?: C): Promise<InvestmentCostsQuery> {
      return requester<InvestmentCostsQuery, InvestmentCostsQueryVariables>(InvestmentCostsDocument, variables, options) as Promise<InvestmentCostsQuery>;
    },
    Parties(variables: PartiesQueryVariables, options?: C): Promise<PartiesQuery> {
      return requester<PartiesQuery, PartiesQueryVariables>(PartiesDocument, variables, options) as Promise<PartiesQuery>;
    },
    PartiesInfo(variables: PartiesInfoQueryVariables, options?: C): Promise<PartiesInfoQuery> {
      return requester<PartiesInfoQuery, PartiesInfoQueryVariables>(PartiesInfoDocument, variables, options) as Promise<PartiesInfoQuery>;
    },
    PartiesWithFilters(variables: PartiesWithFiltersQueryVariables, options?: C): Promise<PartiesWithFiltersQuery> {
      return requester<PartiesWithFiltersQuery, PartiesWithFiltersQueryVariables>(PartiesWithFiltersDocument, variables, options) as Promise<PartiesWithFiltersQuery>;
    },
    PartyActivity(variables: PartyActivityQueryVariables, options?: C): Promise<PartyActivityQuery> {
      return requester<PartyActivityQuery, PartyActivityQueryVariables>(PartyActivityDocument, variables, options) as Promise<PartyActivityQuery>;
    },
    PartyHoldings(variables: PartyHoldingsQueryVariables, options?: C): Promise<PartyHoldingsQuery> {
      return requester<PartyHoldingsQuery, PartyHoldingsQueryVariables>(PartyHoldingsDocument, variables, options) as Promise<PartyHoldingsQuery>;
    },
    PartyInfo(variables: PartyInfoQueryVariables, options?: C): Promise<PartyInfoQuery> {
      return requester<PartyInfoQuery, PartyInfoQueryVariables>(PartyInfoDocument, variables, options) as Promise<PartyInfoQuery>;
    },
    PartyJoinRequests(variables?: PartyJoinRequestsQueryVariables, options?: C): Promise<PartyJoinRequestsQuery> {
      return requester<PartyJoinRequestsQuery, PartyJoinRequestsQueryVariables>(PartyJoinRequestsDocument, variables, options) as Promise<PartyJoinRequestsQuery>;
    },
    PartyManagers(variables?: PartyManagersQueryVariables, options?: C): Promise<PartyManagersQuery> {
      return requester<PartyManagersQuery, PartyManagersQueryVariables>(PartyManagersDocument, variables, options) as Promise<PartyManagersQuery>;
    },
    PartyMemberHistoricalState(variables: PartyMemberHistoricalStateQueryVariables, options?: C): Promise<PartyMemberHistoricalStateQuery> {
      return requester<PartyMemberHistoricalStateQuery, PartyMemberHistoricalStateQueryVariables>(PartyMemberHistoricalStateDocument, variables, options) as Promise<PartyMemberHistoricalStateQuery>;
    },
    PartyMembers(variables: PartyMembersQueryVariables, options?: C): Promise<PartyMembersQuery> {
      return requester<PartyMembersQuery, PartyMembersQueryVariables>(PartyMembersDocument, variables, options) as Promise<PartyMembersQuery>;
    },
    PartyPortfolio(variables: PartyPortfolioQueryVariables, options?: C): Promise<PartyPortfolioQuery> {
      return requester<PartyPortfolioQuery, PartyPortfolioQueryVariables>(PartyPortfolioDocument, variables, options) as Promise<PartyPortfolioQuery>;
    },
    PartyTokenGate(variables: PartyTokenGateQueryVariables, options?: C): Promise<PartyTokenGateQuery> {
      return requester<PartyTokenGateQuery, PartyTokenGateQueryVariables>(PartyTokenGateDocument, variables, options) as Promise<PartyTokenGateQuery>;
    },
    PartyTransactions(variables?: PartyTransactionsQueryVariables, options?: C): Promise<PartyTransactionsQuery> {
      return requester<PartyTransactionsQuery, PartyTransactionsQueryVariables>(PartyTransactionsDocument, variables, options) as Promise<PartyTransactionsQuery>;
    },
    Portfolios(variables: PortfoliosQueryVariables, options?: C): Promise<PortfoliosQuery> {
      return requester<PortfoliosQuery, PortfoliosQueryVariables>(PortfoliosDocument, variables, options) as Promise<PortfoliosQuery>;
    },
    PortfolioSharesHistory(variables: PortfolioSharesHistoryQueryVariables, options?: C): Promise<PortfolioSharesHistoryQuery> {
      return requester<PortfolioSharesHistoryQuery, PortfolioSharesHistoryQueryVariables>(PortfolioSharesHistoryDocument, variables, options) as Promise<PortfolioSharesHistoryQuery>;
    },
    PortfolioSharesSnapshot(variables: PortfolioSharesSnapshotQueryVariables, options?: C): Promise<PortfolioSharesSnapshotQuery> {
      return requester<PortfolioSharesSnapshotQuery, PortfolioSharesSnapshotQueryVariables>(PortfolioSharesSnapshotDocument, variables, options) as Promise<PortfolioSharesSnapshotQuery>;
    },
    PublicParties(variables?: PublicPartiesQueryVariables, options?: C): Promise<PublicPartiesQuery> {
      return requester<PublicPartiesQuery, PublicPartiesQueryVariables>(PublicPartiesDocument, variables, options) as Promise<PublicPartiesQuery>;
    },
    UserParties(variables?: UserPartiesQueryVariables, options?: C): Promise<UserPartiesQuery> {
      return requester<UserPartiesQuery, UserPartiesQueryVariables>(UserPartiesDocument, variables, options) as Promise<UserPartiesQuery>;
    },
    UserPartyDeposits(variables: UserPartyDepositsQueryVariables, options?: C): Promise<UserPartyDepositsQuery> {
      return requester<UserPartyDepositsQuery, UserPartyDepositsQueryVariables>(UserPartyDepositsDocument, variables, options) as Promise<UserPartyDepositsQuery>;
    },
    UserPartyRole(variables: UserPartyRoleQueryVariables, options?: C): Promise<UserPartyRoleQuery> {
      return requester<UserPartyRoleQuery, UserPartyRoleQueryVariables>(UserPartyRoleDocument, variables, options) as Promise<UserPartyRoleQuery>;
    },
    UserPartyShares(variables: UserPartySharesQueryVariables, options?: C): Promise<UserPartySharesQuery> {
      return requester<UserPartySharesQuery, UserPartySharesQueryVariables>(UserPartySharesDocument, variables, options) as Promise<UserPartySharesQuery>;
    },
    UserPartyWithdrawals(variables: UserPartyWithdrawalsQueryVariables, options?: C): Promise<UserPartyWithdrawalsQuery> {
      return requester<UserPartyWithdrawalsQuery, UserPartyWithdrawalsQueryVariables>(UserPartyWithdrawalsDocument, variables, options) as Promise<UserPartyWithdrawalsQuery>;
    },
    UncalculatedWithdrawals(variables?: UncalculatedWithdrawalsQueryVariables, options?: C): Promise<UncalculatedWithdrawalsQuery> {
      return requester<UncalculatedWithdrawalsQuery, UncalculatedWithdrawalsQueryVariables>(UncalculatedWithdrawalsDocument, variables, options) as Promise<UncalculatedWithdrawalsQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;