import {
  Avatar,
  Button as ChakraButton,
  Flex,
  Heading,
  Tag,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
  type BoxProps,
} from "@chakra-ui/react";
import { GiPartyPopper } from "react-icons/gi";

import { formatDate, getDateFromNow, shortenHex } from "@partyfinance/core";

import { capitalizeFirstLetter } from "~/utils/text";

import { Button } from "~/components/Buttons";
import { Jazzicon } from "~/components/Icons";
import { MembersModal } from "~/components/Modals";

import StatItem from "~/modules/party/components/Financials/StatItem";
import { type PublicPartyWithFinancials } from "~/modules/party/types";

import Card from "./Card";

interface IPartyListItemCard extends BoxProps {
  party: PublicPartyWithFinancials;
  openParty: (_selectedParty: string) => void;
}
const PartyWarsListItemCard = ({ party, openParty }: IPartyListItemCard) => {
  const inceptionDate = formatDate(party?.inception || 0);
  return (
    <Card
      overflowX="auto"
      px={2}
      py={{ base: 3, md: 2, lg: 3 }}
      borderWidth={1}
      borderColor={useColorModeValue("gray.200", "transparent")}
    >
      <Flex
        justify={"space-between"}
        alignItems="center"
        gap={{ base: 2, sm: 4, md: 4, lg: 4, xl: 6 }}
      >
        <Avatar
          display={{ base: "none", sm: "block" }}
          icon={
            <GiPartyPopper
              fontSize={useBreakpointValue({
                base: "4rem",
                md: "6rem",
                lg: "8rem",
              })}
            />
          }
          src={party.img}
          borderRadius="xl"
          size={useBreakpointValue({ base: "lg", md: "xl", lg: "2xl" })}
          alignSelf="center"
        />
        <Flex
          flex={{ base: 1, md: 1, lg: 0.5 }}
          alignItems="stretch"
          gap={{ base: 4, sm: 4, md: 4, lg: 4, xl: 6 }}
        >
          <Flex direction="column" align="left" gap={2}>
            <Avatar
              display={{ base: "block", sm: "none" }}
              icon={
                <GiPartyPopper
                  fontSize={useBreakpointValue({
                    base: "4rem",
                    md: "6rem",
                    lg: "8rem",
                  })}
                />
              }
              src={party.img}
              borderRadius="xl"
              size={useBreakpointValue({ base: "lg", md: "xl", lg: "2xl" })}
              alignSelf="center"
            />
            <Heading
              textAlign={{ base: "center", lg: "start" }}
              wordBreak={"break-all"}
              fontSize={["md", "lg", "2xl"]}
            >
              {party.name}
            </Heading>
            <Tooltip
              hasArrow
              placement="bottom-start"
              label={`${shortenHex(
                party.owner,
                4,
              )} created this party ${getDateFromNow(party.inception)}`}
              shouldWrapChildren={false}
            >
              <Flex
                color={"gray.400"}
                gap={2}
                display={{ base: "none", md: "flex" }}
              >
                <Jazzicon account={party.owner} diameter={24} />
                {shortenHex(party.owner, 4)}
              </Flex>
            </Tooltip>
            <Text
              fontSize={"md"}
              textAlign={{ base: "center", lg: "start" }}
              color={"gray.500"}
            >
              Created on {inceptionDate}
            </Text>
            <Flex
              gap={{ base: 2, md: 4 }}
              flexWrap="wrap"
              justifyContent={{ base: "center", lg: "flex-start" }}
            >
              <Tag fontWeight="bold" fontSize="sm">
                {capitalizeFirstLetter(party.model)}
              </Tag>
              <Tag fontWeight="bold" fontSize="sm">
                {capitalizeFirstLetter(party.purpose)}
              </Tag>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          direction={{ base: "column-reverse", lg: "row" }}
          flexWrap="wrap"
          justifyContent="space-around"
          alignItems="center"
          mx="auto"
          fontSize="xl"
          flex={1}
          gap={6}
        >
          <StatItem
            label="Min. Deposit"
            mx="auto"
            centered
            textAlign="center"
            value={`$${party.minDeposit}`}
            isValidating={false}
          />
          <StatItem
            label="Max. Deposit"
            mx="auto"
            centered
            textAlign="center"
            value={party.maxDeposit ? `$${party.maxDeposit}` : "∞"}
            isValidating={false}
          />
          <StatItem
            label="Balance"
            mx="auto"
            centered
            textAlign="center"
            value={
              party.financials === null
                ? "-"
                : `$${Math.floor(party.financials.valueUSD * 100) / 100}`
            }
            isValidating={false}
          />
        </Flex>
        <Flex direction="column" gap={2} justifyContent="center">
          <MembersModal
            partyAddress={party.address}
            chainId={party.chainId}
            defaultMembersCount={party.membersCount}
            asTag
          />
          <StatItem
            as={ChakraButton}
            pt={2}
            pb={1}
            height={12}
            borderRadius={"lg"}
            mb={0}
            centered
            alignItems="center"
            textAlign="center"
            _focus={{ boxShadow: "none", outline: "none" }}
            onClick={() => openParty(party.address)}
            label="ROI"
            color={
              party.financials === null || party.financials.roi === null
                ? undefined
                : party.financials.roi < 0
                ? "red.300"
                : "green.400"
            }
            value={
              party.financials === null || party.financials.roi === null
                ? "-"
                : `${party.financials.roi}%`
            }
            isValidating={false}
          />
          <Button
            w={"100%"}
            onClick={() => openParty(party.address)}
            borderRadius={"lg"}
            size={useBreakpointValue({ base: "sm", md: "md" })}
          >
            View Party
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};

export default PartyWarsListItemCard;
