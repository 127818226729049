import { useEffect, useState, type ReactNode } from "react";

import {
  Avatar,
  Box,
  Button,
  Center,
  CircularProgress,
  Flex,
  Icon,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiCamera, FiCheck, FiImage } from "react-icons/fi";

import toast from "~/compounds/toast";
import useImageUploader from "~/hooks/media/useImageUploader";

import InputBase from "./Base";

interface IInputImageProps {
  id: string;
  label?: string;
  placeholder?: string;
  preview?: string;
  error?: string;
  isDisabled?: boolean;
  setRef?:
    | React.RefObject<HTMLInputElement>
    | React.RefCallback<HTMLInputElement>;
  value?: string;
  onBlur?: () => void;
  onChange: (fileUrl: string) => void;
  showIcon?: boolean;
  asButton?: boolean;
  children?: ReactNode;
}

const InputImage: React.FC<IInputImageProps> = ({
  id,
  label,
  placeholder,
  error,
  setRef,
  onBlur,
  isDisabled,
  preview,
  onChange,
  children,
  showIcon = false,
  asButton = false,
}) => {
  const [img, setImg] = useState<string>(preview || "");
  const { handleUploadImage, isLoading } = useImageUploader();

  // const borderColor = useColorModeValue('gray.200', 'gray.900')
  const iconColor = useColorModeValue("gray.900", "#3d4d62");
  const avatarBg = useColorModeValue("gray.200", "brand.light");
  const buttonBg = useColorModeValue("gray.200", "gray.700");
  const buttonBorderColor = useColorModeValue("gray.300", "gray.600");

  useEffect(() => {
    setImg(preview || "");
  }, [preview]);

  const handleOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    // 1. Validator required
    if (!target.files || target.files.length === 0) {
      return toast.warn({
        title: "File is required",
      });
    }
    if (target.files.length > 1) return;

    // 2. Validator file type
    for (const file of Array.from(target.files)) {
      const fileType = file.type;
      if (!fileType.match(/(jpg|jpeg|png)$/)) {
        return toast.warn({
          title: "Only image types allowed are JPG/JPEG/PNG",
        });
      }
    }

    // 3. Validator file size
    for (const file of Array.from(target.files)) {
      const fsMb = file.size / (1024 * 1024);
      const MAX_FILE_SIZE = 2; // 2MB
      const MIN_FILE_SIZE = 1049; // 0.001MB
      if (fsMb > MAX_FILE_SIZE) {
        return toast.warn({
          title: "Max file size is 2mb",
        });
      }
      if (file.size < MIN_FILE_SIZE) {
        return toast.warn({
          title: "Min file size is 1 kb",
        });
      }
    }

    // 4. Upload image to storage
    const { fileUrl, error } = await handleUploadImage(target.files[0] as File);
    onChange(fileUrl || "");
    if (error) {
      return toast.error({
        title: "Oops! There was an error while trying to upload this image",
        body: "Please, try again later...",
      });
    }
  };

  return (
    <Box as={InputBase} id={id} label={label} error={error}>
      {children ? (
        <Box as="label" htmlFor={id} display="flex" flexWrap="wrap">
          {children}
        </Box>
      ) : asButton ? (
        <Box>
          <Button
            as={"label"}
            htmlFor={id}
            variant={"outline"}
            borderColor={buttonBorderColor}
            bg={buttonBg}
            isDisabled={isDisabled}
            cursor="pointer"
            leftIcon={
              isLoading ? (
                <CircularProgress isIndeterminate size={4} color="brand.300" />
              ) : !img ? (
                <FiImage />
              ) : (
                <FiCheck color="#21f64e" />
              )
            }
          >
            Upload Image
          </Button>
        </Box>
      ) : (
        <Center as="label" htmlFor={id}>
          <Box position="relative">
            <Center cursor="pointer">
              <Avatar
                size="2xl"
                bg={avatarBg}
                icon={
                  isLoading ? (
                    <CircularProgress isIndeterminate color="brand.300" />
                  ) : (
                    <FiImage color={iconColor} />
                  )
                }
                src={img || undefined}
              />
              {showIcon && (
                <Box
                  as={Flex}
                  background="white"
                  position="absolute"
                  right="0"
                  bottom="0"
                  padding="10px"
                  borderRadius="50%"
                  bgGradient="linear(to-b, brand.300, brand.500)"
                  _hover={{ bg: "brand.200" }}
                  cursor="pointer"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Icon fontSize="16" color="white" as={FiCamera} />
                </Box>
              )}
            </Center>
          </Box>
        </Center>
      )}
      <Input
        type="file"
        accept="image/*"
        ref={setRef}
        id={id}
        disabled={isDisabled}
        onBlur={onBlur}
        onChange={(e) => void handleOnChange(e)}
        placeholder={placeholder}
        hidden
      />
    </Box>
  );
};

export default InputImage;
