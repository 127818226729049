/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";

import { type ContractTransaction } from "@ethersproject/contracts";

import { type PartyInfoStruct } from "@partyfinance/contracts/src/types/Party";

import { useTransactionContext } from "~/contexts/TransactionContext";
import { handleCustomError } from "~/modules/party/errors";
import { TransactionActions } from "~/modules/party/types";

import { usePartyContract } from ".";
import { usePlatformCheck } from "../platform";

const steps = [
  {
    label: "Party info",
    content: "Confirm the party information update action in your wallet",
  },
  {
    label: "Party confirmation",
    content:
      "Please wait until the party information update action is confirmed",
    loading: true,
    showGame: true,
  },
];

const usePartyInfoEdit = (partyAddress: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const partyContract = usePartyContract(partyAddress);
  const { checkWallet } = usePlatformCheck();
  const {
    addTransaction,
    updateTransaction,
    openConfirmationWithSteps,
    closeConfirmationWithSteps,
    setStep,
    setErrorMsg,
  } = useTransactionContext();

  const updatePartyInfo = async (newPartyInfo: PartyInfoStruct) => {
    const account = checkWallet();
    if (!account || !partyContract) return null;
    setLoading(true);
    openConfirmationWithSteps(steps);
    let txHash = "";

    try {
      const isOwner = await partyContract.managers(account);
      if (!isOwner) {
        throw Error(
          `Only the Owner is allowed to update the party information`,
        );
      }
      console.log("newPartyInfo", newPartyInfo);
      const editPartyInfoTx: ContractTransaction =
        await partyContract.editPartyInfo(newPartyInfo);
      txHash = editPartyInfoTx.hash;
      addTransaction(TransactionActions.updatePartyInfo, txHash);

      setStep(1);
      await editPartyInfoTx.wait();
      updateTransaction(txHash, true, true);
      txHash = "";

      closeConfirmationWithSteps();
    } catch (err: any) {
      console.log(err);
      const customError = handleCustomError(err);
      setErrorMsg(customError.message);
      if (txHash) {
        updateTransaction(txHash, false, true);
      }
    }
    setLoading(false);
  };

  return {
    updatePartyInfo,
    loading,
  };
};

export default usePartyInfoEdit;
