import { useMemo } from "react";

import { Flex, Progress, Text, type FlexProps } from "@chakra-ui/react";

import { type RouterOutput } from "~/utils/api";

interface IPollResults extends FlexProps {
  poll: NonNullable<RouterOutput["poll"]["byId"]>;
}

export default function PollResults({ poll, ...props }: IPollResults) {
  const totalVotesCast = useMemo(() => {
    return poll.Options.reduce<number>(
      (acc, option) => acc + option.totalVotes,
      0,
    );
  }, [poll]);
  return (
    <Flex
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      gap={3}
      {...props}
    >
      <Text fontWeight="bold">
        {poll.status === 0 ? "Current results" : "Results"}
      </Text>
      {poll.Options.map((option) => (
        <Flex key={option.id} direction="column">
          <Flex justifyContent="space-between" pb={1}>
            <Text fontWeight="bold">{option.value.toUpperCase()}</Text>
            <Text maxWidth="260px" fontSize="sm">
              {`${option.totalVotes} votes`}
              {totalVotesCast === 0
                ? ""
                : ` (${Math.round(
                    (option.totalVotes / totalVotesCast) * 100,
                  )}%)`}
            </Text>
          </Flex>
          <Progress
            value={
              totalVotesCast === 0
                ? 0
                : (option.totalVotes || 0) / totalVotesCast
            }
            min={0}
            max={1}
            size="xs"
            colorScheme="pink"
            borderRadius="sm"
          />
        </Flex>
      ))}
      <Flex justifyContent="space-between">
        <Text fontWeight="bold" fontSize="sm">
          Total votes
        </Text>
        <Text fontSize="sm">{totalVotesCast}</Text>
      </Flex>
    </Flex>
  );
}
