import { CloseIcon } from "@chakra-ui/icons";
import { IconButton, type IconButtonProps } from "@chakra-ui/react";

interface ICloseButtonProps extends IconButtonProps {
  onClose: () => void;
}

const CloseButton = ({ onClose, ...rest }: ICloseButtonProps) => {
  return (
    <IconButton
      size={"xs"}
      bg={"transparent"}
      border={"2px solid"}
      borderColor={"brand.300"}
      borderRadius="full"
      onClick={onClose}
      display="block"
      marginLeft="auto"
      _focus={{
        border: "2px solid",
        borderColor: "brand.300",
        borderRadius: "full",
        outline: "none",
        boxShadow: "none",
      }}
      icon={
        <CloseIcon
          display="block"
          fontSize="8"
          //   fontWeight={'bold'}
          bg={"transparent"}
          color="brand.300"
          mx={"auto"}
          my={"auto"}
        />
      }
      {...rest}
    />
  );
};

export default CloseButton;
