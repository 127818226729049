import { isAddress } from "@ethersproject/address";
import { BigNumber } from "@ethersproject/bignumber";
import { formatUnits } from "@ethersproject/units";
import { useQuery } from "@tanstack/react-query";
import { useAccount } from "wagmi";

import { type ERC20, type PartyFactory } from "@partyfinance/contracts";
import { formatBalance, getPFIAddress } from "@partyfinance/core";

import { usePartyContext } from "~/contexts/PartyContext";
import { usePartyFactoryContract } from "~/hooks/factory";
import { useERC20Contract } from "~/hooks/token";

async function getPlatformFee(
  partyFactoryContract: PartyFactory,
  pfiTokenContract: ERC20,
  account: string,
) {
  // 1) Get the platform creation fee
  const creationFee = await partyFactoryContract.PFI_FEE();

  // 2) Check if holder has enough
  let balance = BigNumber.from(0);
  let isSufficient = false;
  if (isAddress(account)) {
    balance = await pfiTokenContract.balanceOf(account);
    // console.log('current user PFI balance', balance)

    isSufficient = balance.gte(creationFee);
    // console.log('has sufficient holdings?', isSufficient)
  }

  return {
    balance,
    creationFee,
    isSufficient,
  };
}

export default function usePlatformFee() {
  const partyFactoryContract = usePartyFactoryContract();
  const { chainId } = usePartyContext();
  const { address: account } = useAccount();
  const pfiTokenContract = useERC20Contract(getPFIAddress(chainId));

  const enabled = !!partyFactoryContract && !!pfiTokenContract;

  function query() {
    if (!enabled) return null;
    return getPlatformFee(
      partyFactoryContract,
      pfiTokenContract,
      account ?? "",
    );
  }

  const { data, error } = useQuery(
    ["platform-fee", chainId, account ?? ""],
    query,
  );

  return {
    holderBalance: data
      ? formatBalance(
          Math.round(Number(formatUnits(data.balance)) * 10000) / 10000,
          0,
        )
      : "0",
    creationFee: data
      ? formatBalance(
          Math.round(Number(formatUnits(data.creationFee)) * 10000) / 10000,
          0,
        )
      : "0",
    creationFee_BN: data ? data.creationFee : null,
    holderWithSufficient: data ? data.isSufficient : false,
    loading: !error && data === undefined,
    error,
  };
}
