import { type FC } from "react";

import { Box, Skeleton } from "@chakra-ui/react";

import { Card } from "~/components/Card";

const Loader: FC = () => {
  return (
    <Box as={Card} width="100%" mt={6} position="relative" overflow="hidden">
      <Skeleton height="16px" width="100%" mx="auto" />
      <Skeleton height="16px" mt="4" width="100%" mx="auto" />
      <Skeleton height="16px" mt="4" width="100%" mx="auto" />
      <Skeleton height="16px" mt="4" width="100%" mx="auto" />
    </Box>
  );
};
export default Loader;
