import { Box, Kbd, Text } from "@chakra-ui/react";

import { type PartyMemberFinancials } from "~/modules/party/types";

import StatItem from "./StatItem";

interface IMemberFinanceStats {
  loadingFinancials: boolean;
  isValidating?: boolean;
  partyMemberFinancials: PartyMemberFinancials | null;
}

const MemberFinanceStats = ({
  loadingFinancials,
  isValidating,
  partyMemberFinancials,
}: IMemberFinanceStats) => {
  return (
    <>
      <StatItem
        label="Total Deposits"
        value={`$${
          partyMemberFinancials === null
            ? "-"
            : Math.round(partyMemberFinancials.totalDeposits * 100) / 100
        }`}
        loading={loadingFinancials}
        isValidating={isValidating}
        labelTooltip={"Your total deposits amounts in USD made into the party"}
      />
      <StatItem
        label="Total Withdraws"
        value={`$${
          partyMemberFinancials === null
            ? "-"
            : Math.round(partyMemberFinancials.totalWithdrawals * 100) / 100
        }`}
        loading={loadingFinancials}
        isValidating={isValidating}
        labelTooltip={"Your total withdraws made into the party"}
      />
      <StatItem
        label="Total Balance"
        value={`$${
          partyMemberFinancials === null
            ? "-"
            : Math.round(partyMemberFinancials.valueUSD * 100) / 100
        }`}
        loading={loadingFinancials}
        isValidating={isValidating}
        labelTooltip={
          "Your current balance share of the Party. Average sum of all holdings expressed in USD prices"
        }
      />
      <StatItem
        label="Total Profit Loss"
        value={`${Number(partyMemberFinancials?.profitLoss) < 0 ? "-" : ""}$${
          partyMemberFinancials === null
            ? "-"
            : Math.abs(Math.round(partyMemberFinancials.profitLoss * 100) / 100)
        }`}
        color={
          Number(partyMemberFinancials?.profitLoss) < 0
            ? "red.300"
            : "green.400"
        }
        loading={loadingFinancials}
        isValidating={isValidating}
        labelTooltip={
          <Box pb={2} textAlign="center">
            The formula to calculate <Kbd bg="gray.400">Profit/Loss</Kbd> is
            <Text>
              <Kbd bg="gray.400">Party Balance</Kbd> -{" "}
              <Kbd bg="gray.400">Total Deposits</Kbd> +{" "}
              <Kbd bg="gray.400">Total Withdraws</Kbd>
            </Text>
          </Box>
        }
      />
      <StatItem
        label="ROI"
        color={
          !partyMemberFinancials || partyMemberFinancials.roi === null
            ? undefined
            : partyMemberFinancials.roi < 0
            ? "red.300"
            : "green.400"
        }
        value={`${
          partyMemberFinancials === null || partyMemberFinancials.roi === null
            ? "-"
            : Math.round(partyMemberFinancials.roi * 100) / 100
        }%`}
        loading={loadingFinancials}
        isValidating={isValidating}
        labelTooltip={
          <Box pb={2} textAlign="center">
            The formula to calculate <Kbd bg="gray.400">ROI</Kbd> is
            <Text>
              (<Kbd bg="gray.400">Party Balance</Kbd> -{" "}
              <Kbd bg="gray.400">Total Deposits</Kbd> +{" "}
              <Kbd bg="gray.400">Total Withdraws</Kbd>) /{" "}
              <Kbd bg="gray.400">Total Deposits</Kbd>
            </Text>
          </Box>
        }
      />
    </>
  );
};

export default MemberFinanceStats;
