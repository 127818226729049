import { type LibSignatures } from "@partyfinance/contracts/src/types/Party";

export const emptyAllocationArgs: LibSignatures.AllocationStruct = {
  sellTokens: [],
  sellAmounts: [],
  buyTokens: [],
  spenders: [],
  swapsTargets: [],
  swapsCallData: [],
  partyValueDA: 0,
  partyTotalSupply: 0,
  expiresAt: 0,
};
export const emptySignature: LibSignatures.SigStruct = {
  r: "0x0000000000000000000000000000000000000000000000000000000000000000",
  s: "0x0000000000000000000000000000000000000000000000000000000000000000",
  v: 0,
};
