import { Text, type TextProps } from "@chakra-ui/react";

import { useCountdown } from "~/modules/common/hooks/useCountdown";

interface IRemainingTime extends TextProps {
  date: string | number | Date;
}

const RemainingTime = ({ date, ...textProps }: IRemainingTime) => {
  const [days, hours, minutes, seconds] = useCountdown(date);
  return (
    <Text {...textProps}>
      {Number(days) > 0
        ? `${days} day${days === 1 ? "" : "s"} left`
        : Number(hours) > 0
        ? `${hours} hour${hours === 1 ? "" : "s"} left`
        : Number(minutes) > 0
        ? `${minutes} minute${minutes === 1 ? "" : "s"} left`
        : Number(seconds) > 0
        ? `${seconds} second${seconds === 1 ? "" : "s"} left`
        : ""}
    </Text>
  );
};

export default RemainingTime;
