import type { ReactNode } from "react";

import type { BigNumberish, BytesLike } from "ethers";
import type { IconType } from "react-icons";

import type { LibSignatures } from "@partyfinance/contracts/src/types/Party";
import type {
  AssetAmount,
  Asset as AssetDB,
  AssetPrice,
  Financial,
  InvestmentCost,
  UserFinancial,
} from "@partyfinance/db";
import type {
  PartyMember,
  PublicParty,
  TokenGateInfoRes,
  TokenGateTypeEnum,
} from "@partyfinance/thegraph-queries";

import { type RouterOutput } from "~/utils/api";

import { type ListItem } from "~/modules/common/types";

export enum PartyTypeEnum {
  Monarchy = "monarchy",
  Republic = "republic",
  Democracy = "democracy",
  WeightedDemocracy = "weighted_democracy",
}

export enum PartyPurposeEnum {
  Trading = "trading",
  PartyWars = "party_wars",
  YieldFarming = "yield_farming",
  LiquidityProviding = "liquidity_providing",
  NFT = "nft",
  Other = "other",
}

export interface PartyInfoUpdate {
  name: string;
  bio: string;
  img: string;
  isPublic: boolean;
  minDeposit: string;
  maxDeposit: string;
  enable_maxDeposit: boolean;
}

export interface DepositForm {
  amount: number;
  allocate: 0 | 1;
}

export interface WithdrawForm {
  withdrawPercent_input: number;
  withdrawPercent_slider: number;
  liquidate: 0 | 1;
}

export interface LeavePartyForm {
  liquidate: 0 | 1;
}

export interface PartyCreateForm {
  isPublic: boolean;
  type: string;
  minDeposit: string;
  enable_minDeposit: boolean;
  maxDeposit: string;
  enable_maxDeposit: boolean;
  purpose: string;
  img: string;
  name: string;
  symbol: string;
  bio: string;
  initialDeposit: string;
  tokenGates: TokenGateInfoRes[];
}

export interface ManagerForm {
  address: string;
}

export type Party = {
  address: string;
  name: string;
  bio: string;
  img: string;
  model: string;
  purpose: string;
  isPublic: boolean;
  isClosed: boolean;
  minDeposit: number;
  maxDeposit: number;
  membersCount: number;
  denominationAsset: {
    address: string;
    decimals: number;
    symbol: string;
  };
  owner: string;
};
export type PartySearch = {
  inception: number;
  address: string;
  name: string;
  img: string;
  model: string;
  purpose: string;
  minDeposit: number;
  maxDeposit: number;
  membersCount: number;
  denominationAsset: {
    address: string;
    decimals: number;
    symbol: string;
  };
  owner: string;
};

export interface PartySearchWithFinancials extends PartySearch {
  financials: PartyFinancials;
}

export type PartyWithFinancialsRes = {
  inception: number;
  id: string;
  name: string;
  img: string;
  model: string;
  purpose: string;
  minDeposit: number;
  maxDeposit: number;
  membersCount: number;
  denominationAsset: {
    decimals: number;
    id: string;
    symbol: string;
  };
  owner: string;
  financials: PartyFinancials;
};

export interface PartyWithFinancials extends PublicParty {
  financials: PartyFinancials;
}
export interface PublicPartyWithFinancials extends PublicParty {
  financials: PartyFinancials | null;
}

export interface IPartyPerformer extends Party {
  roi: number;
  funds?: PartyFunds;
  partyValue?: number; // Party total assets value in DA
  holdings?: PartyHolding[]; // Party total assets value in DA
}
export interface IPartyItem {
  address: string;
  chainId: number;
  name: string;
  img: string;
  roi: number;
  partyValue: number;
  isClosed?: boolean;
}

export type PartyFunds = {
  id: string;
  amount: string;
  percentage: string;
};

export type ChartData = {
  date: Date | string | number;
  holdings: number;
  memberHoldings?: number;
};
export interface PartyTypeListItem extends ListItem<PartyTypeEnum> {
  disabled?: boolean;
}

export interface PartyPurposeListItem extends ListItem<PartyPurposeEnum> {
  disabled?: boolean;
  icon?: IconType;
}

export type IProfile = RouterOutput["profile"]["get"];

export interface IProfileRes {
  success: boolean;
  profile: IProfile | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
}

export interface SwapForm {
  sellTokenAmount: string;
  buyTokenAmount: string;
}
export interface LimitOrderForm {
  sellTokenAmount: string;
  buyTokenAmount: string;
  expiration: number;
  rate: string;
}

export interface AnnouncementForm {
  title: string;
  content: string;
  url?: string;
  img: string;
}

export type Announcement = {
  title: string;
  content: string;
  img: string;
  url?: string;
  created: number;
  updated: number;
};

export type ConfirmationStep = {
  label: string;
  content: string;
  loading?: boolean;
  icon?: IconType;
  showGame?: boolean;
  requiresUserAction?: boolean;
  CustomView?: ReactNode;
  nextCallback?: () => void;
};

export interface Member extends PartyMember {
  nickname?: string;
  img?: string;
}

export type Token = {
  chainId: number;
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  logoURI?: string;
  hidden?: boolean;
};

export enum TradeCategoryEnum {
  Swap = "swap",
  LimitOrder = "limit-order",
}

export type PartyTokenAsset = {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  balance: number;
};

export type AllocationStruct = {
  sellTokens: string[];
  sellAmounts: BigNumberish[];
  buyTokens: string[];
  spenders: string[];
  swapsTargets: string[];
  swapsCallData: BytesLike[];
  partyValueDA: BigNumberish;
  partyTotalSupply: BigNumberish;
  expiresAt: BigNumberish;
};

export interface IAllocationSignatureResp {
  signature: LibSignatures.SigStruct;
  allocationArgs: AllocationStruct;
}

export enum TransactionStatus {
  pending = "pending",
  confirmed = "confirmed",
  failed = "failed",
  dropped = "dropped",
}

export interface ITransaction {
  txHash: string;
  name: string;
  chainId: number;
  status:
    | TransactionStatus.confirmed
    | TransactionStatus.failed
    | TransactionStatus.pending
    | TransactionStatus.dropped;
  from?: string;
  addedTime?: number;
  confirmedTime?: number;
}

export enum TransactionActions {
  approvePFI = "Approve pFi",
  approveUSDC = "Approve USDC",
  approveERC20 = "Approve ERC20",
  createParty = "Create Party",
  joinParty = "Join Party",
  leaveParty = "Leave Party",
  closeParty = "Close Party",
  kickMember = "Kick Member from Party",
  depositToParty = "Deposit USDC to Party",
  withdrawParty = "Withdraw from Party",
  createAnnouncement = "Create Announcement",
  deleteAnnouncement = "Delete Announcement",
  swapParty = "Swap Party ERC20 tokens",
  swapToken = "Swap ERC20 tokens",
  joinRequest = "Request to join a Party",
  acceptJoinRequest = "Accept a join request",
  declineJoinRequest = "Decline a join request",
  updatePartyInfo = "Update a Party info",
  mintPartyPunk = "Mint PartyPunk",
  mintPartyApe = "Mint PartyApe",
  upgradePartyVersion = "Upgrade a Party version",
  aproveLimitOrder = "Aprove a limit order",
  cancelLimitOrder = "Cancel a limit order",
  addManager = "Add a manager",
  removeManager = "Remove a manager",
  addTokenGates = "Add token gates",
  editTokenGates = "Edit token gates",
  createTokenGates = "Create token gates",
  updateTokenGates = "Update token gates",
  deleteTokenGates = "Delete token gates",
}

export type PartyFinancials = Pick<
  Financial,
  "totalSupply" | "valueUSD" | "profitLoss" | "roi" | "updatedAt"
> &
  Pick<InvestmentCost, "totalDeposits" | "totalWithdrawals"> & {
    holdings: PartyHolding[];
    memberFinancials?: PartyMemberFinancials;
  };
export type PartyMemberFinancials = Pick<
  UserFinancial,
  | "totalSupply"
  | "valueUSD"
  | "totalDeposits"
  | "totalWithdrawals"
  | "profitLoss"
  | "roi"
  | "updatedAt"
>;

export type PartyHolding = Pick<
  AssetDB,
  "address" | "name" | "symbol" | "decimals" | "logoUrl"
> &
  Pick<AssetPrice, "price"> &
  Pick<AssetAmount, "amount" | "valueUSD" | "partyAssetShare"> & {
    //
    /// @dev I'm adding comments to the types below, just for understanding.
    //
    /** Amount in units [Taken from `AssetAmount`] */
    amount: number;
    /** Price of 1 unit [Taken from `AssetPrice`] */
    price: number;
    /** Value of the holding balance in USD [Taken from `AssetPrice`] */
    valueUSD: number;
    /** Share of the holding balance in the party [Taken from `AssetAmount`] */
    partyAssetShare: number | null;
  };

export enum PartyChartView {
  PartySharePrice = "sharePrice",
  PartyTotalValue = "totalValue",
  PartyTotalSupply = "totalSupply",
  PartyChartHodlingValue = "holdingValue",
}

export interface Manager {
  address: string;
  since: number;
}

export type PartyWithFinancialsReq = {
  address: string;
  chainId: number;
};
export interface TokenGate {
  address: string;
  amount: number;
}

export interface TokenGateReq extends TokenGate {
  decimals?: number;
}

export type TokenGateForm = {
  address: string;
  name: string;
  symbol: string;
  type: TokenGateTypeEnum;
  amount: number;
  decimals: number;
};
