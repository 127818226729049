import axios from "axios";
import Bottleneck from "bottleneck";

import { type Quote0xResp } from "./types";

/** 0x Public API has a rate limit of 2 requests per second */
const limiter0x = new Bottleneck({
  reservoir: 2,
  reservoirRefreshAmount: 2,
  reservoirRefreshInterval: 1000,
  maxConcurrent: 2,
});

const BASE_URLS: { [networkId: number]: string } = {
  1: "https://api.0x.org",
  5: "https://goerli.api.0x.org",
  10: "https://optimism.api.0x.org",
  137: "https://polygon.api.0x.org",
};

interface QuoteInput {
  sellToken: string;
  buyToken: string;
  sellAmount?: string;
  buyAmount?: string;
  slippage?: number;
  feeRecipient?: string;
  buyTokenPercentageFee?: string;
}

type Fetch0xQuoteInput = {
  input: QuoteInput;
  networkId: number;
  apiKey: string;
  isQuote: boolean;
  shouldCollectFee: boolean;
};

type Fetch0xQuoteRes =
  | {
      quote: Quote0xResp;
      error: null;
      notEnoughLiquidity: false;
    }
  | {
      quote: null;
      notEnoughLiquidity: true;
      error: null;
    }
  | {
      quote: null;
      error: string;
      notEnoughLiquidity: false;
    };

export const fetch0xQuote = limiter0x.wrap(
  async ({
    input,
    networkId,
    apiKey,
    isQuote,
    shouldCollectFee,
  }: Fetch0xQuoteInput): Promise<Fetch0xQuoteRes> => {
    const params: QuoteInput = {
      sellToken: input.sellToken,
      buyToken: input.buyToken,
      slippage: input.slippage || 0.005,
    };
    if (input.sellAmount) {
      params.sellAmount = input.sellAmount;
    } else {
      params.buyAmount = input.buyAmount;
    }
    if (shouldCollectFee) {
      params.feeRecipient = "0x1c1ff818Cba2AA4DCeE2d1d4f91475F58F48B15e";
      params.buyTokenPercentageFee = "0.005";
    }
    try {
      const { data } = await axios.get<Quote0xResp>(
        `${BASE_URLS[networkId]}/swap/v1/${isQuote ? "quote" : "price"}`,
        {
          params,
          headers: {
            "0x-api-key": apiKey,
          },
        },
      );
      // console.log("Received 0x quote", data);

      return { quote: data, error: null, notEnoughLiquidity: false };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.log(
          "fetch0xQuote error (%s)",
          error.response?.status || "No response",
          error.response?.data,
        );
        if (
          error.response &&
          error.response.data &&
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          Array.isArray(error.response.data.validationErrors)
        ) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const validationErrors: { reason: string }[] =
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            error.response.data.validationErrors;
          const insufficientAssetLiquidity = validationErrors.filter(
            (x) => x.reason === "INSUFFICIENT_ASSET_LIQUIDITY",
          );
          if (insufficientAssetLiquidity.length > 0) {
            return { quote: null, error: null, notEnoughLiquidity: true };
          }
        }
        return {
          quote: null,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          error: error.response?.data?.message ?? error.message,
          notEnoughLiquidity: false,
        };
      }
      return {
        quote: null,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        error: error.message as string,
        notEnoughLiquidity: false,
      };
    }
  },
);

export type { Quote0xResp, Fetch0xQuoteRes };
