import { useEffect, useState } from "react";

import {
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  Heading,
  Icon,
  Spacer,
  Stack,
  Tag,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue,
  useRadioGroup,
  type BoxProps,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

import { type PeriodEnum } from "@partyfinance/core";

import { formatNumber } from "~/utils/number";

import { Button } from "~/components/Buttons";
import { Chart } from "~/components/Charts";
import Isotype from "~/components/Logo/Isotype";
import { RadioCard } from "~/components/Radio";

import { useCountdown } from "~/modules/common/hooks/useCountdown";
import { fetchChartData } from "~/modules/staking/api/chart";
import {
  ClaimRewardsButton,
  StakeButton,
  UnstakeButton,
} from "~/modules/staking/components/buttons";
import { type IPool } from "~/modules/staking/types";

import Card from "./Card";

interface IStakingPoolCard extends BoxProps {
  pool: IPool;
  isOpen: boolean;
}

const StakingPoolCard = ({ pool, isOpen = false }: IStakingPoolCard) => {
  // @dev: replace value with amount of LPs
  const userTokens = 15;
  const userBalance = 10;
  const userRewards = 2.5;
  const [days, hours, minutes, seconds] = useCountdown(pool.endTime);
  const timeLeft = `${days ? `${days} d ` : ""}${hours ? `${hours} h ` : ""}${
    minutes ? `${minutes} m ` : ""
  }${seconds ? `${seconds} s` : ""}`;

  // Custom Radio Button
  const options = ["1D", "1W", "1M"];
  const [period, setPeriod] = useState<string>("1D");
  const [chartData, setChartData] = useState<
    [{ date: string; holdings: number }]
  >([{ date: "", holdings: 0 }]);
  const updateChartData = (value: string) => {
    console.log("selcted period changed", value);
    setPeriod(value);
  };
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "timeperiod",
    defaultValue: "1D",
    onChange: (nextValue) => updateChartData(nextValue),
  });
  const group = getRootProps();

  useEffect(() => {
    const chart = fetchChartData(period);
    setChartData(chart as [{ date: string; holdings: number }]);
  }, [period]);

  useEffect(() => {
    const chart = fetchChartData();
    setChartData(chart as [{ date: string; holdings: number }]);
  }, []);

  // Animated Card
  const [isPoolOpen, setIsPoolOpen] = useState<boolean>(false);
  const cardVariants = {
    visible: {
      display: "block",
      clipPath: "inset(0% 0% 0% 0% round 10px)",
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 1.25,
      },
    },
    hidden: {
      display: "none",
      clipPath: "inset(10% 50% 90% 50% round 10px)",
      transition: {
        type: "spring",
        bounce: 0,
        duration: 0.3,
      },
    },
  };
  const poolVariants = {
    visible: {
      display: "block",
      clipPath: "inset(0% 0% 0% 0% round 10px)",
      transition: {
        type: "spring",
        bounce: 0,
        duration: 1,
      },
    },
    hidden: {
      display: "none",
      clipPath: "inset(10% 50% 90% 50% round 10px)",
      transition: {
        type: "bounce",
        bounce: 0,
        duration: 0.3,
      },
    },
  };

  useEffect(() => {
    console.log("pool state changed", isPoolOpen);
  }, [isPoolOpen]);

  useEffect(() => {
    if (isOpen) {
      setIsPoolOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box mb={6}>
        <Box
          as={motion.div}
          variants={cardVariants}
          animate={isPoolOpen ? "hidden" : "visible"}
          initial="visible"
        >
          <Card maxW="4xl" mx="auto" w="full">
            <Flex
              justify={{ base: "center", sm: "stretch" }}
              gap={4}
              wrap={{ base: "wrap", sm: "nowrap" }}
            >
              <Box mx={{ base: "auto", sm: 0 }}>
                <Avatar
                  size={useBreakpointValue({ base: "lg", md: "xl" })}
                  mx={{ base: "auto", md: 0 }}
                  bg={useColorModeValue("brand.50", "gray.300")}
                  icon={<Icon as={Isotype} w="42px" ml={2} color="brand.300" />}
                  src={pool.img}
                  borderRadius={{ base: "xl", lg: "full" }}
                />
              </Box>
              <VStack
                align="flex-start"
                mb="auto"
                w={{ base: "full", sm: "inherit" }}
              >
                <Heading
                  whiteSpace="nowrap"
                  size={"md"}
                  w="fit-content"
                  mx="auto"
                >
                  {pool.title}
                </Heading>
                <Flex
                  gap={2}
                  wrap="wrap"
                  mx={{ base: "auto!important", sm: "initial!important" }}
                  w="fit-content"
                >
                  <Tag>{pool.type}</Tag>
                  <Tag>Official</Tag>
                </Flex>
              </VStack>
              <Stack
                display={{ base: "none", lg: "inherit" }}
                direction={{ base: "column", lg: "row" }}
                justify="space-around"
                spacing={4}
                mx="auto"
                my={"auto!important"}
              >
                <Box h={"fit-content"}>
                  <Text color="gray.400" fontSize={"xs"}>
                    Pool Balance
                  </Text>
                  <Text fontWeight={"bold"}>${formatNumber(pool.balance)}</Text>
                </Box>
                <Spacer />
                <Box h={"fit-content"}>
                  <Text color="gray.400" fontSize={"xs"}>
                    APY
                  </Text>
                  <Text fontWeight={"bold"}>{pool.apy}%</Text>
                </Box>
                <Spacer />
                <Box h={"fit-content"}>
                  <Text color="gray.400" fontSize={"xs"}>
                    Access
                  </Text>
                  <Text fontWeight={"bold"}>{pool.access}</Text>
                </Box>
              </Stack>
              <Box
                my="auto"
                ml="auto"
                mr={{ base: "0", md: "inherit" }}
                w={{ base: "full", sm: "inherit" }}
              >
                <Button
                  w={"100%"}
                  onClick={() => setIsPoolOpen(!isPoolOpen)}
                  borderRadius={"lg"}
                >
                  View Pool
                </Button>
              </Box>
            </Flex>
          </Card>
        </Box>
        <Box
          flexDirection="column"
          justifyContent={"flex-start"}
          as={motion.div}
          variants={poolVariants}
          style={{ pointerEvents: isPoolOpen ? "auto" : "none" }}
          // transition="tween .25"
          initial="hidden"
          animate={isPoolOpen ? "visible" : "hidden"}
          // variants={variants}
        >
          <HStack justify="space-between" py={4}>
            <Heading size={"md"}>{pool.title}</Heading>
            <CloseButton
              color="brand.300"
              onClick={() => setIsPoolOpen(false)}
              size="xl"
            />
          </HStack>
          <Flex
            borderWidth={"2px"}
            borderStyle={"solid"}
            borderColor={useColorModeValue("gray.200", "gray.700")}
            borderRadius={"xl"}
            p={4}
            wrap={{ base: "wrap", lg: "nowrap" }}
          >
            {/* Pool Info */}
            <Flex
              wrap={{ base: "nowrap", lg: "wrap" }}
              maxW={{ base: "100%", lg: "200px" }}
              w={"100%"}
              h={"fit-content"}
              pt={0}
              px={8}
            >
              <Flex
                wrap={{ base: "wrap", md: "nowrap", lg: "wrap" }}
                mb={{ base: 8, lg: 0 }}
                w={"100%"}
              >
                <Box mx={{ base: "auto", lg: 0 }}>
                  <Avatar
                    size={"xl"}
                    mb={{ base: 6, md: 0, lg: 6 }}
                    mx={{ base: "auto", md: 0 }}
                    bg={useColorModeValue("brand.50", "gray.300")}
                    icon={
                      <Icon as={Isotype} w="42px" ml={2} color="brand.300" />
                    }
                  />
                </Box>
                <Stack
                  direction={{ base: "column", sm: "row", lg: "column" }}
                  w={"100%"}
                  justify={"space-between"}
                  my={"auto"}
                  ml={{ base: 0, sm: 4, lg: 0 }}
                >
                  <Box minW={{ lg: "100%" }} h={"fit-content"}>
                    <Text color="gray.400" fontSize={"xs"}>
                      Pool Balance
                    </Text>
                    <Text fontWeight={"bold"}>
                      ${formatNumber(pool.balance)}
                    </Text>
                  </Box>
                  <Spacer />
                  <Box minW={{ lg: "100%" }} h={"fit-content"}>
                    <Text color="gray.400" fontSize={"xs"}>
                      APY
                    </Text>
                    <Text fontWeight={"bold"}>{pool.apy}%</Text>
                  </Box>
                  <Spacer />
                  <Box minW={{ lg: "100%" }} h={"fit-content"}>
                    <Text color="gray.400" fontSize={"xs"}>
                      Type
                    </Text>
                    <Text fontWeight={"bold"}>{pool.type}</Text>
                  </Box>
                  <Spacer />
                  <Box minW={{ lg: "100%" }} h={"fit-content"}>
                    <Text color="gray.400" fontSize={"xs"}>
                      Access
                    </Text>
                    <Text fontWeight={"bold"}>{pool.access}</Text>
                  </Box>
                </Stack>
              </Flex>
            </Flex>
            {/* Chart */}
            <Card
              h={"fit-content"}
              w={{ base: "100%", lg: "calc(80% - 200px)" }}
              mb={{ base: 6, lg: 0 }}
            >
              <HStack pb={4} {...group}>
                {options.map((value) => {
                  const radio = getRadioProps({ value });
                  return (
                    <RadioCard key={value} {...radio}>
                      {value}
                    </RadioCard>
                  );
                })}
              </HStack>
              <Box height="333" width={["100%"]}>
                <Chart data={chartData} period={period as PeriodEnum} />
              </Box>
            </Card>
            {/* Personal Info */}
            <Flex
              ml={{ base: 0, lg: 4 }}
              w={{ base: "100%", lg: "20%" }}
              wrap={{ base: "wrap", md: "nowrap", lg: "wrap" }}
              gap={2}
              minW={210}
            >
              <Card p={8}>
                <Box
                  minW={{ lg: "100%" }}
                  h={"fit-content"}
                  textAlign={"center"}
                >
                  <Text color="gray.400" fontSize={"lg"}>
                    Your Balance
                  </Text>
                  <Text fontSize={"2xl"} fontWeight={"bold"}>
                    {pool.userBalance
                      ? "$" + formatNumber(pool.userBalance)
                      : "-"}
                  </Text>
                </Box>
              </Card>
              <Card p={8}>
                <Box
                  minW={{ lg: "100%" }}
                  h={"fit-content"}
                  textAlign={"center"}
                >
                  <Text color="gray.400" fontSize={"lg"}>
                    Live Rewards
                  </Text>
                  <Text fontSize={"2xl"} fontWeight={"bold"}>
                    {pool.userRewards
                      ? "$" + formatNumber(pool.userRewards)
                      : "-"}
                  </Text>
                </Box>
              </Card>
              <Card p={8}>
                <Box
                  minW={{ lg: "100%" }}
                  h={"fit-content"}
                  textAlign={"center"}
                >
                  <Text color="gray.400" fontSize={"lg"}>
                    Time Remaining
                  </Text>
                  <Text fontSize={"md"} fontWeight={"bold"}>
                    {pool.endTime >= Date.now() ? timeLeft : "Staking Ended"}
                  </Text>
                </Box>
              </Card>
            </Flex>
          </Flex>
          <Stack
            w={{ base: "fit-content", md: "auto" }}
            mx={{ base: "auto", md: 0 }}
            direction={{ base: "column", md: "row" }}
            justifyContent={{ base: "center", lg: "left" }}
            mt={4}
          >
            <StakeButton pool={pool} userTokens={userTokens} />
            <UnstakeButton pool={pool} userBalance={userBalance} />
            <ClaimRewardsButton pool={pool} userRewards={userRewards} />
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default StakingPoolCard;
