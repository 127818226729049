import { useMemo, type ReactNode } from "react";

import Image from "next/image";

import {
  RainbowKitProvider as RKProvider,
  RainbowKitAuthenticationProvider,
  createAuthenticationAdapter,
  darkTheme,
  type AvatarComponent,
} from "@rainbow-me/rainbowkit";
import { getCsrfToken, signIn, signOut, useSession } from "next-auth/react";
import { SiweMessage } from "siwe";

import { Jazzicon } from "~/components/Icons";

import { chains } from "./WagmiProvider";

const CustomAvatar: AvatarComponent = ({ address, ensImage, size }) => {
  return ensImage ? (
    <Image
      alt="ENS Image"
      src={ensImage}
      width={size}
      height={size}
      style={{ borderRadius: 999 }}
    />
  ) : (
    <Jazzicon diameter={size} account={address} />
  );
};

export default function RainbowKitProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { status } = useSession();
  // Authentication adapter
  const authenticationAdapter = useMemo(
    () =>
      createAuthenticationAdapter({
        getNonce: async () => {
          const nonce = await getCsrfToken();
          if (!nonce) throw new Error();
          return nonce;
        },

        createMessage: ({ nonce, address, chainId }) => {
          return new SiweMessage({
            domain: window.location.host,
            address,
            statement:
              "Welcome to PartyFinance! Sign in to access the platform.",
            uri: window.location.origin,
            version: "1",
            chainId,
            nonce,
          });
        },

        getMessageBody: ({ message }) => {
          return message.prepareMessage();
        },

        verify: async ({ message, signature }) => {
          const response = await signIn("credentials", {
            message: JSON.stringify(message),
            redirect: false,
            signature,
          });
          return response?.ok ?? false;
        },

        signOut: async () => {
          await signOut({ redirect: false });
        },
      }),
    [],
  );
  return (
    <RainbowKitAuthenticationProvider
      adapter={authenticationAdapter}
      status={status}
    >
      <RKProvider
        appInfo={{
          appName: "PartyFinance",
          learnMoreUrl: "https://party.finance",
        }}
        chains={chains}
        theme={darkTheme({
          accentColor: "#f740cf",
          borderRadius: "medium",
          overlayBlur: "small",
        })}
        avatar={CustomAvatar}
      >
        {children}
      </RKProvider>
    </RainbowKitAuthenticationProvider>
  );
}
