import { useQuery } from "@tanstack/react-query";

import {
  fetchPartyJoinRequests,
  type JoinRequest,
} from "@partyfinance/thegraph-queries";

import { env } from "~/env.mjs";

const usePartyJoinRequests = (
  partyAddress: string,
  chainId: number,
  available = true,
) => {
  const enabled = !!partyAddress && available;

  const { data, error } = useQuery<JoinRequest[]>(
    ["party-join-requests", chainId, partyAddress.toLowerCase()],
    () =>
      fetchPartyJoinRequests(
        chainId,
        env.NEXT_PUBLIC_THEGRAPH_API_KEY,
        partyAddress.toLowerCase(),
      ),
    { enabled },
  );

  return {
    joinRequests: data || [],
    loading: !error && data === undefined,
    error,
  };
};

export default usePartyJoinRequests;
