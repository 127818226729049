import NextLink from "next/link";

import {
  Box,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  type UseDisclosureProps,
} from "@chakra-ui/react";

import { shortenHex } from "@partyfinance/core";
import {
  TokenGateTypeEnum,
  type TokenGateInfoRes,
} from "@partyfinance/thegraph-queries";

import { formatNumber } from "~/utils/number";

import { Button, CloseButton } from "~/components/Buttons";

import { usePartyContext } from "~/contexts/PartyContext";
import { getOpenSeaCollectionUrl } from "~/modules/opensea/utils";

interface Props extends UseDisclosureProps {
  onClose: () => void;
  isOpen: boolean;
  title?: string;
  message?: string;
  missingTokens: TokenGateInfoRes[];
}

const GatesRequiredModal = ({
  onClose,
  isOpen,
  title = "Sorry...",
  message = "The following tokens are required to join the party:",
  missingTokens,
}: Props) => {
  const { chainId } = usePartyContext();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent my="auto">
        <ModalHeader>
          <Flex>
            <Text fontSize="xl">{title}</Text>
            <CloseButton aria-label={"Close Button"} onClose={onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody px="3rem">
          <Text color="gray.400" mb={8} textAlign="start">
            {message}
          </Text>
          <Box maxW="full" overflowX="auto">
            <Table variant="striped" size="sm" whiteSpace="nowrap">
              <Thead>
                <Tr>
                  <Th>Address</Th>
                  <Th>Symbol</Th>
                  <Th>Type</Th>
                  <Th>Required</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {missingTokens.map((gate, idx) => (
                  <Tr key={`gate-table-row-${idx}`}>
                    <Td>{shortenHex(gate.address, 4)}</Td>
                    <Td>{gate.symbol}</Td>
                    <Td>{gate.type}</Td>
                    <Td>{formatNumber(gate.amount)}</Td>
                    <Td pr={0}>
                      {gate.type === TokenGateTypeEnum.ERC721 ? (
                        <Button
                          as={Link}
                          href={getOpenSeaCollectionUrl(chainId, gate.address)}
                          size="sm"
                          target="_blank"
                          rel="noreferrer"
                          minW={130}
                        >
                          View collection
                        </Button>
                      ) : (
                        // Redirect to /trade page to buy the missing asset
                        <Button
                          as={NextLink}
                          href={`/trade?buyToken=${gate.address}&buyAmount=${gate.amount}`}
                          size="sm"
                          target="_blank"
                          rel="noreferrer"
                          minW={130}
                        >
                          Buy token
                        </Button>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
              {/* This button adds another form which messes with the mainForm */}
            </Table>
          </Box>
          <Text fontSize="sm" color="gray.500" my={6} textAlign="center">
            In case you have already bought the missing tokens, wait a couple of
            seconds or reload the page.
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default GatesRequiredModal;
