import {
  createContext,
  useContext,
  useMemo,
  type FC,
  type ReactNode,
} from "react";

import { useSession } from "next-auth/react";

import { type PartyRoles } from "@partyfinance/thegraph-queries";

import { api } from "~/utils/api";

import { usePartyRole } from "~/hooks/party";
import { type IProfile } from "~/modules/party/types";

import { usePartyContext } from "./PartyContext";

interface IPlatformContext {
  profile: IProfile | null;
  loadingProfile: boolean;
  partyRoles: PartyRoles;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reloadRoles: () => Promise<any>;
  loadingPartyRole: boolean;
}

const defaultState = {
  profile: null,
  reloadProfile: () => null,
  setProfile: () => null,
  loadingProfile: false,
  disconnectWallet: () => null,
  partyRoles: {
    isCreator: false,
    isManager: false,
    isMember: false,
  }, // Defined when a user is within a [partyId] route
  reloadRoles: () => Promise.resolve(null),
  loadingPartyRole: false,
};

const PlatformContext = createContext<IPlatformContext>(defaultState);

interface IPlatformProvider {
  children: ReactNode;
}

export const PlatformProvider: FC<IPlatformProvider> = ({ children }) => {
  // Party context
  const { partyAddress } = usePartyContext();
  // Web3 hooks
  const { status } = useSession();
  // Api hooks
  const { data, isLoading: loadingProfile } = api.profile.get.useQuery(
    undefined,
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: status === "authenticated",
    },
  );
  const profile = useMemo(
    () => (status === "unauthenticated" ? null : data || null),
    [status, data],
  );
  // TheGraph hooks
  const {
    data: partyRoles,
    refetch: reloadRoles,
    isLoading: loadingPartyRole,
  } = usePartyRole(partyAddress, profile?.address ?? "");
  return (
    <PlatformContext.Provider
      value={{
        profile,
        loadingProfile,
        partyRoles:
          profile && partyRoles
            ? partyRoles
            : { isCreator: false, isManager: false, isMember: false },
        reloadRoles,
        loadingPartyRole,
      }}
    >
      {children}
    </PlatformContext.Provider>
  );
};

export const usePlatformContext = () => useContext(PlatformContext);

export default PlatformContext;
