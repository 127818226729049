import NextLink from "next/link";
import { useRouter } from "next/router";

import {
  Box,
  Flex,
  HStack,
  Heading,
  Image,
  LinkBox,
  LinkOverlay,
  Text,
} from "@chakra-ui/react";

import { formatDate, getDateFromNow } from "@partyfinance/core";

import { shortenText } from "~/utils/text";

import { Card } from "~/components/Card";

import { type Announcement } from "~/modules/party/types";

interface IAnnouncementCard {
  announcement: Announcement;
  index: number;
}

const AnnouncementCard = ({ announcement, index }: IAnnouncementCard) => {
  const router = useRouter();
  const { partyId, chain } = router.query;
  return (
    <LinkBox as="article">
      <Card>
        <Flex
          direction={{ base: "column", md: "row" }}
          gap={4}
          alignItems="center"
          justifyContent={{ base: "center", md: "space-between" }}
        >
          <Image
            src={announcement.img}
            alt="Img"
            maxW={{
              base: "auto",
              sm: "150px",
              md: "200px",
            }}
            width="100%"
            mx="auto"
            height="fit-content"
            borderRadius={{ base: "xl" }}
          />
          <Flex
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            flex={1}
          >
            <Box
              as="time"
              color="gray.500"
              dateTime="2021-01-15 15:30:00 +0000 UTC"
            >
              {getDateFromNow(announcement.created)}
            </Box>
            <Heading size="md" my="2">
              <LinkOverlay
                as={NextLink}
                href={`/parties/${chain}/${partyId}/announcements/${index}`}
              >
                {announcement.title}
              </LinkOverlay>
            </Heading>
            <Text mb="3">{shortenText(announcement.content, 200)}</Text>
            <HStack
              marginTop="2"
              spacing="2"
              justifyContent="space-between"
              display="flex"
              alignItems="center"
            >
              <Text color="gray.500">{formatDate(announcement.created)}</Text>
              <Box color="brand.300" fontWeight="bold">
                Read More
              </Box>
            </HStack>
          </Flex>
        </Flex>
      </Card>
    </LinkBox>
  );
};

export default AnnouncementCard;
