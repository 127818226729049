const getOpenSeaChain = (chainId: number) => {
  switch (chainId) {
    case 1: {
      return "ethereum";
    }
    case 10: {
      return "optimism";
    }
    case 137: {
      return "matic";
    }
    default: {
      return "";
    }
  }
};

export const getOpenSeaCollectionUrl = (
  chainId: number,
  collection: string,
) => {
  return `https://opensea.io/assets/${getOpenSeaChain(chainId)}/${collection}`;
};
