import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  useColorModeValue,
} from "@chakra-ui/react";

import { Card } from "~/components/Card";

export default function PartySearchLoader(): JSX.Element {
  return (
    <Card
      overflowX="hidden"
      minW={270}
      py={2}
      px={4}
      borderWidth={1}
      borderColor={useColorModeValue("gray.200", "transparent")}
      flexBasis={230}
      flexGrow={1}
    >
      <Grid gap={4}>
        <GridItem>
          <Flex gap={2} wrap="nowrap">
            <Skeleton h={16} w={20} />

            <Flex
              justifyContent="space-around"
              align="flex-start"
              flexDir="column"
              wrap="nowrap"
              w="full"
            >
              <SkeletonText w={"100px"} />
            </Flex>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex wrap="nowrap" w="full" align="center" gap={2}>
            <SkeletonCircle size="8" />
            <Box w="fit-content">
              <SkeletonText w={"150px"} noOfLines={2} />
            </Box>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex wrap="nowrap" w="full" justify="space-between">
            <SkeletonText w={"50px"} noOfLines={2} />
            <SkeletonText w={"50px"} noOfLines={2} />
          </Flex>
        </GridItem>
        <GridItem>
          <Skeleton w="full" h={20} />
        </GridItem>
        <GridItem>
          <Flex wrap="wrap" w="full" justify="center" textAlign="center">
            <SkeletonText w={"50px"} noOfLines={2} />
          </Flex>
        </GridItem>
        <Divider />
        <GridItem>
          <Flex justify="space-around">
            <Flex
              wrap="wrap"
              flexDir="column"
              justify="center"
              align="center"
              gap={2}
            >
              <SkeletonCircle size="8" />
              <SkeletonText w={"20px"} noOfLines={2} />
            </Flex>
            <Flex
              wrap="wrap"
              flexDir="column"
              justify="center"
              align="center"
              gap={2}
            >
              <SkeletonCircle size="8" />
              <SkeletonText w={"20px"} noOfLines={2} />
            </Flex>
            <Flex
              wrap="wrap"
              flexDir="column"
              justify="center"
              align="center"
              gap={2}
            >
              <SkeletonCircle size="8" />
              <SkeletonText w={"20px"} noOfLines={2} />
            </Flex>
            <Flex
              wrap="wrap"
              flexDir="column"
              justify="center"
              align="center"
              gap={2}
            >
              <SkeletonCircle size="8" />
              <SkeletonText w={"20px"} noOfLines={2} />
            </Flex>
          </Flex>
        </GridItem>
      </Grid>
    </Card>
  );
}
