import { api } from "~/utils/api";

import { usePlatformContext } from "~/contexts/PlatformContext";

const usePartyFinancials = (
  partyAddress: string,
  chainId: number,
  refetchOnWindowFocus: boolean,
  /** Setting this as `true` will not generate the financial if it doesn't exist */
  justFromDB: boolean,
  externalDisable = false,
  showMemberFinancials = false,
) => {
  const { profile } = usePlatformContext();

  const { data, error, isFetching } = api.party.financials.useQuery(
    {
      memberAddress:
        showMemberFinancials && !!profile ? profile.address : undefined,
      partyAddress,
      chainId,
      justFromDB,
    },
    {
      retry: false,
      staleTime: 60 * 1000,
      cacheTime: 15 * 60 * 1000,
      refetchOnWindowFocus,
      enabled: !!partyAddress && !externalDisable,
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    },
  );

  return {
    partyFinancials: data || null,
    loading: !error && data === undefined && !!partyAddress,
    isValidating: isFetching,
    error,
  };
};

export default usePartyFinancials;
