import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { parseUnits } from "@ethersproject/units";
import { Controller, useForm } from "react-hook-form";

import { type IPartyInfo } from "@partyfinance/thegraph-queries";

import { getErrorMessage } from "~/utils/form/errorMessage";

import { Button, CloseButton } from "~/components/Buttons";
import { FormStepTitle } from "~/components/Form";
import {
  InputImage,
  InputNumber,
  InputText,
  InputToggle,
} from "~/components/Inputs";

import { minPartyDeposit } from "~/config";
import { usePartyContext } from "~/contexts/PartyContext";
import { usePartyInfoEdit } from "~/hooks/party";
import { type PartyInfoUpdate } from "~/modules/party/types";

const PartySettingsModal = ({
  partyInfo,
}: {
  partyInfo: IPartyInfo;
}): JSX.Element => {
  const { reloadPartyInfo } = usePartyContext();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { updatePartyInfo, loading } = usePartyInfoEdit(partyInfo.address);

  const { control, register, watch, getValues, handleSubmit, setValue } =
    useForm<PartyInfoUpdate>();

  const handleOpen = () => {
    setValue("name", partyInfo.name);
    setValue("bio", partyInfo.bio);
    setValue("img", partyInfo.img);
    setValue("isPublic", partyInfo.isPublic);
    setValue("minDeposit", partyInfo.minDeposit.toString());
    setValue("maxDeposit", partyInfo.maxDeposit.toString());
    setValue(
      "enable_maxDeposit",
      partyInfo.maxDeposit.toString() === "0" ? false : true,
    );
    onOpen();
  };

  const handleUpdateParty = async (form: PartyInfoUpdate) => {
    console.log("handleUpdateParty", form);
    try {
      onClose();
      await updatePartyInfo({
        name: form.name,
        bio: form.bio,
        img: form.img,
        model: partyInfo.model,
        purpose: partyInfo.purpose,
        isPublic: form.isPublic,
        minDeposit: parseUnits(
          form.minDeposit.toString(),
          partyInfo.denominationAsset.decimals,
        ), // min deposit
        maxDeposit: form.enable_maxDeposit
          ? parseUnits(
              form.maxDeposit.toString(),
              partyInfo.denominationAsset.decimals,
            )
          : 0, // max deposit
      });
      await reloadPartyInfo();
    } catch (error) {
      console.log("handleUpdateParty error", error);
    }
  };

  return (
    <>
      <Button onClick={handleOpen}>Change info</Button>
      <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
        <ModalOverlay />
        <ModalContent my="auto" borderRadius="xl">
          <ModalHeader>
            <CloseButton aria-label={"Close Button"} onClose={onClose} />
            <Heading size={"lg"} mx="auto" width="fit-content">
              Party Settings
            </Heading>
          </ModalHeader>
          <ModalBody>
            <Box
              as="form"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSubmit={handleSubmit(handleUpdateParty)}
            >
              <Flex
                width="100%"
                justifyContent="space-between"
                py={4}
                flexWrap="wrap"
              >
                <Box minWidth="20rem" mb="1rem">
                  <FormStepTitle
                    title="Party Details"
                    description="These are the details party goers will see before joining your party:"
                  />
                </Box>
                <Stack
                  justify={"space-between"}
                  direction={{ base: "column", lg: "row" }}
                  w={"100%"}
                >
                  <Box mx="auto" my="auto" pb="1rem">
                    <Controller
                      control={control}
                      name="img"
                      render={({
                        field: { value, onBlur, onChange, ref },
                        formState: { errors },
                      }) => {
                        return (
                          <InputImage
                            id="file-image"
                            setRef={ref}
                            onBlur={onBlur}
                            preview={value}
                            onChange={onChange}
                            showIcon
                            error={getErrorMessage(
                              "Party image",
                              errors.img?.type,
                            )}
                          />
                        );
                      }}
                      rules={{ required: true }}
                    />
                  </Box>
                  <Box w={{ base: "100%", lg: "75%" }}>
                    <Box mb={["0.5rem"]}>
                      <Controller
                        control={control}
                        name="name"
                        render={({
                          field: { onBlur, onChange, value, ref },
                          formState: { errors },
                        }) => {
                          return (
                            <InputText
                              id="input-name"
                              placeholder="Party Name"
                              width="100%"
                              maxLength={64}
                              setRef={ref}
                              isDisabled={loading}
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                              error={getErrorMessage(
                                "Party name",
                                errors.name?.type,
                              )}
                            />
                          );
                        }}
                        rules={{
                          required: true,
                        }}
                      />
                    </Box>
                    <Box width="100%">
                      <Controller
                        control={control}
                        name="bio"
                        render={({
                          field: { onBlur, onChange, value, ref },
                          formState: { errors },
                        }) => {
                          return (
                            <InputText
                              id="input-bio"
                              as="textarea"
                              placeholder="Description"
                              height="123px"
                              width={{ base: "100%" }}
                              py="5px"
                              className="vertical-scroll"
                              resize="none"
                              setRef={ref}
                              isDisabled={loading}
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                              error={getErrorMessage(
                                "Party bio",
                                errors.bio?.type,
                              )}
                            />
                          );
                        }}
                        rules={{
                          required: true,
                        }}
                      />
                    </Box>
                  </Box>
                </Stack>
              </Flex>

              <Divider orientation="horizontal" my={6} />

              <Stack
                w={"100%"}
                direction={{ base: "column", lg: "row" }}
                justifyContent="space-between"
                align={"start"}
                spacing={4}
              >
                <VStack align={"start"} justify={"space-between"}>
                  <Heading size={"md"} mb={{ base: "inherit", lg: 4 }}>
                    Access
                  </Heading>
                  <Controller
                    control={control}
                    name="isPublic"
                    render={({ field: { ref, onBlur, onChange, value } }) => {
                      return (
                        <InputToggle
                          id="toggle-access-type"
                          label={["Private", "Public"]}
                          setRef={ref}
                          onBlur={onBlur}
                          onChange={onChange}
                          checked={!!value}
                        />
                      );
                    }}
                  />
                </VStack>
                <VStack align={"start"}>
                  <Heading size={"md"}>Party Deposit</Heading>
                  <Flex
                    minW={{ base: "100%", sm: "50%" }}
                    // width="30rem"
                    alignItems="flex-end"
                    flexWrap="wrap"
                    justifyContent="flex-end"
                  >
                    <Box
                      mr={{ base: 0, sm: 2 }}
                      mb={{ base: 2, sm: "auto" }}
                      width={["100%", "calc(50% - 0.25rem)"]}
                    >
                      <FormControl
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        pb={1}
                      >
                        <FormLabel
                          htmlFor="enable_minDeposit"
                          mb="0"
                          fontWeight="bold"
                        >
                          Min
                        </FormLabel>
                      </FormControl>
                      <Controller
                        control={control}
                        name="minDeposit"
                        render={({
                          field: { ref, onBlur, onChange, value },
                          formState: { errors },
                        }) => {
                          return (
                            <InputNumber
                              id="minDeposit"
                              setRef={ref}
                              isDisabled={loading}
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                              placeholder="0 USDC"
                              // disabled={!watch('enable_minDeposit')}
                              exponent={{ max: 6, min: 6 }}
                              error={getErrorMessage(
                                "Min deposit",
                                errors.minDeposit?.type,
                                {
                                  moreThanEqual: minPartyDeposit,
                                  lessThanEqual: "maximum deposit",
                                },
                              )}
                            />
                          );
                        }}
                        rules={{
                          required: true,
                          validate: {
                            moreThanEqual: (value) =>
                              Number(value) >= Number(minPartyDeposit),
                            lessThanEqual: (value) =>
                              watch("enable_maxDeposit")
                                ? Number(value) <=
                                  Number(getValues("maxDeposit"))
                                : true,
                          },
                        }}
                      />
                    </Box>
                    <Box
                      width={["100%", "calc(50% - 0.25rem)"]}
                      mb={[0, "auto", "auto", "auto", "auto"]}
                    >
                      <FormControl
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        pb={1}
                      >
                        <FormLabel
                          htmlFor="enable_maxDeposit"
                          mb="0"
                          fontWeight="bold"
                        >
                          Max
                        </FormLabel>
                        <Switch
                          colorScheme="brand"
                          {...register("enable_maxDeposit", { value: false })}
                        />
                      </FormControl>
                      <Controller
                        control={control}
                        name="maxDeposit"
                        render={({
                          field: { ref, onBlur, onChange, value },
                          formState: { errors },
                        }) => {
                          return (
                            <InputNumber
                              id="maxDeposit"
                              setRef={ref}
                              isDisabled={loading}
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                              placeholder={
                                watch("enable_maxDeposit")
                                  ? "100 USDC"
                                  : "Unlimited"
                              }
                              disabled={!watch("enable_maxDeposit")}
                              exponent={{ max: 6, min: 6 }}
                              error={getErrorMessage(
                                "Max deposit",
                                errors.maxDeposit?.type,
                                {
                                  moreThan: 0,
                                  moreThanEqual: "minimum deposit",
                                },
                              )}
                            />
                          );
                        }}
                        rules={{
                          required: watch("enable_maxDeposit"),
                          validate: {
                            moreThan: (value) =>
                              watch("enable_maxDeposit")
                                ? Number(value) > 0
                                : true,
                            moreThanEqual: (value) =>
                              watch("enable_maxDeposit")
                                ? Number(value) >=
                                  Number(getValues("maxDeposit"))
                                : true,
                          },
                        }}
                      />
                    </Box>
                  </Flex>
                </VStack>
              </Stack>

              <Divider orientation="horizontal" my={6} />

              <Flex
                width="100%"
                justifyContent="flex-end"
                py={4}
                flexWrap="wrap"
              >
                <Button
                  my="auto"
                  width="15rem"
                  type="submit"
                  isLoading={loading}
                >
                  Edit Party
                </Button>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PartySettingsModal;
