import { type LegacyRef } from "react";

import {
  Box,
  Radio,
  RadioGroup,
  SimpleGrid,
  Text,
  type BoxProps,
  type ChakraComponent,
  type RadioGroupProps,
  type SimpleGridProps,
} from "@chakra-ui/react";

import InputBase from "./Base";

interface IOption {
  label: string;
  value: string | number;
  disabled?: boolean;
}

interface IInputRadioProps extends Omit<RadioGroupProps, "children"> {
  id: string;
  label?: string;
  error?: string;
  setRef: LegacyRef<HTMLDivElement> | undefined;
  name: string;
  options: IOption[];
  gridProps?: SimpleGridProps;
  itemProps?: ((option: IOption) => BoxProps) | BoxProps;
  onBlur: () => void;
  onChange: () => void;
}

const InputRadio: ChakraComponent<"div", IInputRadioProps> = ({
  id,
  label,
  error,
  setRef,
  name,
  value,
  options,
  onBlur,
  onChange,
  gridProps,
  itemProps,
  ...rest
}) => {
  return (
    <InputBase id={id} label={label} error={error}>
      <RadioGroup
        name={name}
        ref={setRef}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        {...rest}
      >
        <SimpleGrid {...gridProps}>
          {options.map((option, i) => (
            <Box
              as="label"
              {...(typeof itemProps === "function"
                ? itemProps(option)
                : itemProps)}
              key={i}
            >
              <Radio
                value={String(option.value)}
                isDisabled={option.disabled}
                colorScheme="brand"
                _focus={{ outline: "none" }}
              >
                <Text>{option.label}</Text>
              </Radio>
            </Box>
          ))}
        </SimpleGrid>
      </RadioGroup>
    </InputBase>
  );
};

export default InputRadio;
