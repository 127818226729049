import { useQuery } from "@tanstack/react-query";

import {
  fetchPartyActivity,
  type PartyActivity,
} from "@partyfinance/thegraph-queries";

import { env } from "~/env.mjs";

const limit = 10; // Number of events per page

const usePartyActivity = (
  partyAddress: string,
  chainId: number,
  pageIndex = 0,
  externalDisable = false,
) => {
  const enabled = !!partyAddress && !externalDisable;

  const { data, error, isFetching } = useQuery<PartyActivity[]>(
    ["party-activity", chainId, partyAddress.toLowerCase(), pageIndex],
    () =>
      fetchPartyActivity(
        chainId,
        env.NEXT_PUBLIC_THEGRAPH_API_KEY,
        partyAddress.toLowerCase(),
        limit,
        limit * pageIndex,
      ),
    {
      staleTime: 10000,
      enabled,
    },
  );

  return {
    activity: data || [],
    limit,
    loading: !error && data === undefined,
    isValidating: isFetching,
    error,
  };
};

export default usePartyActivity;
