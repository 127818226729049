import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import { CloseButton } from "~/components/Buttons";

interface IConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: string;
  confirmAction: () => void;
}

const ConfirmModal = (props: IConfirmModalProps) => {
  const onConfirm = () => {
    props.confirmAction();
    props.onClose();
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent my="auto" borderRadius="xl">
        <ModalHeader>
          <CloseButton aria-label={"Close Button"} onClose={props.onClose} />
          <Text fontSize="xl" mx="auto" width="fit-content">
            {props.title}
          </Text>
        </ModalHeader>
        <ModalBody>
          <Text width="fit-content" mx="auto">
            {props.message}
          </Text>
        </ModalBody>
        <ModalFooter my="1rem">
          <Flex gap={4} justifyContent="center" flex={1}>
            <Button onClick={props.onClose} flex={1}>
              Cancel
            </Button>
            <Button
              colorScheme="brand"
              bgGradient="linear(to-b, brand.300, brand.500)"
              outline="none"
              color="white"
              onClick={onConfirm}
              flex={1}
            >
              Confirm
            </Button>
          </Flex>
          <Flex></Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
