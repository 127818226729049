import { useState } from "react";

import { api } from "~/utils/api";

const useImageUploader = () => {
  const [isLoading, setIsLoading] = useState(false);
  // Api hooks
  const imageAddMutation = api.media.image.add.useMutation();

  const handleUploadImage = async (file: File) => {
    setIsLoading(true);
    try {
      // 1. Getting the presigned post url
      const { fields, url } = await imageAddMutation.mutateAsync({
        imageType: file.type.split("/")[1] as "jpg" | "jpeg" | "png",
        imageSize: file.size,
      });
      // 2. Creating the FormData to post the image
      const formData = new FormData();
      for (const key in fields) {
        // @ts-expect-error `fields[key]` is defined
        formData.append(key, fields[key]);
      }
      formData.append("file", file);

      // 3. Post the FormData to upload the image
      await fetch(url, { method: "POST", body: formData });
      const fileUrl = `https://${fields.bucket}/${fields.key}`;

      setIsLoading(false);
      return { fileUrl, error: null };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.log(err);
      setIsLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return { fileUrl: null, error: err.message as string };
    }
  };

  return {
    handleUploadImage,
    isLoading,
  };
};
export default useImageUploader;
