import { type BigNumber } from "@ethersproject/bignumber";
import { useQuery } from "@tanstack/react-query";

import { type Party } from "@partyfinance/contracts";

import { usePartyContext } from "~/contexts/PartyContext";
import { usePlatformContext } from "~/contexts/PlatformContext";

import usePartyContract from "./usePartyContract";

interface PartyBalance {
  totalSupply: BigNumber;
  memberBalance: BigNumber;
  memberShare: number; // Between 0-1
}

async function fetchPartyBalance(
  partyContract: Party,
  account: string,
): Promise<PartyBalance> {
  const totalSupply = await partyContract.totalSupply();
  const memberBalance = await partyContract.balanceOf(account);
  const memberShare =
    Number(memberBalance.mul("1000000").div(totalSupply).toString()) / 1000000;
  return {
    totalSupply,
    memberBalance,
    memberShare,
  };
}
export default function usePartyBalance(
  partyAddress: string,
  externalDisable = false,
) {
  const { chainId } = usePartyContext();
  const { profile } = usePlatformContext();
  const contract = usePartyContract(partyAddress);

  const enabled = !!contract && !!profile && !externalDisable;

  function query() {
    if (!enabled) return null;
    return fetchPartyBalance(contract, profile.address);
  }

  const { data, refetch, error, isFetching } = useQuery(
    ["party-balance", partyAddress, chainId, profile?.address ?? ""],
    query,
    {
      enabled,
      staleTime: 20 * 1000,
      refetchOnWindowFocus: false,
    },
  );

  return {
    memberPartyBalance: data?.memberBalance || null,
    partyTotalSupply: data?.totalSupply || null,
    memberShare: data?.memberShare || null,
    reloadBalance: () => refetch(),
    loading: !error && data === undefined,
    isValidating: isFetching,
    error,
  };
}
