import {
  Button as ButtonChakra,
  forwardRef,
  type ButtonProps,
} from "@chakra-ui/react";

const Button = forwardRef(({ children, ...btnProps }: ButtonProps, ref) => {
  return (
    <ButtonChakra
      ref={ref}
      colorScheme="brand"
      bgGradient="linear(to-b, brand.300, brand.500)"
      outline="none"
      borderRadius="xl"
      color="white"
      _hover={{ bg: "brand.300", boxShadow: "md" }}
      _active={{
        bg: "brand.300",
        transform: "scale(0.98)",
        borderColor: "brand.300",
      }}
      _focus={{ boxShadow: "none", border: "none", outline: "none" }}
      {...btnProps}
    >
      {children}
    </ButtonChakra>
  );
});

export default Button;
