import {
  Avatar,
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Skeleton,
  Tag,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
  type BoxProps,
} from "@chakra-ui/react";
import { BiBlock } from "react-icons/bi";
import { GiPartyPopper } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";
import { Sparklines, SparklinesLine } from "react-sparklines";

import {
  PeriodEnum,
  formatDate,
  getDateFromNow,
  shortenHex,
} from "@partyfinance/core";

import { api } from "~/utils/api";
import { formatNumber } from "~/utils/number";
import { shortenText } from "~/utils/text";
import { getChainIcon, getChainName } from "~/utils/web3/chains";

import { Jazzicon } from "~/components/Icons";

import { usePartyFinancials } from "~/hooks/party";
import useSparklineStyles from "~/hooks/useSparklineStyles";
import { type PublicPartyWithFinancials } from "~/modules/party/types";
import { getToken } from "~/modules/tokens";

import Card from "./Card";

interface IPartyListItemCard extends BoxProps {
  party: PublicPartyWithFinancials;
  openParty: () => void;
  showMemberFinancials?: boolean;
  ownerNickname?: string;
}

const PartyListItemCard = ({
  party,
  openParty,
  showMemberFinancials = false,
  ownerNickname,
}: IPartyListItemCard) => {
  const inceptionDate = formatDate(party?.inception || 0);
  const utils = api.useContext();
  const { data, isLoading } = api.party.chart.useQuery(
    {
      partyAddress: party.address,
      networkId: party.chainId,
      period: PeriodEnum.LastDay,
      denominationAsset: party.denominationAsset.id,
      onSingleParty: false,
    },
    {
      cacheTime: 15 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
      enabled: !!party.address,
      refetchOnWindowFocus: false,
      retry: false,
      trpc: {
        context: {
          skipBatch: true,
        },
      },
      onSuccess(data) {
        utils.party.chart.setData(
          {
            partyAddress: party.address,
            networkId: party.chainId,
            period: PeriodEnum.LastDay,
            denominationAsset: party.denominationAsset.id,
            onSingleParty: true,
          },
          data,
        );
      },
    },
  );
  const { partyFinancials } = usePartyFinancials(
    party.address,
    party.chainId,
    false,
    true,
    false,
    showMemberFinancials,
  );

  const balance =
    Math.floor(
      (showMemberFinancials
        ? partyFinancials?.memberFinancials?.valueUSD || 0
        : party.financials?.valueUSD || 0) * 100,
    ) / 100;

  const shortenHexBreakpoint = useBreakpointValue({ base: 8, md: 4, "2xl": 8 });
  // Responsive values
  const balanceColor = useColorModeValue("gray.500", "white");
  // Sparklines
  const { sparklineBg, sparklineColor, percentChange } =
    useSparklineStyles(data);
  return (
    <Card
      p={0}
      overflow="hidden"
      maxW="sm"
      minW={270}
      borderWidth={1}
      borderColor={useColorModeValue("gray.200", "transparent")}
      flexBasis={230}
      flexGrow={1}
      onClick={openParty}
      cursor="pointer"
      h="full"
    >
      <Grid>
        <GridItem py={4} px={4}>
          <Flex gap={2} wrap="nowrap">
            <Avatar
              icon={
                <GiPartyPopper
                  fontSize={useBreakpointValue({
                    base: "4rem",
                    md: "6rem",
                    lg: "8rem",
                  })}
                />
              }
              src={party.img}
              borderRadius="xl"
              size={useBreakpointValue({
                base: "lg",
                md: "md",
                xl: "sm",
                "2xl": "lg",
              })}
              alignSelf="center"
            />
            <Flex
              justifyContent="space-around"
              align="flex-start"
              flexDir="column"
              wrap="nowrap"
              w="full"
            >
              <Heading
                textAlign="start"
                // wordBreak={'break-all'}
                fontSize={["md", "lg"]}
                w="full"
              >
                <Flex wrap="nowrap" justify="space-between">
                  <Tooltip
                    hasArrow
                    placement="bottom-start"
                    label={party.name}
                    shouldWrapChildren={false}
                  >
                    <Text>{shortenText(party.name, 12)}</Text>
                  </Tooltip>
                  <Tooltip
                    hasArrow
                    placement="top"
                    label={getChainName(party.chainId)}
                    shouldWrapChildren={false}
                  >
                    <Avatar
                      src={getChainIcon(party.chainId)}
                      size={useBreakpointValue({
                        base: "xs",
                        md: "2xs",
                        "2xl": "xs",
                      })}
                      ml="auto"
                    />
                  </Tooltip>
                </Flex>
              </Heading>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem py={4} px={4}>
          <Tooltip
            hasArrow
            placement="bottom-start"
            label={`${shortenHex(
              party.owner,
              4,
            )} created this party ${getDateFromNow(party.inception)}`}
            shouldWrapChildren={false}
          >
            <Flex
              color={"gray.400"}
              gap={2}
              // justify="center"
              alignItems="center"
            >
              <Jazzicon account={party.owner} diameter={32} />
              <Box>
                <Text fontSize="xs" fontWeight="bold">
                  {ownerNickname ||
                    shortenHex(party.owner, shortenHexBreakpoint)}
                </Text>
                <Text fontSize="xs">Created on {inceptionDate}</Text>
              </Box>
              <Flex
                // justify="flex-end"
                align="center"
                color={"gray.500"}
                gap={1}
                ml="auto"
              >
                <HiUserGroup />
                <Text fontSize={"md"} fontWeight="bold" color={"gray.500"}>
                  {party.membersCount}
                </Text>
              </Flex>
            </Flex>
          </Tooltip>
        </GridItem>
        <GridItem pb={4} px={4}>
          <Flex gap={2} alignItems="center" justify="space-between" height={45}>
            <Box>
              <Text
                fontSize={"xs"}
                textAlign={{ base: "center", lg: "start" }}
                color={"gray.500"}
              >
                {showMemberFinancials ? "Your Balance" : "Balance"}
              </Text>
              <Flex alignItems="flex-end" gap={1.5}>
                <Text fontWeight="bold" color={balanceColor}>
                  ${formatNumber(balance)}
                </Text>
                {percentChange !== null && (
                  <Tooltip
                    hasArrow
                    placement="right"
                    label="Last 24h change"
                    shouldWrapChildren={false}
                  >
                    <Text
                      fontSize={11}
                      alignSelf="center"
                      // mt={1}
                      color={sparklineColor}
                    >
                      {percentChange > 0 ? "+" : ""}
                      {percentChange}%
                    </Text>
                  </Tooltip>
                )}
              </Flex>
            </Box>
            {party.financials && (
              <Box>
                {/* @dev: Change this to 'Last 24h' if we provide this info on the financials */}
                <Text fontSize={"xs"} textAlign="center" color={"gray.500"}>
                  Performance
                </Text>
                <Box ml="auto" w="fit-content">
                  {showMemberFinancials ? (
                    <Tag
                      bg={
                        !partyFinancials
                          ? undefined
                          : partyFinancials.memberFinancials &&
                            partyFinancials.memberFinancials.profitLoss >= 0
                          ? "green.400"
                          : "red.400"
                      }
                      fontSize="xs"
                      fontWeight="bold"
                      size="sm"
                    >
                      {!partyFinancials?.memberFinancials ||
                      partyFinancials.memberFinancials.roi === null
                        ? "-%"
                        : `${
                            partyFinancials.memberFinancials.roi > 0 ? "+" : ""
                          } ${
                            Math.round(
                              partyFinancials.memberFinancials.roi * 100,
                            ) / 100
                          }%`}
                    </Tag>
                  ) : (
                    <Tag
                      bg={
                        party.financials.profitLoss >= 0
                          ? "green.400"
                          : "red.400"
                      }
                      fontSize="xs"
                      fontWeight="bold"
                      size="sm"
                    >
                      {party.financials.roi === null
                        ? "-%"
                        : `${party.financials.roi > 0 ? "+" : ""} ${
                            Math.round(party.financials.roi * 100) / 100
                          }%`}
                    </Tag>
                  )}
                </Box>
              </Box>
            )}
          </Flex>
        </GridItem>
        <GridItem>
          {isLoading ? (
            <Skeleton w="full" h={75} />
          ) : (
            data && (
              <Sparklines
                data={data.map((d) => d.value)}
                height={75}
                margin={0}
                max={Math.max(...data.map((x) => x.value)) * 1.0008}
                min={Math.min(...data.map((x) => x.value)) * 0.9992}
              >
                <SparklinesLine color={sparklineColor} />
              </Sparklines>
            )
          )}{" "}
        </GridItem>
        <GridItem bg={sparklineBg} py={4}>
          <Flex justify="space-around" h="full">
            {party.financials && party.financials.holdings.length > 0 ? (
              party.financials.holdings
                .filter((token) => token.valueUSD > 0)
                .sort((a, b) => b.valueUSD - a.valueUSD)
                .slice(0, 4)
                .map((token, idx) => (
                  <Flex
                    flex={1}
                    wrap="wrap"
                    flexDir="column"
                    justify="center"
                    align="center"
                    key={`party-holding-${token.address}-${idx}`}
                  >
                    <Avatar
                      icon={<BiBlock fontSize="22px" color="white" />}
                      src={
                        getToken(token.address)?.logoURI || token.logoUrl || ""
                      }
                      size="sm"
                      mb={1}
                    />
                    <Text fontSize={"xs"} fontWeight="bold" color={"gray.500"}>
                      {token.symbol}
                    </Text>
                    <Text fontSize={"xs"} color={"gray.500"}>
                      {token.partyAssetShare === null
                        ? "-"
                        : Math.floor(token.partyAssetShare * 100) / 100}
                      %
                    </Text>
                  </Flex>
                ))
            ) : (
              <Text fontSize="sm" my="auto" color="gray.500" alignSelf="center">
                No holdings
              </Text>
            )}
          </Flex>
        </GridItem>
      </Grid>
    </Card>
  );
};

export default PartyListItemCard;
