import { useQuery } from "@tanstack/react-query";

import {
  fetchPartyTransactions,
  type Transaction,
} from "@partyfinance/thegraph-queries";

import { env } from "~/env.mjs";

const limit = 10; // Number of transactions per page

const usePartyTransactions = (
  partyAddress: string,
  chainId: number,
  pageIndex = 0,
  externalDisable = false,
) => {
  const enabled = !!partyAddress && !externalDisable;

  const { data, error } = useQuery<Transaction[]>(
    ["party-transactions", chainId, partyAddress.toLowerCase(), pageIndex],
    () =>
      fetchPartyTransactions(
        chainId,
        env.NEXT_PUBLIC_THEGRAPH_API_KEY,
        partyAddress.toLowerCase(),
        limit,
        limit * pageIndex,
      ),
    {
      enabled,
      staleTime: 15000,
    },
  );

  return {
    transactions: data || [],
    limit,
    loading: !error && data === undefined,
    error,
  };
};

export default usePartyTransactions;
