export enum PeriodEnum {
  LastDay = "1D",
  LastWeek = "1W",
  LastMonth = "1M",
  Last3Months = "3M",
  Last6Months = "6M",
  LastYear = "1Y",
  AllTime = "All",
}

export const periodOffset: Record<
  Exclude<PeriodEnum, PeriodEnum.AllTime>,
  number
> = {
  [PeriodEnum.LastDay]: 86400000, // 1 day in ms
  [PeriodEnum.LastWeek]: 604800000, // 7 days in ms
  [PeriodEnum.LastMonth]: 2592000000, // 30 days in ms
  [PeriodEnum.Last3Months]: 7776000000, // 90 days in ms
  [PeriodEnum.Last6Months]: 15552000000, // 180 days in ms
  [PeriodEnum.LastYear]: 31536000000, // 365 days in ms
};

const ONE_DAY_IN_MINUTES = 1440;
export const periodMinutesInterval: Record<
  Exclude<PeriodEnum, PeriodEnum.AllTime>,
  number
> = {
  [PeriodEnum.LastDay]: 60, // one tick every hour (60min) = 24 ticks
  [PeriodEnum.LastWeek]: ONE_DAY_IN_MINUTES, // one tick every day (min granularity of Covalent)
  [PeriodEnum.LastMonth]: ONE_DAY_IN_MINUTES, // one tick every day (min granularity of Covalent)
  [PeriodEnum.Last3Months]: ONE_DAY_IN_MINUTES * 3, // one tick every 3 days = 90 / 3 = 30 ticks
  [PeriodEnum.Last6Months]: ONE_DAY_IN_MINUTES * 6, // one tick every 6 days = 180 / 6 = 30 ticks
  [PeriodEnum.LastYear]: ONE_DAY_IN_MINUTES * 7, // one tick every 10 days = 365 / 10 ~= 36 ticks
};

export function getPeriodOffset(
  period: Exclude<PeriodEnum, PeriodEnum.AllTime> | null | undefined,
) {
  return !period ? undefined : periodOffset[period];
}

export function getPeriodMinutesInterval(
  period: Exclude<PeriodEnum, PeriodEnum.AllTime>,
) {
  return periodMinutesInterval[period];
}

export const isPeriodOptionVisible = (
  period: PeriodEnum,
  inception?: number,
) => {
  if (!inception) return false;
  if (period === PeriodEnum.AllTime) return true;

  return inception * 1000 < Date.now() - periodOffset[period];
};
