import type { BigNumber } from "@ethersproject/bignumber";

import { platformFee } from "~/config";

export const getPlatformFee = (value: BigNumber) => {
  const fee = value.mul(platformFee.toString()).div("10000");
  console.log("fee", fee);

  return fee;
};

export const addPlatformFee = (value: BigNumber) => {
  const fee = value.mul(platformFee.toString()).div("10000");
  const valueWithFee = value.add(fee);

  return valueWithFee;
};
