import {
  createContext,
  useContext,
  useEffect,
  useState,
  type FC,
  type ReactNode,
} from "react";

import { useRouter } from "next/router";

import { type IPartyInfo } from "@partyfinance/thegraph-queries";

import { api } from "~/utils/api";
import { getChainId, getChainName } from "~/utils/web3/chains";

import toast from "~/compounds/toast";
import { supportedChainIds } from "~/config";

import indexParties from "../modules/party/index/indexParties.json";

interface IPartyContext {
  chainName: string;
  chainId: number; // URL chain ID
  setChainState: ({ id, name }: ChainState) => void;
  routerChain: string | string[] | undefined;
  partyAddress: string;
  isParty: boolean;
  isIndexParty: boolean;
  checkIndexParty: (_address: string) => void;
  partyInfo: IPartyInfo | null;
  reloadPartyInfo: () => Promise<void>;
}

const defaultState = {
  chainName: "polygon",
  chainId: 137,
  setChainState: () => null,
  routerChain: undefined,
  network: "polygon",
  partyAddress: "",
  isParty: false,
  isIndexParty: false,
  checkIndexParty: () => null,
  partyInfo: null,
  reloadPartyInfo: () => Promise.resolve(),
};

const PartyContext = createContext<IPartyContext>(defaultState);

interface IPartyProvider {
  children: ReactNode;
}

type ChainState = {
  id: number;
  name: string;
};

export const PartyProvider: FC<IPartyProvider> = ({ children }) => {
  const router = useRouter();
  const { partyId, chain } = router.query;
  const partyAddress = partyId as string;
  const [isIndexParty, setIsIndexParty] = useState<boolean>(false);
  const [chainState, setChainState] = useState<ChainState>({
    id: 137,
    name: "polygon",
  }); // chainId from URL
  const { data, refetch } = api.party.info.useQuery(
    { chainId: chainState.id, partyAddress },
    {
      refetchOnWindowFocus: false,
      staleTime: 30 * 1000,
      cacheTime: 4 * 60 * 60 * 1000,
      enabled: !!partyAddress,
      select(data) {
        return {
          partyInfo: data,
          isParty: !!data,
        };
      },
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    },
  );

  useEffect(() => {
    if (chain) {
      const chainId = getChainId(String(chain).toLowerCase());
      if (!chainId) {
        toast.dismiss();
        toast.error(
          {
            title: "Network from URL is invalid",
            body: "Redirected to explore page",
          },
          { autoClose: 4000, pauseOnHover: false },
        );
        void router.push("/parties");
      } else {
        setChainState({
          id: chainId,
          name: String(chain).toLowerCase(),
        });
      }
    } else {
      // If chain is not defined then the page is not from /parties/:chain/:partyId
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain]);

  const checkIndexParty = (address: string) => {
    const _isIndexParty = !!indexParties.find(
      (p) => p.address.toLowerCase() === address.toLowerCase(),
    );
    console.log("isIndex", _isIndexParty);
    if (_isIndexParty) {
      setIsIndexParty(_isIndexParty);
    }
  };

  useEffect(() => {
    if (window && window.localStorage) {
      const _chainId = window.localStorage.getItem("chainId");
      if (
        _chainId &&
        !isNaN(Number(_chainId)) &&
        supportedChainIds.includes(Number(_chainId))
      ) {
        setChainState({
          id: Number(_chainId),
          name: getChainName(Number(_chainId), true),
        });
      } else {
        if (window && window.localStorage) {
          window.localStorage.setItem("chainId", "137");
        }
        setChainState({
          id: 137,
          name: "polygon",
        });
      }
    }
  }, []);

  // Checks if party is an index party
  useEffect(() => {
    if (data && data.partyInfo) {
      checkIndexParty(data.partyInfo.address);
    }
  }, [data]);

  // Redirects user to the index party route if he isn't there already
  useEffect(() => {
    if (
      partyAddress &&
      isIndexParty &&
      (router.pathname.includes(`/parties/${partyAddress}`) ||
        router.pathname.includes("/parties/[partyId]"))
    ) {
      void router.push(`/index/${chainState.name}/${partyAddress}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname, isIndexParty, partyAddress, chainState.name]);

  // If route contains chain & router chain is not equal to selected chain, set selected chain to router chain
  useEffect(() => {
    const _chainId = getChainId(String(chain));
    if (_chainId && chainState.id !== _chainId) {
      setChainState({
        id: _chainId,
        name: String(chain),
      });
    }
  }, [chainState.id, chain]);

  const reloadPartyInfo = async () => {
    await refetch();
  };

  return (
    <PartyContext.Provider
      value={{
        chainName: chainState.name,
        chainId: chainState.id,
        setChainState,
        routerChain: chain,
        partyAddress,
        isParty: !!data?.isParty,
        isIndexParty,
        checkIndexParty,
        partyInfo: data?.partyInfo || null,
        reloadPartyInfo,
      }}
    >
      {children}
    </PartyContext.Provider>
  );
};

export const usePartyContext = () => useContext(PartyContext);

export default PartyContext;
