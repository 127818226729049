import {
  forwardRef,
  useImperativeHandle,
  useState,
  type ReactNode,
} from "react";

import { CheckIcon, CopyIcon } from "@chakra-ui/icons";
import {
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useClipboard,
  useDisclosure,
} from "@chakra-ui/react";

import { CloseButton } from "~/components/Buttons";

import toast from "~/compounds/toast";

export interface ShareModalRef {
  openModal: (url: string, closeCallback?: null | (() => void)) => void;
}
export interface ShareModalProps {
  children: ReactNode;
}

// eslint-disable-next-line react/display-name
const ShareModal = forwardRef<ShareModalRef, ShareModalProps>(
  ({ children }, ref) => {
    const [state, setState] = useState<{
      url: string;
      callback: null | (() => void);
    }>({
      url: "",
      callback: null,
    });
    const { hasCopied, onCopy } = useClipboard(state.url);
    const { isOpen, onOpen, onClose } = useDisclosure();

    useImperativeHandle(ref, () => ({
      openModal: (url, callback = null) => {
        setState({ url, callback });
        onOpen();
      },
    }));

    const handleCopy = () => {
      onCopy();
      toast.dismiss();
      toast.info({
        title: "Link copied to clipboard",
      });
    };

    const handleClose = () => {
      onClose();
      if (state.callback) {
        state.callback();
      }
    };

    return (
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={handleClose}
        size={"xl"}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay bg={"rgba(11, 8, 31, 0.6)"} />
        <ModalContent
          borderRadius={"xl"}
          py={{ base: 2, sm: 3, md: 4 }}
          px={{ base: 0, sm: 3, md: 4 }}
          textAlign="center"
        >
          <CloseButton aria-label={"Close Button"} onClose={handleClose} />
          <ModalBody pb={4}>
            {children}
            <HStack
              justifyContent="space-between"
              alignItems="center"
              bg="brand.nav"
              p={3}
              borderRadius="xl"
            >
              <Text
                width="calc(100% - 20px)"
                textAlign="left"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {state.url}
              </Text>
              {hasCopied ? (
                <CheckIcon color="green.400" />
              ) : (
                <CopyIcon
                  color="brand.200"
                  cursor="pointer"
                  onClick={handleCopy}
                />
              )}
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  },
);

export default ShareModal;
