function getTheGraphBaseUrl(apiKey: string | null) {
  if (!apiKey) return "api.thegraph.com/subgraphs/name/party-finance";
  return `gateway-arbitrum.network.thegraph.com/api/${apiKey}/subgraphs/id`;
}

export const SUBGRAPHS = {
  mainnet: {
    isSubgraphStudio: true,
    subgraphId: "EaMnZiVafQ9vXLUBBXd1v9cySXr6CHKVSvqy1E1TVKxL",
    networkId: 1,
  },
  goerli: {
    isSubgraphStudio: false,
    subgraphId: "partyfinance-goerli",
    networkId: 5,
  },
  optimism: {
    isSubgraphStudio: false,
    subgraphId: "partyfinance-optimism",
    networkId: 10,
  },
  polygon: {
    isSubgraphStudio: true,
    subgraphId: "H8PNJMWLiUY5LgCUhhyRP7ddhtZHaADgAiH9jWAzDbjw",
    // @dev Uncomment to use hosted-service
    // isSubgraphStudio: false,
    // subgraphId: "partyfinance-polygon",
    networkId: 137,
  },
};

export function getNetworkIdFromSubgraph(subgraph: string): number {
  switch (subgraph) {
    case SUBGRAPHS.mainnet.subgraphId:
      return 1;
    case SUBGRAPHS.goerli.subgraphId:
      return 5;
    case SUBGRAPHS.optimism.subgraphId:
      return 10;
    case SUBGRAPHS.polygon.subgraphId:
      return 137;
    default:
      throw new Error(`getNetworkIdFromSubgraph: Invalid subgraph ${subgraph}`);
  }
}

export function getSubgraphConfig(
  networkId: number,
  apiKey: string | null,
): {
  subgraphId: string;
  baseUrl: string;
} {
  switch (networkId) {
    case 1:
      if (!apiKey)
        throw new Error(
          `getSubgraphConfig: apiKey is required for networkId ${networkId}`,
        );
      return {
        subgraphId: SUBGRAPHS.mainnet.subgraphId, // SubgraphStudio
        baseUrl: getTheGraphBaseUrl(apiKey),
      };
    case 5:
      return {
        subgraphId: SUBGRAPHS.goerli.subgraphId,
        baseUrl: getTheGraphBaseUrl(null),
      };
    case 10:
      return {
        subgraphId: SUBGRAPHS.optimism.subgraphId,
        baseUrl: getTheGraphBaseUrl(null),
      };
    case 137:
      // return {
      //   subgraphId: SUBGRAPHS.polygon.subgraphId,
      //   baseUrl: getTheGraphBaseUrl(null),
      // };
      if (!apiKey)
        throw new Error(
          `getSubgraphConfig: apiKey is required for networkId ${networkId}`,
        );
      return {
        subgraphId: SUBGRAPHS.polygon.subgraphId,
        baseUrl: getTheGraphBaseUrl(apiKey),
      };
    default:
      throw new Error(`getSubgraphConfig: Invalid networkId ${networkId}`);
  }
}
