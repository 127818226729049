import { useState } from "react";

import {
  type ContractReceipt,
  type ContractTransaction,
} from "@ethersproject/contracts";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { BigNumber } from "ethers";
import { useSigner } from "wagmi";

import { ERC20__factory } from "@partyfinance/contracts";
import { getUSDCAddress, getUSDCDecimals } from "@partyfinance/core";

import { api } from "~/utils/api";
import { formatCount } from "~/utils/text";

import { minLiquidityTokenUSD } from "~/config";
import { usePartyContext } from "~/contexts/PartyContext";
import { useTransactionContext } from "~/contexts/TransactionContext";
import { env } from "~/env.mjs";
import { usePlatformCheck } from "~/hooks/platform";
import { fetch0xQuote } from "~/modules/0x";
import { handleCustomError } from "~/modules/party/errors";
import { TransactionActions } from "~/modules/party/types";

import { usePartyContract } from ".";

const steps = [
  {
    label: "Quote",
    content: "Fetching swap quote from 0x Protocol",
    loading: true,
  },
  {
    label: "Signing quote",
    content: "Securely signing the quote of your swap",
    loading: true,
  },
  {
    label: "Swap token",
    content: "Confirm the swap token action in your wallet",
  },
  {
    label: "Party confirmation",
    content: "Please wait until the swap is confirmed",
    loading: true,
    showGame: true,
  },
];

const usePartySwap = (partyAddress: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { data: signer } = useSigner();
  const { chainId } = usePartyContext();
  const { checkWallet } = usePlatformCheck();
  const partyContract = usePartyContract(partyAddress);
  const {
    addTransaction,
    updateTransaction,
    openConfirmationWithSteps,
    closeConfirmationWithSteps,
    setStep,
    setErrorMsg,
  } = useTransactionContext();
  // Api hooks
  const signatureSwap = api.signature.swap.useMutation();

  const swapToken = async ({
    sellTokenAddress,
    sellAmount,
    buyTokenAddress,
    isNewToken,
    slippage = 0.005, // 0.5% slippage
  }: {
    sellTokenAddress: string;
    sellAmount: string;
    buyTokenAddress: string;
    isNewToken: boolean;
    slippage?: number;
  }) => {
    const account = checkWallet();
    if (!account || !chainId || !partyContract) return null;
    setLoading(true);
    openConfirmationWithSteps(steps);
    let txHash = "";

    try {
      // 1) Validate action
      const isOwner = await partyContract.managers(account);
      if (!isOwner) {
        throw Error(`Only Party Owner is allowed to swap`);
      }

      // 2) Check if party has enough balance and get swap quote from 0x
      if (!signer) {
        throw Error("Please reconnect your wallet again");
      }
      const desiredSellAmount = BigNumber.from(sellAmount);
      const sellTokenContract = ERC20__factory.connect(
        sellTokenAddress,
        signer,
      );
      const sellTokenPartyBalance = await sellTokenContract.balanceOf(
        partyAddress,
      );
      if (sellTokenPartyBalance.lt(desiredSellAmount)) {
        const sellTokenSymbol = await sellTokenContract.symbol();
        const sellTokenDecimals = await sellTokenContract.decimals();
        throw Error(
          `Not enough ${sellTokenSymbol} balance to swap. Current balance ${formatUnits(
            sellTokenPartyBalance,
            sellTokenDecimals,
          )} vs Desired balance ${formatUnits(
            desiredSellAmount,
            sellTokenDecimals,
          )}`,
        );
      }

      // 2.1) Get swap quote from 0x
      const { error, notEnoughLiquidity } = await fetch0xQuote({
        input: {
          sellToken: sellTokenAddress,
          buyToken: buyTokenAddress,
          sellAmount: sellAmount,
          slippage: slippage,
        },
        networkId: chainId,
        apiKey: env.NEXT_PUBLIC_ZEROX_API_KEY,
        isQuote: true,
        shouldCollectFee: false,
      });
      if (error || notEnoughLiquidity) {
        console.log(notEnoughLiquidity, error);
        throw Error(
          notEnoughLiquidity
            ? "Not enough liquidity available on multiple dexes to make this swap possible"
            : "An error occurred while trying to get the quotes",
        );
      }

      // 2.2) Check if new token has enough liquidity
      if (isNewToken) {
        const USDC_ADDRESS = getUSDCAddress(chainId);
        const USDC_DECIMALS = getUSDCDecimals(chainId);
        // Check if new token has enough liquidity to sell 20k USDC worth of token
        const { notEnoughLiquidity: notEnoughLiquidity20k } =
          await fetch0xQuote({
            input: {
              sellToken: buyTokenAddress,
              buyToken: USDC_ADDRESS ?? "",
              buyAmount: parseUnits(
                minLiquidityTokenUSD.toString(),
                USDC_DECIMALS,
              ).toString(),
            },
            networkId: chainId,
            apiKey: env.NEXT_PUBLIC_ZEROX_API_KEY,
            isQuote: true,
            shouldCollectFee: false,
          });
        if (notEnoughLiquidity20k) {
          throw Error(
            `The liquidity of this token is lower than ${formatCount(
              minLiquidityTokenUSD,
            )} USDC, which represent a great risk to your Party. Please choose another token with greater reputation.`,
          );
        }
      }

      // 3) Get allocation signature with swap quote
      setStep(1);
      const data = await signatureSwap.mutateAsync({
        partyAddress: partyContract.address,
        userAddress: account,
        sellToken: sellTokenAddress,
        buyToken: buyTokenAddress,
        sellAmount: sellAmount,
        slippage: slippage,
        chainId: chainId,
      });
      setStep(2);

      // 4) Swap token on party
      const swapTx: ContractTransaction = await partyContract.swapToken(
        data.allocationArgs,
        data.signature,
      );
      txHash = swapTx.hash;
      addTransaction(TransactionActions.swapParty, txHash);

      setStep(3);
      const confirmedTx: ContractReceipt = await swapTx.wait(2);
      updateTransaction(txHash, true, true);
      txHash = "";

      closeConfirmationWithSteps();
      setLoading(false);
      return confirmedTx;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.log(err);
      const customError = handleCustomError(err);
      setErrorMsg(customError.message);
      if (txHash) {
        updateTransaction(txHash, false, true);
      }
      setLoading(false);
    }
    return null;
  };

  return {
    swapToken,
    loadingSwap: loading,
  };
};

export default usePartySwap;
