import { useState } from "react";

import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  CircularProgress,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Stack,
  Tag,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { formatUnits } from "@ethersproject/units";
import { Controller, useForm } from "react-hook-form";

import { type IPartyInfo } from "@partyfinance/thegraph-queries";

import { Button, CloseButton } from "~/components/Buttons";

import { type WithdrawForm } from "~/modules/party/types";

interface IConfirmModalProps {
  isWithdrawOpen: boolean;
  onCloseWithdraw: () => void;
  confirmAction: (_form: WithdrawForm) => void;
  party: IPartyInfo;
  loadingBalance: boolean;
  userPartyBalance: string;
}

const Withdraw = ({
  isWithdrawOpen,
  onCloseWithdraw,
  confirmAction,
  party,
  loadingBalance,
  userPartyBalance,
}: IConfirmModalProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  // form
  const { control, handleSubmit, setValue } = useForm<WithdrawForm>({
    defaultValues: {
      withdrawPercent_input: 50,
      withdrawPercent_slider: 50,
      liquidate: 0,
    },
  });

  const handleWithdraw = (form: WithdrawForm) => {
    console.log(`Withdrawing percent: ${form.withdrawPercent_slider}%`);
    console.log("Withdrawing liquidate?", form.liquidate);
    confirmAction(form);
  };

  const tagColor = useColorModeValue("brand.300", "brand.300");
  const tagBg = useColorModeValue("#ffe5fb", "#694160");

  return (
    <Modal isOpen={isWithdrawOpen} onClose={onCloseWithdraw}>
      <ModalOverlay />
      <ModalContent my="auto" borderRadius="xl">
        <ModalHeader>
          <CloseButton aria-label={"Close Button"} onClose={onCloseWithdraw} />
          <Text fontSize="xl" mx="auto" width="fit-content">
            {party.name}
          </Text>
          <Text fontSize="md" mt={4} mx="auto" width="fit-content">
            Withdraw from party
          </Text>
        </ModalHeader>
        {loadingBalance ? (
          <ModalBody pb={12}>
            <Flex alignItems="center" justifyContent="center">
              <Text>Loading your balance...</Text>
              <CircularProgress
                color="brand.300"
                size={4}
                isIndeterminate
                ml={2}
              />
            </Flex>
          </ModalBody>
        ) : (
          <ModalBody pb={4}>
            <Flex mb={2}>
              <Text>Your holdings: </Text>
              <Text ml={"0.5rem"}>
                {!userPartyBalance
                  ? 0
                  : Math.round(Number(formatUnits(userPartyBalance)) * 10000) /
                    10000}{" "}
                PartyTokens
              </Text>
            </Flex>
            <Box
              as={"form"}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSubmit={handleSubmit(handleWithdraw)}
            >
              <Controller
                control={control}
                name="withdrawPercent_input"
                render={({ field: { ref, onChange, value } }) => {
                  return (
                    <Flex alignItems="center" gap={2}>
                      <Text>Your withdraw share (%): </Text>
                      <NumberInput
                        id="withdrawPercent_input"
                        ref={ref}
                        size="sm"
                        maxW="90px"
                        max={100}
                        min={0}
                        value={value}
                        onChange={(e) => onChange(Number(e))}
                        onBlur={() => {
                          const newValue =
                            Number(value) > 100
                              ? 100
                              : Number(value) < 0
                              ? 0
                              : Number(value);
                          onChange(newValue);
                          setValue("withdrawPercent_slider", newValue);
                        }}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                      {value !== 100 && (
                        <Tag
                          size="sm"
                          color={tagColor}
                          bg={tagBg}
                          cursor="pointer"
                          onClick={() => {
                            onChange(100);
                            setValue("withdrawPercent_slider", 100);
                          }}
                        >
                          Max
                        </Tag>
                      )}
                    </Flex>
                  );
                }}
              />
              <Controller
                control={control}
                name="withdrawPercent_slider"
                render={({ field: { ref, onChange, value } }) => {
                  return (
                    <Slider
                      id="withdrawPercent_slider"
                      ref={ref}
                      defaultValue={50}
                      min={0}
                      max={100}
                      mt={"1rem"}
                      color={"gray.500"}
                      value={value}
                      onChange={(newValue: number) => {
                        onChange(newValue);
                        setValue("withdrawPercent_input", newValue);
                      }}
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                    >
                      <SliderMark value={0} mt="1rem" ml="0" fontSize="xs">
                        0%
                      </SliderMark>
                      <SliderMark value={25} mt="1rem" ml="-2.5" fontSize="xs">
                        25%
                      </SliderMark>
                      <SliderMark value={50} mt="1rem" ml="-2.5" fontSize="xs">
                        50%
                      </SliderMark>
                      <SliderMark value={75} mt="1rem" ml="-2.5" fontSize="xs">
                        75%
                      </SliderMark>
                      <SliderMark value={100} mt="1rem" ml="-7" fontSize="xs">
                        100%
                      </SliderMark>
                      <SliderTrack boxSize={"1rem"} borderRadius={"full"}>
                        <SliderFilledTrack bg={"#e30ccd"} />
                      </SliderTrack>
                      <Tooltip
                        hasArrow
                        bg="black"
                        color="white"
                        placement="top"
                        isOpen={showTooltip}
                        label={`${value}%`}
                      >
                        <SliderThumb
                          boxSize={"1.3rem"}
                          bg={"#e30ccd"}
                          borderColor={"white"}
                          borderWidth={"5px"}
                        />
                      </Tooltip>
                    </Slider>
                  );
                }}
              />
              <Box mt={8} width={"fit-content"}>
                <Text mb={2}>Your withdraw options: </Text>
                <Controller
                  control={control}
                  name="liquidate"
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      value={String(value)}
                      onChange={(v) => {
                        onChange(Number(v) as 0 | 1);
                      }}
                      colorScheme="brand"
                    >
                      <Stack>
                        <Radio value="1" isDisabled>
                          <Flex alignItems="center" gap={2}>
                            {`Withdraw in ${party.denominationAsset.symbol}`}
                            <Tooltip
                              fontSize="sm"
                              hasArrow
                              placement="right"
                              label={`You will receive your withdraw share in ${party.denominationAsset.symbol}`}
                            >
                              <InfoIcon />
                            </Tooltip>
                          </Flex>
                        </Radio>
                        <Radio value="0">
                          <Flex alignItems="center" gap={2}>
                            Withdraw in holdings
                            <Tooltip
                              fontSize="sm"
                              hasArrow
                              placement="right"
                              label="You will receive your withdraw share expressed in different ERC-20 tokens that the party is curerntly holding"
                            >
                              <InfoIcon />
                            </Tooltip>
                          </Flex>
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                />
              </Box>
              <Box mx={["auto"]} mt={6} mb={3} width={"fit-content"}>
                <Button minW={4} type={"submit"}>
                  Withdraw
                </Button>
              </Box>
            </Box>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};

export default Withdraw;
