import { createElement, useCallback, type ReactElement } from "react";

import { useDisclosure } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

import { type ERC721 } from "@partyfinance/contracts";
import { getPartyApesAddress } from "@partyfinance/core";

import {
  ApeRequiredModal,
  PlatinumApeRequiredModal,
} from "~/components/Modals";

import { governanceWhitelist, pApesValidationEnabled } from "~/config";
import { usePlatformCheck } from "~/hooks/platform";
import { useERC721Contract } from "~/hooks/token";
import partyApesMetadataList from "~/modules/partyapes/partyApesMetadataList.json";
import {
  PartyApeTierEnum,
  PartyApeVotingPowerEnum,
  type IPartyApeMetadata,
  type IUserApes,
} from "~/modules/vote/types";

const getHoldings = async (contract: ERC721, account: string) => {
  if (!account || !contract) return [];
  const balance = await contract.balanceOf(account);
  const promises: Promise<number | null>[] = [];
  for (let i = 0; i < balance.toNumber(); i++) {
    promises.push(
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      new Promise(async (resolve) => {
        try {
          const tokenByIndex = await contract.tokenOfOwnerByIndex(account, i);
          resolve(tokenByIndex.toNumber());
        } catch (error) {
          resolve(null);
        }
      }),
    );
  }
  try {
    const holdings = await Promise.all(promises);
    return holdings.filter((x) => x !== null) as number[];
  } catch (error) {
    console.log("error fetching users party punks", error);
    return [];
  }
};

const getApesMetadata = async (contract: ERC721, account: string) => {
  const _holdings: number[] = await getHoldings(contract, account);
  const _holdingsWithMetadata: IPartyApeMetadata[] = [];

  try {
    _holdings.forEach((apeId) => {
      const _ape = partyApesMetadataList[apeId] as PartyApeTierEnum;

      if (_ape) {
        const _tier = Object.values(_ape)[0] as PartyApeTierEnum;
        _holdingsWithMetadata.push({ id: apeId.toString(), tier: _tier });
      }
    });

    return _holdingsWithMetadata;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const accumulateVotes = (apes: IUserApes) => {
  let _votes = 0;

  _votes += apes.common.ids.length * PartyApeVotingPowerEnum.Common;
  _votes += apes.bronze.ids.length * PartyApeVotingPowerEnum.Bronze;
  _votes += apes.silver.ids.length * PartyApeVotingPowerEnum.Silver;
  _votes += apes.gold.ids.length * PartyApeVotingPowerEnum.Gold;
  _votes += apes.platinum.ids.length * PartyApeVotingPowerEnum.Platinum;

  return _votes;
};

const mapTotalApes = () => {
  const apes: IUserApes = {
    common: {
      ids: [],
      img: "",
    },
    bronze: {
      ids: [],
      img: "",
    },
    silver: {
      ids: [],
      img: "",
    },
    gold: {
      ids: [],
      img: "",
    },
    platinum: {
      ids: [],
      img: "",
    },
  };

  for (const tier in PartyApeTierEnum) {
    const amount = partyApesMetadataList.filter(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (ape, i) => ape && Object(ape)[i] === tier,
    ).length;

    if (tier === PartyApeTierEnum.Common) apes.common.ids.length = amount;
    if (tier === PartyApeTierEnum.Bronze) apes.bronze.ids.length = amount;
    if (tier === PartyApeTierEnum.Silver) apes.silver.ids.length = amount;
    if (tier === PartyApeTierEnum.Gold) apes.gold.ids.length = amount;
    if (tier === PartyApeTierEnum.Platinum) apes.platinum.ids.length = amount;
  }

  return apes;
};

const calcVotes = (apes: IUserApes) => {
  const totalApes = mapTotalApes();
  let userVotingPower = 0;
  let totalVotes = 0;
  // calc total votes

  // calc user votes
  totalVotes = accumulateVotes(totalApes);

  // get power enum value by key
  userVotingPower = accumulateVotes(apes);

  return {
    userVotingPower,
    totalVotes,
  };
};

const fetchUserPartyApes = async (contract: ERC721, account: string) => {
  const apesMetadata = await getApesMetadata(contract, account);

  // map metadata to user apes per tier
  const userApes: IUserApes = {
    common: {
      ids: [],
      img: "",
    },
    bronze: {
      ids: [],
      img: "",
    },
    silver: {
      ids: [],
      img: "",
    },
    gold: {
      ids: [],
      img: "",
    },
    platinum: {
      ids: [],
      img: "",
    },
  };
  for (const tier in PartyApeTierEnum) {
    const apesByTier = apesMetadata.filter((ape) => ape.tier === tier);
    const img = `/assets/images/apes/${tier}.png`;

    if (tier === PartyApeTierEnum.Common)
      userApes.common = {
        ids: apesByTier.map((apes) => Number(apes.id)),
        img,
      };
    if (tier === PartyApeTierEnum.Bronze)
      userApes.bronze = {
        ids: apesByTier.map((apes) => Number(apes.id)),
        img,
      };
    if (tier === PartyApeTierEnum.Silver)
      userApes.silver = {
        ids: apesByTier.map((apes) => Number(apes.id)),
        img,
      };
    if (tier === PartyApeTierEnum.Gold)
      userApes.gold = { ids: apesByTier.map((apes) => Number(apes.id)), img };
    if (tier === PartyApeTierEnum.Platinum)
      userApes.platinum = {
        ids: apesByTier.map((apes) => Number(apes.id)),
        img,
      };
  }

  const { totalVotes, userVotingPower } = calcVotes(userApes);

  return {
    userApes,
    userVotingPower,
    totalVotes,
  };
};

export default function usePartyApesVote(account?: string) {
  const { checkWallet } = usePlatformCheck();
  const contract = useERC721Contract(getPartyApesAddress(137));

  const enabled = !!contract && !!account;
  const { data } = useQuery(
    ["user-party-apes", account],
    () => (enabled ? fetchUserPartyApes(contract, account) : null),
    {
      enabled,
    },
  );
  const { userApes, userVotingPower, totalVotes } = data || {
    userApes: {
      common: {
        ids: [],
        img: "",
      },
      bronze: {
        ids: [],
        img: "",
      },
      silver: {
        ids: [],
        img: "",
      },
      gold: {
        ids: [],
        img: "",
      },
      platinum: {
        ids: [],
        img: "",
      },
    },
    userVotingPower: 0,
    totalVotes: 0,
  };

  // Modal states
  const {
    onOpen: onOpenApeRequired,
    onClose: onCloseApeRequired,
    isOpen: isApeRequiredOpen,
  } = useDisclosure();
  const {
    onOpen: onOpenPlatinumApeRequired,
    onClose: onClosePlatinumApeRequired,
    isOpen: isApePlatinumRequiredOpen,
  } = useDisclosure();

  const isWhitelisted = () => {
    // Whitelisted to create polls
    return !!account && governanceWhitelist.includes(account.toLowerCase());
  };

  const isApeHolder = async () => {
    if (!checkWallet()) return false;
    if (!account || !contract) return false;
    if (!pApesValidationEnabled) return true;

    const balance = await contract.balanceOf(account);
    const isHolder = balance.toNumber();
    if (isHolder) return true;

    onOpenApeRequired();
    return false;
  };

  const ApeRequired = useCallback(
    (): ReactElement =>
      createElement(
        ApeRequiredModal,
        { onClose: onCloseApeRequired, isOpen: isApeRequiredOpen },
        [],
      ),
    [isApeRequiredOpen, onCloseApeRequired],
  );

  const isPlatinumApeHolder = () => {
    if (!checkWallet()) return false;
    if (!account || !contract) return false;
    // Whitelisted to create polls
    if (isWhitelisted()) return true;
    if (!pApesValidationEnabled) return true;

    const platApe = userApes?.platinum;
    if (platApe && platApe.ids.length >= 1) return true;

    onOpenPlatinumApeRequired();
    return false;
  };

  const PlatinumApeRequired = useCallback(
    (): ReactElement =>
      createElement(
        PlatinumApeRequiredModal,
        {
          onClose: onClosePlatinumApeRequired,
          isOpen: isApePlatinumRequiredOpen,
        },
        [],
      ),
    [isApePlatinumRequiredOpen, onClosePlatinumApeRequired],
  );

  return {
    userApes,
    totalVotes,
    userVotingPower,
    ApeRequired,
    isApeHolder,
    PlatinumApeRequired,
    isPlatinumApeHolder,
  };
}
