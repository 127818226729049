import { useState, type Dispatch, type SetStateAction } from "react";

import dynamic from "next/dynamic";

import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useBreakpoint,
  type BoxProps,
} from "@chakra-ui/react";
import { BiBlock } from "react-icons/bi";

import { PeriodEnum, isPeriodOptionVisible } from "@partyfinance/core";

import { api } from "~/utils/api";

import { Card } from "~/components/Card";

// import { Chart } from '~components/Charts'
import { usePartyContext } from "~/contexts/PartyContext";
import { usePartyMemberHistoricalState } from "~/hooks/theGraph";

import { PartyChartView, type ChartData, type PartyHolding } from "../types";

const Chart = dynamic(() => import("~/components/Charts/Chart"), {
  ssr: false,
});

interface PartyChart extends BoxProps {
  partyAddress: string;
  denominationAsset?: string;
  loading?: boolean;
  showMemberHoldings?: boolean;
  selectedHolding: PartyHolding | null;
  setChartType: Dispatch<SetStateAction<PartyChartView>>;
  chartType: PartyChartView;
  chainId: number;
  inception?: number;
}

const typeOptions = [
  {
    value: PartyChartView.PartyTotalValue,
    label: "Total balance",
    extra: " (in USDC)",
  },
  {
    value: PartyChartView.PartySharePrice,
    label: "Share price",
    extra: " (in USDC)",
  },
  {
    value: PartyChartView.PartyTotalSupply,
    label: "Party shares supply",
    extra: "",
  },
  {
    value: PartyChartView.PartyChartHodlingValue,
    label: "Asset Balance",
    extra: " (in USDC)",
  },
];

export default function PartyChart({
  partyAddress,
  denominationAsset,
  showMemberHoldings = false,
  selectedHolding,
  chartType,
  setChartType,
  chainId,
  loading,
  inception,
  ...props
}: PartyChart) {
  // Period Options
  const [period, setPeriod] = useState<PeriodEnum>(PeriodEnum.LastDay);
  const { partyInfo } = usePartyContext();
  const { data, error, isFetching, isInitialLoading } =
    api.party.chart.useQuery(
      {
        partyAddress,
        networkId: chainId,
        period,
        denominationAsset:
          (!denominationAsset
            ? partyInfo?.denominationAsset.address
            : denominationAsset) || "",
        onSingleParty: true,
      },
      {
        cacheTime: 15 * 60 * 1000,
        staleTime: 5 * 60 * 1000,
        enabled: !!partyAddress && !loading,
        refetchOnWindowFocus: false,
        retry: false,
        trpc: {
          context: {
            skipBatch: true,
          },
        },
      },
    );

  const { partyMemberHistoricalState } = usePartyMemberHistoricalState(
    partyAddress,
    chainId,
    period,
    (!!partyAddress &&
      !loading &&
      showMemberHoldings &&
      chartType === PartyChartView.PartyTotalValue) ||
      chartType === PartyChartView.PartyChartHodlingValue,
  );

  const chartData: ChartData[] | undefined = data?.map((c) => {
    let holdings = undefined;
    if (
      chartType === PartyChartView.PartyTotalValue &&
      showMemberHoldings &&
      partyMemberHistoricalState.length > 0
    ) {
      const memberBalance =
        partyMemberHistoricalState.find(
          (a) => a.timestamp <= new Date(c.timestamp).getTime(),
        )?.shares || 0;
      const memberShareFactor = memberBalance / c.supply;
      holdings = Math.floor(c.value * memberShareFactor * 100) / 100;
    } else if (chartType === PartyChartView.PartySharePrice) {
      holdings = c.shareValue;
    } else if (chartType === PartyChartView.PartyTotalSupply) {
      holdings = c.supply;
    } else if (
      chartType === PartyChartView.PartyChartHodlingValue &&
      selectedHolding
    ) {
      // Value of a selected asset
      const asset = c.Holdings.find((h) => h.asset === selectedHolding.address);
      if (asset) {
        if (showMemberHoldings && partyMemberHistoricalState.length > 0) {
          const memberBalance =
            partyMemberHistoricalState.find(
              (a) => a.timestamp <= new Date(c.timestamp).getTime(),
            )?.shares || 0;
          const memberShareFactor = memberBalance / c.supply;
          holdings = Math.floor(asset.value * memberShareFactor * 100) / 100;
        } else {
          holdings = asset.value;
        }
      } else {
        holdings = 0;
      }
    } else {
      holdings = c.value;
    }
    return {
      holdings: Math.floor(holdings * 100) / 100,
      date: c.timestamp,
    };
  });

  // Only show period options for which there is enough data to fill the chart
  const periodOptions = Object.values(PeriodEnum).filter((periodOption) =>
    isPeriodOptionVisible(periodOption, partyInfo?.inception || inception),
  );
  const isSmallPeriodSelector = useBreakpoint() === ("base" || "sm" || "md");

  const isLoading = isInitialLoading || !!loading;

  return (
    <Card mt={4} {...props}>
      <Flex pb={3} justifyContent="space-between">
        <Flex>
          {!isSmallPeriodSelector ? (
            periodOptions.map((periodOption, idx) => (
              <Button
                key={periodOption}
                colorScheme={period === periodOption ? "brand" : undefined}
                bgGradient={
                  period === periodOption
                    ? "linear(to-b, brand.300, brand.500)"
                    : undefined
                }
                color={period === periodOption ? "white" : undefined}
                size="sm"
                width={12}
                _focus={{ boxShadow: "none", outline: "none" }}
                onClick={() => setPeriod(periodOption as PeriodEnum)}
                borderRightRadius={idx < periodOptions.length - 1 ? 0 : "md"}
                borderLeftRadius={0 < idx ? 0 : "md"}
                borderLeftWidth={0 < idx ? 1 : 0}
                borderLeftColor={0 < idx ? "gray.700" : undefined}
              >
                {periodOption}
              </Button>
            ))
          ) : (
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                _focus={{ boxShadow: "none", outline: "none" }}
                _active={{ bg: "brand.300" }}
                size="sm"
                colorScheme={"brand"}
                bgGradient={"linear(to-b, brand.300, brand.500)"}
                color={"white"}
              >
                {period}
              </MenuButton>
              <MenuList minW="auto">
                {periodOptions
                  .filter((o) => o !== period)
                  .map((value) => (
                    <MenuItem
                      onClick={() => setPeriod(value as PeriodEnum)}
                      key={value}
                    >
                      {value}
                    </MenuItem>
                  ))}
              </MenuList>
            </Menu>
          )}
        </Flex>
        <Flex alignItems="center" gap={1}>
          {isFetching && (
            <CircularProgress
              color="brand.300"
              size={4}
              isIndeterminate
              mr={1}
            />
          )}
          {selectedHolding && (
            <Flex>
              <Avatar
                size="xs"
                src={selectedHolding.logoUrl || undefined}
                icon={<BiBlock fontSize="22px" color="white" />}
              />
              <Box ml={1} mr={2} fontWeight="bold">
                {selectedHolding.symbol}
              </Box>
            </Flex>
          )}
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              _focus={{ boxShadow: "none", outline: "none" }}
              size="sm"
            >
              {typeOptions.find((x) => x.value === chartType)?.label ||
                "Share per price"}
            </MenuButton>
            <MenuList>
              {typeOptions.map(
                ({
                  value,
                  label,
                  extra,
                }: {
                  value: string;
                  label: string;
                  extra: string;
                }) => (
                  <MenuItem
                    onClick={() =>
                      setChartType && setChartType(value as PartyChartView)
                    }
                    key={value}
                  >
                    {label}
                    {extra}
                  </MenuItem>
                ),
              )}
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      <Box height="310px" width={["100%"]} borderRadius="md" overflow="hidden">
        <Chart
          loading={isLoading}
          error={error?.message}
          data={chartData}
          period={period}
          valuePrefix={chartType === PartyChartView.PartyTotalSupply ? "" : "$"}
          valueSuffix={
            chartType === PartyChartView.PartyTotalSupply ? " PT" : ""
          }
        />
      </Box>
    </Card>
  );
}
