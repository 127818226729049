import React from "react";

import { Box, Button, Checkbox, Text, useColorMode } from "@chakra-ui/react";

import InputBase from "./Base";

interface IInputToggleProps {
  id: string;
  label?: string | string[];
  error?: string;
  themeMode?: "light" | "dark";
  setRef?:
    | React.RefObject<HTMLInputElement>
    | React.RefCallback<HTMLInputElement>;
  value?: string | number;
  checked?: boolean;
  onBlur?: () => void;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}

export const InputToggle = ({
  id,
  label,
  error,
  setRef,
  themeMode,
  onBlur,
  onChange,
  checked,
  disabled = false,
}: IInputToggleProps) => {
  const { colorMode } = useColorMode();
  const isLightMode = themeMode === "light" || colorMode === "light";

  return (
    <InputBase id={id} error={error}>
      <Box
        rounded="full"
        position="relative"
        overflow="hidden"
        width="fit-content"
        transitionProperty="color"
        transition="ease-in-out"
        transitionDuration="200ms"
        borderColor={isLightMode ? "gray.200" : "gray.800"}
        // sx={{
        //   '-webkit-backface-visibility': 'hidden',
        //   '-moz-backface-visibility': 'hidden',
        //   '-webkit-transform': 'translate3d(0, 0, 0)',
        //   '-moz-transform': 'translate3d(0, 0, 0)',
        // }}
      >
        <Button
          variant="unstyled"
          w="175px"
          h={10}
          rounded="full"
          role="switch"
          onClick={() => onChange(!checked)}
          overflow="hidden"
          disabled={disabled}
        >
          <Checkbox
            isReadOnly
            ref={setRef}
            id={id}
            isChecked={checked}
            onBlur={onBlur}
          />
          <Box
            position="absolute"
            top="0"
            w="175px"
            h={10}
            transitionProperty="color"
            transition="ease-in-out"
            transitionDuration="200ms"
            bg={isLightMode ? "gray.200" : "gray.800"}
          />
          <Box
            display="flex"
            position="absolute"
            top="1"
            left="2"
            h={8}
            w="100px"
            rounded="full"
            bgGradient="linear(to-b, brand.300, brand.500)"
            shadow="md"
            transition="transform"
            transform="auto"
            transitionDuration="200ms"
            justifyContent="center"
            alignItems="center"
            translateX={checked ? "60px" : "0"}
          >
            <Text color={"white"}>
              {Array.isArray(label) ? label[checked ? 1 : 0] : label}
            </Text>
          </Box>
        </Button>
      </Box>
    </InputBase>
  );
};

export default InputToggle;
