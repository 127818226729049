export const shortenText = (text: string, length: number) => {
  const indexes = [];
  const limitedDesc = text.substring(0, length);
  indexes.push(limitedDesc.lastIndexOf("."));
  indexes.push(limitedDesc.lastIndexOf("?"));
  indexes.push(limitedDesc.lastIndexOf("!"));

  const maxIndex = Math.max(...indexes);

  const final = limitedDesc.substring(
    0,
    maxIndex === -1 ? length : maxIndex + 1,
  );
  return final + (text.length > length ? "..." : "");
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Configuration
const COUNT_FORMATS = [
  {
    // 0 - 999
    letter: "",
    limit: 1e3,
  },
  {
    // 1,000 - 999,999
    letter: "K",
    limit: 1e6,
  },
  {
    // 1,000,000 - 999,999,999
    letter: "M",
    limit: 1e9,
  },
  {
    // 1,000,000,000 - 999,999,999,999
    letter: "B",
    limit: 1e12,
  },
  {
    // 1,000,000,000,000 - 999,999,999,999,999
    letter: "T",
    limit: 1e15,
  },
];

// Format Method:
export function formatCount(value: number) {
  const format = COUNT_FORMATS.find((format) => value < format.limit);
  if (!format) return value;

  value = (1000 * value) / format.limit;
  value = Math.round(value * 10) / 10; // keep one decimal number, only if needed

  return `${value} ${format.letter}`;
}
