import { useMemo } from "react";

import { useColorModeValue } from "@chakra-ui/react";

import { type PartyChartTicksGeneration } from "@partyfinance/generator";

export default function useSparklineStyles(
  data: PartyChartTicksGeneration | undefined,
) {
  const sparklineBgPositive = useColorModeValue("#e9f2e7", "#2c3f44");
  const sparklineBgNegative = useColorModeValue("#ffe9e9", "#443644");
  return useMemo(() => {
    let percentChange: number | null = null;
    let sparklineColor: undefined | "green" | "red" = undefined;
    let sparklineBg:
      | undefined
      | typeof sparklineBgNegative
      | typeof sparklineBgPositive = undefined;
    if (data) {
      if (data.length > 1) {
        const first = data[0] as PartyChartTicksGeneration[number];
        const last = data[data.length - 1] as PartyChartTicksGeneration[number];
        sparklineColor = first.value > last.value ? "red" : "green";
        sparklineBg =
          first.value > last.value ? sparklineBgNegative : sparklineBgPositive;
        percentChange =
          first.value > 0
            ? Math.round((last.value / first.value - 1) * 10000) / 100
            : null;
      }
    }
    return {
      sparklineColor,
      sparklineBg,
      percentChange,
    };
  }, [data, sparklineBgNegative, sparklineBgPositive]);
}
