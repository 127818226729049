import {
  Avatar,
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  type UseDisclosureProps,
} from "@chakra-ui/react";

import { Button, CloseButton } from "~/components/Buttons";

import { usePlatformNFT } from "~/hooks/platform";

interface ActionDeniedProps extends UseDisclosureProps {
  onClose: () => void;
  isOpen: boolean;
  title?: string;
  message?: string;
}

const PunkRequiredModal = ({
  onClose,
  isOpen,
  title = "Sorry...",
  message = "You must own a PartyPunk in order to use PartyFinance.",
}: ActionDeniedProps) => {
  const { mintPartyPunk } = usePlatformNFT();

  const handleMint = async () => {
    onClose();
    await mintPartyPunk();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent my="auto">
        <ModalHeader>
          <CloseButton aria-label={"Close Button"} onClose={onClose} />
        </ModalHeader>
        <ModalBody px="3rem">
          <Flex>
            <Avatar
              size="lg"
              src="/assets/images/party-punk.jpg"
              mr="1rem"
              my="auto"
            />
            <Box>
              <Text fontSize="xl">{title}</Text>
              <Text width="fit-content" mx="auto">
                {message}
              </Text>
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter mt="1rem">
          <Button
            minW="12rem"
            ml={["auto"]}
            mx="auto"
            mb="0.5rem"
            onClick={() => void handleMint()}
          >
            Mint Now
          </Button>
        </ModalFooter>
        <Text color="gray.500" mx={6} pb={6} textAlign="center">
          In case you have already minted your PartyPunk, wait a couple of
          seconds or reload the page.
        </Text>
      </ModalContent>
    </Modal>
  );
};

export default PunkRequiredModal;
