import { type FC } from "react";

import { Box, Link, Text, useColorModeValue } from "@chakra-ui/react";
import {
  FiAlertTriangle,
  FiCheckCircle,
  FiInfo,
  FiXCircle,
} from "react-icons/fi";
import {
  ToastContainer as ToastifyContainer,
  toast as reactToastify,
  type ToastOptions,
} from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { formatBlockExplorerLink } from "@partyfinance/core";

type ToastContentProps = {
  title: string;
  body?: string;
};

export const ToastContent: FC<ToastContentProps> = (props) => {
  const bodyColor = useColorModeValue("gray.400", "gray.500");
  return (
    <Box fontSize="sm">
      <Text fontWeight="400">{props.title}</Text>
      {props.body && <Text color={bodyColor}>{props.body}</Text>}
    </Box>
  );
};

interface IToastTxnContent {
  title: string;
  chainId: number;
  txHash: string;
}

export const ToastTxnContent = ({
  title,
  chainId,
  txHash,
}: IToastTxnContent) => {
  return (
    <Box fontSize="sm">
      <Text fontWeight="500">{title}</Text>
      <Link
        href={formatBlockExplorerLink("tx", [chainId, txHash])}
        target={"_blank"}
        rel={"noreferrer"}
        _hover={{ textDecoration: "none" }}
      >
        <Text color={"brand.500"} _hover={{ color: "brand.400" }}>
          View transaction
        </Text>
      </Link>
    </Box>
  );
};

export const ToastContainer = () => (
  <ToastifyContainer
    theme={useColorModeValue("light", "dark")}
    toastClassName="toastify-custom"
    bodyClassName="toastify-custom-body"
    limit={8}
    autoClose={2000}
  />
);

const toast = {
  info(
    content: ToastContentProps,
    options?: ToastOptions<Record<string, unknown>> | undefined,
  ) {
    return reactToastify.info(
      <ToastContent title={content.title} body={content.body} />,
      {
        icon: <FiInfo color="#3498db" size="xl" />,
        ...options,
      },
    );
  },
  warn(
    content: ToastContentProps,
    options?: ToastOptions<Record<string, unknown>> | undefined,
  ) {
    return reactToastify.warn(
      <ToastContent title={content.title} body={content.body} />,
      {
        icon: <FiAlertTriangle color="#f1c40f" size="xl" />,
        ...options,
      },
    );
  },
  success(
    content: ToastContentProps,
    options?: ToastOptions<Record<string, unknown>> | undefined,
  ) {
    return reactToastify.success(
      <ToastContent title={content.title} body={content.body} />,
      {
        icon: <FiCheckCircle color="#07bc0c" size="xl" />,
        ...options,
      },
    );
  },
  error(
    content: ToastContentProps,
    options?: ToastOptions<Record<string, unknown>> | undefined,
  ) {
    return reactToastify.error(
      <ToastContent title={content.title} body={content.body} />,
      {
        icon: <FiXCircle color="#e74c3c" size="xl" />,
        ...options,
      },
    );
  },
  successTxn(
    content: IToastTxnContent,
    options?: ToastOptions<Record<string, unknown>> | undefined,
  ) {
    return reactToastify.success(
      <ToastTxnContent
        chainId={content.chainId}
        title={content.title}
        txHash={content.txHash}
      />,
      {
        icon: <FiCheckCircle color="#07bc0c" size="xl" />,
        ...options,
      },
    );
  },
  errorTxn(
    content: IToastTxnContent,
    options?: ToastOptions<Record<string, unknown>> | undefined,
  ) {
    return reactToastify.error(
      <ToastTxnContent
        chainId={content.chainId}
        title={content.title}
        txHash={content.txHash}
      />,
      {
        icon: <FiXCircle color="#e74c3c" size="xl" />,
        ...options,
      },
    );
  },
  dismiss: reactToastify.dismiss,
  isActive: reactToastify.isActive,
};

export default toast;
