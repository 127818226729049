import { useEffect, useState, type ReactNode } from "react";

import { Fade, Slide } from "@chakra-ui/react";

export default function LoaderWithFeedback({
  children,
  delay = 15000,
}: {
  children: ReactNode;
  delay?: number;
}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(false);
    const timeout = setTimeout(() => {
      setShow(true);
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Slide
      direction="bottom"
      in={show}
      style={{ position: "relative", top: "-24px" }}
    >
      <Fade in={show}>{children}</Fade>
    </Slide>
  );
}
