import { useQuery } from "@tanstack/react-query";

import {
  fetchPartyInfo,
  type IPartyInfo,
} from "@partyfinance/thegraph-queries";

import { env } from "~/env.mjs";

const usePartyInfo = (
  partyAddress: string,
  chainId: number,
  available = true,
) => {
  const enabled = !!partyAddress && available;

  const { data, error, refetch } = useQuery<IPartyInfo | null>(
    ["party-info", chainId, partyAddress],
    () =>
      fetchPartyInfo(chainId, env.NEXT_PUBLIC_THEGRAPH_API_KEY, partyAddress),
    { enabled },
  );

  return {
    partyInfo: data || null,
    loading: !error && data === undefined && !!partyAddress,
    reloadPartyInfo: () => refetch(),
    error,
  };
};

export default usePartyInfo;
