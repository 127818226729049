import { useRef, useState, type ReactNode } from "react";

import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Button as ChakraButton,
  Flex,
  Heading,
  Text,
  VStack,
  useDisclosure,
  type ButtonProps,
} from "@chakra-ui/react";
import { useAccount } from "wagmi";

import { api, type RouterOutput } from "~/utils/api";

import { Button } from "~/components/Buttons";
import { Modal, ShareModal, type ShareModalRef } from "~/components/Modals";

import toast from "~/compounds/toast";
import { useGovernanceContext } from "~/contexts/GovernanceContext";

import PollResults from "../PollResults";

interface IVoteButtonProps extends ButtonProps {
  children: ReactNode;
  poll: NonNullable<RouterOutput["poll"]["byId"]>;
  option: NonNullable<RouterOutput["poll"]["byId"]>["Options"][number];
}

const VoteButton = ({
  children,
  poll,
  option,
  ...btnProps
}: IVoteButtonProps) => {
  const { isApeHolder, userApes, userVotingPower, fetchUserVotes, userVotes } =
    useGovernanceContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { address: account } = useAccount();

  const [loading, setLoading] = useState(false);

  // Api hooks
  const utils = api.useContext();
  const voteMutation = api.poll.vote.useMutation({
    async onSuccess() {
      // refetches polls after a proposal is added
      await utils.poll.byId.invalidate({ id: poll.id });
    },
    onError(error) {
      toast.dismiss();
      console.log(error.message);
      toast.error({
        title: "An error occurred while casting the vote",
        body: error.message,
      });
    },
  });

  // ShareModal
  const shareModalRef = useRef<ShareModalRef>(null);

  const handleOpenConfirm = async () => {
    const hasAlreadyVoted =
      userVotes.filter((v) => v.pollId === poll.id).length > 0;
    if (hasAlreadyVoted) {
      toast.dismiss();
      return toast.info({
        title: "Already Voted!",
        body: "You have already cast a vote on this proposal",
      });
    }
    setLoading(true);
    const isHolder = await isApeHolder();
    setLoading(false);
    if (!isHolder) return;
    onOpen();
  };

  const handleConfirmVote = async () => {
    // set option ID if type multi, else pass yes or no
    if (!option) return;

    console.log("user voting power ", userVotingPower);
    setLoading(true);

    if (!account) {
      toast.info({
        title: "Wallet connection required.",
        body: "Connect your wallet to proceed casting a vote.",
      });
      return false;
    }

    console.log(option);

    try {
      await voteMutation.mutateAsync({
        pollId: poll.id,
        optionId: option.id,
        common: userApes.common.ids,
        bronze: userApes.bronze.ids,
        silver: userApes.silver.ids,
        gold: userApes.gold.ids,
        platinum: userApes.platinum.ids,
      });
      void fetchUserVotes();
      toast.success({
        title: "Vote Cast",
        body: "Your vote has been cast successfully.",
      });
      setLoading(false);
      onClose();
      if (shareModalRef.current) {
        shareModalRef.current.openModal(
          `${window.location.origin.toString()}/vote/${poll.id}`,
        );
      }
    } catch {
      onClose();
      setLoading(false);
      return false;
    }
  };

  return (
    <>
      <ChakraButton
        minW={40}
        w={{ base: "full", md: "auto" }}
        onClick={() => void handleOpenConfirm()}
        isLoading={loading}
        {...btnProps}
      >
        {children}
      </ChakraButton>
      <ShareModal ref={shareModalRef}>
        <CheckCircleIcon boxSize={16} color={"green.500"} />
        <Heading size="md" mt={6} mb={4}>
          Your vote has been cast!
        </Heading>
        <PollResults
          poll={poll}
          borderRadius="lg"
          border={"1px solid #3A4055"}
          p={4}
          w="full"
        />
        <Text my={6}>Invite others to vote</Text>
      </ShareModal>
      <Modal
        isOpen={isOpen}
        onClose={() => (loading ? null : onClose())}
        title={"Vote confirmation"}
        size={"lg"}
        isCentered
      >
        <VStack spacing={6} p={4} pt={0}>
          <Flex
            direction="column"
            w="full"
            borderRadius="lg"
            border={"1px solid #3A4055"}
            p={4}
            gap={2}
          >
            <Flex justifyContent="space-between">
              <Text fontWeight="bold">Proposal</Text>
              <Text maxWidth="260px" textAlign="end" fontSize="sm">
                {poll.name}
              </Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text fontWeight="bold">Vote</Text>
              <Text maxWidth="260px" fontSize="sm">
                {option.value.toUpperCase() || ""}
              </Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text fontWeight="bold">Your voting power</Text>
              <Text fontSize="sm">
                {userVotingPower} vote{userVotingPower === 1 ? "" : "s"}
              </Text>
            </Flex>
          </Flex>
          <Text fontSize="sm" color="gray.400">
            Once the vote is submitted it cannot be undone
          </Text>
          <Button
            minW={40}
            onClick={() => void handleConfirmVote()}
            isLoading={loading}
          >
            Confirm Vote
          </Button>
        </VStack>
      </Modal>
    </>
  );
};

export default VoteButton;
