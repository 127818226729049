import { useQuery } from "@tanstack/react-query";

import { type Party } from "@partyfinance/contracts";
import { type AnnouncementStructOutput } from "@partyfinance/contracts/src/types/Party";
import { type IPartyInfo } from "@partyfinance/thegraph-queries";

import { endOfLegacyContract } from "~/config";
import { usePartyContext } from "~/contexts/PartyContext";
import { type Announcement } from "~/modules/party/types";

import { useReadPartyContract } from "..";

async function getPartyAnnouncements(partyInfo: IPartyInfo, contract: Party) {
  try {
    let announcements: Announcement[];
    const isLegacyParty = partyInfo.inception < endOfLegacyContract;

    // Fetch old type announcements if party is a legacy party
    if (isLegacyParty) {
      /// @dev [PostMigration] Remove this function
      const _announcements = await contract.getPosts();
      announcements = _announcements.map((x) => ({
        title: x.title,
        content: x.description,
        img: x.img,
        url: x.url || "",
        created: Number(x.date.toString()),
        updated: Number(x.date.toString()),
      }));
    } else {
      // Else fetch the new type of announcements
      const _announcements: AnnouncementStructOutput[] =
        await contract.getAnnouncements();
      announcements = _announcements.map((x) => ({
        title: x.title,
        content: x.content,
        img: x.img,
        url: x.url || "",
        created: x.created.toNumber(),
        updated: x.updated.toNumber(),
      }));
    }

    return announcements.sort((a, b) => b.created - a.created);
  } catch (err) {
    console.log("Error while running getPartyAnnouncements", err);
    throw Error("Error while trying to fetch party announcements");
  }
}

const useReadPartyAnnouncements = (partyAddress: string, available = true) => {
  const { partyInfo } = usePartyContext();
  const readContract = useReadPartyContract(partyAddress);

  const enabled = !!partyInfo && !!readContract && !!partyAddress && available;

  function query() {
    if (!enabled) return null;
    return getPartyAnnouncements(partyInfo, readContract);
  }

  const { data, error, isFetching, refetch } = useQuery(
    ["party-announcements", partyInfo?.chainId, partyAddress],
    query,
    {
      enabled,
      refetchOnWindowFocus: false,
    },
  );

  return {
    announcements: data || [],
    reload: () => refetch(),
    isValidating: isFetching,
    loading: !error && !data,
    error,
  };
};

export default useReadPartyAnnouncements;
