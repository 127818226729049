import { type RouterOutput } from "~/utils/api";

export enum PartyApeTierEnum {
  Common = "Common",
  Bronze = "Bronze",
  Silver = "Silver",
  Gold = "Gold",
  Platinum = "Platinum",
}

export enum PartyApeVotingPowerEnum {
  Common = 1,
  Bronze = 2,
  Silver = 3,
  Gold = 4,
  Platinum = 5,
}

export interface PartyApe {
  tier: PartyApeTierEnum;
  amount: number;
}

export enum PollTypeEnum {
  Simple = "simple",
  Multi = "multi",
}

type PollOptionReq = {
  description: string;
};

export enum PollStatus {
  Active = 0,
  Executed = 1,
  Discarted = 2,
}

export interface PollForm {
  title: string;
  description: string;
  type: PollTypeEnum;
  endDate: number;
  options?: PollOptionReq[];
}

export interface IPartyApeMetadata {
  id: string;
  tier: PartyApeTierEnum;
}
type IUserApe = {
  ids: number[];
  img: string;
};

export interface IUserApes {
  common: IUserApe;
  bronze: IUserApe;
  silver: IUserApe;
  gold: IUserApe;
  platinum: IUserApe;
}

export type UserVote = {
  pollId: string;
  option: string;
  user: string;
  votingPower: number;
  votedAt: number;
  common: number[];
  bronze: number[];
  silver: number[];
  gold: number[];
  platinum: number[];
};
export type PollItem = RouterOutput["poll"]["getAll"][number];
