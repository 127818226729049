import { useEffect, type FC } from "react";

import {
  Box,
  Button,
  CloseButton,
  Collapse,
  Flex,
  Link,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";

/**
 * @dev
 * Change this whenever we need to show a global banner to
 * display all users
 * Example: Competitions, updates, funding, etc...
 */
const notification = {
  visible: false,
  timeBasedInterval: 24 * 60 * 60 * 1000, // show at least once a day
  title: "We are on Party Wars!",
  description: "May the best performing parties win!",
  learnMoreUrl:
    "https://medium.com/@PartyFi/partyfis-community-exclusive-crypto-trading-competition-partywars-is-underway-e0432f3b3473",
};

const NotificationBanner: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (notification.visible && window && !!window.localStorage) {
      const lastDismissedTime = Number(
        window.localStorage.getItem("lastBannerDismised"),
      );
      if (
        !lastDismissedTime ||
        Date.now() - lastDismissedTime * 1000 > notification.timeBasedInterval
      ) {
        timeout = setTimeout(onOpen, 5000);
      }
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    if (window && window.localStorage) {
      window.localStorage.setItem(
        "lastBannerDismised",
        (Date.now() / 1000).toString(),
      );
    }
    onClose();
  };

  return (
    <Collapse in={isOpen} animateOpacity>
      <>
        <Box
          as={Flex}
          position="fixed"
          width="full"
          px={2}
          py={1}
          m={0}
          height={useBreakpointValue({ base: "98px", md: 10 }, "md")}
          zIndex={99}
          bg="brand.300"
          alignItems="center"
          justifyContent="space-between"
          fontSize="sm"
        >
          <Text />
          <Flex
            direction={{ base: "column", md: "row" }}
            alignItems="center"
            gap={{ base: 2, md: 0 }}
          >
            <Flex alignItems="center">
              🎉
              <Text mx={1} fontWeight="bold" textAlign="center">
                {notification.title}
              </Text>
              🎉
            </Flex>
            <Text ml={2} mr={3} textAlign="center">
              {notification.description}
            </Text>
            <Link
              href={notification.learnMoreUrl}
              isExternal
              _hover={{ textDecoration: "none" }}
            >
              <Button size="sm" _focus={{ outline: "none" }}>
                Learn more
              </Button>
            </Link>
          </Flex>
          <CloseButton
            justifySelf="flex-end"
            onClick={handleClose}
            size="sm"
            _focus={{ outline: "none" }}
          />
        </Box>
        <Box height={useBreakpointValue({ base: "98px", md: 10 }, "md")} />
      </>
    </Collapse>
  );
};

export default NotificationBanner;
