/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";

import { type ContractTransaction } from "@ethersproject/contracts";

import { type LibSignatures } from "@partyfinance/contracts/src/types/Party";

import { api } from "~/utils/api";
import { emptyAllocationArgs, emptySignature } from "~/utils/web3/args";

import { usePartyContext } from "~/contexts/PartyContext";
import { useTransactionContext } from "~/contexts/TransactionContext";
import { usePlatformCheck } from "~/hooks/platform";
import { handleCustomError } from "~/modules/party/errors";
import { TransactionActions } from "~/modules/party/types";

import { usePartyContract } from ".";

const steps = [
  {
    label: "Leave party",
    content: "Confirm that you want to leave the party in your wallet",
  },
  {
    label: "Party confirmation",
    content: "Please wait until the party leaving is confirmed",
    loading: true,
    showGame: true,
  },
];

const usePartyLeave = (partyAddress: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { chainId } = usePartyContext();
  const { checkWallet } = usePlatformCheck();
  const partyContract = usePartyContract(partyAddress);
  const {
    addTransaction,
    updateTransaction,
    openConfirmationWithSteps,
    closeConfirmationWithSteps,
    setStep,
    setErrorMsg,
  } = useTransactionContext();
  // Api hooks
  const signatureWithdraw = api.signature.withdraw.useMutation();

  const leaveParty = async (liquidateMemberFunds: boolean) => {
    const account = checkWallet();
    if (!account || !partyContract) return null;
    setLoading(true);
    openConfirmationWithSteps(steps);
    let txHash = "";

    try {
      let allocationArgs: LibSignatures.AllocationStruct = emptyAllocationArgs;
      let signature: LibSignatures.SigStruct = emptySignature;
      if (liquidateMemberFunds) {
        // 1) Get allocation signature
        const data = await signatureWithdraw.mutateAsync({
          partyAddress: partyContract.address,
          userAddress: account,
          withdrawPercentage: 10000, // 100%, expressed as bps
          chainId: chainId,
          action: "leave",
        });
        allocationArgs = data.allocationArgs;
        signature = data.signature;
      }

      // 2) Leave the party
      const newLeave: ContractTransaction = await partyContract.leaveParty(
        allocationArgs,
        signature,
        liquidateMemberFunds,
      );
      txHash = newLeave.hash;
      addTransaction(TransactionActions.leaveParty, txHash);

      setStep(1);
      await newLeave.wait(2);
      updateTransaction(txHash, true, true);
      txHash = "";

      closeConfirmationWithSteps();
    } catch (err: any) {
      console.log(err);
      const customError = handleCustomError(err);
      setErrorMsg(customError.message);
      if (txHash) {
        updateTransaction(txHash, false, true);
      }
    }
    setLoading(false);
  };

  return {
    leaveParty,
    loadingLeave: loading,
  };
};

export default usePartyLeave;
