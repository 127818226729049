import { useDisclosure } from "@chakra-ui/react";
import { useAccount } from "wagmi";

import { Button } from "~/components/Buttons";
import { ClaimRewardsModal } from "~/components/Modals";

import { useStake } from "~/hooks/staking";
import { type IPool } from "~/modules/staking/types";

interface IClaimRewardsButton {
  pool: IPool;
  userRewards: number;
}

const ClaimRewardsButton = ({
  pool,
  userRewards,
  ...btnProps
}: IClaimRewardsButton) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { address: account } = useAccount();
  const { claimRewards } = useStake();

  const handleClaimRewards = async () => {
    try {
      onClose();
      await claimRewards();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Button
        w={{ base: 60, md: 40 }}
        onClick={onOpen}
        disabled={!account}
        {...btnProps}
      >
        Claim Rewards
      </Button>

      <ClaimRewardsModal
        isOpen={isOpen}
        onClose={onClose}
        pool={pool}
        userRewards={userRewards}
        confirmAction={handleClaimRewards}
      />
    </>
  );
};

export default ClaimRewardsButton;
