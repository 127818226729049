import { type FC } from "react";

import { Box, Skeleton, useColorModeValue } from "@chakra-ui/react";

import { Card } from "~/components/Card";

const LimitOrderLoader: FC = () => {
  const itemBg = useColorModeValue("gray.200", "gray.800");
  return (
    <Box
      as={Card}
      width="100%"
      mt={6}
      position="relative"
      overflow="hidden"
      bg={itemBg}
    >
      <Skeleton height="16px" width="100%" mx="auto" />
      <Skeleton height="16px" mt="4" width="100%" mx="auto" />
      <Skeleton height="16px" mt="4" width="100%" mx="auto" />
      <Skeleton height="16px" mt="4" width="100%" mx="auto" />
    </Box>
  );
};
export default LimitOrderLoader;
