import { type IPool } from "../types";

const POOLS: IPool[] = [
  {
    id: "PFI3MO",
    title: "LP Staking Pool 3MO",
    img: "",
    balance: 170000,
    apy: 3.6,
    type: "Staking",
    access: "PFi Holders",
    endTime: 1664470404000,
    userBalance: 1000,
  },
  {
    id: "PFI6MO",
    title: "LP Staking Pool 6MO",
    img: "",
    balance: 360000,
    apy: 7.6,
    type: "Staking",
    access: "PFi Holders",
    endTime: 1680042804000,
  },
];

export function fetchPools() {
  return POOLS;
}
