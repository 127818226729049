import { useMemo, useState } from "react";

import { JsonRpcProvider } from "@ethersproject/providers";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  BigNumber,
  type ContractReceipt,
  type ContractTransaction,
} from "ethers";
import { useSigner } from "wagmi";

import {
  ERC20__factory,
  PartyApes__factory,
  type PartyApes,
} from "@partyfinance/contracts";
import { type MintRequestedEvent } from "@partyfinance/contracts/src/types/PartyApes";
import { getPartyApesAddress, getUSDCAddress } from "@partyfinance/core";

import { getClientInfuraRPC } from "~/utils/web3/rpc";

import { useTransactionContext } from "~/contexts/TransactionContext";
import { handleCustomError } from "~/modules/party/errors";
import { TransactionActions } from "~/modules/party/types";

import usePlatformCheck from "./usePlatformCheck";

const steps = [
  {
    label: "Allow USDC spend",
    content:
      "Confirm the mint USDC spend allowance in your wallet. This will consume your USDC to mint the PartyApe",
  },
  {
    label: "Allowance confirmation",
    content: "Please wait until the USDC allowance transaction is confirmed",
    loading: true,
    showGame: true,
  },
  {
    label: "Request PartyApe",
    content: "Confirm the mint request action in your wallet",
  },
  {
    label: "Mint request",
    content: "Please wait until the PartyApe mint request is confirmed",
    loading: true,
    showGame: true,
  },
  {
    label: "Mint confirmation",
    content:
      "Please wait until Chainlink VRF process the random minting of your PartyApe. This should take at least 3 blocks confirmations",
    loading: true,
    showGame: true,
  },
];

function getPartyApesInfo(contract: PartyApes | null) {
  return async () => {
    if (!contract) return null;
    const price = await contract.price();
    const remaining = await contract.getRemaining();
    return {
      price,
      availablePartyApes: Number(remaining.toString()),
    };
  };
}

export function getRequestIdFromReceipt(receipt: ContractReceipt) {
  const contractInterface = PartyApes__factory.createInterface();
  const eventTopic = contractInterface.getEventTopic("MintRequested");
  const log = receipt.logs.find((log) => log.topics[0] === eventTopic);
  if (!log) throw new Error("`MintRequestedEvent` event not found on receipt");
  const createEvent = contractInterface.parseLog(log) as unknown as Pick<
    MintRequestedEvent,
    "args"
  >;
  return createEvent.args.requestId;
}

export default function usePartyApes(
  onSuccess: (tokenId: number, tier: string, img: string) => void,
) {
  const [loading, setLoading] = useState(false);
  const readPartyApes = useMemo(() => {
    const RPC_URL = getClientInfuraRPC(137);
    if (!RPC_URL) {
      console.error("RPC is not defined");
      return null;
    }
    try {
      const provider = new JsonRpcProvider(RPC_URL);
      return PartyApes__factory.connect(getPartyApesAddress(137), provider);
    } catch (error) {
      console.error("Failed To Get PartyApes Contract", error);
      return null;
    }
  }, []);
  const { checkWallet } = usePlatformCheck();
  const { data: signer } = useSigner();
  const {
    addTransaction,
    updateTransaction,
    openConfirmationWithSteps,
    setStep,
    reset,
    setErrorMsg,
    closeConfirmationWithSteps,
  } = useTransactionContext();

  const { data, refetch, isFetching } = useQuery(
    ["party-apes-info"],
    getPartyApesInfo(readPartyApes),
    {
      enabled: !!readPartyApes,
      refetchInterval: 60 * 1000,
    },
  );
  const { availablePartyApes, price } = data || {
    price: BigNumber.from("0"),
    availablePartyApes: 0,
  };

  const mintPartyApe = async () => {
    const account = checkWallet();
    if (!signer || !account) return false;
    const partyApes = PartyApes__factory.connect(
      getPartyApesAddress(137),
      signer,
    );
    const usdc = ERC20__factory.connect(getUSDCAddress(137), signer);
    reset();
    openConfirmationWithSteps(steps);
    let txHash = "";

    try {
      setLoading(true);
      // 1) Check USDC allowance
      const allowance = await usdc.allowance(account, partyApes.address);
      if (allowance.lt(price)) {
        console.log("User needs to approve pFi");
        const approval: ContractTransaction = await usdc.approve(
          partyApes.address,
          price,
        );
        setStep(1);
        addTransaction(TransactionActions.approveUSDC, approval.hash);
        txHash = approval.hash;
        await approval.wait();
        updateTransaction(txHash, true, false);
        txHash = "";
      }
      setStep(2);
      partyApes.removeAllListeners();
      const newMint: ContractTransaction = await partyApes.mint();
      console.log("newMint", newMint);
      txHash = newMint.hash;
      addTransaction(TransactionActions.mintPartyPunk, txHash);
      setStep(3);
      const receipt = await newMint.wait(1);
      console.log("receipt", receipt);
      updateTransaction(txHash, true, false);
      txHash = "";
      setStep(4);
      const mintRequestId = getRequestIdFromReceipt(receipt);
      partyApes.on(
        "MintSettled",
        (requestId: BigNumber, user: string, tokenId: BigNumber) => {
          console.log("Mint was settled", requestId, user, tokenId);
          void refetch();
          if (mintRequestId.toString() === requestId.toString()) {
            console.log(
              "User minted successfully PartyApe #",
              tokenId.toString(),
            );
            partyApes.removeAllListeners();
            axios
              .get(
                `https://bafybeifvamsyowjclci4dts4aypttiaymu7gy5ffubpt54hcgddtphbevi.ipfs.nftstorage.link/${tokenId.toString()}`,
              )
              .then(({ data }) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                const tier = data.attributes[0].value as string;
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                const img = data.image as string;
                onSuccess(Number(tokenId.toString()), tier, img);
                closeConfirmationWithSteps();
                setLoading(false);
              })
              .catch(() => {
                closeConfirmationWithSteps();
                setLoading(false);
              });
          }
        },
      );
    } catch (err) {
      console.log(err);
      const customError = handleCustomError(err);
      setErrorMsg(customError.message);
      if (txHash) {
        updateTransaction(txHash, false, true);
      }
      setLoading(false);
    }
  };
  return {
    data,
    loading,
    isValidating: isFetching,
    mintPartyApe,
    extraSupply: 1111,
    availablePartyApes,
    price,
  };
}
