import { useCallback } from "react";

import { useAccount, useNetwork } from "wagmi";

import { isAddressEqual } from "@partyfinance/core";

import { getChainName } from "~/utils/web3/chains";

import toast from "~/compounds/toast";
import { usePartyContext } from "~/contexts/PartyContext";
import { usePlatformContext } from "~/contexts/PlatformContext";

export default function usePlatformCheck() {
  const { chainId } = usePartyContext();
  const { profile } = usePlatformContext();
  const { chain: walletChain } = useNetwork();
  const { address: account } = useAccount();

  const checkWallet = useCallback(() => {
    if (!account) {
      // Show modal that wallet is not connected
      toast.dismiss();
      toast.info({
        title: "Please connect your wallet to use the platform",
      });
      return null;
    }
    if (chainId !== walletChain?.id) {
      // Show modal that connected network is invalid
      toast.dismiss();
      toast.info(
        {
          title: `Your wallet is connected to the wrong network`,
          body: `Please, change it to ${getChainName(chainId)}`,
        },
        { autoClose: 3000 },
      );
      return null;
    }
    if (!profile || !isAddressEqual(profile.address, account)) {
      // Show modal that current profile is not matching current connected wallet
      toast.dismiss();
      toast.warn(
        {
          title: `Reconnect your wallet!`,
          body: "Disconnect and connect again to your wallet to continue.",
        },
        { autoClose: 3000 },
      );
      return null;
    }
    return account;
  }, [account, chainId, profile, walletChain]);

  return {
    checkWallet,
  };
}
