import { useRef } from "react";

import { Avatar, Box, Divider, Flex, Portal, Text } from "@chakra-ui/react";
import { BiBlock } from "react-icons/bi";
import {
  Cell,
  Pie,
  PieChart as RechartPieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
} from "recharts";

import { type PartyHolding } from "~/modules/party/types";
import { getToken } from "~/modules/tokens";

const COLORS = ["#ee2a7b", "#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const SHOW_TOP_HOLDINGS = 2;

type LabelProps = {
  cx: number;
  innerRadius: number;
  outerRadius: number;
  index: number;
  percent: number;
  fill: string;
};
const RenderLabel = (
  { cx, innerRadius, outerRadius, index, percent, fill }: LabelProps,
  dataLength: number,
) => {
  const showTopHoldings =
    !dataLength || SHOW_TOP_HOLDINGS >= dataLength ? SHOW_TOP_HOLDINGS : 2;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
  const x = cx;
  const y = ((index + 1) / showTopHoldings) * radius;
  const dy = showTopHoldings * (18 - (index + 1));
  return (
    <text
      fontSize={16}
      x={x}
      dx={-12}
      y={y}
      dy={dy}
      fill={fill}
      alignmentBaseline="central"
      textAnchor={"start"}
      dominantBaseline="central"
    >
      {index < showTopHoldings && `${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

type ActiveShareProps = {
  cx: number;
  cy: number;
  innerRadius: number;
  outerRadius: number;
  startAngle: number;
  endAngle: number;
  fill: string;
};

const RenderActiveShape = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
}: ActiveShareProps) => {
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 5}
        outerRadius={outerRadius + 8}
        fill={fill}
      />
    </g>
  );
};

const HoldingsPieChart = ({ data }: { data: PartyHolding[] }) => {
  const ref = useRef<HTMLDivElement>(null);
  const activeIndexArray: number[] = [];
  for (let i = 0; i < SHOW_TOP_HOLDINGS; i++) {
    activeIndexArray.push(i);
  }

  return (
    <Box height={"175px"} mx="auto">
      <ResponsiveContainer>
        <RechartPieChart style={{ margin: "auto" }}>
          <Tooltip
            content={({ active, payload }) => {
              if (ref && active && payload && payload.length > 0) {
                // @ts-expect-error `payload[0]` is defined
                const item = payload[0].payload as PartyHolding;

                const logoURI = getToken(item.address)?.logoURI;
                return (
                  <Portal containerRef={ref}>
                    <Box
                      mt={1}
                      py={1}
                      px={2}
                      bg={"gray.600"}
                      borderRadius="md"
                      zIndex={2}
                      position="relative"
                      width="100%"
                    >
                      <Flex mb="0.25rem" alignItems="center" gap={2}>
                        <Avatar
                          size="xs"
                          src={logoURI}
                          icon={<BiBlock fontSize="22px" color="white" />}
                        />
                        <Box>
                          <Text>{item.symbol}</Text>
                          <Text color={"gray.300"}>{item.name}</Text>
                        </Box>
                      </Flex>
                      <Divider />
                      <Text color={"gray.300"}>Total:</Text>
                      <Text color={"white"}>
                        {`$${
                          // @ts-expect-error `payload[0]` is defined
                          Math.floor(Number(payload[0].value) * 10000) / 10000
                        }`}
                      </Text>
                      <Divider />
                      <Text color={"gray.300"}>Asset Balance:</Text>
                      <Text color={"white"}>{item.amount}</Text>
                      <Divider />
                      <Text color={"gray.300"}>Weight in Party:</Text>
                      <Text color={"white"}>
                        {item.partyAssetShare === null
                          ? "-"
                          : Math.floor(item.partyAssetShare * 100) / 100}
                        %
                      </Text>
                    </Box>
                  </Portal>
                );
              }
              return null;
            }}
          />
          <Pie
            data={data.filter((x) => x.valueUSD > 0)}
            innerRadius={60}
            outerRadius={75}
            fill="#8884d8"
            paddingAngle={4}
            dataKey="valueUSD"
            activeIndex={activeIndexArray}
            activeShape={RenderActiveShape}
            isAnimationActive={false}
            label={(props: LabelProps) => RenderLabel(props, data.length)}
            labelLine={false}
          >
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </RechartPieChart>
      </ResponsiveContainer>
      <div ref={ref} />
    </Box>
  );
};

export default HoldingsPieChart;
