import { formatUnits } from "@ethersproject/units";

import {
  type PartySortDirection,
  type PartySortEnum,
} from "@partyfinance/thegraph-queries";

import { api } from "~/utils/api";

interface IPartyFilters {
  sortType: PartySortEnum;
  sortDirection: PartySortDirection;
  partyNameSearch: string;
  pageIndex: number;
  pageSize: number;
  minValue?: number;
  selectedChainId?: number;
  keepPreviousData?: boolean;
  revalidateOnFocus?: boolean;
}

const usePublicParties = ({
  partyNameSearch,
  sortType,
  sortDirection,
  pageIndex,
  pageSize,
  revalidateOnFocus,
  keepPreviousData,
  minValue,
  selectedChainId,
}: IPartyFilters) => {
  const { data, error, isFetching } = api.party.all.useQuery(
    {
      partyNameSearch,
      sortType,
      sortDirection,
      pageIndex,
      pageSize,
      minValue,
      selectedChainId,
    },
    {
      keepPreviousData,
      refetchOnWindowFocus: revalidateOnFocus,
      staleTime: 60000,
      select(data) {
        return {
          parties: data.parties.map((p) => ({
            inception: p.inception,
            address: p.address,
            name: p.name,
            img: p.img,
            model: p.model,
            purpose: p.purpose,
            minDeposit: Number(
              formatUnits(String(p.minDeposit), p.denominationAsset.decimals),
            ),
            maxDeposit: Number(
              formatUnits(String(p.maxDeposit), p.denominationAsset.decimals),
            ),
            membersCount: p.membersCount,
            denominationAsset: p.denominationAsset,
            owner: p.owner,
            financials: p.financials,
            chainId: p.chainId,
          })),
          canFetch: data.canFetch,
        };
      },
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    },
  );

  return {
    parties: data?.parties || [],
    canFetchMore: data?.canFetch || false,
    loading: isFetching, //!error && data === undefined,
    error,
  };
};

export default usePublicParties;
