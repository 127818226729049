import { type AppType } from "next/app";

import { ChakraProvider } from "@chakra-ui/react";
import { Global } from "@emotion/react";
import { SessionProvider } from "next-auth/react";
import NextNProgress from "nextjs-progressbar";

import type { Session } from "@partyfinance/auth";

import { api } from "~/utils/api";

import { ToastContainer } from "~/compounds/toast";
import { PartyProvider } from "~/contexts/PartyContext";
import { PlatformProvider } from "~/contexts/PlatformContext";
import RainbowKitProvider from "~/contexts/RainbowKitProvider";
import { TransactionProvider } from "~/contexts/TransactionContext";
import WagmiProvider from "~/contexts/WagmiProvider";
import NotificationBanner from "~/modules/common/components/NotificationBanner";
import { chakraTheme } from "~/theme";

import "../styles/globals.css";
import "@rainbow-me/rainbowkit/styles.css";

const App: AppType<{ session: Session | null }> = ({
  Component,
  pageProps: { session, ...pageProps },
}) => {
  return (
    <ChakraProvider theme={chakraTheme}>
      <Global
        styles={`@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');`}
      />
      <NextNProgress
        color="#ee2a7b"
        stopDelayMs={200}
        options={{ showSpinner: false }}
      />
      <ToastContainer />
      <SessionProvider refetchInterval={0} session={session}>
        <WagmiProvider>
          <RainbowKitProvider>
            <PartyProvider>
              <PlatformProvider>
                <TransactionProvider>
                  <NotificationBanner />
                  <Component {...pageProps} />
                </TransactionProvider>
              </PlatformProvider>
            </PartyProvider>
          </RainbowKitProvider>
        </WagmiProvider>
      </SessionProvider>
    </ChakraProvider>
  );
};

export default api.withTRPC(App);
