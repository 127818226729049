import { Flex, Tag, Text } from "@chakra-ui/react";

import { useCountdown } from "~/modules/common/hooks/useCountdown";

function addLeadingZeros(_value: number) {
  let value = String(_value);
  while (value.length < 2) {
    value = "0" + value;
  }
  return value;
}

const CountdownCol = ({ value, label }: { value: string; label: string }) => (
  <Flex direction="column" width="60px" gap={0}>
    <Text fontSize="3xl">{value}</Text>
    <Text>{label}</Text>
  </Flex>
);

interface IDateCountdown {
  targetDate: string | number | Date;
  asText?: boolean;
  label?: string;
  expirationLabel?: string;
}

export default function DateCountdown({
  targetDate,
  asText = false,
  label,
  expirationLabel = "Expired",
}: IDateCountdown) {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (asText) {
    return (
      <Flex>
        {days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0 ? (
          <Tag
            bgGradient="linear(to-r, red.400, red.500, red.600)"
            colorScheme="red"
            color="white"
            size="xs"
            fontSize="xs"
            px={2}
          >
            {expirationLabel}
          </Tag>
        ) : (
          <Tag>
            {`${label ? `${label} ` : ""}${[days, hours, minutes, seconds]
              .filter((x, i) => (i < 2 ? x > 0 : true))
              .map((x) => addLeadingZeros(x))
              .join(":")}`}
          </Tag>
        )}
      </Flex>
    );
  }
  return (
    <Flex gap={6} justifyContent="center" px={12}>
      <CountdownCol
        value={addLeadingZeros(days)}
        label={days === 1 ? "Day" : "Days"}
      />
      <CountdownCol value={addLeadingZeros(hours)} label="Hours" />
      <CountdownCol value={addLeadingZeros(minutes)} label="Mins" />
      <CountdownCol value={addLeadingZeros(seconds)} label="Secs" />
    </Flex>
  );
}
