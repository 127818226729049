import { type FC } from "react";

import { Box, Skeleton } from "@chakra-ui/react";

import { Card } from "~/components/Card";

const PartyLoader: FC = () => (
  <Box as={Card} width="100%" mt={6} mx={6}>
    <Skeleton height="16px" width="100%" mx="auto" />
    <Skeleton height="16px" mt="4" width="100%" mx="auto" />
    <Skeleton height="16px" mt="4" width="100%" mx="auto" />
    <Skeleton height="16px" mt="4" width="100%" mx="auto" />
  </Box>
);
export default PartyLoader;
