/* eslint-disable turbo/no-undeclared-env-vars */
import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
  server: {
    // App Env
    APP_ENV: z.enum(["local", "dev", "prod"]).refine((x) => {
      return x === process.env.NEXT_PUBLIC_APP_ENV;
    }, "`APP_ENV` not equal to NEXT_PUBLIC_APP_ENV"),
    NODE_ENV: z.enum(["development", "test", "production"]),

    // Infura key
    INFURA_KEY: z.string().length(32),

    // Canny.io
    CANNY_PRIVATE_KEY: z.string().min(1),

    // MongoDB url
    DATABASE_URL: z.string().url(),

    // Next-auth
    NEXTAUTH_SECRET:
      process.env.NODE_ENV === "production"
        ? z.string().min(1)
        : z.string().min(1).optional(),
    NEXTAUTH_URL: z.preprocess(
      // This makes Vercel deployments not fail if you don't set NEXTAUTH_URL
      // Since NextAuth.js automatically uses the VERCEL_URL if present.
      (str) => process.env.VERCEL_URL ?? str,
      // VERCEL_URL doesn't include `https` so it cant be validated as a URL
      process.env.VERCEL ? z.string() : z.string().url(),
    ),

    // Pubnub
    PUBNUB_SECRET_KEY: z.string().min(1),

    // Sentinel signer
    SIGNER_API_KEY: z.string().min(1),
    SIGNER_API_URL: z.string().url(),

    // AWS
    UPLOADS_ACCESS_KEY: z.string().min(1),
    UPLOADS_BUCKET: z.string().min(1),
    UPLOADS_REGION: z.string().min(1),
    UPLOADS_SECRET_KEY: z.string().min(1),

    // Upstash Redis
    UPSTASH_REDIS_JOBS_REST_TOKEN: z.string().min(1),
    UPSTASH_REDIS_US_EAST_REST_TOKEN: z.string().min(1),
    UPSTASH_REDIS_US_WEST_REST_TOKEN: z.string().min(1),
    UPSTASH_REDIS_EU_CENTRAL_REST_TOKEN: z.string().min(1),
    UPSTASH_REDIS_EU_WEST_REST_TOKEN: z.string().min(1),

    // Covalent
    COVALENT_API_KEY: z.string().min(1),

    // 0x
    ZEROX_API_KEY: z.string().uuid(),

    // TheGraph
    THEGRAPH_API_KEY: z.string().min(1),
  },
  client: {
    NEXT_PUBLIC_APP_ENV: z.enum(["local", "dev", "prod"]),
    NEXT_PUBLIC_SUPPORTED_CHAIN_IDS: z.string().min(1),
    NEXT_PUBLIC_INFURA_KEY: z.string().min(1),

    // 0x
    NEXT_PUBLIC_ZEROX_API_KEY: z.string().uuid(),

    // TheGraph
    NEXT_PUBLIC_THEGRAPH_API_KEY: z.string().min(1),
  },
  runtimeEnv: {
    NODE_ENV: process.env.NODE_ENV,
    ////////////
    // Server //
    ////////////
    APP_ENV: process.env.APP_ENV,
    INFURA_KEY: process.env.INFURA_KEY,
    CANNY_PRIVATE_KEY: process.env.CANNY_PRIVATE_KEY,
    DATABASE_URL: process.env.DATABASE_URL,
    NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
    NEXTAUTH_URL: process.env.NEXTAUTH_URL,
    PUBNUB_SECRET_KEY: process.env.PUBNUB_SECRET_KEY,
    SIGNER_API_KEY: process.env.SIGNER_API_KEY,
    SIGNER_API_URL: process.env.SIGNER_API_URL,
    UPLOADS_ACCESS_KEY: process.env.UPLOADS_ACCESS_KEY,
    UPLOADS_BUCKET: process.env.UPLOADS_BUCKET,
    UPLOADS_REGION: process.env.UPLOADS_REGION,
    UPLOADS_SECRET_KEY: process.env.UPLOADS_SECRET_KEY,
    UPSTASH_REDIS_JOBS_REST_TOKEN: process.env.UPSTASH_REDIS_JOBS_REST_TOKEN,
    UPSTASH_REDIS_US_EAST_REST_TOKEN:
      process.env.UPSTASH_REDIS_US_EAST_REST_TOKEN,
    UPSTASH_REDIS_US_WEST_REST_TOKEN:
      process.env.UPSTASH_REDIS_US_WEST_REST_TOKEN,
    UPSTASH_REDIS_EU_CENTRAL_REST_TOKEN:
      process.env.UPSTASH_REDIS_EU_CENTRAL_REST_TOKEN,
    UPSTASH_REDIS_EU_WEST_REST_TOKEN:
      process.env.UPSTASH_REDIS_EU_WEST_REST_TOKEN,
    COVALENT_API_KEY: process.env.COVALENT_API_KEY,
    ZEROX_API_KEY: process.env.ZEROX_API_KEY,
    THEGRAPH_API_KEY: process.env.THEGRAPH_API_KEY,
    ////////////
    // Client //
    ////////////
    NEXT_PUBLIC_APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
    NEXT_PUBLIC_SUPPORTED_CHAIN_IDS:
      process.env.NEXT_PUBLIC_SUPPORTED_CHAIN_IDS,
    NEXT_PUBLIC_INFURA_KEY: process.env.NEXT_PUBLIC_INFURA_KEY,
    NEXT_PUBLIC_ZEROX_API_KEY: process.env.NEXT_PUBLIC_ZEROX_API_KEY,
    NEXT_PUBLIC_THEGRAPH_API_KEY: process.env.NEXT_PUBLIC_THEGRAPH_API_KEY,
  },
});
