import Image from "next/image";

import {
  Flex,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { useAccount } from "wagmi";

import { shortenHex } from "@partyfinance/core";

import { Jazzicon } from "~/components/Icons";

import { useGovernanceContext } from "~/contexts/GovernanceContext";
import { usePlatformContext } from "~/contexts/PlatformContext";

import { PartyApeTierEnum } from "../types";

interface IUserApeInfoBoxProps {
  tier: PartyApeTierEnum;
  amount: number;
}

const UserApeInfoBox = ({ tier, amount }: IUserApeInfoBoxProps) => {
  return (
    <VStack spacing={2} justifyContent="center" alignItems="center">
      <Image
        src={`/assets/images/apes/${tier.toLowerCase()}.png`}
        height={50}
        width={50}
        alt={tier.toLowerCase()}
      />
      <Text fontWeight={"bold"}>{amount}</Text>
    </VStack>
  );
};

const VotingPower = () => {
  const { profile } = usePlatformContext();
  const { userApes, userVotingPower, totalVotes } = useGovernanceContext();
  const { address: account } = useAccount();

  const jazzIconDiameter = useBreakpointValue({ base: 45, sm: 80, lg: 80 });
  const jazzIconRes = useBreakpointValue({ base: -4, sm: -2, lg: -2 });
  const jazzIconDiameterContainer = useBreakpointValue({
    base: "40px",
    sm: 12,
    lg: 16,
  });

  const borderColor = useColorModeValue("gray.200", "gray.700");
  const bg = useColorModeValue("", "rgba(0,0,0,0.15)");

  return (
    <>
      {profile && account && (
        <Stack
          direction={{ base: "column", md: "row" }}
          mx="auto"
          justify={"space-between"}
          border={"1px solid"}
          borderColor={borderColor}
          borderRadius={"lg"}
          bgColor={bg}
          w={"full"}
          spacing={{ sm: 6, md: 6, lg: 8 }}
          p={4}
        >
          <Flex
            gap={2}
            alignItems="center"
            justify={{ base: "center", md: "flex-start" }}
            wrap={{ base: "wrap", md: "nowrap" }}
          >
            <Flex
              w={jazzIconDiameterContainer}
              h={jazzIconDiameterContainer}
              borderRadius="md"
              overflow="hidden"
            >
              <Flex ml={jazzIconRes} mt={jazzIconRes}>
                <Jazzicon
                  diameter={jazzIconDiameter}
                  account={profile.address}
                />
              </Flex>
            </Flex>

            <Flex
              direction="column"
              alignItems={{ base: "center", sm: "flex-start" }}
              justifyContent="center"
              mr={{ base: 0, md: 8 }}
              fontWeight={"bold"}
            >
              {profile !== null && (
                <Text fontSize="sm" textOverflow="ellipsis" maxWidth="130px">
                  {profile?.name || "Anonymous"}
                </Text>
              )}
              <Text
                fontSize="sm"
                color="gray.500"
                textOverflow="ellipsis"
                maxWidth="130px"
              >
                {shortenHex(account || "", 4)}
              </Text>
            </Flex>
            <VStack
              spacing={0}
              justify={"center"}
              align={{ base: "center", sm: "start" }}
              pl={{ sm: 4, md: 0 }}
            >
              <Text fontSize={"sm"} fontWeight={"bold"}>
                Voting Power
              </Text>
              <Text fontSize={"sm"} fontWeight={"bold"}>
                {userVotingPower} / {totalVotes}
              </Text>
            </VStack>
          </Flex>
          <Flex gap={{ base: 4, lg: 6 }} flexWrap="wrap" justify="center">
            <UserApeInfoBox
              tier={PartyApeTierEnum.Common}
              amount={userApes.common.ids.length}
            />
            <UserApeInfoBox
              tier={PartyApeTierEnum.Bronze}
              amount={userApes.bronze.ids.length}
            />
            <UserApeInfoBox
              tier={PartyApeTierEnum.Silver}
              amount={userApes.silver.ids.length}
            />
            <UserApeInfoBox
              tier={PartyApeTierEnum.Gold}
              amount={userApes.gold.ids.length}
            />
            <UserApeInfoBox
              tier={PartyApeTierEnum.Platinum}
              amount={userApes.platinum.ids.length}
            />
          </Flex>
        </Stack>
      )}
    </>
  );
};

export default VotingPower;
