import { Flex, Heading, Text, useColorModeValue } from "@chakra-ui/react";

const StepTitle = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <Flex flexDirection="column">
      <Heading fontSize="18px">{title}</Heading>
      <Text color={useColorModeValue("gray.600", "gray.400")} pt={1}>
        {description}
      </Text>
    </Flex>
  );
};
export default StepTitle;
