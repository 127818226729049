import React from "react";

import { Box, useColorModeValue, type BoxProps } from "@chakra-ui/react";

interface ICard {
  children: React.ReactNode;
}

const Card: React.FC<ICard | BoxProps> = ({ children, ...rest }) => {
  return (
    <Box
      width="100%"
      p={{ base: "4", sm: "6", md: "4" }}
      bg={useColorModeValue("white", "gray.700")}
      boxShadow={{ base: "none", sm: "md" }}
      borderRadius={{ base: "xl" }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Card;
