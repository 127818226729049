import { isAddressEqual } from "@partyfinance/core";

import { type Token } from "~/modules/party/types";
import allTokensList from "~/modules/tokens/tokensList.json";

export const getTokens = (chainId?: number): Token[] => {
  return allTokensList.filter(
    (x) => !x.hidden && (chainId ? x.chainId === chainId : true),
  );
};

export const getToken = (
  address: string,
  chainId?: number,
): Token | undefined => {
  return allTokensList.find(
    (x) =>
      isAddressEqual(x.address, address) &&
      (chainId ? x.chainId === chainId : true),
  );
};
