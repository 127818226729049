import { useDisclosure } from "@chakra-ui/react";
import { useAccount } from "wagmi";

import { Button } from "~/components/Buttons";
import { UnstakeModal } from "~/components/Modals";

import { useStake } from "~/hooks/staking";
import { type IPool } from "~/modules/staking/types";

interface IUnstakeButton {
  pool: IPool;
  userBalance: number;
}

const StakeButton = ({ pool, userBalance, ...btnProps }: IUnstakeButton) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { address: account } = useAccount();
  const { unstake } = useStake();

  const handleUnstake = async (amount: number) => {
    try {
      onClose();
      await unstake(amount);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Button
        w={{ base: 60, md: 40 }}
        onClick={onOpen}
        disabled={!account}
        {...btnProps}
      >
        Unstake
      </Button>

      <UnstakeModal
        isOpen={isOpen}
        onClose={onClose}
        pool={pool}
        userBalance={userBalance}
        confirmAction={handleUnstake}
      />
    </>
  );
};

export default StakeButton;
