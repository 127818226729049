import { useEffect, useMemo, useState } from "react";

import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Step, Steps } from "chakra-ui-steps";

import { Snake } from "~/components/Snake";

import { type ConfirmationStep } from "~/modules/party/types";

interface IConfirmationWithSteps {
  steps: ConfirmationStep[];
  activeStep: number;
  isOpen: boolean;
  onClose: (resetSteps?: boolean) => void;
  errorMsg: string;
}

// Just a visual aid for the user when the method involves multiple TXs to be confirmed
export const ConfirmationWithSteps = ({
  steps,
  activeStep,
  isOpen,
  onClose,
  errorMsg,
}: IConfirmationWithSteps) => {
  const [showGame, setShowGame] = useState<boolean>(false);
  useEffect(() => {
    if (!isOpen) {
      setShowGame(false);
    }
  }, [isOpen]);
  const activeStepItem = useMemo(
    () => (activeStep < steps.length ? steps[activeStep] : null),
    [steps, activeStep],
  );
  const gameShowable = !errorMsg && activeStepItem && activeStepItem.showGame;
  return (
    <Modal
      onClose={() => null}
      isOpen={isOpen}
      isCentered
      size={steps.length > 5 ? "5xl" : "3xl"}
    >
      <ModalOverlay />
      <ModalContent
        my={activeStepItem && activeStepItem.showGame ? 0 : "inherit"}
        borderRadius="xl"
      >
        <ModalBody>
          <Flex flexDir="column" width="100%" pt="24px" pb="0">
            <Steps
              state={
                !!errorMsg
                  ? "error"
                  : activeStepItem && activeStepItem.loading
                  ? "loading"
                  : undefined
              }
              responsive
              activeStep={activeStep}
              size="sm"
            >
              {steps.map(({ label, content, icon }) => (
                <Step label={label} key={label} icon={icon}>
                  <Box textAlign="center" pb={0} px={6}>
                    <Heading as="h2" size="lg" mt={6} mb={2}>
                      {label}
                    </Heading>
                    {errorMsg ? (
                      <Text
                        color={"gray.400"}
                        mb={6}
                        maxH={"50vh"}
                        overflowY={"auto"}
                      >
                        {errorMsg}
                      </Text>
                    ) : (
                      <Text color={"gray.400"} mb={6}>
                        {content}
                      </Text>
                    )}
                  </Box>
                </Step>
              ))}
            </Steps>
            {activeStepItem &&
            activeStepItem.requiresUserAction &&
            !!activeStepItem.CustomView ? (
              <Box mt={-3}>{activeStepItem.CustomView}</Box>
            ) : gameShowable && showGame ? (
              <Snake />
            ) : gameShowable && !showGame ? (
              <Box width={"fit-content"} mx={"auto"} mb={4}>
                <Button
                  onClick={() => setShowGame(true)}
                  width={"fit-content"}
                  variant="ghost"
                  size="sm"
                  _focus={{ outline: "none" }}
                >
                  Play a game while you wait?
                </Button>
              </Box>
            ) : null}
          </Flex>
          <Flex py={4} justifyContent="center" gap={3}>
            {gameShowable && showGame ? (
              <Button
                _focus={{ boxShadow: "none", outline: "none" }}
                onClick={() => setShowGame(false)}
              >
                Hide game
              </Button>
            ) : (
              <Button
                _focus={{ boxShadow: "none", outline: "none" }}
                onClick={() => onClose(!!errorMsg)}
              >
                Close
              </Button>
            )}
            {!!activeStepItem &&
            !!activeStepItem.requiresUserAction &&
            !!activeStepItem.nextCallback ? (
              <Button
                onClick={activeStepItem.nextCallback}
                colorScheme="brand"
                bgGradient="linear(to-b, brand.300, brand.500)"
                outline="none"
                color="white"
                _hover={{ bg: "brand.300", boxShadow: "md" }}
                _active={{
                  bg: "brand.300",
                  transform: "scale(0.98)",
                  borderColor: "brand.300",
                }}
                _focus={{ boxShadow: "none", border: "none", outline: "none" }}
              >
                Next
              </Button>
            ) : null}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationWithSteps;
