import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(isoWeek);

export const getDateFromNow = (timestamp: number): string => {
  if (!timestamp) return "";
  return dayjs(timestamp * 1000).fromNow();
};

export enum FormatDate {
  Short = "DD/MM/YYYY",
  Long = "DD MMMM YYYY",
  LT = "LT",
  ll = "ll",
  lll = "lll",
  hmmA = "h:mm A",
}

export const formatDate = (
  blockTimestamp: number,
  format: string = FormatDate.Short,
): string => {
  if (!blockTimestamp) return "";
  const d = new Date(blockTimestamp * 1000);
  return dayjs(d).format(format);
};

export function getLastWeekInfo() {
  // Get the current date
  const today = new Date();

  // Subtract 7 days to get the date for last week
  const lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);

  // Get the day of the week for last week's date (0 = Sunday, 1 = Monday, etc.)
  const lastWeekDay = lastWeek.getDay();

  // Calculate the date ranges for last week (Monday to Sunday)
  const lastMonday = new Date(
    lastWeek.getTime() - (lastWeekDay - 1) * 24 * 60 * 60 * 1000,
  );
  const lastSunday = new Date(lastMonday.getTime() + 6 * 24 * 60 * 60 * 1000);

  // Format the dates as DD/MMMM/YYYY
  const lastMondayFormatted = dayjs(lastMonday).format(FormatDate.Long);
  const lastSundayFormatted = dayjs(lastSunday).format(FormatDate.Long);

  // Get the week number for last week
  const lastWeekNumber = dayjs(lastSunday).isoWeek();

  // Output the results
  return {
    lastWeekNumber,
    lastMondayFormatted,
    lastSundayFormatted,
  };
}
