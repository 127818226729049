import { Divider, Stack, type BoxProps } from "@chakra-ui/react";

import {
  MemberFinanceStats,
  PartyFinanceStats,
} from "~/modules/party/components/Financials";
import { type PartyFinancials } from "~/modules/party/types";

import { Card } from ".";

interface IPartyFinanceInfoCardProps extends BoxProps {
  showMemberHoldings?: boolean;
  loadingFinancials: boolean;
  isValidating?: boolean;
  partyFinancials: PartyFinancials | null;
}

const PartyFinanceInfoCard = ({
  showMemberHoldings = false,
  loadingFinancials,
  isValidating,
  partyFinancials,
  ...props
}: IPartyFinanceInfoCardProps) => {
  return (
    <Card mt={4} {...props}>
      <Stack
        direction={["column", "row"]}
        align={"center"}
        flexWrap="wrap"
        height={{ base: "auto", md: 12 }}
        spacing={"auto"}
        justifyContent="space-between"
        maxW={800}
        mx="auto"
        divider={<Divider orientation="vertical" display={["none", "block"]} />}
      >
        {showMemberHoldings ? (
          <MemberFinanceStats
            loadingFinancials={loadingFinancials}
            isValidating={isValidating}
            partyMemberFinancials={partyFinancials?.memberFinancials || null}
          />
        ) : (
          <PartyFinanceStats
            loadingFinancials={loadingFinancials}
            isValidating={isValidating}
            partyFinancials={partyFinancials}
          />
        )}
      </Stack>
    </Card>
  );
};

export default PartyFinanceInfoCard;
