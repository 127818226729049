import {
  Avatar,
  AvatarGroup,
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Skeleton,
  Tag,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
  type BoxProps,
} from "@chakra-ui/react";
import { BiBlock } from "react-icons/bi";
import { GiPartyPopper } from "react-icons/gi";
import { Sparklines, SparklinesLine } from "react-sparklines";

import { PeriodEnum } from "@partyfinance/core";

import { api } from "~/utils/api";
import { formatNumber } from "~/utils/number";
import { shortenText } from "~/utils/text";
import { getChainIcon, getChainName } from "~/utils/web3/chains";

import TooltipAvatar from "~/components/Chakra/TooltipAvatar";

import useSparklineStyles from "~/hooks/useSparklineStyles";
import { type PartyWithFinancials } from "~/modules/party/types";
import { getToken } from "~/modules/tokens";

import Card from "./Card";

interface IIndexPartyCard extends BoxProps {
  party: PartyWithFinancials;
  openParty: () => void;
}

const MAX_HOLDINGS = 4;
const IndexPartyCard = ({ party, openParty }: IIndexPartyCard) => {
  const utils = api.useContext();
  const { data, isLoading } = api.party.chart.useQuery(
    {
      partyAddress: party.address,
      networkId: party.chainId,
      period: PeriodEnum.LastDay,
      denominationAsset: party.denominationAsset.id,
      onSingleParty: false,
    },
    {
      cacheTime: 15 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
      enabled: !!party.address,
      refetchOnWindowFocus: false,
      retry: false,
      trpc: {
        context: {
          skipBatch: true,
        },
      },
      onSuccess(data) {
        utils.party.chart.setData(
          {
            partyAddress: party.address,
            networkId: party.chainId,
            period: PeriodEnum.LastDay,
            denominationAsset: party.denominationAsset.id,
            onSingleParty: true,
          },
          data,
        );
      },
    },
  );

  // Responsive values
  const balanceColor = useColorModeValue("gray.500", "white");

  // Sparklines
  const { sparklineBg, sparklineColor, percentChange } =
    useSparklineStyles(data);

  return (
    <Card
      p={0}
      overflow="hidden"
      borderWidth={1}
      borderColor={useColorModeValue("gray.200", "transparent")}
      flexBasis={230}
      flexGrow={1}
      onClick={openParty}
      cursor="pointer"
      h="full"
      minW={240}
      maxW={{ base: "auto", md: 330 }}
    >
      <Grid>
        <GridItem py={4} px={4}>
          <Flex gap={2} wrap="nowrap">
            <Avatar
              icon={
                <GiPartyPopper
                  fontSize={useBreakpointValue({
                    base: "4rem",
                    md: "6rem",
                    lg: "8rem",
                  })}
                />
              }
              src={party.img}
              borderRadius="xl"
              size={useBreakpointValue({
                base: "lg",
                md: "md",
                xl: "sm",
                "2xl": "lg",
              })}
              alignSelf="center"
            />
            <Flex
              justifyContent="space-around"
              align="flex-start"
              flexDir="column"
              wrap="nowrap"
              w="full"
            >
              <Heading
                textAlign="start"
                // wordBreak={'break-all'}
                fontSize={["md", "lg"]}
                w="full"
              >
                <Flex wrap="nowrap" justify="space-between">
                  <Tooltip
                    hasArrow
                    placement="bottom-start"
                    label={party.name}
                    shouldWrapChildren={false}
                  >
                    <Text>{shortenText(party.name, 12)}</Text>
                  </Tooltip>
                  <AvatarGroup size="2xs" spacing={1}>
                    <TooltipAvatar
                      tooltipLabel={getChainName(party.chainId)}
                      src={getChainIcon(party.chainId)}
                      border="none"
                    />
                    <TooltipAvatar
                      tooltipLabel="This is an official party created by PartyFinance"
                      src="/logo.png"
                      border="none"
                    />
                  </AvatarGroup>
                </Flex>
              </Heading>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem pb={4} px={4}>
          <Flex color={"gray.400"} gap={2} alignItems="center">
            {party.financials && (
              <Flex justify="space-between" w="full" color={"gray.500"}>
                <Box>
                  <Text fontSize={"xs"} color={"gray.500"}>
                    Balance
                  </Text>
                  <Flex alignItems="flex-end" gap={1.5}>
                    <Text fontWeight="bold" color={balanceColor}>
                      $
                      {formatNumber(
                        Math.floor((party.financials.valueUSD || 0) * 100) /
                          100,
                      )}
                    </Text>
                    {percentChange !== null && (
                      <Tooltip
                        hasArrow
                        placement="right"
                        label="Last 24h change"
                        shouldWrapChildren={false}
                      >
                        <Text
                          fontSize={11}
                          alignSelf="center"
                          // mt={1}
                          color={sparklineColor}
                        >
                          {percentChange > 0 ? "+" : ""}
                          {percentChange}%
                        </Text>
                      </Tooltip>
                    )}
                  </Flex>
                </Box>
                <Box>
                  {/* @dev: Change this to 'Last 24h' if we provide this info on the financials */}
                  <Text fontSize={"xs"} textAlign="center" color={"gray.500"}>
                    Performance
                  </Text>
                  <Box ml="auto" w="fit-content">
                    <Tag
                      bg={
                        party.financials.profitLoss >= 0
                          ? "green.400"
                          : "red.400"
                      }
                      fontSize="xs"
                      fontWeight="bold"
                      size="sm"
                    >
                      {party.financials.roi === null
                        ? "-%"
                        : `${party.financials.roi > 0 ? "+" : ""} ${
                            Math.round(party.financials.roi * 100) / 100
                          }%`}
                    </Tag>
                  </Box>
                </Box>
              </Flex>
            )}
          </Flex>
        </GridItem>
        <GridItem>
          {isLoading ? (
            <Skeleton w="full" h={75} />
          ) : (
            data && (
              <Sparklines
                data={data.map((d) => d.value)}
                height={75}
                margin={0}
                max={Math.max(...data.map((x) => x.value)) * 1.0008}
                min={Math.min(...data.map((x) => x.value)) * 0.9992}
              >
                <SparklinesLine color={sparklineColor} />
              </Sparklines>
            )
          )}
        </GridItem>
        <GridItem bg={sparklineBg} py={4}>
          <Flex justify="space-around" wrap="wrap">
            {party.financials && party.financials.holdings ? (
              <AvatarGroup max={4} size="sm" spacing={-2}>
                {party.financials?.holdings
                  .filter((token) => token.valueUSD > 0)
                  .sort((a, b) => b.valueUSD - a.valueUSD)
                  .slice(
                    0,
                    party.financials.holdings.length >= MAX_HOLDINGS
                      ? MAX_HOLDINGS - 1
                      : MAX_HOLDINGS,
                  )
                  .map((token, idx) => (
                    <TooltipAvatar
                      tooltipLabel={token.name}
                      key={`tooltip-avatar-${token.address}-${idx}`}
                      icon={<BiBlock fontSize="22px" color="white" />}
                      src={
                        getToken(token.address)?.logoURI || token.logoUrl || ""
                      }
                      border="none"
                    />
                  ))}
              </AvatarGroup>
            ) : (
              <Text fontSize="sm" my="auto" color="gray.500" alignSelf="center">
                No holdings
              </Text>
            )}
          </Flex>
        </GridItem>
      </Grid>
    </Card>
  );
};

export default IndexPartyCard;
