import { Box, useColorModeValue, useRadio } from "@chakra-ui/react";

const RadioCard = ({ ...props }) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();
  const bg = useColorModeValue("gray.200", "gray.600");

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        bg={bg}
        // borderWidth='1px'
        borderRadius="lg"
        boxShadow="lg"
        _checked={{
          bgGradient: "linear(to-b, brand.300, brand.500)",
          color: "white",
        }}
        px={5}
        py={1}
        fontWeight="bold"
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default RadioCard;
