import { formatUnits } from "@ethersproject/units";

import {
  getPeriodOffset,
  getSubgraphConfig,
  isAddress,
  isAddressEqual,
  type PeriodEnum,
} from "@partyfinance/core";

import {
  type AssetsQueryVariables,
  type InvestmentCostsQuery,
  type InvestmentCostsQueryVariables,
  type PartiesInfoQueryVariables,
  type PartiesQueryVariables,
  type PartiesWithFiltersQueryVariables,
  type PartyActivityQueryVariables,
  type PartyHoldingsQueryVariables,
  type PartyInfoQueryVariables,
  type PartyJoinRequestsQueryVariables,
  type PartyManagersQueryVariables,
  type PartyMemberHistoricalStateQueryVariables,
  type PartyMembersQueryVariables,
  type PartyPortfolioQueryVariables,
  type PartyTokenGateQueryVariables,
  type PartyTransactionsQueryVariables,
  type PortfolioSharesHistoryQueryVariables,
  type PortfolioSharesSnapshotQueryVariables,
  type PortfoliosQuery,
  type PortfoliosQueryVariables,
  type PublicPartiesQueryVariables,
  type UncalculatedWithdrawalsQueryVariables,
  type UserPartiesQueryVariables,
  type UserPartyDepositsQuery,
  type UserPartyDepositsQueryVariables,
  type UserPartyRoleQueryVariables,
  type UserPartySharesQueryVariables,
  type UserPartyWithdrawalsQuery,
  type UserPartyWithdrawalsQueryVariables,
} from "./.graphclient";
import { MAX_PAGE_SIZE, sdk } from "./constants";
import {
  type Block,
  type Holding,
  type IPartyInfo,
  type JoinRequest,
  type PartyActivity,
  type PartyEventEnum,
  type PartyInvestmentCost,
  type PartyMember,
  type PartyMemberState,
  type PartyPortfolio,
  type PartyRoles,
  type PartySortDirection,
  type PartySortEnum,
  type PartyUncalculatedWithdrawal,
  type PorfolioSharesHistory,
  type PorfolioSharesSnapshot,
  type PublicParty,
  type TokenGateInfoRes,
  type TokenGateTypeEnum,
  type Transaction,
  type UserDeposit,
  type UserParty,
  type UserWithdrawal,
} from "./types";
import {
  parseInvestmentCost,
  parsePartyPortfolio,
  parseUncalculatedWithdrawal,
} from "./utils";

/////////////////////////////////////////
// Finding the latest processed block
/////////////////////////////////////////
export async function getLatestTheGraphBlock(
  chainId: number,
  apiKey: string | null,
): Promise<Block | null> {
  const { _meta } = await sdk.Block({}, getSubgraphConfig(chainId, apiKey));
  const timestamp =
    _meta && _meta.block.timestamp ? _meta.block.timestamp : null;
  const number = _meta && _meta.block.number ? _meta.block.number : null;
  const hash = _meta && _meta.block.hash ? (_meta.block.hash as string) : null;
  return timestamp && number && hash ? { timestamp, number, hash } : null;
}
/////////////////////////////////////////
// Finding public parties
/////////////////////////////////////////
export async function getPublicParties(
  chainId: number,
  apiKey: string | null,
  partyNameSearch: string,
  sortType: PartySortEnum | null,
  sortDirection: PartySortDirection | null,
  pageIndex: number | null,
  pageSize: number | null,
): Promise<PublicParty[]> {
  const variables: PublicPartiesQueryVariables = {
    partyNameSearch,
    orderDirection: sortDirection,
    orderBy: sortType,
    first: pageSize,
    skip: pageIndex,
  };

  const { parties } = await sdk.PublicParties(
    variables,
    getSubgraphConfig(chainId, apiKey),
  );
  return parties.map((p) => ({
    chainId,
    inception: Number(p.inception),
    address: p.id,
    name: p.name,
    img: p.img ?? "",
    model: p.model ?? "",
    purpose: p.purpose ?? "",
    minDeposit: Number(p.minDeposit),
    maxDeposit: Number(p.maxDeposit),
    membersCount: Number(p.membersCount),
    denominationAsset: {
      decimals: Number(p.denominationAsset.decimals),
      id: p.denominationAsset.id,
      symbol: p.denominationAsset.symbol,
    },
    owner: p.creator.id,
  }));
}

/////////////////////////////////////////
// Finding many parties info
/////////////////////////////////////////
export async function getPartiesInfo(
  chainId: number,
  apiKey: string | null,
  partyAddresses: string[],
  isClosed?: boolean | null,
): Promise<PublicParty[]> {
  const variables: PartiesInfoQueryVariables = {
    partyAddresses,
    isClosed,
  };

  const { parties } = await sdk.PartiesInfo(
    variables,
    getSubgraphConfig(chainId, apiKey),
  );
  return parties.map((p) => ({
    chainId,
    inception: Number(p.inception),
    address: p.id,
    name: p.name,
    img: p.img ?? "",
    model: p.model ?? "",
    purpose: p.purpose ?? "",
    minDeposit: Number(p.minDeposit),
    maxDeposit: Number(p.maxDeposit),
    membersCount: Number(p.membersCount),
    denominationAsset: {
      decimals: Number(p.denominationAsset.decimals),
      id: p.denominationAsset.id,
      symbol: p.denominationAsset.symbol,
    },
    owner: p.creator.id,
  }));
}

/////////////////////////////////////////
// Finding parties with filters
/////////////////////////////////////////
export async function getPartiesWithFilters(
  chainId: number,
  apiKey: string | null,
  partyAddresses: string[],
  inception = 0,
  isPublic = true,
  isClosed = false,
): Promise<PublicParty[]> {
  const variables: PartiesWithFiltersQueryVariables = {
    partyAddresses,
    inception,
    isPublic,
    isClosed: isClosed || undefined,
  };

  const { parties } = await sdk.PartiesWithFilters(
    variables,
    getSubgraphConfig(chainId, apiKey),
  );
  return parties.map((p) => ({
    chainId,
    inception: Number(p.inception),
    address: p.id,
    name: p.name,
    img: p.img ?? "",
    model: p.model ?? "",
    purpose: p.purpose ?? "",
    minDeposit: Number(p.minDeposit),
    maxDeposit: Number(p.maxDeposit),
    membersCount: Number(p.membersCount),
    denominationAsset: {
      decimals: Number(p.denominationAsset.decimals),
      id: p.denominationAsset.id,
      symbol: p.denominationAsset.symbol,
    },
    owner: p.creator.id,
  }));
}

/////////////////////////////////////////
// Finding a party information
/////////////////////////////////////////
export async function getPartyInfo(
  chainId: number,
  apiKey: string | null,
  partyAddress: string,
): Promise<IPartyInfo | null> {
  const variables: PartyInfoQueryVariables = {
    partyAddress,
  };

  const { party } = await sdk.PartyInfo(
    variables,
    getSubgraphConfig(chainId, apiKey),
  );

  if (!party) return null;
  return {
    chainId,
    inception: Number(party.inception),
    address: party.id,
    name: party.name,
    bio: party.bio ?? "",
    img: party.img ?? "",
    model: party.model ?? "",
    purpose: party.purpose ?? "",
    membersCount: Number(party.membersCount),
    isPublic: !!party.isPublic,
    isClosed: !!party.isClosed,
    minDeposit: Number(
      formatUnits(
        String(party.minDeposit),
        party.denominationAsset.decimals || 6,
      ),
    ),
    maxDeposit: Number(
      formatUnits(
        String(party.maxDeposit),
        party.denominationAsset.decimals || 6,
      ),
    ),
    denominationAsset: {
      address: party.denominationAsset.id,
      decimals: Number(party.denominationAsset.decimals),
      symbol: party.denominationAsset.symbol,
    },
    owner: party.creator.id,
  };
}

/**
 * Fetches the closest Party Portfolio state for a given past timestamp
 * @param chainId Network chain Id
 * @param partyAddress Party contract address
 * @param timestamp Timestamp until when
 * @returns Past party portfolio state closest to the given timestamp
 */
export async function getClosestPorfolioSharesSnapshot(
  chainId: number,
  apiKey: string | null,
  partyAddress: string,
  timestamp: number,
): Promise<PorfolioSharesSnapshot> {
  const variables: PortfolioSharesSnapshotQueryVariables = {
    partyAddress,
    timestamp,
  };

  const { party } = await sdk.PortfolioSharesSnapshot(
    variables,
    getSubgraphConfig(chainId, apiKey),
  );

  const portfolio = party ? party.portfolioHistory[0] ?? null : null;
  const share = party ? party.sharesHistory[0] ?? null : null;
  return {
    portfolio: portfolio
      ? {
          timestamp: Number(portfolio.timestamp),
          holdings: portfolio.holdings.map((holding) => ({
            timestamp: Number(holding.timestamp),
            asset: {
              address: holding.asset.id,
              symbol: holding.asset.symbol,
              decimals: Number(holding.asset.decimals),
            },
            amount: holding.amount as string,
          })),
        }
      : null,
    share: share
      ? {
          timestamp: Number(share.timestamp),
          totalSupply: Number(share.totalSupply),
        }
      : null,
  };
}

/**
 * Fetches the closest Party Portfolio state from a given timestamp till now
 */
export async function getCurrentPorfolioSharesHistory(
  chainId: number,
  apiKey: string | null,
  partyAddress: string,
  timestamp: number,
  skip: number | null,
  first: number | null,
): Promise<PorfolioSharesHistory> {
  const variables: PortfolioSharesHistoryQueryVariables = {
    partyAddress,
    timestamp,
    skip,
    first,
  };

  const { party } = await sdk.PortfolioSharesHistory(
    variables,
    getSubgraphConfig(chainId, apiKey),
  );
  return {
    portfolioHistory: !party
      ? []
      : party.portfolioHistory.map((portfolio) => ({
          timestamp: Number(portfolio.timestamp),
          holdings: portfolio.holdings.map((holding) => ({
            timestamp: Number(holding.timestamp),
            asset: {
              address: holding.asset.id,
              symbol: holding.asset.symbol,
              decimals: Number(holding.asset.decimals),
            },
            amount: holding.amount as string,
          })),
        })),
    sharesHistory: !party
      ? []
      : party.sharesHistory.map((x) => ({
          timestamp: Number(x.timestamp),
          totalSupply: Number(x.totalSupply),
        })),
    inception: party ? Number(party.inception) : 0,
  };
}

export async function getUserPartyDeposits(
  chainId: number,
  apiKey: string | null,
  partyAddress: string,
  userAddress: string,
  latestTimestamp: number,
): Promise<UserDeposit[]> {
  const deposits: UserPartyDepositsQuery["deposits"] = [];
  let pageIndex = 0;
  let fetchedAll = false;
  while (!fetchedAll) {
    console.log("Fetching in pageIndex #%s", pageIndex);
    const variables: UserPartyDepositsQueryVariables = {
      userAddress,
      partyAddress,
      latestTimestamp,
      first: MAX_PAGE_SIZE,
      skip: MAX_PAGE_SIZE * pageIndex,
    };
    const result = await sdk.UserPartyDeposits(
      variables,
      getSubgraphConfig(chainId, apiKey),
    );
    if (result.deposits.length > 0) {
      deposits.push(...result.deposits);
      pageIndex++;
    } else {
      console.log("Pagination ended in pageIndex #%s", pageIndex);
      fetchedAll = true;
    }
  }
  return deposits;
}
export async function getUserPartyWithdrawals(
  chainId: number,
  apiKey: string | null,
  partyAddress: string,
  userAddress: string,
  latestTimestamp: number,
): Promise<UserWithdrawal[]> {
  const withdrawals: UserPartyWithdrawalsQuery["withdrawals"] = [];
  let pageIndex = 0;
  let fetchedAll = false;
  while (!fetchedAll) {
    console.log("Fetching in pageIndex #%s", pageIndex);
    const variables: UserPartyWithdrawalsQueryVariables = {
      userAddress,
      partyAddress,
      latestTimestamp,
      first: MAX_PAGE_SIZE,
      skip: MAX_PAGE_SIZE * pageIndex,
    };
    const result = await sdk.UserPartyWithdrawals(
      variables,
      getSubgraphConfig(chainId, apiKey),
    );
    if (result.withdrawals.length > 0) {
      withdrawals.push(...result.withdrawals);
      pageIndex++;
    } else {
      console.log("Pagination ended in pageIndex #%s", pageIndex);
      fetchedAll = true;
    }
  }
  return withdrawals;
}

/////////////////////////////////////////
// Finding the open parties of a user
/////////////////////////////////////////
export async function fetchUserOpenedParties(
  chainIds: number[],
  apiKey: string | null,
  userAddress: string,
): Promise<UserParty[]> {
  const variables: UserPartiesQueryVariables = {
    userAddress,
  };

  const chainsResults = await Promise.all(
    chainIds.map(async (chainId) => {
      const { userParties } = await sdk.UserParties(
        variables,
        getSubgraphConfig(chainId, apiKey),
      );
      return { chainId, userParties };
    }),
  );
  return chainsResults.reduce<UserParty[]>((acc, chainResult) => {
    acc.push(
      ...chainResult.userParties
        .filter((x) => !x.party.isClosed)
        .map((userParty) => ({
          chainId: chainResult.chainId,
          address: userParty.party.id,
          name: userParty.party.name,
          img: userParty.party.img ?? "",
          isClosed: !!userParty.party.isClosed,
        })),
    );
    return acc;
  }, []);
}

/////////////////////////////////////////
// Finding a user's joined parties
/////////////////////////////////////////
export async function fetchUserParties(
  chainIds: number[],
  apiKey: string | null,
  userAddress: string,
  type: "all" | "open" | "closed",
): Promise<UserParty[]> {
  const variables: UserPartiesQueryVariables = {
    userAddress,
  };
  const chainsResults = await Promise.all(
    chainIds.map(async (chainId) => {
      const { userParties } = await sdk.UserParties(
        variables,
        getSubgraphConfig(chainId, apiKey),
      );
      return { chainId, userParties };
    }),
  );
  return chainsResults.reduce<UserParty[]>((acc, chainResult) => {
    acc.push(
      ...chainResult.userParties
        .filter((x) =>
          type === "all"
            ? true
            : type === "open"
            ? !x.party.isClosed
            : x.party.isClosed,
        )
        .map((userParty) => ({
          chainId: chainResult.chainId,
          address: userParty.party.id,
          name: userParty.party.name,
          img: userParty.party.img ?? "",
          isClosed: !!userParty.party.isClosed,
        })),
    );
    return acc;
  }, []);
}

/////////////////////////////////////////
// Fetching a specific party details
// (used on YourParties.tsx)
/////////////////////////////////////////
export async function fetchPartyInfo(
  chainId: number,
  apiKey: string | null,
  partyAddress: string,
): Promise<IPartyInfo | null> {
  if (!isAddress(partyAddress)) return null;
  const variables: PartyInfoQueryVariables = {
    partyAddress,
  };
  const { party } = await sdk.PartyInfo(
    variables,
    getSubgraphConfig(chainId, apiKey),
  );
  if (!party) return null;
  const partyInfo = {
    inception: Number(party.inception),
    address: party.id,
    name: party.name,
    bio: party.bio ?? "",
    img: party.img ?? "",
    model: party.model ?? "",
    purpose: party.purpose ?? "",
    membersCount: party.membersCount,
    isPublic: !!party.isPublic,
    isClosed: !!party.isClosed,
    minDeposit: Number(
      formatUnits(String(party.minDeposit), party.denominationAsset.decimals),
    ),
    maxDeposit: Number(
      formatUnits(String(party.maxDeposit), party.denominationAsset.decimals),
    ),
    denominationAsset: {
      address: party.denominationAsset.id,
      decimals: party.denominationAsset.decimals,
      symbol: party.denominationAsset.symbol,
    },
    chainId,
    owner: party.creator.id,
  };
  return partyInfo;
}

/////////////////////////////////////////
// Fetching a specific party's transactions
// (used on Transactions.tsx)
/////////////////////////////////////////
export async function fetchPartyTransactions(
  chainId: number,
  apiKey: string | null,
  partyAddress: string,
  first: number,
  skip: number,
): Promise<Transaction[]> {
  if (!isAddress(partyAddress)) return [];
  const variables: PartyTransactionsQueryVariables = {
    partyAddress,
    first,
    skip,
  };
  const { tokenSwaps } = await sdk.PartyTransactions(
    variables,
    getSubgraphConfig(chainId, apiKey),
  );

  const transactions = tokenSwaps.map((tx) => ({
    action: {
      name: `Swap ${tx.sellToken.symbol} for ${tx.buyToken.symbol}`,
      txHash: tx.transaction as string,
    },
    totalValue: 0,
    sellToken: tx.sellToken,
    buyToken: tx.buyToken,
    soldAmount: String(tx.soldAmount),
    boughtAmount: String(tx.boughtAmount),
    timestamp: Number(tx.timestamp),
  }));

  return transactions;
}

/////////////////////////////////////////
// Fetching a specific party's activity
// (used on Activity.tsx)
/////////////////////////////////////////
export async function fetchPartyActivity(
  chainId: number,
  apiKey: string | null,
  partyAddress: string,
  first: number,
  skip: number,
): Promise<PartyActivity[]> {
  if (!isAddress(partyAddress)) return [];
  const variables: PartyActivityQueryVariables = {
    partyAddressId: partyAddress,
    partyAddress,
    first,
    skip,
  };
  const { partyActivities, party } = await sdk.PartyActivity(
    variables,
    getSubgraphConfig(chainId, apiKey),
  );
  if (!party) return [];

  const activity = partyActivities.map((activity) => ({
    id: activity.id,
    timestamp: Number(activity.timestamp),
    txHash: activity.txHash,
    event: activity.event as PartyEventEnum,
    incomingAssets:
      activity.incomingAssets?.map((asset) => ({
        asset: asset.asset.id,
        symbol: asset.asset.symbol,
        decimals: Number(asset.asset.decimals),
        amount: asset.amount as string,
      })) ?? [],
    outcomingAssets:
      activity.outcomingAssets?.map((asset) => ({
        asset: asset.asset.id,
        symbol: asset.asset.symbol,
        decimals: Number(asset.asset.decimals),
        amount: asset.amount as string,
      })) ?? [],
    incomingShares: activity.incomingShares as string,
    outcomingShares: activity.outcomingShares as string,
    user: activity.user.id,
    isOwner: isAddressEqual(activity.user.id, party.creator.id),
  }));

  return activity;
}

/////////////////////////////////////////
// Finding a party's join requests
/////////////////////////////////////////
export async function fetchPartyJoinRequests(
  chainId: number,
  apiKey: string | null,
  partyAddress: string,
): Promise<JoinRequest[]> {
  if (!isAddress(partyAddress)) return [];
  const variables: PartyJoinRequestsQueryVariables = {
    partyAddress,
  };
  const { joinRequests } = await sdk.PartyJoinRequests(
    variables,
    getSubgraphConfig(chainId, apiKey),
  );

  return joinRequests.map((joinRequest) => ({
    user: joinRequest.user.id,
    requestedSince: Number(joinRequest.requestedSince),
  }));
}

/////////////////////////////////////////
// Finding a party's current holdings
/////////////////////////////////////////
export async function fetchPartyHoldings(
  chainId: number,
  apiKey: string | null,
  partyAddress: string,
) {
  if (!isAddress(partyAddress)) {
    return {
      holdings: [],
      updatedAt: Date.now(),
    };
  }
  const variables: PartyHoldingsQueryVariables = {
    partyAddress,
  };

  const { party } = await sdk.PartyHoldings(
    variables,
    getSubgraphConfig(chainId, apiKey),
  );

  if (!party || !party.portfolio) {
    return {
      holdings: [],
      updatedAt: Date.now(),
    };
  }

  return {
    holdings: party.portfolio.holdings.map((holding) => ({
      address: holding.asset.address,
      name: holding.asset.name,
      symbol: holding.asset.symbol,
      decimals: holding.asset.decimals,
      balance: holding.amount as string,
    })) as Holding[],
    updatedAt: Number(party.portfolio.timestamp) * 1000,
  };
}

/////////////////////////////////////////
// Finding a party member historical state
/////////////////////////////////////////
export async function fetchPartyMemberHistoricalState(
  chainId: number,
  apiKey: string | null,
  partyAddress: string,
  memberAddress: string,
  period: PeriodEnum,
): Promise<PartyMemberState[]> {
  if (!isAddress(partyAddress) || !isAddress(memberAddress)) {
    return [];
  }

  const offset =
    getPeriodOffset(period as Exclude<PeriodEnum, PeriodEnum.AllTime> | null) ||
    0;

  const variables: PartyMemberHistoricalStateQueryVariables = {
    partyAddress,
    memberAddress,
    startTimestamp: Math.floor((Date.now() - offset) / 1000),
  };
  const { historical, snapshot } = await sdk.PartyMemberHistoricalState(
    variables,
    getSubgraphConfig(chainId, apiKey),
  );

  return snapshot
    .concat(historical)
    .map((state) => ({
      timestamp: Number(state.timestamp) * 1000,
      shares: Number(state.shares),
    }))
    .sort((a, b) => b.timestamp - a.timestamp);
}

/////////////////////////////////////////
// Finding party managers
/////////////////////////////////////////
export async function fetchPartyManagers(
  chainId: number,
  apiKey: string | null,
  partyAddress: string,
) {
  const variables: PartyManagersQueryVariables = {
    partyAddress,
  };
  const { userParties } = await sdk.PartyManagers(
    variables,
    getSubgraphConfig(chainId, apiKey),
  );

  return userParties.map((up) => ({
    address: up.user.id,
    since: Number(up.managerSince),
  }));
}

/////////////////////////////////////////
// Finding party token gates
/////////////////////////////////////////
export async function fetchPartyTokenGates(
  chainId: number,
  apiKey: string | null,
  partyAddress: string,
): Promise<TokenGateInfoRes[]> {
  const variables: PartyTokenGateQueryVariables = {
    id: partyAddress,
  };
  const { partyTokenGate } = await sdk.PartyTokenGate(
    variables,
    getSubgraphConfig(chainId, apiKey),
  );

  if (!partyTokenGate || !partyTokenGate.tokens) return [];

  return partyTokenGate.tokens.map((t) => ({
    address: t.token as string,
    name: t.name,
    symbol: t.symbol,
    type: t.type as TokenGateTypeEnum,
    amount: Number(formatUnits(t.value as string, t.decimals)),
    decimals: t.decimals,
  }));
}

/////////////////////////////////////////
// Finding a user's party role
/////////////////////////////////////////
export async function fetchUserPartyRoles(
  chainId: number,
  apiKey: string | null,
  user: string,
  partyAddress: string,
): Promise<PartyRoles> {
  const userPartyId = `${user.toLowerCase()}/${partyAddress.toLowerCase()}`;

  const variables: UserPartyRoleQueryVariables = {
    userPartyId,
  };

  const { userParty } = await sdk.UserPartyRole(
    variables,
    getSubgraphConfig(chainId, apiKey),
  );

  return {
    isCreator: !userParty ? false : userParty.isCreator,
    isManager: !userParty ? false : userParty.isManager,
    isMember: !userParty
      ? false
      : userParty.status === "Joined" && userParty.leftAt === null,
  };
}
/////////////////////////////////////////
// Finding all members in a party
/////////////////////////////////////////
export async function fetchPartyMembers(
  chainId: number,
  apiKey: string | null,
  partyAddress: string,
): Promise<PartyMember[]> {
  if (!isAddress(partyAddress) || chainId === 0) return [];

  const variables: PartyMembersQueryVariables = {
    partyAddress: partyAddress,
    partyId: partyAddress,
  };

  const { userParties, party } = await sdk.PartyMembers(
    variables,
    getSubgraphConfig(chainId, apiKey),
  );
  if (!party) return [];

  return userParties.map(({ user, joinedSince }) => {
    const userPartyTokens =
      user.investments.length > 0
        ? (user.investments[user.investments.length - 1]?.shares as string) ||
          "0"
        : "0";
    return {
      address: user.id,
      joinedSince: Number(joinedSince),
      holdings: Number(userPartyTokens),
      weight: Number(userPartyTokens) / party.shares.totalSupply,
    };
  });
}

/////////////////////////////////////////
// Finding all parties across chains
/////////////////////////////////////////
export async function gellAllChainParties(
  chainId: number,
  apiKey: string | null,
  infoUpdatedAt: number,
) {
  const latestParties = [];
  let pageIndex = 0;
  let fetchedAll = false;
  while (!fetchedAll) {
    console.log(
      "Fetching assets (chainId %s) in pageIndex #%s",
      chainId,
      pageIndex,
    );
    const variables: PartiesQueryVariables = {
      infoUpdatedAt,
      first: MAX_PAGE_SIZE,
      skip: MAX_PAGE_SIZE * pageIndex,
    };
    const { parties } = await sdk.Parties(
      variables,
      getSubgraphConfig(chainId, apiKey),
    );
    if (parties.length > 0) {
      latestParties.push(
        ...parties.map((party) => ({
          slug: party.slug,
          address: party.id,
          networkId: party.networkId,
          denominationAsset: party.denominationAsset.id,
          name: party.name,
          isPublic: party.isPublic || false,
          isClosed: party.isClosed || false,
          inception: Number(party.inception),
          infoUpdatedAt: Number(party.infoUpdatedAt),
        })),
      );
      pageIndex++;
    } else {
      console.log(
        "Pagination ended (chainId %s) in pageIndex #%s",
        chainId,
        pageIndex,
      );
      fetchedAll = true;
    }
  }
  return latestParties;
}
/////////////////////////////////////////
// Finding all investment cost across chains
/////////////////////////////////////////
export async function getAllChainInvestmentCosts(
  chainId: number,
  apiKey: string | null,
  ids: string[],
  latestTimestamp: number,
): Promise<PartyInvestmentCost[]> {
  const latestInvestmentCosts: InvestmentCostsQuery["investmentCosts"] = [];
  let pageIndex = 0;
  let fetchedAll = false;
  while (!fetchedAll) {
    console.log(
      "Fetching assets (chainId %s) in pageIndex #%s",
      chainId,
      pageIndex,
    );
    const variables: InvestmentCostsQueryVariables = {
      latestTimestamp,
      ids,
      first: MAX_PAGE_SIZE,
      skip: MAX_PAGE_SIZE * pageIndex,
    };
    const { investmentCosts } = await sdk.InvestmentCosts(
      variables,
      getSubgraphConfig(chainId, apiKey),
    );
    if (investmentCosts.length > 0) {
      latestInvestmentCosts.push(...investmentCosts);
      pageIndex++;
    } else {
      console.log(
        "Pagination ended (chainId %s) in pageIndex #%s",
        chainId,
        pageIndex,
      );
      fetchedAll = true;
    }
  }
  return latestInvestmentCosts.map((x) => parseInvestmentCost(x));
}
/////////////////////////////////////////
// Finding all assets across chains
/////////////////////////////////////////
export async function gellAllChainAssets(
  chainId: number,
  apiKey: string | null,
  firstSeen: number,
) {
  const latestAssets = [];
  let pageIndex = 0;
  let fetchedAll = false;
  while (!fetchedAll) {
    console.log(
      "Fetching assets (chainId %s) in pageIndex #%s",
      chainId,
      pageIndex,
    );
    const variables: AssetsQueryVariables = {
      firstSeen,
      first: MAX_PAGE_SIZE,
      skip: MAX_PAGE_SIZE * pageIndex,
    };
    const { assets } = await sdk.Assets(
      variables,
      getSubgraphConfig(chainId, apiKey),
    );
    if (assets.length > 0) {
      latestAssets.push(
        ...assets.map((asset) => ({
          slug: asset.slug,
          networkId: asset.networkId,
          address: asset.id,
          name: asset.name,
          symbol: asset.symbol,
          decimals: asset.decimals,
          firstSeen: Number(asset.firstSeen),
        })),
      );
      pageIndex++;
    } else {
      console.log(
        "Pagination ended (chainId %s) in pageIndex #%s",
        chainId,
        pageIndex,
      );
      fetchedAll = true;
    }
  }
  return latestAssets;
}
/////////////////////////////////////////
// Finding the uncalculated withdrawals
/////////////////////////////////////////
export async function fetchUncalculatedWithdrawals(
  chainId: number,
  apiKey: string | null,
  first: number,
  skip: number,
): Promise<PartyUncalculatedWithdrawal[]> {
  const variables: UncalculatedWithdrawalsQueryVariables = {
    first,
    skip,
  };
  const { sharesRedeemedEvents } = await sdk.UncalculatedWithdrawals(
    variables,

    getSubgraphConfig(chainId, apiKey),
  );
  return sharesRedeemedEvents.map((x) => parseUncalculatedWithdrawal(x));
}
/////////////////////////////////////////
// Finding a party portoflio
/////////////////////////////////////////
export async function getPartyPortfolio(
  chainId: number,
  apiKey: string | null,
  partyAddress: string,
): Promise<PartyPortfolio | null> {
  const variables: PartyPortfolioQueryVariables = {
    partyAddress,
  };
  const { portfolioStates } = await sdk.PartyPortfolio(
    variables,
    getSubgraphConfig(chainId, apiKey),
  );

  return portfolioStates[0] ? parsePartyPortfolio(portfolioStates[0]) : null;
}
/////////////////////////////////////////
// Finding all portfolios across chains
/////////////////////////////////////////
export async function getAllPortfolios(
  chainId: number,
  apiKey: string | null,
  ignoredParties: string[],
): Promise<PartyPortfolio[]> {
  const portfolios: PortfoliosQuery["portfolioStates"] = [];
  let pageIndex = 0;
  let fetchedAll = false;
  while (!fetchedAll) {
    console.log("Fetching portfolios in pageIndex #", pageIndex);
    const variables: PortfoliosQueryVariables = {
      ignoredParties: ignoredParties.length === 0 ? [""] : ignoredParties,
      first: MAX_PAGE_SIZE,
      skip: MAX_PAGE_SIZE * pageIndex,
    };
    const { portfolioStates } = await sdk.Portfolios(
      variables,
      getSubgraphConfig(chainId, apiKey),
    );
    if (portfolioStates.length > 0) {
      portfolios.push(...portfolioStates);
      pageIndex++;
    } else {
      console.log("Pagination ended in pageIndex #", pageIndex);
      fetchedAll = true;
    }
  }
  return portfolios.map((x) => parsePartyPortfolio(x));
}
/////////////////////////////////////////
// Finding a user's party share
/////////////////////////////////////////
export async function getUserPartyShare(
  chainId: number,
  apiKey: string | null,
  partyAddress: string,
  userAddress: string,
) {
  const variables: UserPartySharesQueryVariables = {
    id: `${partyAddress}/${userAddress}`,
  };
  const { investment } = await sdk.UserPartyShares(
    variables,
    getSubgraphConfig(chainId, apiKey),
  );
  return investment ? (investment.shares as string) : null;
}
