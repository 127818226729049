import { env } from "~/env.mjs";

// App base url
export const baseUrl_App =
  env.NEXT_PUBLIC_APP_ENV === "local"
    ? "http://localhost:3022"
    : env.NEXT_PUBLIC_APP_ENV === "prod"
    ? "https://app.party.finance"
    : process.env.VERCEL_URL || "";

// Network
export const supportedChainIds: number[] =
  (env.NEXT_PUBLIC_SUPPORTED_CHAIN_IDS ?? "")
    .split(",")
    .map((n) => Number(n)) || [];

// Platform Address
export const platformFee = 50; // 50 bps -> 0.5%

// PubNub - Chat SDK
export const pubnubPublishKey =
  env.NEXT_PUBLIC_APP_ENV === "prod"
    ? "pub-c-e6d02001-9d60-49cc-b45a-79b81c8cc637"
    : "pub-c-949ede98-6cb2-4ac4-be8f-7fec3aaebe94";
export const pubnubSubscribeKey =
  env.NEXT_PUBLIC_APP_ENV === "prod"
    ? "sub-c-73802a2a-c9b9-11ec-8ef5-82b465a2b170"
    : "sub-c-670b5ee6-c8f5-11ec-b93e-fed9c56767c0";

// PartyWars Rules
export const pWarsStartTimestamp = 1651795200; // Timestamp for Friday, May 6, 2022 12:00:00 AM
export const pWarsMinTotalDeposit = 150;

// Enable/Disable pPunks Validation
export const pPunksValidationEnabled = false;

// Enable/disable pApes validation
export const pApesValidationEnabled = true;

// Restrictions - Swap a new token
export const minLiquidityTokenUSD = 10000;

// Top Performers min. Value DA (expressed in usd)
export const topPerformersMinValue = 100;

// Governance whitelist
export const governanceWhitelist = [
  "0x7fd0c5bde1e12629cd16f3f57793add7b62ffdc0",
  "0xee98db705e16086c45c96bb685ceec9b2e2bb54d",
];

// End timestamp of legacy contract (Sat Oct 1 2022 14:00:00 GMT+0000).
export const endOfLegacyContract = 1664635010;

// Minimum deposit allowed on parties
export const minPartyDeposit =
  env.NEXT_PUBLIC_APP_ENV === "local" ? "0.1" : "5";
