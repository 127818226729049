import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useColorModeValue,
  type ChakraComponent,
  type FormLabelProps,
  type InputProps,
} from "@chakra-ui/react";

import InputBase from "./Base";

export interface IInputTextProps extends InputProps {
  id: string;
  label?: string;
  helper?: string;
  placeholder?: string;
  error?: string;
  setRef?:
    | React.RefObject<HTMLInputElement>
    | React.RefCallback<HTMLInputElement>;
  leftElementIcon?: React.ReactNode;
  rightElementIcon?: React.ReactNode;
  labelOptions?: FormLabelProps;
}

const InputText: ChakraComponent<"input", IInputTextProps> = ({
  id,
  label,
  helper,
  placeholder,
  error,
  setRef,
  value,
  onBlur,
  onChange,
  leftElementIcon,
  rightElementIcon,
  labelOptions,
  ...rest
}) => {
  return (
    <InputBase
      id={id}
      label={label}
      error={error}
      labelOptions={labelOptions}
      helper={helper}
    >
      <InputGroup>
        {leftElementIcon && (
          <InputLeftElement pointerEvents="none">
            {leftElementIcon}
          </InputLeftElement>
        )}

        <Input
          type="text"
          autoComplete="off"
          ref={setRef}
          id={id}
          value={value || ""}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          bg={useColorModeValue("gray.200", "transparent")}
          _focus={{
            borderColor: "brand.200",
            borderWidth: 1,
          }}
          {...rest}
        />

        {rightElementIcon && (
          <InputRightElement>{rightElementIcon}</InputRightElement>
        )}
      </InputGroup>
    </InputBase>
  );
};

export default InputText;
