import { type FC } from "react";

import { Box, Flex, HStack, Heading, Skeleton } from "@chakra-ui/react";

import { Card } from "~/components/Card";

const AnnouncementsLoader: FC = () => {
  return (
    <Card>
      <Flex
        direction={{ base: "column", md: "row" }}
        gap={4}
        alignItems="center"
        justifyContent={{ base: "center", md: "space-between" }}
      >
        <Skeleton
          width={{
            base: "100px",
            sm: "150px",
            md: "150px",
          }}
          height={{
            base: "100px",
            sm: "150px",
            md: "150px",
          }}
          mx="auto"
          borderRadius={{ base: "xl" }}
        />
        <Flex
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          flex={1}
        >
          <Box>
            <Skeleton height="12px" width="75px" />
          </Box>
          <Heading size="md" my="2">
            <Skeleton height="24px" width="125px" />
          </Heading>
          <Box>
            <Skeleton height="20px" />
            <Skeleton height="20px" my={1} />
            <Skeleton height="20px" />
          </Box>
          <HStack
            marginTop="2"
            spacing="2"
            justifyContent="space-between"
            display="flex"
            alignItems="center"
          >
            <Skeleton height="12px" width="75px" />
          </HStack>
        </Flex>
      </Flex>
    </Card>
  );
};
export default AnnouncementsLoader;
