import { Avatar, Box, Flex, Heading, Link } from "@chakra-ui/react";
import { BiBlock } from "react-icons/bi";

import { formatBlockExplorerLink, shortenHex } from "@partyfinance/core";

import { formatNumber } from "~/utils/number";

import { usePartyHoldingsBalance } from "~/hooks/theGraph";
import { getToken } from "~/modules/tokens";

interface IAllocationConfirmation {
  chainId: number;
  partyAddress: string;
  partyValueDA: number | string;
  mintedShare: number | string;
  deposit: number | string;
  allocation: {
    token: string;
    sellAmountDA: string;
  }[];
}

export default function AllocationConfirmation({
  chainId,
  partyAddress,
  partyValueDA,
  deposit,
  mintedShare,
  allocation,
}: IAllocationConfirmation) {
  const { partyHoldings } = usePartyHoldingsBalance(partyAddress, chainId);
  return (
    <Box textAlign="center">
      <Heading size="sm">
        {`Estimated current Party value: ${formatNumber(
          Number(partyValueDA),
        )} USDC`}
      </Heading>
      <Heading size="sm" mt={2} mb={allocation.length > 0 ? 0 : 4}>
        Your {formatNumber(Number(deposit))} USDC deposit will represent ~
        {mintedShare}% shares of the party
      </Heading>
      {allocation.length > 0 && (
        <>
          <Heading size="sm" mt={6} mb={4}>
            Your deposit will be distributed to buy the following assets:
          </Heading>
          <Box maxW="xs" mx="auto">
            {allocation.map((asset) => {
              const logoURI = getToken(asset.token)?.logoURI;
              const foundAsset =
                partyHoldings &&
                partyHoldings.find((x) => x.address === asset.token);
              return (
                <Flex mb="0.25rem" key={asset.token}>
                  <Link
                    target={"_blank"}
                    rel={"noreferrer"}
                    href={formatBlockExplorerLink("token", [
                      chainId,
                      asset.token,
                    ])}
                  >
                    <Avatar
                      size="xs"
                      src={logoURI}
                      icon={<BiBlock fontSize="22px" color="white" />}
                    />
                  </Link>
                  <Box ml={2}>
                    {foundAsset?.symbol || shortenHex(asset.token)}
                  </Box>
                  <Box ml="auto" fontSize={["md", "sm", "md", "sm", "md"]}>
                    {formatNumber(
                      Math.floor(Number(asset.sellAmountDA) * 100) / 100,
                    )}{" "}
                    USDC
                  </Box>
                </Flex>
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
}
