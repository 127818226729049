/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, type ReactNode } from "react";

import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  chakra,
  useColorModeValue,
  type TableProps,
} from "@chakra-ui/react";
import { BiFirstPage, BiLastPage } from "react-icons/bi";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { usePagination, useSortBy, useTable } from "react-table";

import { Jazzicon } from "~/components/Icons";

interface ISortableTableProps extends TableProps {
  data: Array<any>;
  columns: Array<any>;
  recordsPerPage?: number;
  CustomFooter?: ReactNode | null;
  showFooter?: boolean;
}

const SortableTable = ({
  data,
  columns,
  recordsPerPage,
  CustomFooter,
  showFooter = true,
  ...tableProps
}: ISortableTableProps) => {
  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex },
  } = useTable(
    {
      data,
      columns,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    if (recordsPerPage) {
      setPageSize(recordsPerPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Table
        colorScheme={useColorModeValue("gray.300", "gray.800")}
        {...tableProps}
      >
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <chakra.span pl="4">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <TriangleDownIcon aria-label="sorted descending" />
                      ) : (
                        <TriangleUpIcon aria-label="sorted ascending" />
                      )
                    ) : null}
                  </chakra.span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <Td {...cell.getCellProps()}>
                    {/* @ts-expect-error `isBlockie` is defined */}
                    {cell.column.isBlockie ? (
                      <Flex
                        borderRadius="50%"
                        overflow="hidden"
                        width="48px"
                        height="48px"
                      >
                        <Jazzicon account={cell.value as string} />
                      </Flex>
                    ) : // @ts-expect-error `isImage` is defined
                    cell.column.isImage ? (
                      <Avatar src={cell.value as string} />
                    ) : (
                      cell.render("Cell")
                    )}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {!showFooter ? null : CustomFooter ? (
        CustomFooter
      ) : (
        <Flex width={"fit-content"} mx={"auto"} py={4}>
          <Button
            onClick={() => gotoPage(0)}
            leftIcon={<BiFirstPage />}
            disabled={!canPreviousPage}
            my={"auto"}
            px={0}
            width={"fit-content"}
            height={"fit-content"}
            bg={"transparent"}
            _hover={{
              bg: "transparent",
            }}
          />
          <Button
            onClick={() => previousPage()}
            leftIcon={<GrFormPrevious />}
            disabled={!canPreviousPage}
            my={"auto"}
            px={0}
            width={"fit-content"}
            height={"fit-content"}
            bg={"transparent"}
            _focus={{ boxShadow: "none" }}
            _hover={{ bg: "transparent" }}
          />
          <Box my={"auto"} height={"fit-content"}>
            <Text>
              {pageIndex + 1} of {pageCount}
            </Text>
          </Box>
          <Button
            onClick={() => nextPage()}
            leftIcon={<GrFormNext />}
            disabled={!canNextPage}
            my={"auto"}
            px={0}
            width={"fit-content"}
            height={"fit-content"}
            bg={"transparent"}
            _focus={{ boxShadow: "none" }}
            _hover={{
              bg: "transparent",
            }}
          />
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            leftIcon={<BiLastPage />}
            disabled={!canNextPage}
            my={"auto"}
            px={0}
            width={"fit-content"}
            height={"fit-content"}
            bg={"transparent"}
            _focus={{ boxShadow: "none" }}
            _hover={{
              bg: "transparent",
            }}
          />
        </Flex>
      )}
    </>
  );
};

export default SortableTable;
