export type Block = {
  number: number;
  timestamp: number;
  hash: string;
};

export type Holding = {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  balance: string; // balance expresed with decimals (ex. "0.45" USDC)
};

export type JoinRequest = {
  user: string;
  requestedSince: number;
};

export enum PartyEventEnum {
  PartyCreated = "PartyCreated",
  PartyClosed = "PartyClosed",
  MemberJoined = "MemberJoined",
  MemberKicked = "MemberKicked",
  MemberLeft = "MemberLeft",
  TokenSwap = "TokenSwap",
  Deposit = "Deposit",
  Withdraw = "Withdraw",
}

export type PartyActivity = {
  id: string;
  timestamp: number;
  txHash: string;
  event: PartyEventEnum;
  incomingAssets: {
    asset: string;
    symbol: string;
    decimals: number;
    amount: string;
  }[];
  outcomingAssets: {
    asset: string;
    symbol: string;
    decimals: number;
    amount: string;
  }[];
  incomingShares: string;
  outcomingShares: string;
  user: string;
  isOwner: boolean;
};

export type PartyMemberState = {
  timestamp: number;
  shares: number;
};

export enum TokenGateTypeEnum {
  ERC20 = "ERC20",
  ERC721 = "ERC721",
}

export type TokenGateInfo = {
  address: string;
  name: string;
  symbol: string;
  type: TokenGateTypeEnum;
  amount: number;
};

export interface TokenGateInfoRes extends TokenGateInfo {
  decimals?: number;
}

type Asset = {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
};

export type Transaction = {
  action: {
    name: string;
    txHash: string;
  };
  totalValue: number;
  sellToken: Asset;
  buyToken: Asset;
  soldAmount: string;
  boughtAmount: string;
  timestamp: number;
};

export type PartyRoles = {
  isCreator: boolean;
  isManager: boolean;
  isMember: boolean;
};

export interface PartyMember {
  address: string;
  joinedSince: number;
  holdings: number;
  weight: number;
}

export type UserParty = {
  chainId: number;
  address: string;
  name: string;
  img: string;
  isClosed: boolean;
};
export type UserDeposit = {
  timestamp: number;
  value: string;
  blockNumber: number;
};
export type UserWithdrawal = {
  timestamp: number;
  redeemedAmount: string;
  blockNumber: number;
};

export interface IPartyInfo {
  inception: number;
  address: string;
  name: string;
  bio: string;
  img: string;
  model: string;
  purpose: string;
  membersCount: number;
  isPublic: boolean;
  isClosed: boolean;
  minDeposit: number;
  maxDeposit: number;
  denominationAsset: {
    address: string;
    decimals: number;
    symbol: string;
  };
  chainId: number;
  owner: string;
}

export type PublicParty = {
  inception: number;
  address: string;
  name: string;
  img: string;
  model: string;
  purpose: string;
  minDeposit: number;
  maxDeposit: number;
  membersCount: number;
  denominationAsset: {
    decimals: number;
    id: string;
    symbol: string;
  };
  owner: string;
  chainId: number;
};

export enum PartySortEnum {
  Name = "name",
  Inception = "inception",
  Members = "membersCount",
  LastActivity = "lastActivity",
}
export enum PartySortDirection {
  Asc = "asc",
  Desc = "desc",
}

export type PartyPortfolioHoldings = {
  timestamp: number;
  asset: {
    address: string;
    symbol: string;
    decimals: number;
  };
  amount: string;
};

export type PartyPortfolioForChart = {
  holdings: PartyPortfolioHoldings[];
  timestamp: number;
};

export type PartyShareForChart = {
  timestamp: number;
  totalSupply: number;
};

export type PorfolioSharesSnapshot = {
  portfolio: PartyPortfolioForChart | null;
  share: PartyShareForChart | null;
};

export type PorfolioSharesHistory = {
  portfolioHistory: PartyPortfolioForChart[];
  sharesHistory: PartyShareForChart[];
  inception: number;
};

export type PartyPortfolio = {
  networkId: number;
  partySlug: string;
  party: {
    id: string;
    shares: {
      totalSupply: string;
    };
  };
  value: string;
  withUncalculatedPrices: boolean;
  timestamp: number;
  blockNumber: number;
  holdings: {
    asset: {
      id: string;
      networkId: number;
      slug: string;
      name: string;
      symbol: string;
      decimals: number;
      firstSeen: number;
    };
    amount: string;
    price: string;
  }[];
};

export type PartyInvestmentCost = {
  networkId: number;
  partySlug: string;
  partyAddress: string;
  depositCount: number;
  totalDeposits: string;
  withdrawalCount: number;
  totalWithdrawals: string;
  latestTimestamp: number;
  latestBlockNumber: number;
};
export type PartyUncalculatedWithdrawal = {
  networkId: number;
  partySlug: string;
  partyAddress: string;
  userAddress: string;
  entityId: string;
  redeemedAmount: string;
  fee: string;
  redeemedAmounts: {
    asset: {
      address: string;
      networkId: number;
      slug: string;
      name: string;
      symbol: string;
      decimals: number;
      firstSeen: number;
    };
    amount: string;
    price: string | null;
  }[];
  withLiquidation: boolean;
  timestamp: number;
  blockNumber: number;
};
