/* eslint-disable @typescript-eslint/no-explicit-any */
import { Area, AreaChart, ResponsiveContainer } from "recharts";

export default function ChartPreview({ data }: { data: any[] }) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={data}
        style={{
          background: "transparent",
        }}
        margin={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <defs>
          <linearGradient id="colorHoldings" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#1fd848" stopOpacity={0.8} />
            <stop offset="100%" stopColor="#ffffff" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="holdings"
          stroke="#1fd848"
          fillOpacity={1}
          fill="url(#colorHoldings)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
