import { isAddress } from "@ethersproject/address";
import { BigNumber } from "@ethersproject/bignumber";
import { z } from "zod";

import { getUSDCAddress } from "./constants";

export const isBigNumberString = z.string().refine((val) => {
  try {
    return BigNumber.isBigNumber(BigNumber.from(val));
  } catch (error) {
    return false;
  }
});
export const isEthAddress = z
  .string()
  .transform((x) => x.toLowerCase())
  .refine((x) => isAddress(x), "Not a valid address");

export const isEthArrayAddress = z
  .array(
    z.string().refine((x) => {
      const wallets = x.split(",");
      for (const wallet of wallets) {
        if (!isAddress(wallet)) {
          return false;
        }
      }
      return true;
    }, "Invalid eth address found"),
  )
  .min(1);

export const isDateFromTimestamp = z
  .number()
  .int()
  .transform((ts) => new Date(ts));

export const getPartySlug = z
  .object({
    networkId: z.number().int().positive(),
    party: isEthAddress,
  })
  .transform((x) => {
    return {
      networkId: x.networkId,
      party: x.party,
      partySlug: `${x.networkId}-${x.party}`,
    };
  });

export const networkSchema = z.string().transform((x) => {
  const networkId = parseInt(x);
  return {
    networkId,
    usdcAddress: getUSDCAddress(networkId),
  };
});

export const partySchema = z
  .object({
    network: networkSchema,
    party: z.string().transform((x) => x.toLowerCase()),
    jobType: z.enum(["financial", "chart"]).optional(),
  })
  .transform((x) => {
    const partySlug = `${x.network.networkId}-${x.party}`;
    const jobName = x.jobType ? `${x.jobType}-${partySlug}` : undefined;
    return {
      networkId: x.network.networkId,
      usdcAddress: x.network.usdcAddress,
      partyAddress: x.party,
      partySlug,
      jobName,
    };
  });

export const userWithPartySchema = z
  .object({
    networkId: z.string(),
    user: z.string(),
    party: z.string(),
  })
  .transform((x) => {
    const networkId = parseInt(x.networkId);
    return {
      networkId,
      usdcAddress: getUSDCAddress(networkId),
      userAddress: x.user,
      partyAddress: x.party,
      userPartySlug: `${networkId}-${x.party}-${x.user}`,
      partySlug: `${networkId}-${x.party}`,
    };
  });

export const latestAssetPricesSchema = z
  .object({
    networkId: z.number(),
    assets: isEthArrayAddress,
  })
  .transform((x) => {
    return {
      networkId: x.networkId,
      assets: x.assets,
      slugs: x.assets.map((asset) => `${x.networkId}-${asset}`),
    };
  });

export type LatestAssetPricesInput = z.input<typeof latestAssetPricesSchema>;
