import dynamic from "next/dynamic";

import {
  Box,
  Flex,
  Heading,
  Skeleton,
  useColorModeValue,
} from "@chakra-ui/react";

// import { Chart } from '~/components/Charts'
import { PeriodEnum } from "@partyfinance/core";

import { Card } from "~/components/Card";

const Chart = dynamic(() => import("~/components/Charts/Chart"), {
  ssr: false,
});

const PartyDetailLoader = (): JSX.Element => {
  const borderColor = useColorModeValue("gray.300", "gray.700");
  return (
    <Flex
      mt={4}
      flexWrap="wrap"
      p="1rem"
      borderWidth="2px"
      borderColor={borderColor}
      borderRadius={"10px"}
    >
      <Card mb={3}>
        <Flex
          width="100%"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          direction="column"
          gap={3}
        >
          <Skeleton height="24px" width="100%" />
          <Skeleton height="24px" width="100%" />
        </Flex>
      </Card>
      <Flex
        width={["100%", "100%", "100%", "15%"]}
        flexWrap={["nowrap", "nowrap", "nowrap", "wrap"]}
        height={"fit-content"}
        mt={[0, 0, 0, "1.5rem"]}
        p={"0.5rem"}
      >
        <Box
          pb={"1rem"}
          width={["fit-content", "fit-content", "fit-content", "100%"]}
          height="fit-content"
          mx={["auto", "auto", "auto", "right"]}
        >
          <Heading size="xs" color={"#6c737b"}>
            Balance
          </Heading>
          <Skeleton height="16px" width="100px" mt={2} />
        </Box>
        <Box
          pb={"1rem"}
          width={["fit-content", "fit-content", "fit-content", "100%"]}
          height="fit-content"
          mx={["auto", "auto", "auto", "right"]}
        >
          <Heading size="xs" color={"#6c737b"}>
            ROI
          </Heading>
          <Skeleton height="16px" width="100px" mt={2} />
        </Box>
        <Box
          pb={"1rem"}
          width={["fit-content", "fit-content", "fit-content", "100%"]}
          height="fit-content"
          mx={["auto", "auto", "auto", "right"]}
        >
          <Heading size="xs" color={"#6c737b"}>
            Model
          </Heading>
          <Skeleton height="16px" width="100px" mt={2} />
        </Box>
        <Box
          pb={"1rem"}
          width={["fit-content", "fit-content", "fit-content", "100%"]}
          height="fit-content"
          mx={["auto", "auto", "auto", "right"]}
        >
          <Heading size="xs" color={"#6c737b"}>
            Members
          </Heading>
          <Skeleton height="16px" width="100px" mt={2} />
        </Box>
        <Box
          pb={"1rem"}
          width={["fit-content", "fit-content", "fit-content", "100%"]}
          height="fit-content"
          mx={["auto", "auto", "auto", "right"]}
        >
          <Heading size="xs" color={"#6c737b"}>
            Access
          </Heading>
          <Skeleton height="16px" width="100px" mt={2} />
        </Box>
      </Flex>
      <Flex
        width={["100%", "100%", "100%", "85%"]}
        flexWrap={["wrap", "wrap", "wrap", "nowrap"]}
      >
        <Box
          width={["100%", "100%", "100%", "calc(75% - 0.5rem)"]}
          mr={[0, 0, "0.5rem"]}
        >
          <Card>
            <Box
              height="311px"
              width={["100%"]}
              borderRadius="md"
              overflow="hidden"
            >
              <Chart data={undefined} period={PeriodEnum.LastDay} />
            </Box>
          </Card>
        </Box>
        <Box width={["100%", "100%", "100%", "25%"]}>
          <Card mt={[6, 6, 6, 0]}>
            <Box minH={["fit-content", "fit-content", "fit-content", "311px"]}>
              <Heading size="md" mb={4}>
                Holdings
              </Heading>
              {[0, 0, 0, 0, 0, 0].map((_, i) => {
                return (
                  <Flex mb={2} key={i} width="full">
                    <Skeleton height="24px" width="24px" borderRadius="full" />
                    <Skeleton ml={4} height="16px" width="75px" />
                    <Skeleton ml="auto" height="16px" width="50px" />
                  </Flex>
                );
              })}
            </Box>
          </Card>
        </Box>
      </Flex>
    </Flex>
  );
};

export default PartyDetailLoader;
