/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";

import { type ContractTransaction } from "@ethersproject/contracts";

import { api } from "~/utils/api";

import { usePartyContext } from "~/contexts/PartyContext";
import { useTransactionContext } from "~/contexts/TransactionContext";
import { usePlatformCheck } from "~/hooks/platform";
import { handleCustomError } from "~/modules/party/errors";
import { TransactionActions } from "~/modules/party/types";

import { usePartyContract } from ".";

const steps = [
  {
    label: "Kick member",
    content: "Confirm the kicking member action in your wallet",
  },
  {
    label: "Party confirmation",
    content: "Please wait until the kicking member action is confirmed",
    loading: true,
    showGame: true,
  },
];

const usePartyKick = (partyAddress: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { chainId } = usePartyContext();
  const { checkWallet } = usePlatformCheck();
  const partyContract = usePartyContract(partyAddress);
  const {
    addTransaction,
    updateTransaction,
    openConfirmationWithSteps,
    closeConfirmationWithSteps,
    setStep,
    setErrorMsg,
  } = useTransactionContext();
  // Api hooks
  const signatureKick = api.signature.kick.useMutation();

  const kickMember = async (kickingMember: string, onSuccess?: () => void) => {
    const account = checkWallet();
    if (!account || !partyContract) return null;
    setLoading(true);
    openConfirmationWithSteps(steps);
    let txHash = "";

    try {
      // 1) Validating
      const isOwner = await partyContract.managers(account);
      if (!isOwner) {
        throw Error(`Only Party Owner is allowed to kick`);
      }
      if (!kickingMember) {
        throw Error(`There are no members to be kicked`);
      }

      // 2) Get allocation signature
      const data = await signatureKick.mutateAsync({
        partyAddress: partyContract.address,
        memberToKick: kickingMember,
        chainId: chainId,
      });

      // 3) Kick the party member
      const liquidateMemberFunds = true;
      const kickTx: ContractTransaction = await partyContract.kickMember(
        kickingMember,
        data.allocationArgs,
        data.signature,
        liquidateMemberFunds,
      );
      txHash = kickTx.hash;
      addTransaction(TransactionActions.kickMember, txHash);

      setStep(1);
      await kickTx.wait(2);
      updateTransaction(txHash, true, true);
      txHash = "";

      if (onSuccess) {
        onSuccess();
      }

      closeConfirmationWithSteps();
    } catch (err: any) {
      console.log(err);
      const customError = handleCustomError(err);
      setErrorMsg(customError.message);
      if (txHash) {
        updateTransaction(txHash, false, true);
      }
    }
    setLoading(false);
  };

  return {
    kickMember,
    loadingKick: loading,
  };
};

export default usePartyKick;
