import { type ReactNode } from "react";

import {
  connectorsForWallets,
  getDefaultWallets,
} from "@rainbow-me/rainbowkit";
import { ledgerWallet, trustWallet } from "@rainbow-me/rainbowkit/wallets";
import { WagmiConfig, configureChains, createClient } from "wagmi";
import { goerli, mainnet, optimism, polygon } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";

import { supportedChainIds } from "~/config";

export const supportedChains = [mainnet, optimism, polygon, goerli].filter(
  (x) => supportedChainIds.includes(x.id),
);

const { chains: _chains, provider } = configureChains(
  [...supportedChains],
  [publicProvider()],
);

export const chains = _chains;

const PROJECT_ID = "7efd2dbeea486ff295007e27a11dd4bf";

const { wallets } = getDefaultWallets({
  appName: "PartyFinance",
  chains,
  projectId: PROJECT_ID,
});

const connectors = connectorsForWallets([
  ...wallets,
  {
    groupName: "Other",
    wallets: [
      trustWallet({ chains, projectId: PROJECT_ID }),
      ledgerWallet({ chains, projectId: PROJECT_ID }),
    ],
  },
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

export default function WagmiProvider({ children }: { children: ReactNode }) {
  return <WagmiConfig client={wagmiClient}>{children}</WagmiConfig>;
}
