import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import ContentLoader from "react-content-loader";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { FormatDate, PeriodEnum, formatDate } from "@partyfinance/core";

import { formatNumber } from "~/utils/number";

import { LoaderWithFeedback } from "~/components/Loaders";

import { type ChartData } from "~/modules/party/types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomTooltip = ({ active, payload, valuePrefix, valueSuffix }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (active && payload && payload.length) {
    return (
      <Box py={2} px={0} bg={"gray.600"} borderRadius="md">
        <Text color={"gray.400"} px={4}>
          {formatDate(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            new Date(payload[0].payload.date as Date).getTime() / 1000,
            FormatDate.lll,
          )}
        </Text>
        <Divider my={1} />
        <Text color={"white"} fontSize="md" px={4}>
          {valuePrefix}
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
          {formatNumber(payload[0].value as number)}
          {valueSuffix}
        </Text>
      </Box>
    );
  }
  return null;
};

interface IChart {
  data?: ChartData[];
  error?: string;
  period: PeriodEnum;
  loading?: boolean;
  valuePrefix?: string;
  valueSuffix?: string;
  yAxisOrientation?: "left" | "right";
}

const Chart = ({
  data,
  error,
  loading = false,
  period,
  valuePrefix = "$",
  valueSuffix = "",
  yAxisOrientation = "left",
}: IChart) => {
  const bg = useColorModeValue("white", "#1c2736");
  const loaderBg = useColorModeValue("#edf2f7", "#2d3747d9");
  const cartesianStroke = useColorModeValue("#d2d6db", "#3a4558");
  return (
    <>
      {loading ? (
        <Stack overflow="hidden" bg={bg}>
          <ContentLoader
            uniqueKey="partyChartLoader"
            viewBox="0 0 1200 600"
            animate={true}
            backgroundColor={loaderBg}
            foregroundColor={bg}
            speed={2.2}
            gradientDirection="top-bottom"
            interval={0.5}
            transform="scale(1.76)"
            height="320px"
            width="100%"
          >
            <path d="M0 600h1200V356.136a7.13 7.13 0 0 0-12.172-5.042c-6.722 6.722-17.62 6.722-24.343 0l-24.635-24.636c-7.03-7.03-18.427-7.03-25.456 0l-31.516 31.516c-7.03 7.03-18.426 7.03-25.456 0l-10.057-10.057c-4.967-4.968-13.022-4.968-17.99 0-4.967 4.968-13.021 4.968-17.989 0l-43.199-43.2c-4.237-3.794-9.417-3.794-15.54 0a26.38 26.38 0 0 0-1.78 1.205c-7.166 5.295-17.126 4.551-23.426-1.749l-13.407-13.407c-5.1-5.1-13.367-5.1-18.467 0-5.1 5.1-13.368 5.1-18.467 0l-34.573-34.573c-7.03-7.03-18.427-7.03-25.456 0l-70.001 70.001c-7.03 7.03-18.427 7.03-25.456 0L588.839 194.42c-7.03-7.03-18.427-7.03-25.456 0l-1.24 1.24c-7.03 7.03-18.427 7.03-25.456 0l-9.473-9.471c-7.029-7.03-18.426-7.03-25.455 0l-74.196 74.196c-7.03 7.029-18.427 7.029-25.456 0l-9.082-9.082c-7.03-7.03-18.426-7.03-25.456 0l-92.881 92.882c-7.03 7.03-18.427 7.03-25.456 0l-25.472-25.472c-7.03-7.03-18.426-7.03-25.456 0l-41.298 41.298c-5.905 5.905-15.478 5.905-21.383 0-5.905-5.905-15.479-5.905-21.383 0l-18.706 18.706c-4.426 4.426-11.602 4.426-16.028 0-4.426-4.426-11.602-4.426-16.028 0L41.76 400.435c-7.03 7.029-18.427 7.029-25.456 0l-1.788-1.788A8.503 8.503 0 0 0 0 404.659V600Z" />
          </ContentLoader>
          <Box h={0}>
            <LoaderWithFeedback delay={10000}>
              <Text color="gray.500" textAlign="center" pos="relative" top={-8}>
                This is taking some time... Please wait 😊
              </Text>
            </LoaderWithFeedback>
          </Box>
        </Stack>
      ) : error || !data || data.length === 0 ? (
        <Flex
          bg={bg}
          height="320px"
          width="100%"
          alignItems="center"
          justifyContent="center"
          gap={2}
        >
          {error && <CloseIcon color="red.400" />}
          <Text color="gray.400">No available data</Text>
        </Flex>
      ) : (
        <ResponsiveContainer>
          <AreaChart
            data={data}
            style={{
              background: bg,
            }}
            margin={{
              top: 0,
              left: -18,
              bottom: 0,
              right: 0,
            }}
          >
            <defs>
              <linearGradient id="colorHoldings" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#1fd848" stopOpacity={0.6} />
                <stop offset="100%" stopColor="#fff" stopOpacity={0.12} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="date"
              tick={{ fontSize: 14 }}
              tickFormatter={(tickItem) => {
                return formatDate(
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  new Date(tickItem).getTime() / 1000,
                  period === PeriodEnum.LastDay
                    ? FormatDate.LT
                    : FormatDate.Long,
                );
              }}
              // interval={Math.round(data.length / 4)}
              minTickGap={Math.round(data.length / 4)}
            />
            <YAxis
              type="number"
              orientation={yAxisOrientation}
              dataKey="holdings"
              tick={{ fontSize: 14 }}
              tickFormatter={(value) =>
                new Intl.NumberFormat("en", {
                  notation: "compact",
                  compactDisplay: "short",
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                }).format(value)
              }
              domain={[
                (dataMin: number) => Math.floor(dataMin * 0.99 * 1e6) / 1e6,
                (dataMax: number) => Math.round(dataMax * 1.01 * 1e6) / 1e6,
              ]}
            />
            <CartesianGrid stroke={cartesianStroke} strokeDasharray="1 3" />
            <Tooltip
              content={
                <CustomTooltip
                  valuePrefix={valuePrefix}
                  valueSuffix={valueSuffix}
                />
              }
            />
            <Area
              type="linear"
              dataKey="holdings"
              stroke="#1fd848"
              fillOpacity={1}
              fill="url(#colorHoldings)"
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default Chart;
